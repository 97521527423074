import {
  ANSWER_QUESTION_QUIZZ,
  GO_TO_END,
  GO_TO_MARKETING_STEP,
  QUIZZ_CHOOSE_GIFT,
  QUIZZ_NEXT_QUESTION,
  QUIZZ_RECOMPUTE_SCORE,
  QUIZZ_TIME_ELAPSED,
  SET_QUIZZ,
  SET_QUIZZ_GIFTS,
  SET_QUIZZ_QUESTIONS,
  SET_QUIZZ_STEP,
  SET_SCORE,
  START_QUIZZ,
} from '../actions/quizz'
import Quizz, { QuizzGift, QuizzQuestion } from '@tootsweet/model/lottery/Quizz'

export interface QuizzI {
  quizz: Quizz | null
  step: number
  questions: QuizzQuestion[]
  currentQuestion: QuizzQuestion | null
  currentQuestionIdx: number
  score: number
  remainingSeconds: number
  gifts: QuizzGift[]
  chosenGift?: QuizzGift
  hasReachedTerminalScore: boolean
}

export const QUIZZ_STEP_HOME = 0
export const QUIZZ_STEP_PLAY = 1
export const QUIZZ_STEP_TRIPADVISOR = 2
export const QUIZZ_STEP_END = 3
export const QUIZZ_STEP_ML_INTRO = 4
export const QUIZZ_STEP_ML_GAME = 5

export const initialState: QuizzI = {
  quizz: null,
  step: QUIZZ_STEP_HOME,
  score: 0,
  questions: [],
  currentQuestionIdx: -1,
  currentQuestion: null,
  remainingSeconds: 0,
  gifts: [],
  hasReachedTerminalScore: false,
}

export const quizz = (state = initialState, action: any) => {
  switch (action.type) {
    case START_QUIZZ:
      return {
        ...state,
        step: QUIZZ_STEP_PLAY,
      }

    case SET_QUIZZ:
      return {
        ...state,
        quizz: action.quizz,
      }

    case SET_QUIZZ_QUESTIONS:
      const hasQuestions = action.questions && action.questions.length > 0
      return {
        ...state,
        questions: action.questions,
        currentQuestion: hasQuestions ? action.questions[0] : null,
        currentQuestionIdx: hasQuestions ? 0 : -1,
        score: 0,
      }

    case ANSWER_QUESTION_QUIZZ:
      return answer(state, action.index)

    case QUIZZ_TIME_ELAPSED:
      return answer(state)

    case QUIZZ_NEXT_QUESTION:
      return nextQuestion(state)

    case SET_QUIZZ_GIFTS:
      return {
        ...state,
        gifts: action.gifts,
      }

    case GO_TO_MARKETING_STEP:
      return {
        ...state,
        step: QUIZZ_STEP_END,
      }

    case GO_TO_END:
      return {
        ...state,
        step: QUIZZ_STEP_END,
      }

    case QUIZZ_CHOOSE_GIFT:
      return {
        ...state,
        chosenGift: action.gift,
      }

    case QUIZZ_RECOMPUTE_SCORE:
      return recomputeScore(state)

    case SET_QUIZZ_STEP:
      return {
        ...state,
        step: action.step,
      }

    case SET_SCORE:
      return {
        ...state,
        score: action.score,
      }
  }
  return state
}

const answer = (state: QuizzI, index?: number) => {
  const newState = { ...state }

  if (state.quizz?.multi) {
    if (newState.currentQuestion && index !== undefined && index !== null) {
      newState.currentQuestion = {
        ...newState.currentQuestion,
      }

      if (!newState.currentQuestion.clientSideAnswersIdx) {
        newState.currentQuestion.clientSideAnswersIdx = []
      }

      const existingIdx =
        newState.currentQuestion.clientSideAnswersIdx.findIndex(
          (i) => i === index
        )

      if (existingIdx === -1) {
        newState.currentQuestion.clientSideAnswersIdx.push(index)
      } else {
        newState.currentQuestion.clientSideAnswersIdx.splice(existingIdx, 1)
      }
    }
  } else {
    if (index !== undefined) {
      let isCorrect =
        state.currentQuestion && index === state.currentQuestion.answerIdx
      newState.score = isCorrect ? state.score + 1 : state.score

      //@ts-ignore
      if (
        newState.quizz?.winScore &&
        newState.quizz.winScore > 0 &&
        newState.score === newState.quizz.winScore
      ) {
        newState.hasReachedTerminalScore = true
      }
    }
  }

  return newState
}

export const computeScoreForMultiAnswers = (
  currentQuestion?: QuizzQuestion | null
) => {
  if (!currentQuestion) {
    return 0
  }
  if (!currentQuestion.clientSideAnswersIdx || !currentQuestion.answersIdx) {
    return 0
  }
  let result = 0
  currentQuestion.answersIdx.forEach((answerIdx) => {
    result += currentQuestion.clientSideAnswersIdx?.includes(answerIdx) ? 1 : 0
  })
  return result
}

const recomputeScore = (state: QuizzI) => {
  if (state.quizz?.multi) {
    const score = computeScoreForMultiAnswers(state.currentQuestion)
    const newState = { ...state }
    newState.score += score
    return newState
  }
  return state
}

const nextQuestion = (state: QuizzI) => {
  const newState = { ...state }

  if (state.currentQuestionIdx < state.questions.length - 1) {
    newState.currentQuestion = state.questions[state.currentQuestionIdx + 1]
    newState.currentQuestionIdx = state.currentQuestionIdx + 1
    return newState
  }

  newState.step = QUIZZ_STEP_END
  return newState
}

export default quizz
