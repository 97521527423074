import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WheelButton from './cta/WheelButton'
import WheelAnalyticsManager from '../analytics/WheelAnalyticsManager'
import {
  incrementCTAClickCount,
  nextLotteryStep,
  setLotteryLoading,
  setShowTuto,
} from '../redux/actions/lottery'
import TSConstants from '../utils/TSConstants'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import { tt } from '../i18n'
import { FunctionComponent } from 'react'
import { lotteryConfigSelector } from '../redux/selectors/lottery'

interface Props {
  googlePlaceId: string | undefined | null
  isFixedBottom?: boolean
  img?: string
  isWhite?: boolean
  textOnLeft?: boolean
  preventNextStep: boolean
  step: LotteryV2Step | null
  isAfterWheel?: boolean

  showTuto?: boolean
  onClick?: () => void

  style?: any | null
}

const GoogleRatingButton: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch()

  const config = useSelector(lotteryConfigSelector)

  const {
    googlePlaceId,
    img,
    isFixedBottom,
    isWhite,
    textOnLeft,
    isAfterWheel,
    onClick,
  } = props
  let link = `https://search.google.com/local/writereview?placeid=${
    googlePlaceId || (config ? config.googlePlaceId : '')
  }`
  if (
    googlePlaceId &&
    (googlePlaceId.includes('https:') || googlePlaceId.includes('http:'))
  ) {
    link = googlePlaceId
  }

  const onClickPostTutorial = () => {
    WheelAnalyticsManager.googleClicked()

    dispatch(setLotteryLoading(true, props.step || undefined))
    dispatch(setShowTuto(true, false, false, false, false))

    setTimeout(() => {
      dispatch(incrementCTAClickCount())
      if (!props.preventNextStep) {
        dispatch(nextLotteryStep())
      }
      dispatch(setLotteryLoading(false))
    }, TSConstants.LOADER_TIMEOUT_SHORT)
  }

  return (
    <WheelButton
      text={
        isAfterWheel
          ? tt('cta_google_after_wheel')
          : props.step?.alternateWording
          ? tt('cta_google_before_wheel_alternate')
          : tt('cta_google_before_wheel')
      }
      className="w-btn-google"
      onClick={onClick || onClickPostTutorial}
      href={link}
      isFixedBottom={isFixedBottom}
      isWhite={isWhite}
      img={img}
      textOnLeft={textOnLeft}
      noReferrer={true}
      style={props.style}
    />
  )
}

export default GoogleRatingButton
