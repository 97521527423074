import React from 'react'
import { SLOT_MACHINE_SPEED, TIMER_INTERVAL } from './constants'
import { TSUtils } from '../../../utils/TSUtils'

interface SpinnerProps {
  timer: number
  delay: number
  initialIdx: number
  playCounter: number
  shouldWin: boolean
  winningIdx: number
  onFinished: () => void
  marginRight?: boolean
  marginLeft?: boolean
  initialDestIdx: number
  sizeFactor: number
  audioSource: string
  successAudioSrc: string

  /**
   * Ribbon image height
   */
  smH: number

  /**
   * Ribbon image width
   */
  smW: number

  /**
   * Item height
   */
  smIH: number
  smNItems: number

  bgUrl: string

  targetWidth?: number
}

interface SpinnerState {
  destIdx: number
  position: number
  initialPosition: number
  timeRemaining: number
  shrinkFactor: number

  smH: number
  smW: number
  smIH: number
}

export default class Spinner extends React.Component<
  SpinnerProps,
  SpinnerState
> {
  private timer: any | null = null
  private audio: HTMLAudioElement | null = null
  private successAudio: HTMLAudioElement | null = null

  private position = 0
  private remaining = 0
  private speed = 0
  private stopNextTime = false
  private stop = false

  constructor(props: SpinnerProps) {
    super(props)
    this.start = this.start.bind(this)
    this.loop = this.loop.bind(this)
    this.computeStartPosition = this.computeStartPosition.bind(this)
    this.computeLogicalIdx = this.computeLogicalIdx.bind(this)
    this.computePadding = this.computePadding.bind(this)

    const targetWidth =
      props.targetWidth || Math.trunc((window.innerWidth * 0.8) / 3)
    const shrinkFactor = this.props.smW / targetWidth

    const startPosition = this.computeStartPosition(shrinkFactor)
    this.position = startPosition

    this.state = {
      timeRemaining: this.props.timer,
      initialPosition: startPosition,
      position: startPosition,
      destIdx: props.initialDestIdx,
      shrinkFactor,
      smH: props.smH / shrinkFactor,
      smW: props.smW / shrinkFactor,
      smIH: props.smIH / shrinkFactor,
    }
  }

  componentDidMount() {
    this.audio = new Audio(this.props.audioSource)
    this.audio.loop = true
    this.successAudio = new Audio(this.props.successAudioSrc)
  }

  componentDidUpdate(prevProps: Readonly<SpinnerProps>) {
    if (prevProps.shouldWin !== this.props.shouldWin) {
      const destIdx = this.props.shouldWin
        ? this.props.winningIdx
        : Math.trunc(TSUtils.getRandomIntInRange(0, this.props.smNItems))
      this.setState({ destIdx })
    }

    if (this.props.playCounter > prevProps.playCounter) {
      this.start()
    }
  }

  private computePadding(shrinkFactor: number) {
    const paddingPc = Math.abs((1 - this.props.sizeFactor) / 2)
    return Math.trunc(paddingPc * (this.props.smIH / shrinkFactor))
  }

  private computeStartPosition(shrinkFactor: number) {
    const padding = this.computePadding(shrinkFactor)
    return (
      this.props.initialIdx * (this.props.smIH / shrinkFactor) * -1 + padding
    )
  }

  private computeLogicalIdx() {
    const posWithoutPadding =
      this.position - this.computePadding(this.state.shrinkFactor)
    return Math.abs(
      Math.trunc(posWithoutPadding / this.state.smIH) % this.props.smNItems
    )
  }

  start() {
    const _this = this

    this.audio?.play()

    setTimeout(() => {
      if (this.timer) {
        clearInterval(this.timer)
      }

      this.remaining = this.props.timer
      this.speed = SLOT_MACHINE_SPEED

      this.timer = setInterval(() => {
        _this.loop()
      }, TIMER_INTERVAL)
    }, this.props.delay)
  }

  loop() {
    if (this.stop) {
      return
    }

    this.remaining -= TIMER_INTERVAL
    const logicalIdx = this.computeLogicalIdx()

    if (this.stopNextTime) {
      this.stop = true
      this.audio?.pause()
      clearInterval(this.timer)
      this.successAudio?.play()

      const padding = this.computePadding(this.state.shrinkFactor)
      const mod = (this.position - padding) % this.state.smIH
      const newPos = this.position - mod

      let delta = this.state.destIdx - logicalIdx

      if (delta < 0) {
        delta += this.props.smNItems
      }

      let position2 = newPos - delta * this.state.smIH

      this.setState({
        position: position2,
      })

      this.props.onFinished()
    } else {
      // normal regime
      if (this.remaining > 0) {
      }
      // slow down regime
      else if (this.remaining <= 0) {
        this.stopNextTime = true
      }

      this.position = this.position - this.speed

      this.setState({
        position: this.position,
      })
    }
  }

  render() {
    const width = Math.round(this.state.smW)
    const height = Math.round(this.state.smH)

    // console.log("")
    // console.log("")
    // console.log("shrinkFactor", shrinkFactor)
    // console.log("")
    // console.log("this.state.smW ", this.state.smW )
    // console.log("this.state.smH ", this.state.smH )
    // console.log("this.state.smIH ", this.state.smIH )
    // console.log("this.props.smNItems ", this.props.smNItems )
    // console.log("baseIconSize", baseIconSize)
    // console.log("width", width)
    // console.log("height", height)

    return (
      <div
        className={`spinner-item icons ${
          this.props.marginRight ? 'spinner-margin-right' : ''
        } ${this.props.marginLeft ? 'spinner-margin-left' : ''}`}
        style={{
          background: `#fff ${this.props.bgUrl} repeat-y`,
          backgroundPosition: `0px ${this.state.position}px`,
          backgroundSize: `${width}px ${height}px`,
          width,
          height: this.props.sizeFactor * this.state.smIH,
        }}
      />
    )
  }
}
