import React, {useEffect, useRef, useState} from 'react'
import {SlotMachineRibbonParams} from './generateImage'
import {getRandomIntInRange, playSound} from './helpers'

interface SpinnerProps {
  duration: number
  playCounter: number
  shouldWin: boolean
  winningIdx: number
  lostIdx: number
  onFinished: (idx: number) => void
  paramz: SlotMachineRibbonParams
  className?: string
  idx: number
}

export const SPINNER_SPEED = 200
export const TIMER_INTERVAL = 50

const positions: any[] = []
const remainings: any[] = []
const stopNextTime: boolean[] = []
const stop: boolean[] = []
const intervalIds: any[] = []
const destIndices: number[] = []
const audioSpins: HTMLAudioElement[] = []
const audioStops: HTMLAudioElement[] = []

export default function Spinner(props: SpinnerProps) {
  const [initDone, setInitDone] = useState(false)

  const spinnerRef = useRef<HTMLDivElement | null>(null)

  function stopTimer() {
    if (intervalIds[props.idx]) {
      clearInterval(intervalIds[props.idx])
      try {
        audioSpins[props.idx].pause()
        audioSpins[props.idx].currentTime = 0
        playSound(audioStops[props.idx])
      } catch (error) {
        console.error(error)
      }
    }
  }

  const loop = () => {
    remainings[props.idx] = remainings[props.idx] - TIMER_INTERVAL

    if (stopNextTime[props.idx]) {
      stop[props.idx] = true
      stopTimer()

      const logicalPosition =
        -positions[props.idx] % (props.paramz.nItems * props.paramz.itemHeight)

      let logicalIndex = -1

      for (let idx = 0; idx < props.paramz.nItems; idx++) {
        const lBound = idx * props.paramz.itemHeight
        const uBound = (idx + 1) * props.paramz.itemHeight - 1
        if (logicalPosition >= lBound && logicalPosition <= uBound) {
          logicalIndex = idx
          break
        }
      }

      let deltaIdx = destIndices[props.idx] - logicalIndex

      if (deltaIdx < 0) {
        deltaIdx += props.paramz.nItems
      }
      updatePosition(
        positions[props.idx] -
          (positions[props.idx] % props.paramz.itemHeight) -
          deltaIdx * props.paramz.itemHeight
      )
      props.onFinished(props.idx)
    } else {
      // normal regime, do nothing
      if (remainings[props.idx] > 0) {
      }
      // slow down regime
      else if (remainings[props.idx] <= 0) {
        stopNextTime[props.idx] = true
      }

      const newPos = positions[props.idx] - SPINNER_SPEED
      updatePosition(newPos)
    }
  }

  const start = () => {
    reset()
    remainings[props.idx] = props.duration
    intervalIds[props.idx] = setInterval(() => {
      loop()
    }, TIMER_INTERVAL)
  }

  const updatePosition = (newPos: number) => {
    positions[props.idx] = newPos
    if (spinnerRef.current) {
      spinnerRef.current.style.backgroundPositionY = `${newPos}px`
    }
  }

  function reset() {
    stopTimer()
    stop[props.idx] = false
    stopNextTime[props.idx] = false
    const initialIdx = getRandomIntInRange(0, props.paramz.nItems)
    const newPos = -initialIdx * props.paramz.itemHeight
    updatePosition(newPos)
  }

  /**
   * When the game's outcome is known, set the destination index accordingly
   */
  useEffect(() => {
    destIndices[props.idx] = props.shouldWin ? props.winningIdx : props.lostIdx
  }, [props.shouldWin, props.winningIdx, props.lostIdx])

  useEffect(() => {
    if (
      !spinnerRef.current ||
      props.idx === undefined ||
      props.idx === null ||
      initDone
    ) {
      return
    }
    reset()
    setInitDone(true)
  }, [props.idx, spinnerRef.current])

  /**
   * Any increase in the play counter starts the spinner
   */
  useEffect(() => {
    if (props.playCounter > 0) {
      start()
      try {
        playSound(audioSpins[props.idx], true)
      } catch (error) {
        console.error(error)
      }
    }
  }, [props.playCounter])

  useEffect(() => {
    audioSpins[props.idx] = new Audio(
      'https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/mp3/sounds/reels.mp3'
    )
    audioSpins[props.idx].loop = true
    audioStops[props.idx] = new Audio(
      'https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/mp3/sounds/success_1.mp3'
    )
  }, [props.idx])

  return (
    <div
      ref={spinnerRef}
      className={props.className}
      style={{
        backgroundRepeat: 'repeat-y',
        backgroundColor: '#fff',
        backgroundImage: `url('${props.paramz.imgDataURL}')`,
        backgroundSize: `${props.paramz.width}px ${props.paramz.imgHeight}px`,
        backgroundPositionX: 0,
        width: props.paramz.width,
        height: props.paramz.itemHeight,
      }}
    />
  )
}
