import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { getStyleForImage, getStyleForImageContainer, ratio } from '../helpers'
import TSConstants from '../../../utils/TSConstants'
import { PROGRESS_BAR_TOP } from '../constants'
import { tt } from '../../../i18n'
import RotateDisk from '../../../components/RotateDisk'
import WheelButton from '../../../components/cta/WheelButton'
import { useDispatch, useSelector } from 'react-redux'
import {
  currentQuestionIdxSelector,
  currentQuestionSelector,
  hasReachedTerminalScoreSelector,
  quizzSelector,
  quizzStepSelector,
  scoreSelector,
} from '../../../redux/selectors/quizz'
import { lotteryConfigSelector } from '../../../redux/selectors/lottery'
import { AspectRatio } from '../../../utils/aspect_ratio'
import { gameInitialState, quizReducer } from '../reducers/quizReducer'
import {
  answerQuestion,
  nextQuestion,
  recomputeScore,
} from '../../../redux/actions/quizz'
import {
  computeScoreForMultiAnswers,
  QUIZZ_STEP_END,
} from '../../../redux/reducers/quizz'
import {
  GameActions,
  SKILL_GAME_ACTIONTYPE,
  SkillGame,
} from '../../19_Skill/reducers/gameReducer'
import {
  timerReset,
  timerStart,
  timerStop,
  timerTick,
} from '../../../redux/actions/timer'
import {
  isRunningSelector,
  timedOutSelector,
} from '../../../redux/selectors/timer'
import '../styles.scss'

interface Props {
  progressBarPos: number
  game?: {
    state: SkillGame
    dispatch: React.Dispatch<SKILL_GAME_ACTIONTYPE>
  }
  setProgressBarReplacement?: (node: ReactNode | null) => void
}

const Quizz: FunctionComponent<Props> = ({
  progressBarPos,
  game,
  setProgressBarReplacement,
}) => {
  const currentQuestion = useSelector(currentQuestionSelector)
  const currentQuestionIdx = useSelector(currentQuestionIdxSelector)
  const lotteryConfig = useSelector(lotteryConfigSelector)
  const quizz = useSelector(quizzSelector)
  const hasReachedTerminalScore = useSelector(hasReachedTerminalScoreSelector)
  const quizzStep = useSelector(quizzStepSelector)
  const score = useSelector(scoreSelector)
  const timerTimedOut = useSelector(timedOutSelector)
  const timerIsRunning = useSelector(isRunningSelector)

  const [showAnswers, setShowAnswers] = useState(true)

  const [quizGameState, quizGameDispatch] = useReducer(
    quizReducer,
    gameInitialState
  )

  const [pictureHeight, setPictureHeight] = React.useState('0px')

  const dispatch = useDispatch()

  const onContinueClicked = () => {
    if (hasReachedTerminalScore) {
      game?.dispatch({ type: GameActions.STOP })
    }
    if (quizz?.multi && !quizGameState.animatePostAnswer) {
      dispatch(timerStop())
      quizGameDispatch({ type: 'question_answer_no_payload' })
      dispatch(recomputeScore())
    } else {
      if (currentQuestion?.audio) {
        dispatch(timerReset(lotteryConfig?.qTimerSeconds ?? 0))
      } else {
        dispatch(
          timerStart(() => {
            dispatch(timerTick())
          }, lotteryConfig?.qTimerSeconds ?? 0)
        )
      }
      quizGameDispatch({ type: 'question_next' })
      dispatch(nextQuestion())
    }
  }

  const answerWithIndex = async (idx: number) => {
    if (quizz?.multi) {
      if (
        quizz.maxAnswers &&
        quizz.maxAnswers > 0 &&
        currentQuestion?.clientSideAnswersIdx &&
        quizz.maxAnswers === currentQuestion.clientSideAnswersIdx.length
      ) {
        return
      }

      dispatch(answerQuestion(idx))
    } else {
      if (quizGameState.answered) {
        return
      }
      dispatch(answerQuestion(idx))
      dispatch(timerStop())
      quizGameDispatch({ type: 'question_answer', payload: idx })
    }
  }

  const onClickPlayAudio = () => {
    dispatch(
      timerStart(() => {
        dispatch(timerTick())
      }, lotteryConfig?.qTimerSeconds ?? 0)
    )
    quizGameDispatch({ type: 'question_start' })
    setShowAnswers(true)
  }

  useEffect(() => {
    if (quizzStep === QUIZZ_STEP_END) {
      game?.dispatch({ type: GameActions.STOP })
    }
  }, [quizzStep])

  useEffect(() => {
    game?.dispatch({ type: GameActions.UPDATE_SCORE, payload: score })
  }, [score])

  useEffect(() => {
    switch (ratio) {
      case AspectRatio._18_9:
        setPictureHeight('28vh')
        break
      case AspectRatio._19_9:
        setPictureHeight('30vh')
        break
      default:
        break
    }
  }, [])

  useEffect(() => {
    if (!!currentQuestion?.audio) {
      setShowAnswers(false)
    } else {
      if (!quizz?.multi) {
        dispatch(
          timerStart(() => {
            dispatch(timerTick())
          }, lotteryConfig?.qTimerSeconds ?? 0)
        )
      }
    }
  }, [currentQuestion])

  useEffect(() => {
    if (timerTimedOut) {
      quizGameDispatch({ type: 'question_timeout' })
    }
  }, [timerTimedOut])

  let continueButtonText = !!currentQuestion?.audio
    ? tt('quizz_next_question_audio')
    : tt('quizz_next_question')

  if (hasReachedTerminalScore) {
    continueButtonText = tt('lp_discover_my_gift')
  }

  const [answerIsCorrect, setAnswerIsCorrect] = useState(false)

  useEffect(() => {
    setAnswerIsCorrect(
      quizz?.multi
        ? computeScoreForMultiAnswers(currentQuestion) > 0
        : currentQuestion?.answerIdx === quizGameState.answeredIndex
    )
  }, [currentQuestion, quizz, quizGameState.answeredIndex])

  useEffect(() => {
    if (progressBarPos !== PROGRESS_BAR_TOP) {
      return
    }
    if (timerIsRunning || !quizGameState.answered) {
      setProgressBarReplacement?.(null)
      return
    }
    setProgressBarReplacement?.(
      <>
        {!timerIsRunning && quizGameState.answered && (
          <div className="d-flex justify-content-center align-items-center">
            {answerIsCorrect && (
              <span className="animate-answer">
                {tt('quizz_good_answer')}&nbsp;✅
              </span>
            )}
            {!answerIsCorrect && !quizGameState.hasTimedOut && (
              <span className="animate-answer">
                {tt('quizz_wrong_answer')}&nbsp;❌
              </span>
            )}
            {!answerIsCorrect && quizGameState.hasTimedOut && (
              <span className="animate-answer">
                {tt('quizz_time_elapsed')}&nbsp;⏱
              </span>
            )}
          </div>
        )}
      </>
    )
  }, [answerIsCorrect, timerIsRunning, quizGameState])

  /*

   */
  return (
    <div id="quizz" className="cm-pad flex-grow-1">
      <div className="row w-margin-bottom">
        <div className="col s12">
          <p
            className="text-center q-question-text"
            dangerouslySetInnerHTML={{
              __html:
                currentQuestion && !quizGameState.answered
                  ? currentQuestion.text
                  : '&nbsp;',
            }}
          />
        </div>
      </div>
      {currentQuestion && !currentQuestion.audio && !!currentQuestion.img && (
        <div
          className="d-flex justify-content-center align-items-center w-margin-bottom-half"
          style={getStyleForImageContainer()}
        >
          <img
            id={`q-img-${currentQuestionIdx}`}
            className="twic"
            src={TSConstants.PLACEHOLDER_URL}
            data-src={`image:/cdm/img/quizz/${currentQuestion.img}`}
            style={getStyleForImage()}
          />
        </div>
      )}

      {progressBarPos === PROGRESS_BAR_TOP && (
        <div className="middle-zone w-margin-bottom-half d-flex flex-column align-items-center">
          {!!currentQuestion?.audio && (
            <RotateDisk
              audio={currentQuestion?.audio}
              containerHeight={pictureHeight}
              isStart={false}
              onClickPlay={() => onClickPlayAudio()}
              resetCounter={currentQuestionIdx}
              hide={
                timerIsRunning ||
                quizGameState.answered ||
                !currentQuestion?.audio
              }
            />
          )}
        </div>
      )}

      <div
        className="row w-margin-bottom all-answer"
        style={{ marginLeft: 0, marginRight: 0 }}
      >
        {currentQuestion && currentQuestion.answers && showAnswers && (
          <>
            {currentQuestion.answers.map((answer: string, idx: number) => {
              let containerClazz: string
              let btnClazz: string
              let imgClazz: string

              let isCorrectAnswer: boolean
              let shouldFrameAnswer: boolean

              if (quizz?.multi) {
                if (quizGameState.animatePostAnswer) {
                  shouldFrameAnswer = !!(
                    !currentQuestion?.answersIdx?.includes(idx) &&
                    currentQuestion?.clientSideAnswersIdx?.includes(idx)
                  )

                  isCorrectAnswer = !!currentQuestion?.answersIdx?.includes(idx)
                } else {
                  shouldFrameAnswer = false
                  isCorrectAnswer =
                    !!currentQuestion?.clientSideAnswersIdx?.includes(idx)
                }
              } else {
                isCorrectAnswer =
                  quizGameState.animatePostAnswer &&
                  idx === currentQuestion?.answerIdx
                shouldFrameAnswer =
                  quizGameState.animatePostAnswer &&
                  idx === quizGameState.answeredIndex
              }

              if (isCorrectAnswer) {
                containerClazz = ''
                btnClazz = 'quizz-good-answer-btn'
                imgClazz = 'quizz-good-answer-img'
              } else {
                if (shouldFrameAnswer) {
                  containerClazz = 'quizz-correct'
                  btnClazz = ''
                  imgClazz = 'quizz-bad-answer-img'
                } else {
                  containerClazz = ''
                  btnClazz = ''
                  imgClazz = ''
                }
              }

              return (
                <div
                  key={`ans-${idx}`}
                  className={`quizz-answer-container ${
                    currentQuestion?.hasImgAnswers ? 'col-6' : 'col-12'
                  }`}
                  style={{ padding: 0 }}
                >
                  {currentQuestion?.hasImgAnswers && (
                    <span
                      className={`d-flex w-margin-bottom-third justify-content-center ${imgClazz}`}
                      onClick={() => answerWithIndex(idx)}
                    >
                      <img
                        className={`twic`}
                        src={TSConstants.PLACEHOLDER_URL}
                        data-src={`image:/cdm/img/quizz/${answer}`}
                        style={{ height: '10vh' }}
                      />
                    </span>
                  )}
                  {!currentQuestion?.hasImgAnswers && (
                    <WheelButton
                      className={`${btnClazz}`}
                      containerClassName={`quizz-btn ${containerClazz}`}
                      text={answer}
                      isAnwsers={true}
                      isDisplay={quizGameState.isDisplayAnswers}
                      onClick={() => answerWithIndex(idx)}
                    />
                  )}
                </div>
              )
            })}
          </>
        )}
      </div>
      {(quizz?.multi || quizGameState.answered) && (
        <WheelButton
          containerClassName="mt-auto w-margin-bottom-half"
          text={continueButtonText}
          isInverted={true}
          isFixedBottom={true}
          onClick={onContinueClicked}
        />
      )}
    </div>
  )
}

export default Quizz
