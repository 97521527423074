import React, { FunctionComponent, useEffect, useState } from 'react'

interface Props {
  audio: string
  containerHeight: string
  isStart: boolean
  onClickPlay: () => void
  resetCounter: number
  hide: boolean
}

const RotateDisk: FunctionComponent<Props> = (props) => {
  const [audio, setAudio] = useState<any>()
  const [isPlayed, setIsPlayed] = useState(false)

  const onDiskClick = () => {
    if (!audio || isPlayed) {
      return
    }

    audio.play()
    setIsPlayed(true)
    props.onClickPlay()
  }

  useEffect(() => {
    setIsPlayed(false)

    if (audio) {
      audio.pause()
    }
  }, [props.resetCounter])

  useEffect(() => {
    const audioInput = document.getElementById('audio')
    setAudio(audioInput)
  }, [props.audio])

  return (
    <div
      className="disk-container"
      onClick={onDiskClick}
      style={{ display: props.hide ? 'none' : '' }}
    >
      <div className="audio-controller">
        <i className="far fa-play-circle"></i>
      </div>
      <audio
        id="audio"
        src={`https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/mp3/${props.audio}`}
      />
    </div>
  )
}

export default RotateDisk
