import * as React from 'react'
import { TSUtils } from '../../utils/TSUtils'
import FortuneWheelProps from './FortuneWheelProps'
import { COLOR_WHITE } from './WheelColors'

const rand = (min: number, max: number) => {
  return Math.random() * (max - min) + min
}

const deg2rad = (deg: number) => {
  return (deg * Math.PI) / 180
}

const MAX_SPIN = 3
const MAX_SPEED = 6
const DEFAULT_WHEEL_DIMENSION = 420

interface State {
  wheelDimension: number
  TITLE_CONTAINER: HTMLDivElement | null
}

export default class FortuneWheelV1 extends React.PureComponent<
  FortuneWheelProps,
  State
> {
  //region fields
  private CONTAINER: HTMLDivElement | null = null

  private readonly SLOW_DOWN_MULTIPLIER = 0.993
  private readonly SLOW_DOWN_DECREASE = 0.001
  private readonly SPEED_SLOW_DOWN_SWITCH = 0.2
  private readonly SPEED_UP_INCREASE = 0.07

  private readonly MIDDLE_CIRCLE_SIZE = 60
  private readonly MIDDLE_CIRCLE_X_OFFSET = 30
  private readonly MIDDLE_CIRCLE_Y_OFFSET = 30

  private canvas?: any
  private ctx?: any

  private DIRECTION = -1

  private deg = 90 // rand(0, 360)
  private speed = 0

  private spinCount = 0
  private startSlowDownAt = 0

  private isPlaying = false
  private isSlowingDown = false

  private needleDeg = 0
  private needleHasReachMaxDeg = false

  private audios: HTMLAudioElement[] = []
  private audiosLoop: number = 0
  private audiosLength = 3

  private isIOS: boolean = false
  private textMetrics: TextMetrics[] = []

  private interval: any | null
  //endregion

  //region properties
  get centerX() {
    return this.state.wheelDimension / 2
  }

  get centerY() {
    return this.state.wheelDimension / 2
  }

  get radius() {
    return this.state.wheelDimension / 2 - 10
  }

  get innerCircleRadius() {
    return this.radius - 17
  }

  get innerGradientStartRadius() {
    return this.radius - 21
  }

  get innerGradientEndRadius() {
    return this.radius - 17
  }

  get itemRadius() {
    return this.radius - 17
  }

  get FONT_SIZE() {
    return this.state.wheelDimension * 0.045
  }

  get sliceDeg() {
    return 360 / this.props.items.length
  }

  get slicesCount() {
    return this.props.items.length
  }

  get maxSpin() {
    return MAX_SPIN
  }

  get maxSpeed() {
    return MAX_SPEED
  }

  //endregion

  constructor(props: any) {
    super(props)

    this.drawFortuneWheel = this.drawFortuneWheel.bind(this)
    this.drawItem = this.drawItem.bind(this)
    this.drawText = this.drawText.bind(this)
    this.drawLoop = this.drawLoop.bind(this)
    this.updateWheelDimensionIfNeeded =
      this.updateWheelDimensionIfNeeded.bind(this)

    this.state = {
      TITLE_CONTAINER: null,
      wheelDimension: DEFAULT_WHEEL_DIMENSION,
    }

    for (let i = 0; i < this.audiosLength; i++) {
      this.audios.push(
        new Audio(
          `${props.config.PUBLIC_FILES_URL}/static/audio/click_wheel.mp3`
        )
      )
    }
  }

  componentDidMount(): void {
    if (TSUtils.isIOS()) {
      this.isIOS = true
    }
    this.ctx = this.canvas.getContext('2d')
    window.requestAnimationFrame(this.drawFortuneWheel)
  }

  componentDidUpdate(prevProps: Readonly<FortuneWheelProps>) {
    if (prevProps !== this.props) {
      window.requestAnimationFrame(this.drawFortuneWheel)
    }

    this.updateWheelDimensionIfNeeded()
  }

  private drawItem(deg: number, index: number) {
    const color =
      index % 2 === 0 ? this.props.colorBack1 : this.props.colorBack2
    this.ctx.beginPath()
    this.ctx.fillStyle = color
    this.ctx.moveTo(this.centerX, this.centerY)
    this.ctx.arc(
      this.centerX,
      this.centerY,
      this.itemRadius,
      deg2rad(deg),
      deg2rad(deg + this.sliceDeg)
    )
    this.ctx.lineTo(this.centerX, this.centerY)
    this.ctx.fill()
  }

  private drawText(deg: number, text: string, index: number) {
    const color =
      index % 2 === 0 ? this.props.colorFront1 : this.props.colorFront2

    this.ctx.save()
    this.ctx.translate(this.centerX, this.centerY)
    this.ctx.rotate(deg2rad(deg))
    this.ctx.textAlign = 'right'
    this.ctx.fillStyle = color

    let fontSize = this.FONT_SIZE

    if (text.length > 14) {
      fontSize -= 4
    } else if (text.length > 12) {
      fontSize -= 3
    } else if (text.length > 10) {
      fontSize -= 2
    } else if (text.length > 8) {
      fontSize -= 1
    }

    this.ctx.font = `bold ${fontSize}px "Brown", sans-serif`

    let metrics: TextMetrics = this.textMetrics[index]

    if (!metrics) {
      metrics = this.ctx.measureText(text)
      this.textMetrics[index] = metrics
    }

    const sliceWidth = this.radius
    const offsetX = sliceWidth - (sliceWidth - metrics.width) / 2

    this.ctx.fillText(text, offsetX, fontSize / 3)
    this.ctx.restore()
  }

  private drawCircleBackground() {
    this.ctx.arc(this.centerX, this.centerY, this.radius, 0, 2 * Math.PI)
    this.ctx.fillStyle = this.props.colorBack1
    this.ctx.fill()

    this.ctx.beginPath()
    this.ctx.arc(this.centerX, this.centerY, this.radius, 0, 2 * Math.PI)
    this.ctx.lineWidth = 0.8
    this.ctx.strokeStyle = COLOR_WHITE
    this.ctx.stroke()
  }

  private drawCircleInnerShadow() {
    this.ctx.arc(
      this.centerX,
      this.centerY,
      this.innerCircleRadius,
      0,
      2 * Math.PI,
      false
    )

    const gradient = this.ctx.createRadialGradient(
      this.centerX,
      this.centerY,
      this.innerGradientStartRadius,
      this.centerX,
      this.centerY,
      this.innerGradientEndRadius
    )

    gradient.addColorStop(0, 'transparent')
    gradient.addColorStop(1, '#333333')

    this.ctx.fillStyle = gradient
    this.ctx.fill()
  }

  private drawImage(
    ctx: CanvasRenderingContext2D,
    image: any,
    x: number,
    y: number,
    w: number,
    h: number,
    degrees: number
  ) {
    ctx.save()
    ctx.translate(x + w / 2, y + h / 2)
    ctx.rotate((degrees * Math.PI) / 180.0)
    ctx.translate(-x - w / 2, -y - h / 2)
    ctx.drawImage(image, x, y, w, h)
    ctx.restore()
  }

  private drawMiddleCircle() {
    const image = document.getElementById('middleCircleImage')

    if (!image) {
      return
    }

    this.drawImage(
      this.ctx,
      image,
      this.centerX - this.MIDDLE_CIRCLE_X_OFFSET,
      this.centerY - this.MIDDLE_CIRCLE_Y_OFFSET,
      this.MIDDLE_CIRCLE_SIZE,
      this.MIDDLE_CIRCLE_SIZE,
      this.deg
    )
  }

  private drawNeedle() {
    const image = document.getElementById('needleImage')

    if (!image) {
      return
    }

    const NEEDLE_SIZE = this.state.wheelDimension / 6
    const NEEDLE_X = this.state.wheelDimension - NEEDLE_SIZE
    const NEEDLE_Y = (this.state.wheelDimension - NEEDLE_SIZE) / 2

    this.drawImage(
      this.ctx,
      image,
      NEEDLE_X,
      NEEDLE_Y,
      NEEDLE_SIZE,
      NEEDLE_SIZE,
      this.needleDeg
    )
  }

  private drawFortuneWheel() {
    this.ctx.clearRect(
      0,
      0,
      this.state.wheelDimension,
      this.state.wheelDimension
    )

    this.drawCircleBackground()

    for (let i = 0; i < this.slicesCount; i++) {
      this.drawItem(this.deg, i)
      this.drawText(this.deg + this.sliceDeg / 2, this.props.items[i], i)
      this.deg += this.sliceDeg
    }

    this.drawCircleInnerShadow()
    this.drawMiddleCircle()
    this.drawNeedle()
  }

  private needleLoop() {
    if (this.audiosLoop >= this.audiosLength) {
      this.audiosLoop = 0
    }

    if (!this.needleHasReachMaxDeg) {
      this.needleDeg += this.speed
    } else {
      if (Math.abs(this.needleDeg) > 10) {
        this.needleDeg -= (30 / 5) * this.DIRECTION
      } else {
        this.needleDeg = 0
        this.needleHasReachMaxDeg = false

        if (!this.isIOS) {
          this.audios[this.audiosLoop].play()
        }

        this.audiosLoop++
      }
    }

    if (Math.abs(this.needleDeg) >= 30) {
      this.needleHasReachMaxDeg = true
    }

    this.needleDeg %= 30
  }

  private drawLoop() {
    this.drawFortuneWheel()

    if (!this.isPlaying) {
      return
    }

    this.deg -= this.speed
    this.deg %= 360

    if (!this.isSlowingDown) {
      if (Math.abs(this.speed) < this.maxSpeed) {
        this.speed = this.speed + this.SPEED_UP_INCREASE * this.DIRECTION
      } else {
        this.speed = this.maxSpeed * this.DIRECTION
      }
    } else {
      if (Math.abs(this.speed) > this.SPEED_SLOW_DOWN_SWITCH) {
        this.speed *= this.SLOW_DOWN_MULTIPLIER
      } else if (Math.abs(this.speed) > this.SLOW_DOWN_DECREASE) {
        this.speed -= this.SLOW_DOWN_DECREASE * this.DIRECTION
      } else {
        this.speed = 0
        this.needleDeg = 0

        window.requestAnimationFrame(this.drawFortuneWheel)

        let ai = Math.floor(((360 - this.deg) % 360) / this.sliceDeg)
        ai = (this.slicesCount + ai) % this.slicesCount

        // @ts-ignore
        this.props.onFinishedSpinning(ai, this.props.items[ai])

        this.isPlaying = false
        if (this.interval) {
          clearInterval(this.interval)
        }
        return
      }
    }

    this.needleLoop()

    if (
      this.deg >= 360 - this.maxSpeed &&
      Math.abs(this.speed) >= this.maxSpeed
    ) {
      this.spinCount++
    }

    if (this.spinCount > this.maxSpin) {
      if (Math.abs(this.deg - this.startSlowDownAt) < this.maxSpeed) {
        if (!this.isSlowingDown) {
          this.deg = this.startSlowDownAt
        }
        this.isSlowingDown = true
      }
    }
    //
    // window.requestAnimationFrame(() => {
    //   this.drawLoop()
    // });
  }

  public play(
    selectedIndex: number,
    direction?: 'clockwise' | 'counter-clockwise'
  ) {
    if (direction !== undefined) {
      if (direction === 'clockwise') {
        this.DIRECTION = -1
      } else if (direction === 'counter-clockwise') {
        this.DIRECTION = 1
      }
    }

    this.isSlowingDown = false
    this.spinCount = 0
    this.textMetrics = []

    let speed = this.maxSpeed * this.DIRECTION
    const slowDownRand = this.SLOW_DOWN_MULTIPLIER

    let degLeft = 0

    while (Math.abs(speed) > this.SLOW_DOWN_DECREASE) {
      degLeft -= speed
      degLeft = degLeft % 360

      if (Math.abs(speed) > this.SPEED_SLOW_DOWN_SWITCH) {
        speed *= slowDownRand
      } else if (Math.abs(speed) > this.SLOW_DOWN_DECREASE) {
        speed -= this.SLOW_DOWN_DECREASE * this.DIRECTION
      }
    }

    let minDeg = this.sliceDeg * (selectedIndex + 1) - 1
    let maxDeg = this.sliceDeg * selectedIndex + 1

    const stopAtDeg = rand(minDeg, maxDeg)
    let startSlowDownAt = 360 - degLeft + (360 - stopAtDeg)
    this.startSlowDownAt = Math.floor(Math.abs(startSlowDownAt) % 360)
    this.isPlaying = true

    window.requestAnimationFrame(this.drawLoop)

    this.interval = setInterval(() => {
      window.requestAnimationFrame(this.drawLoop)
    }, 20)
  }

  render() {
    const { config } = this.props

    return (
      <>
        <div
          ref={(ref) => (this.CONTAINER = ref)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            width: '100%',
            height: this.props.height,
          }}
        >
          <div ref={(ref) => this.setState({ TITLE_CONTAINER: ref })}>
            {!!this.props.renderTitle && (
              <div className="w-margin-top-2-thirds">
                {this.props.renderTitle(this.props.isPlayStep, true)}
              </div>
            )}
          </div>

          <div
            style={{
              width: this.state.wheelDimension,
              height: '100%',
              overflowX: 'hidden',
              overflowY: 'hidden',
            }}
          >
            <canvas
              ref={(ref) => (this.canvas = ref)}
              id="canvas"
              style={{
                marginLeft: '-45%',
                overflowX: 'hidden',
                overflowY: 'hidden',
              }}
              className={'wheel-canvas wheel-canvas-web'}
              width={this.state.wheelDimension}
              height={this.state.wheelDimension}
            />
          </div>

          <div style={{ display: 'none', height: 0 }}>
            <img
              onLoad={this.drawMiddleCircle.bind(this)}
              id="middleCircleImage"
              src={`${config.PUBLIC_FILES_URL}/static/img/fortuneWheelMiddleCircle.png`}
              style={{ height: 0, left: -5000 }}
            />

            <img
              onLoad={this.drawNeedle.bind(this)}
              id="needleImage"
              src={`${config.PUBLIC_FILES_URL}/static/img/fortuneWheelNeedle.png`}
              style={{ height: 0, left: -5000 }}
            />
          </div>
        </div>
        <div id="shadow" className="app-bottom-shadow">
          &nbsp;
        </div>
      </>
    )
  }

  private updateWheelDimensionIfNeeded() {
    if (!this.state.TITLE_CONTAINER) {
      return
    }

    const titleHeight = this.state.TITLE_CONTAINER.clientHeight || 0
    const wheelDimension = (this.props.height || 0) - (titleHeight || 0)

    if (wheelDimension === this.state.wheelDimension) {
      return
    }

    this.setState({
      wheelDimension,
    })
  }
}
