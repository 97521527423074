import React from 'react'

export const PROCESS_STRING_CONFIG = [
  {
    regex: /((http|https):\/\/)?(\S+)\.([a-z]{2,}?)(.*?)( |,|$|\.)/gim,
    fn: (key: string, result: any[]) => {
      let href = result[0]
      if (!href.startsWith('http://') && !href.startsWith('https://')) {
        href = `https://${href}`
      }
      return (
        <a
          key={key}
          className="descLink"
          target="_blank"
          href={href
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '')
            .replace(/[,.]$/, '')}
          rel="noreferrer"
        >
          {result[0]}
        </a>
      )
    },
  },
]
