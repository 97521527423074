import React from 'react'
import WheelStructure from './components/WheelStructure'
import WheelReflection from './components/WheelReflection'
import WheelCursor from './components/WheelCursor'
import WheelColorsI from '../WheelColors'
import WheelCursorShadow from './components/WheelCursorShadow'
import aspectRatio, { AspectRatio } from '../../../utils/aspect_ratio'
import './styles.scss'
import { WheelItem } from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import { tt } from '../../../i18n'
import MonthlyLotteryDraw from '@tootsweet/model/lottery/MonthlyLotteryDraw'

interface Props {
  width?: number
  height?: number
  wheelItems: WheelItem[]
  colors: WheelColorsI
  mlDraw?: MonthlyLotteryDraw
}

const deg2rad = (deg: number) => {
  return (deg * Math.PI) / 180
}

export default class FortuneWheelCanvas extends React.PureComponent<Props> {
  private wheelCursorShadow?: WheelCursorShadow | null

  get HEIGHT() {
    if (this.props.height !== undefined) {
      return this.props.height
    }
    return 420
  }

  get WIDTH() {
    if (this.props.width !== undefined) {
      return this.props.width
    }
    return 420
  }

  get centerX() {
    return this.WIDTH / 2
  }

  get centerY() {
    return this.HEIGHT / 2
  }

  get radius() {
    return this.WIDTH / 2
  }

  get sliceDeg() {
    return 360 / this.props.wheelItems.length
  }

  get FONT_SIZE() {
    return this.WIDTH * 0.045
  }

  get itemRadius() {
    return this.WIDTH / 2 - this.WIDTH / 20
  }

  get slicesCount() {
    return this.props.wheelItems.length
  }

  private canvas?: HTMLCanvasElement | null
  private topShadowCanvas?: HTMLCanvasElement | null
  private ctx?: CanvasRenderingContext2D | null
  private ctxTopShadow?: CanvasRenderingContext2D | null
  private wheelCursor?: WheelCursor | null
  private textMetrics: TextMetrics[] = []
  private imgElements: HTMLImageElement[] = []
  private imgLoaded: boolean[] = []

  constructor(props: Props) {
    super(props)
    this.drawFortuneWheel = this.drawFortuneWheel.bind(this)
    this.drawSector = this.drawSector.bind(this)
    this.drawText = this.drawText.bind(this)
    this.scaleCanvas = this.scaleCanvas.bind(this)
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    if (
      this.canvas &&
      this.ctx &&
      (prevProps.width !== this.props.width ||
        prevProps.height !== this.props.height)
    ) {
    }
  }

  private scaleCanvas() {
    if (this.canvas && this.ctx) {
      const rect = this.canvas.getBoundingClientRect()
      const dpr = window.devicePixelRatio || 1
      this.canvas.width = Math.trunc(rect.width * dpr)
      this.canvas.height = Math.trunc(rect.height * dpr)
      this.canvas.style.width = `${this.props.width}px`
      this.canvas.style.height = `${this.props.height}px`
      this.ctx?.scale(dpr, dpr)
    }
  }
  componentDidMount(): void {
    if (this.canvas) {
      this.ctx = this.canvas.getContext('2d')
      this.scaleCanvas()
    }
    if (this.topShadowCanvas) {
      this.ctxTopShadow = this.topShadowCanvas.getContext('2d')
    }
  }

  getColorsForIndex = (i: number) => {
    if (i % 4 === 0) {
      return {
        bg: this.props.colors.sector1BGColor,
        fg: this.props.colors.sector1FGColor,
      }
    } else if (i % 4 === 1) {
      return {
        bg: this.props.colors.sector2BGColor,
        fg: this.props.colors.sector2FGColor,
      }
    } else if (i % 4 === 2) {
      return {
        bg: this.props.colors.sector3BGColor,
        fg: this.props.colors.sector3FGColor,
      }
    }

    return {
      bg: this.props.colors.sector4BGColor,
      fg: this.props.colors.sector4FGColor,
    }
  }

  readonly drawSector = (deg: number, index: number) => {
    const colors = this.getColorsForIndex(index)

    if (!this.ctx) {
      return
    }

    this.ctx.beginPath()
    this.ctx.fillStyle = colors.bg
    this.ctx.moveTo(this.centerX, this.centerY)
    this.ctx.arc(
      this.centerX,
      this.centerY,
      this.itemRadius,
      deg2rad(deg),
      deg2rad(deg + this.sliceDeg)
    )

    this.ctx.lineTo(this.centerX, this.centerY)
    this.ctx.strokeStyle = this.props.colors.structureLinesColor
    this.ctx.lineWidth = 3
    this.ctx.stroke()
    this.ctx.fill()
  }

  readonly drawText = (deg: number, text: string, index: number) => {
    const colors = this.getColorsForIndex(index)

    if (!this.ctx) {
      return
    }

    this.ctx.save()
    this.ctx.translate(this.centerX, this.centerY)

    this.ctx.textAlign = 'right'
    this.ctx.fillStyle = this.props.colors.wheelText || colors.fg
    this.ctx.rotate(deg2rad(deg))

    const sliceWidth = this.radius
    const imgDimension = Math.trunc(sliceWidth / 3.5)

    if (this.props.wheelItems[index].img) {
      if (!this.imgElements[index] || !this.imgLoaded[index]) {
        const img = document.createElement('img')
        const dprSize = Math.trunc(imgDimension * window.devicePixelRatio)
        this.imgElements[index] = img
        img.setAttribute(
          'src',
          `https://tootsweet.twic.pics/cdm/kadow/${this.props.wheelItems[index].img}?twic=v1&cover=${dprSize}x${dprSize}`
        )
        img.setAttribute('style', `visibility: none`)
        const self = this
        img.onload = () => {
          self.imgLoaded[index] = true
          self.drawText(deg, text, index)
        }
      } else {
        const dx = Math.trunc(sliceWidth / 2)
        const dy = -Math.trunc(sliceWidth / 7)
        /*
        this.ctx.beginPath()
        this.ctx.strokeStyle = 'blue'
        this.ctx.rect(dx, dy, imgDimension, imgDimension)
        this.ctx.stroke()
        */
        this.ctx.drawImage(
          this.imgElements[index],
          dx,
          dy,
          imgDimension,
          imgDimension
        )
      }
    } else if (text) {
      let fontSize = this.FONT_SIZE

      if (text.length > 14) {
        fontSize -= 4
      } else if (text.length > 12) {
        fontSize -= 3
      } else if (text.length > 10) {
        fontSize -= 2
      } else if (text.length > 8) {
        fontSize -= 1
      }

      this.ctx.font = `bold ${fontSize}px "Brown", sans-serif`

      let metrics: TextMetrics = this.textMetrics[index]

      if (!metrics) {
        metrics = this.ctx.measureText(text)
        this.textMetrics[index] = metrics
      }
      const offsetX = sliceWidth - (sliceWidth - metrics.width) / 2
      this.ctx.fillText(text, offsetX, fontSize / 3)
    }

    this.ctx.restore()
  }

  public drawTopShadow = () => {
    if (this.ctxTopShadow) {
      this.ctxTopShadow.fillStyle = this.props.colors.shadowColor
      this.ctxTopShadow.globalCompositeOperation = 'source-over'
      this.ctxTopShadow.arc(
        this.centerX,
        this.centerY,
        Math.round(this.itemRadius + 2),
        deg2rad(180),
        deg2rad(0)
      )

      this.ctxTopShadow.fill()

      this.ctxTopShadow.beginPath()
      this.ctxTopShadow.globalCompositeOperation = 'destination-out'
      this.ctxTopShadow.arc(
        this.centerX,
        Math.round(this.centerY + this.HEIGHT * 0.04),
        this.itemRadius,
        deg2rad(180),
        deg2rad(0)
      )

      this.ctxTopShadow.fill()
    }
  }

  public drawFortuneWheel = (deg: number, cursorDeg: number = 0) => {
    if (this.ctx) {
      this.ctx.clearRect(0, 0, this.WIDTH, this.WIDTH)

      for (let i = 0; i < this.slicesCount; i++) {
        this.drawSector(deg, i)
        if (this.props.wheelItems[i].isLost) {
          if (this.props.wheelItems[i].isGC && this.props.mlDraw?.nTokens) {
            this.drawText(
              deg + this.sliceDeg / 2,
              `${this.props.mlDraw?.nTokens} ${
                this.props.mlDraw?.nTokens > 1
                  ? tt('golden_clover_plural')
                  : tt('golden_clover_singular')
              }`,
              i
            )
          } else {
            this.drawText(
              deg + this.sliceDeg / 2,
              this.props.wheelItems[i].label,
              i
            )
          }
        } else {
          this.drawText(
            deg + this.sliceDeg / 2,
            this.props.wheelItems[i].label,
            i
          )
        }
        deg += this.sliceDeg
      }
    }

    if (this.wheelCursor) {
      this.wheelCursor.setDeg(cursorDeg)
    }

    if (this.wheelCursorShadow) {
      this.wheelCursorShadow.setDeg(cursorDeg)
    }
  }

  render() {
    const cursorTop = this.centerY - (this.HEIGHT * 0.1) / 2
    const cursorRight = -((this.WIDTH * 0.15) / 4)
    const cursorWidth = this.WIDTH * 0.15
    const cursorHeight = this.HEIGHT * 0.1

    let wheelCursorShadowTop = cursorTop + 20
    let wheelCursorShadowRight = cursorRight + 38

    const aspectRatio1 = aspectRatio()
    switch (aspectRatio1) {
      case AspectRatio._16_9:
        wheelCursorShadowTop = cursorTop + 20
        wheelCursorShadowRight = cursorRight + 32
        break
      case AspectRatio._19_9:
      case AspectRatio._18_9:
        break
    }

    return (
      <div style={{ width: this.WIDTH, height: this.HEIGHT }}>
        <div style={{ position: 'absolute', zIndex: 2 }}>
          <WheelStructure
            topStrokeColor={this.props.colors.structureTopStrokeColor}
            linesColor={this.props.colors.structureFillColor2}
            fillColor={this.props.colors.structureFillColor}
            width={this.WIDTH}
            height={Math.round(
              this.WIDTH * (WheelStructure.height / WheelStructure.width)
            )}
          />
        </div>

        <canvas
          id="fw-canvas"
          style={{ position: 'absolute', top: 0, left: 0, zIndex: 4 }}
          ref={(ref) => (this.canvas = ref)}
          width={this.WIDTH}
          height={this.HEIGHT}
        />

        <canvas
          style={{ position: 'absolute', top: 0, left: 0, zIndex: 6 }}
          ref={(ref) => (this.topShadowCanvas = ref)}
          width={this.WIDTH}
          height={this.HEIGHT}
        />

        <div
          style={{
            position: 'absolute',
            top: this.HEIGHT * 0.03,
            left: 0,
            zIndex: 8,
          }}
        >
          <WheelReflection width={this.WIDTH} height={this.HEIGHT} />
        </div>

        {/*<div*/}
        {/*  style={{*/}
        {/*    position: 'absolute',*/}
        {/*    top: wheelCursorShadowTop,*/}
        {/*    right: wheelCursorShadowRight,*/}
        {/*    zIndex: 9,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <WheelCursorShadow*/}
        {/*    ref={(ref) => (this.wheelCursorShadow = ref)}*/}
        {/*    color={this.props.colors.cursorShadowColor}*/}
        {/*    width={cursorWidth / 2}*/}
        {/*    height={cursorHeight / 2}*/}
        {/*  />*/}
        {/*</div>*/}

        <div
          style={{
            position: 'absolute',
            top: cursorTop,
            right: cursorRight,
            zIndex: 10,
          }}
        >
          <WheelCursor
            ref={(ref) => (this.wheelCursor = ref)}
            shadowColor={this.props.colors.cursorShadowColor}
            color={this.props.colors.cursorColor}
            width={cursorWidth}
            height={cursorHeight}
          />
        </div>
      </div>
    )
  }
}
