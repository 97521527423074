import {TSUtils} from "../../utils/TSUtils";

export function generateArrayOf3DifferentIntegers(nItems: number) {
  let res = [
    Math.trunc(TSUtils.getRandomIntInRange(0, nItems)),
    Math.trunc(TSUtils.getRandomIntInRange(0, nItems)),
    Math.trunc(TSUtils.getRandomIntInRange(0, nItems)),
  ]

  while (new Set(res).size !== res.length) {
    res = [
      Math.trunc(TSUtils.getRandomIntInRange(0, nItems)),
      Math.trunc(TSUtils.getRandomIntInRange(0, nItems)),
      Math.trunc(TSUtils.getRandomIntInRange(0, nItems)),
    ]
  }
  return res
}
