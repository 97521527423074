import { tt } from '../i18n'
import FlexPicture from './FlexPicture'
import WheelButton from './cta/WheelButton'
import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { share } from '../app/helpers/share'
import { offerToFriendURLSelector } from '../redux/selectors/lottery'

interface Props {}

const OfferToFriend: FunctionComponent<Props> = () => {
  const url = useSelector(offerToFriendURLSelector)

  if (!url) {
    return null
  }

  return (
    <>
      <h2 className="h2 highlight cm-pad w-margin-bottom-half">
        {tt('how_generous')}
      </h2>

      <p className="cm-pad">{tt('if_share_failed')}</p>

      <FlexPicture
        isModal={false}
        src={undefined}
        dataSrc={`image:/cdm/img/gift_multicolor.png`}
        dataSrcTransform={'contain=WxH'}
        heightPc={0.5}
      />

      <div className="cm-pad w-margin-bottom-half">
        <WheelButton
          className=""
          text={tt('offer_again')}
          onClick={() => share(url ?? undefined)}
        />
      </div>
    </>
  )
}

export default OfferToFriend
