import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WheelButton from '../../../components/cta/WheelButton'
import { renderError } from '../../02_Error/Error'
import LotteryGameConsume from '@tootsweet/model/lottery/LotteryGameConsume'
import { TSUtils } from '../../../utils/TSUtils'
import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import { RootState } from '../../../redux/combinedReducers'
import {
  async_complete_signing_data,
  async_redeem,
  async_request_sms_auth,
  async_sign,
  async_sms_auth,
  setShowSMSAuthModal,
  setSMSAuthModalMode,
} from '../../../redux/actions/lottery'
import moment from 'moment'
import QrCode from 'qrcode.react'
import Modal from 'react-bootstrap/Modal'
// @ts-ignore
import processString from 'react-process-string'
import { tt } from '../../../i18n'
import TripAdvisorButton from '../../../components/TripAdvisorButton'
import { PROCESS_STRING_CONFIG } from '../../helpers/PROCESS_STRING_CONFIG'
import { classNameForRatio } from '../../../utils/aspect_ratio'
import SMSAuthModal from './SMSAuthModal'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import FlexPicture from '../../../components/FlexPicture'
import SigningModal from './SigningModal'
import Signature from '@tootsweet/model/lottery/Signature'
import GetGiftModal from './GetGiftModal'
import { computeRemainingText } from '../../helpers/computeRemainingText'
//@ts-ignore
import Barcode from 'react-barcode'
import TSConstants from '../../../utils/TSConstants'
import MustSignConfirmation from './components/MustSignConfirmation'
import MustSignRequest from './components/MustSignRequest'
import ReplayButton from '../../23_Replay/ReplayButton'
import RedeemExpired from './components/Redeem_Expired'
import PotentialFraud from './components/PotentialFraud'

let calendlyAdded = false

const QRCODE_SIZE = 250

interface Props {
  validateGameId: string | undefined
  isSpontaneous: boolean
  isIOS: boolean
  qrcode: boolean
}

let mustBlurInterval: any | undefined

const Redeem: FunctionComponent<Props> = (props) => {
  const [mustBlur, setMustBlur] = useState(false)
  const [blurRemainingTime, setBlurRemainingTime] = useState(0)
  const [showGetGiftModal, setShowGetGiftModal] = useState(false)
  const [showEmailAttachmentModal, setShowEmailAttachmentModal] =
    useState(false)
  const [hasJustBeenRedeemed, setHasJustBeenRedeemed] = useState(false)
  const [showTripAdvisorModal, setShowTripAdvisorModal] = useState(false)
  const [showSigningModal, setShowSigningModal] = useState(false)

  const dispatch = useDispatch()

  const game = useSelector(
    (state: RootState) => state.lottery.lotteryGameConsume
  )
  const venue = useSelector((state: RootState) => state.lottery.venue)
  const showSMSAuthModal = useSelector(
    (state: RootState) => state.lottery.showSMSAuthModal
  )
  const smsAuthModalMode = useSelector(
    (state: RootState) => state.lottery.smsAuthModalMode
  )
  const siteConfig = useSelector((state: RootState) => state.config)
  const lotteryConfig = useSelector(
    (state: RootState) => state.lottery.lotteryConfig
  )
  const status = useSelector((state: RootState) => state.lottery.status)
  const user = useSelector((state: RootState) => state.app.user)
  const percentCompleted = useSelector(
    (state: RootState) => state.lottery.percentCompleted
  )

  const sign = async (blob: Blob) => {
    await dispatch(async_sign(new File([blob], 'signature.png')))
  }

  const completeSigningForm = async (signature: Signature) => {
    await dispatch(async_complete_signing_data(signature))
  }

  const closeGetGiftModal = () => {
    setShowGetGiftModal(false)
  }

  const renderInWrapper = (children: ReactNode) => {
    return (
      <div className="ts-redeem cm-pad w-margin-top-2-thirds">{children}</div>
    )
  }

  const renderMustSign = (game: LotteryGameConsume) => {
    if (game?.signature?.signatureUrl) {
      return <MustSignConfirmation />
    }

    return (
      <MustSignRequest game={game} onClick={() => setShowSigningModal(true)} />
    )
  }

  const renderNotRedeemed = (
    lotteryConfig: LotteryConfigurationResponse,
    game: LotteryGameConsume,
    placeName: string | null,
    qrcode: boolean,
    mustBlur: boolean
  ) => {
    let prizeLabel = ' '
    let prizeLongDesc = ' '
    let processed: any | null = null
    let processedConditions: any | null = null

    if (game) {
      prizeLabel = game.prizeLabel || '???'
      prizeLongDesc = game.prizeLongDesc
      if (game.prizeLongDesc && game.prizeLongDesc.length > 0) {
        processed = processString(PROCESS_STRING_CONFIG)(game.prizeLongDesc)
      }
      if (game.conditions && game.conditions.length > 0) {
        processedConditions = processString(PROCESS_STRING_CONFIG)(
          game.conditions
        )
      }
    }

    const mustMarkAsRedeemed =
      game?.hasRedemptionCode || lotteryConfig?.redeemByQRCode || false
    const hideAddress = lotteryConfig && lotteryConfig.hideAddressOnCoupons
    const isVoucher = game && game.isVoucher

    let voucherExpiry: string | null = null

    if (game.expiryTs) {
      voucherExpiry = tt('expires_on', {
        expiryDate: moment(game.expiryTs, 'X')
          .tz('Europe/Paris')
          .format(tt('redeem_date_format')),
      })
    }

    let address = ''

    if (game && game.address) {
      address = game.address
    } else if (venue && venue.address) {
      address = venue.address
    }

    const isEcommerce = lotteryConfig?.isEcommerce || game?.isEcommerce

    if (game && game.mustSign) {
      return renderMustSign(game)
    }

    return (
      <div>
        <h2
          id="k-redeem-title"
          className="h2 w-margin-bottom cm-pad w-margin-top-2-thirds"
          style={{
            zIndex: 5,
          }}
        >
          <span>{tt('redeem_title_1')}</span>
          <br />
          <span className="highlight">{prizeLabel}</span>
        </h2>

        {!!game.prizeLongName && (
          <div className="w-margin-bottom cm-pad">
            <p>{tt('you_won_prize', { prizeLabel: game.prizeLongName })}</p>
          </div>
        )}

        {qrcode && (
          <div className="w-margin-bottom cm-pad">
            <QrCode
              size={QRCODE_SIZE}
              value={JSON.stringify({ id: game.id })}
            />
          </div>
        )}

        {!isEcommerce && (
          <div
            className={`w-margin-bottom cm-pad ${mustBlur ? 'ts-blur' : ''}`}
          >
            <p>
              {tt('won_on_date_in_place', {
                date: TSUtils.formatTimestampAsShortDate(game.timestamp),
                placeName: placeName || '???',
                address: !hideAddress && address ? `, ${address}` : '',
                voucherExpiry: !!voucherExpiry ? voucherExpiry : '',
              })}
            </p>
          </div>
        )}

        {!isVoucher && mustMarkAsRedeemed && (
          <div
            className={`cm-pad ${mustBlur ? 'ts-blur' : ''} w-margin-bottom`}
          >
            <WheelButton
              id="redeem"
              text={tt('get_my_gift')}
              onClick={() => setShowGetGiftModal(true)}
            />
          </div>
        )}

        {isVoucher &&
          renderVoucherCode(
            game,
            mustBlur,
            prizeLabel,
            isEcommerce,
            voucherExpiry
          )}

        {!!game?.emailAtt && (
          <div className="cm-pad w-margin-bottom">
            <WheelButton
              id="emailAtt"
              onClick={() => setShowEmailAttachmentModal(true)}
              text={'Voir la photo du produit'}
            />
          </div>
        )}

        {!!game?.conditions && (
          <div
            className={`w-margin-bottom cm-pad ${mustBlur ? 'ts-blur' : ''}`}
            style={{ whiteSpace: 'pre-wrap' }}
          >
            <b className="d-block">
              <u>{tt('redeem_conditions')}</u>
            </b>
            <p>{processedConditions}</p>
          </div>
        )}

        {!!prizeLongDesc && (
          <div
            className={`w-margin-bottom-half cm-pad ${
              mustBlur ? 'ts-blur' : ''
            }`}
            style={{ whiteSpace: 'pre-wrap' }}
          >
            <b className="d-block">
              <u>{tt('description')}</u>
            </b>
            <p>{processed}</p>
          </div>
        )}

        {!isVoucher && !mustMarkAsRedeemed && !isEcommerce && (
          <div
            className={`w-margin-bottom cm-pad ${mustBlur ? 'ts-blur' : ''}`}
          >
            <p>{tt('show_gift_to_staff_2')}</p>
          </div>
        )}

        {!isVoucher &&
          lotteryConfig &&
          !lotteryConfig.hasSpecificConditions &&
          game.prizeIsForNextTime && (
            <div
              className={`w-margin-bottom cm-pad ${mustBlur ? 'ts-blur' : ''}`}
            >
              <p>{tt('redeem_condition')}</p>
            </div>
          )}

        {lotteryConfig && lotteryConfig.hasSpecificConditions && (
          <div
            className={`w-margin-bottom cm-pad ${mustBlur ? 'ts-blur' : ''}`}
          >
            <p>{lotteryConfig.specificConditions}</p>
          </div>
        )}
      </div>
    )
  }

  const renderVoucherCode = (
    game: LotteryGameConsume,
    mustBlur: boolean,
    prizeLabel: string,
    isEcommerce: boolean | undefined | null,
    voucherExpiry: string | null
  ) => {
    const voucherWording = lotteryConfig?.voucherWording || tt('promo_code')
    const isBarCode = game?.useBarCodes

    return (
      <>
        {isBarCode && (
          <div className="d-flex justify-content-center">
            <Barcode value={game.voucherCode || ''} width={1.3} fontSize={12} />
          </div>
        )}

        <div className={`w-margin-bottom cm-pad ${mustBlur ? 'ts-blur' : ''}`}>
          {!isBarCode && (!game.smsAuth || game.smsAuthDone) && (
            <p id="voucher-w" className="w-voucher-border">
              {voucherWording} :
              <span>
                &nbsp;
                <span>{`${game.voucherCode}${
                  isEcommerce ? voucherExpiry : ''
                }`}</span>
              </span>
            </p>
          )}

          {game.smsAuth && !game.smsAuthDone && (
            <div className="w-margin-top-half">
              <WheelButton
                id="unblock-cta"
                text={`Dévoiler le ${voucherWording.toLowerCase()}`}
                onClick={performSMSAuthentication}
              />
            </div>
          )}

          <SMSAuthModal
            show={showSMSAuthModal}
            mode={smsAuthModalMode}
            voucherWording={voucherWording.toLowerCase()}
            setMode={(mode: number) => dispatch(setSMSAuthModalMode(mode))}
            onPhoneNumberValid={onSMSAuthPhoneNumberValid}
            onCodeSubmitted={onCodeSubmitted}
            closeModal={() => setShowSMSAuthModal(false)}
            title={() => (
              <>
                {tt('redeem_title_1')}
                <br />
                <span>{prizeLabel}</span>
              </>
            )}
          />
        </div>
      </>
    )
  }

  const closeEmailAttachmentModal = () => {
    setShowEmailAttachmentModal(false)
  }

  const closeTripAdvisorModal = () => {
    setShowTripAdvisorModal(false)
  }

  const onCodeSubmitted = async (code: string) => {
    await dispatch(async_sms_auth(code, siteConfig))
  }

  const onSMSAuthPhoneNumberValid = async (phoneNumber: string) => {
    await dispatch(async_request_sms_auth(phoneNumber, siteConfig))
  }

  const performSMSAuthentication = () => {
    dispatch(setShowSMSAuthModal(true))
  }

  const renderHasJustBeenRedeemed = () => {
    return (
      <>
        <h2
          className="h2 highlight w-margin-bottom cm-pad w-margin-top-2-thirds"
          style={{
            zIndex: 5,
          }}
        >
          {tt('enjoy')}
        </h2>

        <p className="w-margin-bottom cm-pad">
          {tt('enjoy_desc', {
            dateStr: moment(
              game?.redemptionTs || game?.redeemedByOwnerTs,
              'X'
            ).format(tt('redeem_date_format')),
            finalPlaceName: game?.placeName,
          })}
        </p>
      </>
    )
  }

  const renderAlreadyRedeemed = () => {
    if (!game) return null
    return (
      <>
        <h2
          className="h2 highlight w-margin-bottom cm-pad w-margin-top-2-thirds"
          style={{
            zIndex: 5,
          }}
        >
          {tt('redeem_expired')}
        </h2>

        <p className="w-margin-bottom cm-pad">
          {tt('redeem_consumed', {
            prizeLabel: game.prizeLabel,
            date: TSUtils.formatTimestampAsDate(
              game.redemptionTs || game.redeemedByOwnerTs
            ),
          })}
        </p>
      </>
    )
  }

  const onRedeemClicked = (code: string) => {
    if (!code || code.length !== 4) {
      alert(tt('redeem_code_must_be_4_digits'))
      return
    }

    if (!game) return

    dispatch(async_redeem(siteConfig, game.id, code))
  }

  const blurIfNeeded = () => {
    const res = shoudBlur()

    setMustBlur(!!res.mustBlur)
    setBlurRemainingTime(res.remainingTime)

    if (!res.mustBlur) {
      return
    }

    if (mustBlurInterval !== undefined) {
      return
    }

    mustBlurInterval = setInterval(() => {
      blurIfNeeded()
    }, 1000)
  }

  const shoudBlur = () => {
    const mustRedeem = !!props.validateGameId
    const nowTs = moment().unix()
    const ts = game?.unblurTs || (game?.timestamp || 0) + 86400
    const remainingTime = game ? nowTs - ts : -1
    return {
      mustBlur:
        mustRedeem &&
        game &&
        (game.prizeIsForNextTime || game.forceBlur) &&
        remainingTime < 0,
      remainingTime,
    }
  }

  useEffect(() => {
    if (
      lotteryConfig &&
      lotteryConfig.taPopupInRedeem &&
      !!lotteryConfig.tripAdvisorLink
    ) {
      setShowTripAdvisorModal(true)
    }
  }, [lotteryConfig])

  useEffect(() => {
    if (game) {
      blurIfNeeded()
    }
    if (game && TSUtils.gameIsRedeemed(game)) {
      setShowGetGiftModal(false)
      setHasJustBeenRedeemed(true)
    }
  }, [game])

  useEffect(() => {
    if (!mustBlur && mustBlurInterval !== undefined) {
      clearInterval(mustBlurInterval)
    } else if (mustBlur && !mustBlurInterval) {
      blurIfNeeded()
    }
  }, [mustBlur, mustBlurInterval])

  useEffect(() => {
    if (game?.signature?.signatureUrl) {
      setShowSigningModal(false)
    }
  }, [game])

  useEffect(() => {
    if (calendlyAdded || !lotteryConfig?.calendlyLink) {
      return
    }

    const head = document.querySelector('head')
    const script = document.createElement('script')
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    )
    head?.appendChild(script)
    calendlyAdded = true
  }, [lotteryConfig])

  const { qrcode } = props
  const hasError = status > 0
  const nowTs = moment().unix()
  const isExpired = game && game.expiryTs && nowTs > game.expiryTs
  const isRedeemed = TSUtils.gameIsRedeemed(game)

  if (hasError) {
    return renderInWrapper(renderError(status, lotteryConfig))
  }

  if (!game) {
    return null
  }

  if (!lotteryConfig) {
    return null
  }

  if (game?.isPotentialFraud) {
    return renderInWrapper(<PotentialFraud />)
  }

  if (isExpired) {
    return renderInWrapper(<RedeemExpired />)
  }

  return (
    <>
      {isRedeemed && hasJustBeenRedeemed && renderHasJustBeenRedeemed()}

      {isRedeemed && !hasJustBeenRedeemed && renderAlreadyRedeemed()}

      {!isRedeemed &&
        renderNotRedeemed(
          lotteryConfig,
          game,
          game?.placeName,
          qrcode,
          mustBlur
        )}

      {!!lotteryConfig?.calendlyLink && (
        <>
          <div
            className="calendly-inline-widget w-margin-bottom-half"
            data-url={lotteryConfig?.calendlyLink}
            style={{ width: window.screen.width, height: 500 }}
          ></div>
        </>
      )}

      {game && !game.mustSign && game.reference && !game.isVoucher && (
        <p className="cm-pad reference">
          {tt('reference')}
          {game.reference}
        </p>
      )}

      {lotteryConfig?.ctaOnCoupon &&
        !!lotteryConfig?.ctaURL &&
        !!lotteryConfig?.ctaText && (
          <>
            <div style={{ minHeight: 100, maxHeight: 100, height: 100 }} />
            <WheelButton
              text={lotteryConfig.ctaText}
              href={lotteryConfig.ctaURL}
              isFixedBottom={true}
            />
          </>
        )}

      {!lotteryConfig?.taCTAOnCoupon && (
        <div style={{ height: 30 }}>&nbsp;</div>
      )}

      {lotteryConfig?.taCTAOnCoupon && (
        <div className="ct-coupon-cta-pad">&nbsp;</div>
      )}

      <div style={{ flexGrow: 1, flexShrink: 1 }} />

      {lotteryConfig?.taCTAOnCoupon && (
        <div className="ct-coupon-cta">
          <TripAdvisorButton
            disableClick={true}
            step={undefined}
            text={tt('quizz_trip_advisor')}
            link={lotteryConfig?.tripAdvisorLink}
            preventNextStep={true}
          />
        </div>
      )}

      {!isRedeemed && mustBlur && (
        <Modal
          show={true}
          backdrop="static"
          centered={true}
          backdropClassName="ts-modal-no-backdrop"
        >
          <Modal.Body>
            <div
              id="k-redeem-blurred"
              className={'w-modal ' + classNameForRatio()}
            >
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h1>
                      {!!game?.unblurTs &&
                        tt('gift_blocked', {
                          date: moment(game.unblurTs, 'X').format(
                            tt('date_format_date_hour')
                          ),
                        })}
                      {!game?.unblurTs &&
                        tt('gift_blocked_until_tomorrow_1', {
                          remainingTime: computeRemainingText(
                            Math.abs(blurRemainingTime),
                            game?.unblurTs || 0
                          ),
                        })}
                    </h1>
                  </div>
                </div>
                <ReplayButton src="redeem" />
                <div className="row w-margin-top-half">
                  <div className="col-12">
                    <p>{tt('you_have_received_an_email')}</p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <GetGiftModal
        show={showGetGiftModal}
        close={closeGetGiftModal}
        onRedeem={onRedeemClicked}
        game={game}
        lotteryConfig={lotteryConfig}
      />

      <Modal
        show={showEmailAttachmentModal}
        centered={true}
        onHide={closeEmailAttachmentModal}
      >
        <Modal.Body>
          <div className="w-modal">
            <div className="container">
              <img
                className="twic"
                src={TSConstants.PLACEHOLDER_URL}
                data-src={`image:/cdm/emailattachments/${game?.emailAtt}`}
                data-src-transform="cover=WxH"
                style={{
                  width: '100%',
                  // height: 400
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showTripAdvisorModal}
        centered={true}
        onHide={closeTripAdvisorModal}
      >
        <Modal.Body>
          <div id="k-modal-redeem-tripad" className="w-modal">
            <div className="container">
              <i
                id="k-modal-redeem-tripad-close"
                className="fas fa-times modal-close"
                onClick={closeTripAdvisorModal}
              />

              <div className="w-margin-bottom">
                <h2 className="h2">
                  {tt('redeem_h1_tripadvisor', {
                    finalPlaceName: game?.placeName,
                  })}
                </h2>
              </div>

              <p className="w-margin-bottom">{tt('redeem_text_tripadvisor')}</p>

              <FlexPicture
                isModal={true}
                src={undefined}
                dataSrc={`image:/cdm/hearts.png`}
                heightPc={0.5}
              />

              <TripAdvisorButton
                step={{
                  stepType: LotteryV2StepType.TripAdvisor,
                  tag: lotteryConfig?.tripAdvisorLink,
                }}
                link={lotteryConfig?.tripAdvisorLink}
                preventNextStep={true}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <SigningModal
        user={user}
        game={game}
        completeForm={completeSigningForm}
        sign={sign}
        show={showSigningModal}
        hide={() => setShowSigningModal(false)}
        percentCompleted={percentCompleted}
        gdprLegalNotice={lotteryConfig?.gdprLegalNotice}
      />
    </>
  )
}

export default Redeem
