import Config from '../../model/config'
import { Action, Dispatch } from 'redux'
import axios from 'axios'
import UserDTO from '@tootsweet/model/datatransfer/UserDTO'
import { RootState } from '../combinedReducers'
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { TSUtils } from '../../utils/TSUtils'
import { ThunkDispatch } from 'redux-thunk'
import {
  async_fetchVenue,
  async_getLotteryGameForManualValidation,
  async_hasAlreadyPlayed,
  nextLotteryStep,
} from './lottery'
import Axios from 'axios'

export const SET_SHOW_FOOTER = 'SET_SHOW_FOOTER'
export const SET_FINGERPRINT = 'SET_FINGERPRINT'
export const SET_FINGERPRINT_BLOCKED = 'SET_FINGERPRINT_BLOCKED'
export const SET_MARKETING_PARAMZ = 'SET_MARKETING_PARAMZ'
export const SET_DISPLAY_MARKETING_MODAL = 'SET_DISPLAY_MARKETING_MODAL'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_INIT_FINISHED = 'SET_INIT_FINISHED'
export const SET_MENU_STATE = 'SET_MENU_STATE'
export const SET_PP_MODAL_STATE = 'SET_PP_MODAL_STATE'
export const SET_CP_MODAL_STATE = 'SET_CP_MODAL_STATE'

export const setInitFinished = (initFinished: boolean) => {
  return {
    type: SET_INIT_FINISHED,
    initFinished,
  }
}

export const setShowFooter = (state: boolean) => {
  return {
    type: SET_SHOW_FOOTER,
    state,
  }
}

export const setFingerprint = (fingerprint: string) => {
  return {
    type: SET_FINGERPRINT,
    fingerprint,
  }
}

export enum ActionTypes {
  SET_USER = 'set user',
  SET_USER_PAYMENT_EMAIL = 'SET_USER_PAYMENT_EMAIL',
  SET_USER_PHONE_NUMBER = 'SET_USER_PHONE_NUMBER',
}

export const setUser = (user: UserDTO | null) => ({
  type: ActionTypes.SET_USER,
  user,
})

export const setUserPaymentEmail = (
  firstName: string | undefined | null,
  email: string
) => {
  return {
    type: ActionTypes.SET_USER_PAYMENT_EMAIL,
    email,
    firstName,
  }
}
export const setUserPhone = (
  firstName: string | undefined | null,
  phoneNumber: string
) => {
  return {
    type: ActionTypes.SET_USER_PHONE_NUMBER,
    phoneNumber,
    firstName,
  }
}
export const async_createAnonymousUser =
  (config: Config) => async (dispatch: Dispatch) => {
    const response = await axios(config.AUTH_URL + '/account/createAnonymous', {
      method: 'POST',
    })
    const user: UserDTO = response.data
    localStorage.setItem('user', JSON.stringify(user))
    dispatch(setUser(user))
  }
export const async_refreshUser =
  (token: string, config: Config) => async (dispatch: Dispatch) => {
    try {
      const res = await axios({
        method: 'get',
        url: `${config.AUTH_URL}/users/me`,
        headers: {
          'x-ts-token': token,
          'Content-Type': 'application/json',
        },
      })
      storeUser(config, res.data, dispatch)
    } catch (err) {
      console.error(err)
    }
  }
const storeUser = (config: Config, userObject: UserDTO, dispatch: Dispatch) => {
  localStorage.setItem('user', JSON.stringify(userObject))
  dispatch(setUser(userObject))
}

export const setUTMs = (
  utm_campaign?: string | null,
  utm_source?: string | null,
  utm_medium?: string | null
) => {
  return {
    type: SET_MARKETING_PARAMZ,
    utm_campaign,
    utm_source,
    utm_medium,
  }
}
export const setMarketingModalState = (
  display: boolean,
  displayProfessionalContactForm?: boolean,
  displayIndividualContactForm?: boolean
) => {
  return {
    type: SET_DISPLAY_MARKETING_MODAL,
    display,
    displayProfessionalContactForm,
    displayIndividualContactForm,
  }
}

export const setLanguage = (lng: string) => {
  return {
    type: SET_LANGUAGE,
    lng,
  }
}

let fingerprintFetched = false

export const async_loadFingerprint = () => async (dispatch: Dispatch) => {
  if (fingerprintFetched) {
    return
  }
  // TODO PPE: if fingerprint exists in local storage, don't fetch it.
  // after fetch, store in local storage
  try {
    const fp = await FingerprintJS.load({
      token: 'LCfC5dV9NfBgahn9iO8K',
      endpoint: 'https://fp.kadow.club',
    })
    const visitorObj = await fp.get()
    dispatch(setFingerprint(visitorObj.visitorId))
    fingerprintFetched = true
  } catch (err) {
    // @ts-ignore
    if (
      err.message &&
      err.message === 'Failed to load the JS script of the agent'
    ) {
      dispatch(setFingerprintBlocked(true))
    }
    console.error(err)
  }
}

export const async_initUser =
  (userToken: string | null, config: Config) =>
  async (
    dispatch: ThunkDispatch<RootState, void, Action>,
    getState: () => RootState
  ) => {
    const state = getState()

    // Init User

    if (
      TSUtils.isDefinedAndNotNull(userToken) &&
      (userToken?.length ?? 0) > 0
    ) {
      // Put the user's token in local storage in order for
      // it to be available immediately (async_refreshUser needs some time
      // before the backends returns a full image of the user
      const tempUser = {
        token: userToken,
      }
      localStorage.setItem('user', JSON.stringify(tempUser))
      await dispatch(async_refreshUser(userToken ?? '', config))
      return
    }

    let user = state.app.user

    if (!user?.token) {
      await dispatch(async_createAnonymousUser(config))
    }
    if (user) {
      return
    }

    const storedUser = localStorage.getItem('user') ?? ''

    if (!user) {
      await dispatch(async_createAnonymousUser(config))
      return
    }

    user = JSON.parse(storedUser)

    if (user) {
      dispatch(setUser(user))
    }
  }

export const async_loadVenueOrGoToSearchBar =
  (config: Config, venueId?: string | null, draftID?: string | null) =>
  async (
    dispatch: ThunkDispatch<any, void, Action>,
    getState: () => RootState
  ) => {
    const state = getState()
    const user = state.app.user
    if (TSUtils.isDefinedAndNotNull(venueId) && (venueId?.length ?? 0) > 0) {
      if (!venueId) {
        return
      }
      await dispatch(async_fetchVenue(config, venueId))
      const venue = getState().lottery.venue
      if (venue) {
        await dispatch(
          async_hasAlreadyPlayed(
            config,
            user && user.token ? user.token : '',
            true,
              draftID
          )
        )
      }
      if (!venue) {
        dispatch(nextLotteryStep())
      }
    } else {
      dispatch(nextLotteryStep())
    }
  }

export const async_initApp =
  (
    userToken: string | null,
    config: Config,
    validateGameId?: string | null,
    redeemGameId?: string | null,
    venueId?: string | null,
    draftID?: string | null
  ) =>
  async (
    dispatch: ThunkDispatch<any, void, Action>,
    getState: () => RootState
  ) => {
    if (validateGameId || redeemGameId) {
      // TODO EGH: à quoi sert redeemGameId ?
      await dispatch(
        async_getLotteryGameForManualValidation(
          config,
          validateGameId || redeemGameId || ''
        )
      )
    } else {
      await Promise.all([
        dispatch(async_initUser(userToken, config)),
        dispatch(async_loadFingerprint()),
      ])
      await dispatch(async_loadVenueOrGoToSearchBar(config, venueId, draftID))
    }

    dispatch(setInitFinished(true))
  }

export const showMenu = () => {
  return {
    type: SET_MENU_STATE,
    shown: true,
  }
}

export const hideMenu = () => {
  return {
    type: SET_MENU_STATE,
    shown: false,
  }
}

export const showPrivacyPolicyModal = () => {
  return {
    type: SET_PP_MODAL_STATE,
    shown: true,
  }
}

export const hidePrivacyPolicyModal = () => {
  return {
    type: SET_PP_MODAL_STATE,
    shown: false,
  }
}

export const showCookesPolicyModal = () => {
  return {
    type: SET_CP_MODAL_STATE,
    shown: true,
  }
}

export const setFingerprintBlocked = (fingerprintBlocked: boolean) => {
  return {
    type: SET_FINGERPRINT_BLOCKED,
    fingerprintBlocked,
  }
}

export const hideCookiesPolicyModal = () => {
  return {
    type: SET_CP_MODAL_STATE,
    shown: false,
  }
}

let venueLoadTracked = false

export const trackVenueLoaded =
  () =>
  async (
    dispatch: ThunkDispatch<RootState, void, Action>,
    getState: () => RootState
  ) => {
    if (venueLoadTracked) {
      return
    }

    const state = getState()
    const config = state.config
    const venueId = state.lottery.venue?.id

    if (!venueId) {
      return
    }
    const isDemo = state.lottery.isDemo

    if (isDemo) {
      return
    }

    if (!state.app.fingerprint) {
      return
    }

    try {
      await Axios({
        method: 'post',
        url: config.BACKEND_URL + '/lottery_v2/venue_loaded',
        params: {
          venueId,
          utm_campaign: state.app.utm_campaign,
          utm_medium: state.app.utm_medium,
          utm_source: state.app.utm_source,
          fp: state.app.fingerprint,
        },
      })
      venueLoadTracked = true
    } catch (err) {}
  }
