import { renderCTAForStep } from '../helpers'
import WheelButton from '../../../components/cta/WheelButton'
import * as React from 'react'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import {
  goToRedeem,
  nextLotteryStep,
  setLotteryLoading,
  setSecondaryOptin,
} from '../../../redux/actions/lottery'
import { getOrCreateStore } from '../../../redux/store'
import { tt } from '../../../i18n'
import FlexPicture from '../../../components/FlexPicture'
import WheelFormModalContent, {
  renderSecondaryOptin,
  WheelFormType,
} from '../02_Form/WheelFormModalContent'
import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import Skin from '../../../model/Skin'
import { TSUtils } from '../../../utils/TSUtils'
import { determineTexts } from './determineTexts'
import InstagramButton, {
  buildInstagramLink,
} from '../../../components/InstagramButton'
import TSConstants from '../../../utils/TSConstants'
import { Dispatch } from 'redux'
import FacebookButton from '../../../components/FacebookButton'
import TikTokButton from '../../../components/TikTokButton'
import GoogleRatingButton from '../../../components/GoogleRatingButton'

const store = getOrCreateStore()

const MARGIN = 20

export default function renderSocialNetwork(
  config: LotteryConfigurationResponse | undefined | null,
  finalPlaceName: string,
  isIOS: boolean,
  isBeforeWheel: boolean,
  isModal: boolean,
  step: LotteryV2Step | null,
  lotteryStepTag: string | undefined | null,
  prizeLabel: string,
  onNoClicked?: () => void,
  hideImg?: boolean,
  imgName?: string,
  enforceImageWidth?: boolean,
  forceGoogleButton?: boolean,
  switchToGoogleButton?: () => void,
  skin?: Skin,
  skinId?: string,
  secondaryOptin?: boolean,
  dispatch?: Dispatch
) {
  if (step && step.stepType && step.stepType === LotteryV2StepType.Email) {
    return (
      <WheelFormModalContent
        formType={WheelFormType.Email}
        finalPlaceName={finalPlaceName}
      />
    )
  }

  let skinImg: string | null = null

  if (
    step?.stepType === LotteryV2StepType.Instagram &&
    skinId &&
    skin?.replaceIGModalImg
  ) {
    skinImg = `${TSUtils.buildSkinBaseURL(skinId, true)}/${
      skin.replaceIGModalImg
    }`
  }

  const texts = determineTexts(config, step, isIOS, finalPlaceName, prizeLabel)

  const isAfterWheel = !isBeforeWheel
  const hasBackupStep = !!(
    step &&
    step.backupStep &&
    step.backupStep.length > 0
  )

  return (
    <>
      <div className={`w-margin-bottom ${isModal ? '' : 'cm-pad'}`}>
        {isBeforeWheel && <h2 className="h2">{texts.beforeWheelH1}</h2>}
        {!isBeforeWheel && (
          <h2 className="h2 highlight">
            {tt('you_won_prize', { prizeLabel })}
          </h2>
        )}
      </div>

      <p className={`w-p-big ${isModal ? '' : 'cm-pad'}`}>
        {isBeforeWheel ? texts.beforeWheelText : texts.afterWheelText || ''}
      </p>

      {!hideImg && isBeforeWheel && (
        <FlexPicture
          isModal={isModal}
          src={undefined}
          dataSrc={skinImg || `image:/cdm/${imgName || 'hearts.png'}`}
          heightPc={0.5}
          hasCustomPicture={!!step?.customLinkImg}
        />
      )}

      {!hideImg && !isBeforeWheel && (
        <FlexPicture
          isModal={false}
          src={undefined}
          dataSrc={skinImg || `image:/cdm/${imgName || 'hearts.png'}`}
          heightPc={0.5}
          hasCustomPicture={!!step?.customLinkImg}
        />
      )}

      {hideImg && <div className="w-margin-bottom"></div>}

      {isModal && (
        <>
          {renderSecondaryOptin(step, undefined, secondaryOptin, () => {
            dispatch?.(setSecondaryOptin(!secondaryOptin))
          })}

          {renderCTAForStep(
            step,
            lotteryStepTag || null,
            true,
            isIOS,
            !!step?.hasSecondaryOptin && !secondaryOptin,
            false,
            isAfterWheel,
            forceGoogleButton,
            step ? step.tag2 : null,
            isAfterWheel
          )}

          {(step?.opt || hasBackupStep) && (
            <div className="w-margin-top-half">
              <span
                className="d-block text-center k-skip"
                onClick={onNoClicked}
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
              >
                {tt('skip')}
              </span>
            </div>
          )}

          {step &&
            step.stepType === LotteryV2StepType.TripAdvisor &&
            !!step.tag2 && (
              <div
                className="text-center"
                style={{ marginTop: 10 }}
                onClick={switchToGoogleButton}
              >
                {!forceGoogleButton && <p>{tt('no_tripadvisor_account')}</p>}
                {forceGoogleButton && <p>{tt('go_back')}</p>}
              </div>
            )}
        </>
      )}

      {step &&
        (!!step.fbLink || !!step.igLink || !!step.gLink || !!step.tkLink) && (
          <div className="d-block d-flex flex-column justify-content-center w-margin-top-half">
            <h4 className="w-margin-top-half w-margin-bottom-half text-center font-weight-bold">
              <p>OU ALORS...</p>
            </h4>

            <div
              style={{
                width: '66%',
                marginLeft: '17%',
                zoom: '70%',
                display: 'flex',
                flexDirection: 'column',
                rowGap: 10,
              }}
            >
              {!!step.fbLink && (
                <FacebookButton
                  facebookPageId={step.fbLink}
                  preventNextStep={false}
                  step={step}
                />
              )}

              {!!step.tkLink && (
                <TikTokButton
                  tikTokUserId={step.tkLink}
                  preventNextStep={false}
                  step={step}
                />
              )}

              {!!step.igLink && (
                <InstagramButton
                  instagramProfileId={step.igLink}
                  preventNextStep={false}
                  step={step}
                />
              )}

              {!!step.gLink && (
                <GoogleRatingButton
                  googlePlaceId={step.gLink}
                  preventNextStep={false}
                  step={step}
                  isWhite={true}
                  img="https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/google_icon.png"
                />
              )}
            </div>
          </div>
        )}

      {!isModal && (
        <div className="bottom-after-wheel cm-pad w-padding-bottom">
          {renderCTAForStep(
            step,
            lotteryStepTag || null,
            false,
            isIOS,
            !!step?.hasSecondaryOptin && !secondaryOptin,
            false,
            false,
            undefined,
            undefined,
            isAfterWheel
          )}
          <div style={{ marginTop: 10 }}>
            <WheelButton
              id="k-go-to-gift"
              text={tt('go_to_gift')}
              isInverted={true}
              onClick={() => goToRedeem()(store.dispatch, store.getState)}
            />
          </div>
        </div>
      )}

      {!!step?.altInstaLink && (
        <>
          <a
            className="modal-bottom-link w-margin-top"
            href={buildInstagramLink(step.altInstaLink)}
            target="_blank"
            onClick={() => {
              store.dispatch(
                setLotteryLoading(true, {
                  stepType: LotteryV2StepType.Instagram,
                  tag: step?.altInstaLink,
                })
              )
              setTimeout(() => {
                store.dispatch(setLotteryLoading(false))
                store.dispatch(nextLotteryStep())
              }, TSConstants.LOADER_TIMEOUT_SHORT)
            }}
          >
            Vous avez déjà laissé un avis ? Venez suivre notre page Insta !
          </a>
        </>
      )}
    </>
  )
}
