import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WheelAnalyticsManager from '../../../analytics/WheelAnalyticsManager'
import GameWinner from '@tootsweet/model/lottery/GameWinner'
import moment from 'moment'
import { TSUtils } from '../../../utils/TSUtils'
import { tt } from '../../../i18n'
import WheelButton from '../../../components/cta/WheelButton'
import { FunctionComponent, useEffect } from 'react'
import {
  last3WinnersSelector,
  lotteryConfigSelector,
} from '../../../redux/selectors/lottery'
import ReplayButton from '../../23_Replay/ReplayButton'
import { RootState } from '../../../redux/combinedReducers'
import { async_canReplay } from '../../../redux/actions/lottery'

interface Props {}

const Lost: FunctionComponent<Props> = () => {
  useEffect(() => {
    WheelAnalyticsManager.logEvent('lost')
  }, [])

  const last3Winners = useSelector(last3WinnersSelector)
  const lotteryConfig = useSelector(lotteryConfigSelector)
  const dispatch = useDispatch()
  const game = useSelector(
    (state: RootState) => state.lottery.lotteryGameConsume
  )
  const canReplay = useSelector((state: RootState) => state.lottery.canReplay)

  const startOfDay = moment().startOf('day').unix()
  const endOfDay = moment().endOf('day').unix()
  const className = lotteryConfig?.disableCentering ? '' : 'text-center'

  useEffect(() => {
    if (game) {
      dispatch(async_canReplay(game.id))
    }
  }, [game])

  return (
    <>
      {!lotteryConfig?.lostText && (
        <h2 className={`h2 w-margin-top-2-thirds ${className}`} id={'lost'}>
          {tt('not_this_time_1')}
          <br />
          <span className="highlight">{tt('not_this_time_2')}</span>
        </h2>
      )}

      {!!lotteryConfig?.lostText && (
        <div className="row w-margin-top-double">
          <div className={`col-12 ${className}`}>
            <h4 className="h4">{lotteryConfig.lostText}</h4>
          </div>
        </div>
      )}

      {last3Winners && last3Winners.length > 0 && (
        <div style={{ marginTop: 40 }}>
          <p className={`font-weight-bold ${className}`}>
            {tt('others_had_more_luck')}
          </p>
          {last3Winners.map((winner: GameWinner, index: number) => {
            const isToday = winner.ts >= startOfDay && winner.ts <= endOfDay
            const str = isToday
              ? tt('today')
              : TSUtils.formatTimestamp(winner.ts, tt('date_format_winner'))
            return (
              <span
                key={'win-' + index}
                className={className}
                style={{ display: 'block', marginTop: 10 }}
              >
                {winner.name || '<Inconnu>'} {tt('has_won')} {winner.prizeLabel}{' '}
                {str}
              </span>
            )
          })}
        </div>
      )}

      {!canReplay &&
        lotteryConfig?.ctaOnCoupon &&
        !!lotteryConfig?.ctaURL &&
        !!lotteryConfig?.ctaText && (
          <>
            <div style={{ minHeight: 100, maxHeight: 100, height: 100 }} />
            <WheelButton
              text={lotteryConfig.ctaText}
              href={lotteryConfig.ctaURL}
              isFixedBottom={true}
            />
          </>
        )}

      <ReplayButton staticBottom={true} src="lost" />
    </>
  )
}

export default Lost
