import WheelColorsI from '../WheelColors'
import React from 'react'
import { FoliageBG } from '../SVG_objects/FoliageBG'
import { LargeFoliage } from '../SVG_objects/LargeFoliage'
import { ThinFoliage } from '../SVG_objects/ThinFoliage'
import './nature.scss'
import WheelFoliage from '../SVG_objects/WheelFoliage'
import aspectRatio, { AspectRatio } from '../../../utils/aspect_ratio'

const aspectRatio1 = aspectRatio()

export function buildSkinForNatureWheel(colors: WheelColorsI) {
  return {
    renderBackground: (width: number, height: number) => {
      return (
        <div className="" style={{ width: '100%', height }}>
          <FoliageBG colors={colors} aspectRatio={aspectRatio1} />
        </div>
      )
    },
    renderForeground: (
      width: number,
      height: number,
      wheelDimension: number,
      wheelLeft: number,
      wheelBottom: number
    ) => {
      let wheelFoliageBottom = wheelBottom + Math.trunc(wheelDimension * 0.86)
      let wheelFoliageLeft =
        wheelDimension / 2 - Math.abs(wheelLeft) + wheelDimension / 8
      let wheelFoliageHeight = 70

      let thinFoliageBottom = '-30%'
      let thinFoliageRight = '-5%'
      let thinFoliageHeight = '50%'

      let largeFoliageBottom = '-10%'
      let largeFoliageRight = '-2%'
      let largeFoliageHeight = '40%'

      switch (aspectRatio1) {
        case AspectRatio._16_9:
          wheelFoliageBottom = wheelBottom + Math.trunc(wheelDimension * 0.83)
          wheelFoliageLeft =
            wheelDimension / 2 - Math.abs(wheelLeft) + wheelDimension / 6
          wheelFoliageHeight = 60

          thinFoliageBottom = '-20%'
          thinFoliageRight = '10%'
          thinFoliageHeight = '45%'

          largeFoliageBottom = '-10%'
          largeFoliageRight = '0%'
          largeFoliageHeight = '40%'

          break
        case AspectRatio._19_9:
        case AspectRatio._18_9:
          break
      }

      return (
        <div
          className="foliage"
          style={{ width: '100%', height, overflow: 'hidden' }}
        >
          <LargeFoliage
            colors={colors}
            top={'-10%'}
            left={'-10%'}
            height={'30%'}
            rotate="180deg"
          />
          <ThinFoliage
            colors={colors}
            bottom={thinFoliageBottom}
            right={thinFoliageRight}
            height={thinFoliageHeight}
          />
          <LargeFoliage
            colors={colors}
            bottom={largeFoliageBottom}
            right={largeFoliageRight}
            height={largeFoliageHeight}
          />
          <WheelFoliage
            colors={colors}
            height={wheelFoliageHeight}
            bottom={wheelFoliageBottom}
            left={wheelFoliageLeft}
          />
        </div>
      )
    },
    renderCTAForeground: () => {
      return null
    },
  }
}
