import React, { FC, ReactNode } from 'react'
import { PROCESS_STRING_CONFIG } from '../../helpers/PROCESS_STRING_CONFIG'
// @ts-ignore
import processString from 'react-process-string'
import WheelButton from '../../../components/cta/WheelButton'
import SkillAppLogo from './SkillAppLogo'
import { useSelector } from 'react-redux'
import { quizzSelector } from '../../../redux/selectors/quizz'

interface HomeProps {
  heading?: {
    text?: string
    className?: string
    color?: string
  }
  subheading?: {
    text?: string
    className?: string
  }
  cta?: {
    text?: string
    color?: string
    bgColor?: string
    onClick: () => void
  }
  footer?: string
  gameContainerHeight?: number
}

const Home: FC<HomeProps> = ({
  gameContainerHeight,
  heading,
  subheading,
  cta,
  footer,
}) => {
  const quizz = useSelector(quizzSelector)
  return (
    <div className="d-flex flex-grow-1 w-100 align-items-center flex-column cm-pad text-center">
      <div className="d-flex flex-column align-items-center justify-content-around flex-grow-1">
        <SkillAppLogo useWidth={true} />
        <h2
          className={`${heading?.className}`}
          style={{ color: heading?.color }}
        >
          {heading?.text}
        </h2>
        {!!subheading?.text && (
          <div className={`${subheading?.className} quizz-home-sub-text`}>
            {subheading?.text}
          </div>
        )}
      </div>
      <div className="d-flex flex-column w-100 w-margin-top w-margin-bottom">
        <WheelButton
          id={'start-quizz'}
          onClick={cta?.onClick}
          text={cta?.text || ''}
          containerClassName="cm-pad"
        />
        {!!footer && (
          <div className="w-margin-top">
            <small>{processString(PROCESS_STRING_CONFIG)(footer)}</small>
          </div>
        )}
      </div>
    </div>
  )
}

export default Home
