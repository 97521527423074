import {
  showCookesPolicyModal,
  showPrivacyPolicyModal,
} from '../redux/actions/app'
import React from 'react'
import { useDispatch } from 'react-redux'

interface Props {
  ctaText: string
}

export default function GDPRReminder(props: Props) {
  const dispatch = useDispatch()
  return (
    <p className="w-margin-bottom-half">
      <small>
        En cliquant sur "{props.ctaText}", vous acceptez le règlement ainsi que
        la{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() => dispatch(showPrivacyPolicyModal())}
        >
          politique de confidentialité
        </span>{' '}
        et la{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() => dispatch(showCookesPolicyModal())}
        >
          politique des cookies
        </span>
      </small>
    </p>
  )
}
