import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { tt } from '../../i18n'
import TitleV2 from '../../components/MonthlyLottery/Title/TitleV2'
import {
  lotteryConfigSelector,
  lotteryStepSelector,
} from '../../redux/selectors/lottery'
import WheelButton from '../../components/cta/WheelButton'
import { setUTMs } from '../../redux/actions/app'
import { nextLotteryStep } from '../../redux/actions/lottery'
import Translations from '../../translations/Translations'

interface Props {
  isQuizz?: boolean
  skillNextStep?: () => void
}

const UTMRedirection: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch()
  const lotteryConfig = useSelector(lotteryConfigSelector)
  const step = useSelector(lotteryStepSelector)

  function onClick(key: string) {
    if (!step?.utmRedir) {
      alert(tt('error'))
      return
    }
    const val = step.utmRedir[key]
    const queryParams = new URLSearchParams(window.location.search)
    if (val.utm_campaign) {
      queryParams.set('utm_campaign', val.utm_campaign)
    }
    if (val.utm_source) {
      queryParams.set('utm_source', val.utm_source)
    }
    if (val.utm_medium) {
      queryParams.set('utm_medium', val.utm_medium)
    }
    window.history.replaceState(null, 'null', `?${queryParams.toString()}`)
    window.history.pushState(null, '')
    dispatch(setUTMs(val.utm_campaign, val.utm_source))
    dispatch(nextLotteryStep())
  }

  return (
    <>
      <div style={{ flex: 1 }} />
      <div className="cm-pad w-margin-top">
        <TitleV2
          title1={
            step?.customLinkTitle
              ? // @ts-ignore
                tt(step?.customLinkTitle)
              : 'A quelle pièce avez-vous assisté ?'
          }
        />
      </div>

      {!!step?.customLinkText && (
        <div className="cm-pad w-margin-top text-center">
          <h4>{tt(step.customLinkText as keyof Translations)}</h4>
        </div>
      )}

      <div style={{ flex: 1 }} />
      {!!step?.utmRedir && (
        <div className="cm-pad" style={{ overflowY: 'scroll' }}>
          {Object.keys(step.utmRedir).map((key, idx) => {
            return (
              <div className="cm-pad w-margin-bottom-third">
                <WheelButton
                  text={key}
                  onClick={() => {
                    onClick(key)
                  }}
                />
              </div>
            )
          })}
          <div style={{ height: 100 }}>&nbsp;</div>
        </div>
      )}
    </>
  )
}

export default UTMRedirection
