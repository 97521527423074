import React, { FunctionComponent, useEffect } from 'react'
import './snowflakes.scss'

const snowFlake1 = require('./img/snowflake1.png')
const snowFlake2 = require('./img/snowflake2.png')

/**
 * The min & max size of snow flakes in pixel
 */
const SNOW_FLAKE_MIN_SIZE = 20
const SNOW_FLAKE_MAX_SIZE = 40

/**
 * The min & max delay in milliseconds between each spawn
 */

// const NEXT_SPAWN_MIN_DELAY = 300;
// const NEXT_SPAWN_MAX_DELAY = 600;

/**
 * The min & max duration in milliseconds
 * These numbers represent how much time it take to reach the bottom of the screen
 */
const ANIMATION_MIN_DURATION = 5000
const ANIMATION_MAX_DURATION = 10000

const ID = 'snowflakes-box'

interface Props {
  zIndex: number
  children?: React.ReactNode
}

const SnowFlakes: FunctionComponent<Props> = (props) => {
  let timer: NodeJS.Timeout | undefined

  const getRandomNumber = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min) + min)
  }

  const getSnowFlake = () => {
    const snowFlakeElm = document.createElement('img')
    const boxElm = document.getElementById(ID)

    const size = getRandomNumber(SNOW_FLAKE_MIN_SIZE, SNOW_FLAKE_MAX_SIZE)
    snowFlakeElm.width = size
    snowFlakeElm.height = size
    snowFlakeElm.src = getRandomNumber(0, 2) ? snowFlake1 : snowFlake2
    snowFlakeElm.style.position = 'absolute'
    snowFlakeElm.style.left = `${getRandomNumber(
      -20,
      (boxElm?.offsetWidth || 40) + 20
    )}px`
    snowFlakeElm.style.top = `-${size}px`
    snowFlakeElm.style.pointerEvents = 'none'
    snowFlakeElm.style.zIndex = `${props.zIndex}`
    boxElm?.appendChild(snowFlakeElm)

    const ratio = ((boxElm?.offsetHeight || 1) / size) * 120
    const anim = snowFlakeElm.animate(
      [
        { transform: 'translateY(0) rotateZ(0)' },
        {
          transform: `translateY(${ratio}%) rotateZ(${getRandomNumber(
            -720,
            720
          )}deg)`,
        },
      ],
      {
        duration: getRandomNumber(
          ANIMATION_MIN_DURATION,
          ANIMATION_MAX_DURATION
        ),
        iterations: 1,
      }
    )

    const nextSpawn = getRandomNumber(100, 500)
    timer = setTimeout(getSnowFlake, nextSpawn)

    anim.onfinish = () => {
      snowFlakeElm.remove()
    }
  }

  useEffect(() => {
    setTimeout(() => getSnowFlake(), 500)
  }, [])

  return (
    <div id={ID} className="sc-snowflakes-container">
      {props.children}
    </div>
  )
}

export default SnowFlakes
