import React from 'react'

interface Props {
  idx: number
  width?: number | string
  height?: number | string
  top?: number | string
  left?: number | string
  bottom?: number | string
  right?: number | string
  isLight?: boolean
  onClick?: () => void
}

export default function StPatricksDayFilledClover(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 310.76 310.66"
      style={{
        position: 'absolute',
        width: props.width,
        height: props.height,
        bottom: props.bottom,
        top: props.top,
        right: props.right,
        left: props.left,
      }}
      onClick={props.onClick}
    >
      <defs>
        <style>{`
        .cls-patrick-1-${props.idx} { fill:${
          props.isLight ? '#07E279' : '#04b364'
        };}
        `}</style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="MASQUE">
          <path
            className={`cls-patrick-1-${props.idx}`}
            d="M166.28,162.8c33.58-12.3,66.6-23.07,102-19.74,33,3.1,51.2,33,38.2,61.67-7,15.37-20.56,22.27-37,25.33,4.11,20.25,1.65,37.68-16.08,50.48-18,13-43.47,11.36-56.72-6.59-8.17-11.06-14.16-24.28-18.58-37.39-4.6-13.66-6.56-28.29-8.89-42.62C167.58,183.86,167.25,173.57,166.28,162.8Z"
          />
          <path
            className={`cls-patrick-1-${props.idx}`}
            d="M163,145.54C150.46,110.63,139.81,78,142.82,43c2.89-33.68,33.41-52.23,62.35-38.39,14.68,7,21.71,20,24.25,34.56,10.7,1,21.1.42,30.62,3.08,16.41,4.57,25.57,17,28.19,33.58s-2.63,31.92-17,40.32a190.49,190.49,0,0,1-47.63,19.73C204.2,140.92,183.91,142.37,163,145.54Z"
          />
          <path
            className={`cls-patrick-1-${props.idx}`}
            d="M80.85,271.37c-10.85-.75-20.56.24-29.17-2.38-16.51-5-27.18-16.84-29.39-34.41-2.23-17.78,3.29-34.31,19.58-41.86,20.21-9.37,41.76-18.13,63.58-21.27,52.32-7.53,40.37-14.4,55.29,36,4.87,16.45,6.78,34.21,7.2,51.45.71,29.18-14.38,48.19-37.83,51.3C106.41,313.38,88.5,299,80.85,271.37Z"
          />
          <path
            className={`cls-patrick-1-${props.idx}`}
            d="M39.15,81.08C40,70.13,39,59.93,41.87,51c5.25-16.37,17.35-26.81,34.92-28.82,18-2.05,34.25,4.29,41.51,20.49,9.48,21.17,18.19,43.68,21.43,66.44,6.84,48,13.21,37-33.24,50.8-18.2,5.41-37.86,7.57-56.93,8C21.45,168.5,2.7,152.51.27,129.46-2.21,105.94,12.3,88,39.15,81.08Z"
          />
        </g>
      </g>
    </svg>
  )
}
