import { useDispatch, useSelector } from 'react-redux'
import React, { FunctionComponent, useEffect } from 'react'
import { tt } from '../../i18n'
import {
  drawSelector,
  lotteryConfigSelector,
  lotteryGameConsumeSelector,
} from '../../redux/selectors/lottery'
import ReplayButton from '../23_Replay/ReplayButton'
import { async_canReplay, buildRedeemURL } from '../../redux/actions/lottery'
import WheelButton from '../../components/cta/WheelButton'

interface Props {}

const MonthlyLotteryEnd: FunctionComponent<Props> = () => {
  const mlDraw = useSelector(drawSelector)
  const game = useSelector(lotteryGameConsumeSelector)
  const lotteryConfig = useSelector(lotteryConfigSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (game) {
      dispatch(async_canReplay(game.id))
    }
  }, [])

  if (!mlDraw?.nTokens) return null
  return (
    <>
      <div
        id="ml-end"
        className="cm-pad w-margin-top-2-thirds w-margin-bottom text-center"
      >
        <h2 className="h2">{tt('ml_participation_recorded')}</h2>
      </div>

      {game?.outcome && (
        <div className="cm-pad w-margin-bottom text-center">
          <p>
            {mlDraw?.nTokens > 1
              ? tt('your_golden_clover_with_gift_plural', {
                  prizeLabel: game?.prizeLabel,
                  goldenClover:
                    lotteryConfig?.customMLTokenNamePlural ||
                    tt('golden_clover_plural'),
                })
              : tt('your_golden_clover_with_gift_singular', {
                  prizeLabel: game?.prizeLabel,
                  goldenClover:
                    lotteryConfig?.customMLTokenNameSingular ||
                    tt('golden_clover_singular'),
                })}
          </p>
        </div>
      )}

      {!game?.outcome && (
        <div className="cm-pad w-margin-bottom text-center">
          <p>
            {mlDraw?.nTokens > 1
              ? tt('your_golden_clover_no_gift_plural', {
                  prizeLabel: game?.prizeLabel,
                  goldenClover:
                    lotteryConfig?.customMLTokenNamePlural ||
                    tt('golden_clover_plural'),
                })
              : tt('your_golden_clover_no_gift_singular', {
                  prizeLabel: game?.prizeLabel,
                  goldenClover:
                    lotteryConfig?.customMLTokenNameSingular ||
                    tt('golden_clover_singular'),
                })}
          </p>
        </div>
      )}

      <div className="cm-pad w-margin-bottom text-center">
        <p>{tt('look_in_spam')}</p>
      </div>

      {!!game && !!game.outcome && (
        <WheelButton
          isFixedBottom={true}
          text={tt('go_to_gift')}
          href={buildRedeemURL(game.id)}
          forceTargetSelf={true}
        />
      )}
      <ReplayButton staticBottom={true} src="glm" />
    </>
  )
}

export default MonthlyLotteryEnd
