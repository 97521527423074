import React from 'react'
import { FoliageProps } from './FoliageFG'

export default function WheelFoliage(props: FoliageProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 146.38 197.68"
      style={{
        position: 'absolute',
        height: props.height,
        bottom: props.bottom,
        top: props.top,
        right: props.right,
        left: props.left,
      }}
    >
      <defs>
        <style>{`
      .cls-wf-1{fill:${props.colors.v2FoliageColor2};}
      `}</style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="ROUE_dessus">
          <path
            className="cls-wf-1"
            d="M2.61,22.66A337.4,337.4,0,0,0,4,71.06l31.58-.84L4.44,76.16c1.25,12.79,2.8,25.42,4,38.33l34.15,7.25L9,119.78c.46,5.24.86,10.54,1.14,15.92L26,138.91l-15.57,3a285.81,285.81,0,0,1-3.13,55.8c33-24.43,60-44.91,82.73-64.68L75.07,113.27,97.83,126a394.9,394.9,0,0,0,28.32-28.19l-36-20.94,45,10.56q5.88-7.12,11.28-14.59c-4.48-6.14-42.79-33.71-67-44.52S7.66-6.09,2.25,1.46,3,6,2.61,22.66Z"
          />
        </g>
      </g>
    </svg>
  )
}
