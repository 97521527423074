import React, {
  FC,
  lazy,
  ReactNode,
  Suspense,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import {
  ctaClickCountSelector,
  lotteryConfigSelector,
  lotteryGameConsumeSelector,
  lotteryStepSelector,
} from '../../redux/selectors/lottery'
import { useDispatch, useSelector } from 'react-redux'
import {
  chosenGiftSelector,
  giftsSelector,
  questionsSelector,
} from '../../redux/selectors/quizz'
import Progress from './components/ProgressBar'
import {
  GameActions,
  gameInitialState,
  gameReducer,
  GameSteps,
} from './reducers/gameReducer'
import Home from './components/Home'
import LotteryType from '@tootsweet/model/lottery/LotteryType'
import MonthlyLotteryIntro from '../14_MonthlyLotteryIntro/MonthlyLotteryIntro'
import MonthlyLotteryGame from '../14_MonthlyLotteryIntro/MonthlyLotteryGame'
import End from './components/End'
import Score from './components/Score'
import { tt } from '../../i18n'
import { getCTAText, getHeading } from './utils/texts'
import {
  async_createGameForQuizz,
  async_fetchQuizz,
  async_finishGame,
  chooseGift,
  setScore,
  startQuizz,
} from '../../redux/actions/quizz'
import WheelAnalyticsManager from '../../analytics/WheelAnalyticsManager'
import useModalControls from '../../hooks/useModalControls'
import Modal from 'react-bootstrap/Modal'
import QuizzStep from '../01_Quizz/Components/QuizzStep'
import { setShowFooter } from '../../redux/actions/app'
import { QuizzGift } from '@tootsweet/model/lottery/Quizz'
import EmailAddressModal from '../03_EmailAddressModal/EmailAddressModal'
import { offerToFriend } from '../../redux/actions/lottery'
import Tutorial from './components/Tutorial'
import Quizz from '../01_Quizz/Components/Quizz'
import { PROGRESS_BAR_TOP } from '../01_Quizz/constants'
import { timerTimeout } from '../../redux/actions/timer'
import {
  isRunningSelector,
  timerRemainingSecondsSelector,
} from '../../redux/selectors/timer'
import { countdownSelector } from '../17_Catcher/reducers/countdownReducer'
import SkillAppLogo from './components/SkillAppLogo'
import { Loader } from '../../components/Loader/Loader'
const Catcher = lazy(() => import('../17_Catcher/Catcher'))

interface Props {
  finalPlaceName: string
  isIOS: boolean
  gameContainerWidth: number
  gameContainerHeight: number
}

const SkillApp: FC<Props> = ({
  finalPlaceName,
  isIOS,
  gameContainerHeight,
  gameContainerWidth,
}) => {
  const dispatch = useDispatch()

  const lotteryConfig = useSelector(lotteryConfigSelector)
  const ctaClickCount = useSelector(ctaClickCountSelector)
  const lotteryStep = useSelector(lotteryStepSelector)
  const timerRemainingSeconds = useSelector(timerRemainingSecondsSelector)
  const timerIsRunning = useSelector(isRunningSelector)
  const questions = useSelector(questionsSelector)
  const countdown = useSelector(countdownSelector)
  const gifts = useSelector(giftsSelector)
  const lotteryGame = useSelector(lotteryGameConsumeSelector)
  const chosenGift = useSelector(chosenGiftSelector)

  const [progressBarReplacement, setProgressBarReplacement] =
    useState<ReactNode | null>(null)
  const [hasStep, setHasStep] = useState(false)
  const [hasPerformedStep, setHasPerformedStep] = useState(false)
  const [hasClickedNo, setHasClickedNo] = useState(false)
  const [progress, setProgress] = useState(100)
  const [giftsThatApply, setGiftsThatApply] = useState<QuizzGift[]>([])

  const hasAudio = useMemo(
    () => questions && questions.findIndex((q) => !!q.audio) !== -1,
    [questions]
  )
  const shouldDisplayBG = useMemo(() => {
    return !!lotteryConfig?.bgURL
  }, [lotteryConfig])
  const shouldDisplayProgress = useMemo(() => {
    return (
      (lotteryConfig?.type === LotteryType.Catcher && !countdown.isRunning) ||
      lotteryConfig?.type === LotteryType.Quizz
    )
  }, [lotteryConfig, countdown])

  const { isOpen, openModal, closeModal } = useModalControls({
    onOpenModal: () => {
      WheelAnalyticsManager.logEvent(
        `${lotteryStep?.stepType?.toLowerCase()}_modal`
      )
    },
  })
  const emailModalControls = useModalControls({})
  const [gameState, gameDispatch] = useReducer(gameReducer, gameInitialState)

  const startGame = () => {
    if (hasStep && !hasPerformedStep) {
      WheelAnalyticsManager.logEvent(lotteryStep?.stepType)
      openModal()
    } else {
      WheelAnalyticsManager.logEvent('start_quizz')
      dispatch(startQuizz())
      if (!gameState.gameCreated) {
        dispatch(async_createGameForQuizz())
        gameDispatch({ type: GameActions.START })
      }
    }
    dispatch(setShowFooter(false))
  }

  const updateScore = (amount: number) => {
    let newScore = gameState.score + amount
    if (newScore < 0) {
      newScore = 0
    }
    gameDispatch({
      type: GameActions.UPDATE_SCORE,
      payload: newScore,
    })
    dispatch(setScore(newScore))
  }

  const getGiftsThatApply = (): QuizzGift[] => {
    let giftsThatApply: QuizzGift[] = []
    let minScoreToWin = Number.MAX_SAFE_INTEGER
    let maxScore = 0
    gifts.forEach((gift: QuizzGift) => {
      if (gift.l < minScoreToWin) {
        minScoreToWin = gift.l
      }
      if (gameState.score >= gift.l && gameState.score <= gift.u) {
        giftsThatApply.push(gift)
      }
      if (gift.u > maxScore) {
        maxScore = gift.u
      }
    })
    if (maxScore && giftsThatApply.length === 0 && gameState.score > maxScore) {
      giftsThatApply = [...gifts]
    }
    return giftsThatApply
  }

  const onGiftChosen = (gift: QuizzGift) => {
    dispatch(chooseGift(gift))
    emailModalControls.openModal()
  }

  const onEmailModalFinished = async () => {
    if (lotteryGame) {
      dispatch(async_finishGame())
      emailModalControls.closeModal()
    } else {
      alert(tt('error'))
    }
  }

  useEffect(() => {
    dispatch(async_fetchQuizz())
    dispatch(setShowFooter(true))
  }, [])

  useEffect(() => {
    if (lotteryConfig?.hasML) {
      gameDispatch({
        type: GameActions.UPDATE_STEP,
        payload: GameSteps.ML_INTRO,
      })
    }
  }, [lotteryConfig])

  useEffect(() => {
    if (!lotteryConfig?.steps) return
    const nSteps = lotteryConfig?.steps?.length || 0
    if (lotteryConfig?.hasML) {
      if (nSteps > 3 && !hasStep) {
        setHasStep(true)
        setHasPerformedStep(false)
      }
    } else if (nSteps > 1 && !hasStep) {
      setHasStep(true)
      setHasPerformedStep(false)
    }
  }, [lotteryConfig])

  useEffect(() => {
    // Performing step triggers ctaClickCount increment thus closing the modal
    if (ctaClickCount !== 0) {
      setHasPerformedStep(true)
      closeModal()
    }
  }, [ctaClickCount])

  useEffect(() => {
    if (timerRemainingSeconds <= 0 && timerIsRunning) {
      dispatch(timerTimeout())
    }
  }, [timerRemainingSeconds, timerIsRunning])

  useEffect(() => {
    if (!lotteryConfig?.qTimerSeconds) {
      return
    }
    const newProgress =
      timerRemainingSeconds * (100 / (lotteryConfig?.qTimerSeconds ?? 0))
    if (gameState.step === GameSteps.HOME && newProgress === 0) {
      setProgress(100)
    } else {
      setProgress(newProgress)
    }
  }, [timerRemainingSeconds, lotteryConfig])

  useEffect(() => {
    if (gameState.step === GameSteps.END) {
      setGiftsThatApply(getGiftsThatApply())
    }
  }, [gameState.step])

  if (!lotteryConfig) {
    return null
  }

  return (
    <>
      <div
        style={{
          backgroundColor: shouldDisplayBG
            ? 'transparent'
            : lotteryConfig?.colBodyBack,
        }}
        className="d-flex flex-grow-1 w-100"
      >
        {gameState.step === GameSteps.ML_INTRO && (
          <div className="d-flex flex-column flex-grow-1">
            <SkillAppLogo
              hasNotch={true}
              gameContainerWidth={gameContainerWidth}
            />
            <MonthlyLotteryIntro
              skillNextStep={() =>
                gameDispatch({
                  type: GameActions.UPDATE_STEP,
                  payload: GameSteps.ML_GAME,
                })
              }
            />
          </div>
        )}{' '}
        {gameState.step === GameSteps.ML_GAME && (
          <div className={'d-flex flex-column flex-grow-1'}>
            <SkillAppLogo
              hasNotch={true}
              gameContainerWidth={gameContainerWidth}
            />
            <MonthlyLotteryGame
              isQuizz={true}
              skillNextStep={() =>
                gameDispatch({
                  type: GameActions.UPDATE_STEP,
                  payload: GameSteps.HOME,
                })
              }
            />
          </div>
        )}
        {gameState.step === GameSteps.HOME && (
          <Home
            gameContainerHeight={gameContainerHeight}
            heading={{
              className:
                lotteryConfig.type === LotteryType.Quizz
                  ? 'quizz-home-text'
                  : '',
              text:
                hasStep && hasPerformedStep
                  ? tt('its_all_good_no_comma')
                  : getHeading({ lotteryConfig, hasAudio }),
              color: lotteryConfig?.colBodyFont,
            }}
            subheading={{
              text: lotteryConfig.qIntroSubText,
              className:
                lotteryConfig.type === LotteryType.Quizz
                  ? 'quizz-home-sub-text'
                  : '',
            }}
            cta={{
              text: getCTAText({ lotteryConfig, hasAudio }),
              onClick: startGame,
              color: lotteryConfig.colCTAFont,
              bgColor: lotteryConfig.colCTABack,
            }}
            footer={lotteryConfig.qIntroFooterText}
          />
        )}
        {gameState.step === GameSteps.PLAY && (
          <div className="d-flex flex-column flex-grow-1">
            <SkillAppLogo
              hasNotch={true}
              gameContainerWidth={gameContainerWidth}
            />
            <div
              className="d-flex flex-column"
              style={{
                padding: '0 25px 15px 25px',
                gap: '10px',
              }}
            >
              <div
                style={{
                  visibility: shouldDisplayProgress ? undefined : 'hidden',
                }}
              >
                {!progressBarReplacement && (
                  <>
                    <Score score={gameState.score} className={'text-right'} />
                    <Progress progress={progress} />
                  </>
                )}
                {!!progressBarReplacement && <>{progressBarReplacement}</>}
              </div>
            </div>
            {lotteryConfig?.type === LotteryType.Quizz && (
              <Quizz
                progressBarPos={PROGRESS_BAR_TOP}
                game={{ state: gameState, dispatch: gameDispatch }}
                setProgressBarReplacement={setProgressBarReplacement}
              />
            )}
            {lotteryConfig?.type === LotteryType.Catcher && (
              <Suspense
                fallback={
                  <Loader
                    loading={true}
                    displayText={true}
                    text={tt('loading')}
                    isFixed={true}
                  />
                }
              >
                <Catcher
                  isActive={true}
                  updateScore={updateScore}
                  game={{ state: gameState, dispatch: gameDispatch }}
                  zIndex={2}
                />
              </Suspense>
            )}
          </div>
        )}
        {gameState.step === GameSteps.END && (
          <End
            score={gameState.score}
            gifts={giftsThatApply}
            onGiftChosen={onGiftChosen}
            logo={<SkillAppLogo useWidth={true} />}
          />
        )}
      </div>

      {!!lotteryStep && (
        <Modal
          show={isOpen}
          onHide={closeModal}
          centered={true}
          backdrop="static"
        >
          <Modal.Body>
            <div className="w-modal">
              <div className="container">
                <i className="fas fa-times modal-close" onClick={closeModal} />
                <QuizzStep
                  finalPlaceName={finalPlaceName}
                  hasClickedNo={hasClickedNo}
                  setHasClickedNo={setHasClickedNo}
                  isIOS={isIOS}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <Tutorial isIOS={isIOS} />

      {emailModalControls.isOpen && (
        <EmailAddressModal
          show={true}
          closeModal={emailModalControls.closeModal}
          onFinished={onEmailModalFinished}
          prizeLabel={chosenGift?.longName || chosenGift?.label}
          offerToFriend={(url: string) => {
            dispatch(offerToFriend(url))
          }}
        />
      )}
    </>
  )
}

export default SkillApp
