import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import { TSUtils } from '../utils/TSUtils'
import WheelButton from './cta/WheelButton'
import WheelAnalyticsManager from '../analytics/WheelAnalyticsManager'
import {
  incrementCTAClickCount,
  nextLotteryStep,
  setLotteryLoading,
} from '../redux/actions/lottery'
import TSConstants from '../utils/TSConstants'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import { tt } from '../i18n'
import { FunctionComponent } from 'react'
import { configSelector } from '../redux/selectors/config'
import { lotteryConfigSelector } from '../redux/selectors/lottery'

interface Props {
  facebookPageId: string | undefined | null
  isFixedBottom?: boolean
  textOnLeft?: boolean
  preventNextStep: boolean
  step: LotteryV2Step | null
  style?: any | null
}

const FacebookButton: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch()

  const config = useSelector(lotteryConfigSelector)
  const siteConfig = useSelector(configSelector)

  const { step, facebookPageId, isFixedBottom, textOnLeft } = props

  const onGoToFBClick = () => {
    WheelAnalyticsManager.facebookClicked()

    dispatch(setLotteryLoading(true, props.step || undefined))

    setTimeout(() => {
      dispatch(incrementCTAClickCount())
      if (!props.preventNextStep) {
        dispatch(nextLotteryStep())
      }
      dispatch(setLotteryLoading(false))
    }, TSConstants.LOADER_TIMEOUT_SHORT)
  }
  const buildFacebookGroupLink = (
    config: LotteryConfigurationResponse | undefined,
    lotteryStepTag: string | null
  ) => {
    if (!config) {
      return ''
    }
    if (
      lotteryStepTag?.includes('http:') ||
      lotteryStepTag?.includes('https:')
    ) {
      return lotteryStepTag
    }
    let link: string
    const facebookPageId = lotteryStepTag || config.facebookPageId

    if (TSUtils.isMobile()) {
      if (TSUtils.isIOS()) {
        link = `fb://group?id=${facebookPageId}`
      } else if (TSUtils.isAndroid()) {
        link = `fb://group/${facebookPageId}`
      } else {
        link = `https://facebook.com/${facebookPageId}`
      }
    } else {
      link = `https://facebook.com/${facebookPageId}`
    }
    return link
  }

  const buildFacebookPageLink = (
    config: LotteryConfigurationResponse | undefined,
    lotteryStepTag: string | null
  ) => {
    if (!config) {
      return ''
    }
    if (
      lotteryStepTag?.includes('http:') ||
      lotteryStepTag?.includes('https:')
    ) {
      return lotteryStepTag
    }
    let link: string
    const facebookPageId = lotteryStepTag || config.facebookPageId

    if (TSUtils.isMobile()) {
      if (TSUtils.isIOS()) {
        link = `fb://profile?id=${facebookPageId}`
      } else if (TSUtils.isAndroid()) {
        link = `fb://page/${facebookPageId}`
      } else {
        link = `https://facebook.com/${facebookPageId}`
      }
    } else {
      link = `https://facebook.com/${facebookPageId}`
    }
    return link
  }

  let link: string
  if (step?.isFBGroup) {
    link = buildFacebookGroupLink(config, facebookPageId ?? null)
  } else {
    link = buildFacebookPageLink(config, facebookPageId ?? null)
  }

  return (
    <WheelButton
      text={tt('cta_facebook')}
      onClick={onGoToFBClick}
      className="w-btn-fb"
      img={`${siteConfig.PUBLIC_FILES_URL}/static/img/fb-small.png`}
      href={link}
      isFixedBottom={isFixedBottom}
      textOnLeft={textOnLeft}
      style={props.style}
    />
  )
}

export default FacebookButton
