import { useDispatch, useSelector } from 'react-redux'
import React, { FunctionComponent, useEffect, useState } from 'react'
import WheelSponsorshipDTO from '@tootsweet/model/lottery/WheelSponsorshipDTO'
import VenueDTO from '@tootsweet/model/datatransfer/VenueDTO'
import Axios from 'axios'
import BaseResponse from '../../../model/BaseResponse'
import {
  setLotteryConfiguration,
  setLotteryLoading,
} from '../../../redux/actions/lottery'
import WheelButton from '../../../components/cta/WheelButton'
import Triplet from '../../../model/Triplet'
import { tt } from '../../../i18n'
import { configSelector } from '../../../redux/selectors/config'
import {
  utmCampaignSelector,
  utmMediumSelector,
  utmSourceSelector,
} from '../../../redux/selectors/app'
import { lotteryConfigSelector } from '../../../redux/selectors/lottery'
import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'

interface Props {
  sponsorshipId: string
}

const SponsorshipIntroduction: FunctionComponent<Props> = (props) => {
  const [sponsorship, setSponsorship] = useState<WheelSponsorshipDTO>()
  const [venue, setVenue] = useState<VenueDTO>()
  const [isError, setIsError] = useState(false)

  const dispatch = useDispatch()

  const siteConfig = useSelector(configSelector)
  const utm_campaign = useSelector(utmCampaignSelector)
  const utm_source = useSelector(utmSourceSelector)
  const utm_medium = useSelector(utmMediumSelector)
  const lotteryConfig = useSelector(lotteryConfigSelector)

  useEffect(() => {
    if (!sponsorship) {
      loadSponsorship()
    }
  }, [])

  const loadSponsorship = async () => {
    try {
      dispatch(setLotteryLoading(true))

      const res = await Axios({
        method: 'get',
        url: `${siteConfig.BACKEND_URL}/lottery_v2/sponsor`,
        params: {
          id: props.sponsorshipId,
        },
        headers: {},
      })

      const response: BaseResponse<
        Triplet<WheelSponsorshipDTO, VenueDTO, LotteryConfigurationResponse>
      > = res.data

      if (!response.data) {
        setIsError(true)
        return
      }

      if (response.data.right) {
        dispatch(setLotteryConfiguration(response.data.right))
      }

      setSponsorship(response.data.left)
      setVenue(response.data.middle)
    } catch (err) {
      alert(tt('error'))
    } finally {
      dispatch(setLotteryLoading(false))
    }
  }

  const isLoaded = !!sponsorship && !!venue
  let url = ''

  if (isLoaded) {
    url = buildDestinationURL(
      sponsorship,
      venue,
      utm_campaign,
      utm_source,
      utm_medium
    )
  }

  if (!isLoaded) {
    return <div></div>
  }

  const isEcommerce = lotteryConfig?.isEcommerce && lotteryConfig?.site

  const text1 = tt(
    isEcommerce
      ? 'sponsorship_introduction_text_1_ecommerce'
      : 'sponsorship_introduction_text_1',
    isEcommerce
      ? {
          siteName: lotteryConfig?.site,
        }
      : {
          venueName: venue?.name,
          venueAddress: venue?.address || '[adresse inconnue]',
        }
  )

  const text2 = tt(
    isEcommerce
      ? 'sponsorship_introduction_text_2_ecommerce'
      : 'sponsorship_introduction_text_2',
    isEcommerce
      ? {
          siteName: lotteryConfig?.site,
        }
      : {
          venueName: venue?.name,
        }
  )
  return (
    <>
      {!isError && isLoaded && !!venue && !!sponsorship && !sponsorship.used && (
        <div className="w-margin-bottom cm-pad w-margin-top-2-thirds">
          <h2 className="h2 highlight">
            {tt('sponsorship_introduction_title')}
          </h2>
        </div>
      )}

      {!isError && isLoaded && !!venue && !!sponsorship && sponsorship.used && (
        <div className="w-margin-bottom cm-pad">
          <h2 className="h2 highlight w-margin-top-2-thirds">
            {tt('sponsorship_introduction_consumed')}
          </h2>
        </div>
      )}

      {isError && (
        <div className="w-margin-bottom cm-pad">
          <p>{tt('error')}</p>
        </div>
      )}

      {!isError && !isLoaded && (
        <div className="w-margin-bottom cm-pad">
          <p>{tt('loading')}</p>
        </div>
      )}

      {!isError && isLoaded && !!venue && !!sponsorship && sponsorship.used && (
        <div className="w-margin-bottom cm-pad">
          <p>{tt('sponsorship_introduction_already_played')}</p>
        </div>
      )}

      {!isError && isLoaded && !!venue && !!sponsorship && !sponsorship.used && (
        <>
          <div className="w-margin-bottom cm-pad">
            <p>{text1}</p>
          </div>
          <div className="w-margin-bottom cm-pad">
            <p>{text2}</p>
          </div>
        </>
      )}

      <div style={{ flexGrow: 1 }} />

      {!!sponsorship && !sponsorship.used && !!url && (
        <div className="w-margin-bottom-half cm-pad">
          <WheelButton
            id="sponsorship-int"
            href={url}
            forceTargetSelf={true}
            text={
              lotteryConfig?.customSponsoreeCTAText || tt('discover_your_gift')
            }
          />
        </div>
      )}
    </>
  )
}

export default SponsorshipIntroduction

function buildDestinationURL(
  sponsorship: WheelSponsorshipDTO | undefined,
  venue: VenueDTO | undefined,
  utm_campaign: string | undefined | null,
  utm_source: string | undefined | null,
  utm_medium: string | undefined | null
) {
  if (!sponsorship || !venue) {
    return ''
  }

  let url = `${window.location.origin}${window.location.pathname}?venueId=${venue.id}&spid2=${sponsorship.id}`

  if (!!utm_campaign) {
    url = `${url}&utm_campaign=${utm_campaign}`
  }

  if (!!utm_source) {
    url = `${url}&utm_source=${utm_source}`
  }

  if (!!utm_medium) {
    url = `${url}&utm_medium=${utm_medium}`
  }

  return url
}
