import React from 'react'
import { SVGComponentProps } from './Balloon'
import { renderStyles } from '../helpers'

export type SmallGiftProps = SVGComponentProps

export default function SmallGift(props: SmallGiftProps) {
  return (
    <div
      style={{
        width: props.width,
        position: 'absolute',
        top: props.top,
        left: props.left,
        right: props.right,
        bottom: props.bottom,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 747.54 775.12"
        style={{ height: '100%', width: '100%' }}
      >
        <defs>
          <style>{`
        ${renderStyles()}
        .sg-fill-light { fill:${props.colors.gift2ColorL}} 
        .sg-fill {fill:${props.colors.gift2Color}} 
        .sg-fill-dark{fill:${props.colors.gift2ColorD}}
        `}</style>
        </defs>
        <g id="b9beb93e-550b-49c4-998d-8bec718c5ad6" data-name="Layer 2">
          <g id="bdb05c23-d580-4e3c-b9c5-6f1527a5e611" data-name="OBJETS_top">
            <polygon
              points="747.54 561.07 381.53 774.49 166.84 229.59 532.84 16.17 747.54 561.07"
              className="sg-fill-dark"
            />
            <polygon
              points="214.69 774.67 381.53 774.49 166.84 229.59 0 229.77 214.69 774.67"
              className="cs-fill-color-red"
            />
            <polygon
              points="214.69 774.67 381.53 774.49 166.84 229.59 0 229.77 214.69 774.67"
              className="sg-fill-dark"
            />
            <polygon
              points="285.58 775.12 310.99 775.09 96.12 229.66 70.72 229.69 285.58 775.12"
              className="cs-fill-color-grey-2"
            />
            <polygon
              points="366 16.35 532.84 16.17 166.84 229.59 0 229.77 366 16.35"
              className="sg-fill-light"
            />

            <polygon
              points="468 16.29 442.38 16.32 77.01 229.76 102.63 229.74 468 16.29"
              className="sg-fill"
            />
            <polygon
              points="328.11 135.91 351.2 122.32 181.74 124.04 158.66 137.63 328.11 135.91"
              className="sg-fill"
            />

            <polygon
              points="465.81 14 440.19 14.03 70.38 229.7 96.01 229.67 461.95 16.25 466.55 16.24 465.81 14"
              className="cs-fill-color-white"
            />
            <polygon
              points="332.3 133.33 355.39 119.75 188.55 119.93 165.46 133.51 332.3 133.33"
              className="cs-fill-color-white"
            />
            <polygon
              points="567.06 666.3 543.92 679.92 329.39 135.5 352.53 121.89 567.06 666.3"
              className="sg-fill-dark"
            />
            <polygon
              points="570.24 666.18 547.1 679.8 331.81 133.09 354.95 119.48 570.24 666.18"
              className="cs-fill-color-white"
            />

            <path
              d="M287.93,158.94s-16.51-15.7-22.32-19.61,27,4.58,27,4.58l-.06,11.51Z"
              className="sg-fill"
            />

            <path
              d="M242.13,134.11c17.18,0,45.35,13.65,50.45,21.31,7.37,11.08,30.06-4.21,26-12.76-4.69-9.85-51.47-22.81-51.47-22.81Z"
              className="cs-fill-color-white"
            />
            <path
              d="M313.23,146a6.69,6.69,0,0,0-.88-1.34l-25.1,14.1c3.88,5.83,3.3,30.09,4.89,49.19,1.49,18,20,40.92,24.69,44.9l19.32-4.75s-15.13-26-21.06-48C308.79,176.73,315.34,150.45,313.23,146Z"
              className="sg-fill-dark"
            />
            <path
              d="M318.55,142.66a6.62,6.62,0,0,0-.87-1.34l-25.1,14.1c3.88,5.83,2.14,28,5.48,47.84,3,17.82,21,39.64,25.68,43.62l22.73-7.48s-17.22-21.57-23.15-43.58C317,172.41,320.67,147.08,318.55,142.66Z"
              className="cs-fill-color-white"
            />
            <path
              d="M268.69,18.86c-20.34,29.94-7.8,107.68-7.8,107.68,1.2-.48,11.28-.08,15-1.74,22.67-10,89.26-44,89-82.76C364.68-3.06,289-11.08,268.69,18.86Zm78.17,26.9c.24,40.29-63.62,66.26-71.18,69.32,0,0-10.09-67.13,8.08-93.88S346.63,5.48,346.86,45.76Z"
              className="cs-fill-color-grey-2"
            />
            <path
              d="M283.7,17.12c-20.34,29.94-7.79,107.68-7.79,107.68,8.47-3.42,91.49-37.51,91.22-82.6S304-12.82,283.7,17.12ZM361.87,44c.24,40.29-73.93,70.74-81.49,73.79,0,0-11.21-69.44,7-96.19S361.64,3.74,361.87,44Z"
              className="cs-fill-color-white"
            />
            <path
              d="M124.74,112.1c23,32,98.22,24.07,122.9,20.12,4.07-.65,12.12-6.41,13.39-6.66,0,0-36.32-70.7-71-84.38S97.9,74.86,124.74,112.1ZM179,51.23c31,12.21,63,72.87,63,72.87-8.06,1.56-76.18,14.59-100.16-18.68S148,39,179,51.23Z"
              className="cs-fill-color-white"
            />
            <path
              d="M123,113.41c26.84,37.24,115.59,20.56,124.61,18.81,0,0-36.33-70.71-71-84.38S96.19,76.17,123,113.41ZM176.32,53.5c31,12.22,63.45,75.38,63.45,75.38-8.06,1.56-87.34,16.47-111.32-16.8S145.33,41.28,176.32,53.5Z"
              className="cs-fill-color-grey-2"
            />
          </g>
        </g>
      </svg>
    </div>
  )
}
