import React, { FunctionComponent } from 'react'
import './styles.scss'
import { tt } from '../../i18n'

interface Props {
  show: boolean
  isIOS: boolean
  isIG: boolean
  isIGStory: boolean
  isGoogle: boolean
  isTikTok: boolean
  renderCTA?: () => any
}

const Tuto: FunctionComponent<Props> = (props: Props) => {
  const display =
    props.isIG || props.isIGStory || props.isGoogle || props.isTikTok

  if (!display) {
    return null
  }

  let text = ''

  if (props.isIOS) {
    if (props.isGoogle) {
      text = tt('tuto_iphone_google')
    } else if (props.isIG || props.isTikTok) {
      text = tt('tuto_iphone_ig')
    } else if (props.isIGStory) {
      text = tt('tuto_iphone_ig_story')
    }
  } else {
    text = tt('tuto_android')
  }

  const renderSeparator = () => {
    return <div className="tuto-sep">&nbsp;</div>
  }

  return (
    <div id="tuto" style={{ display: props.show ? 'block' : 'flex' }}>
      <div id="tuto-bg" />
      <div id="tuto-fg">
        {props.isIOS && (props.isIG || props.isIGStory || props.isTikTok) && (
          <>
            <div className="tuto-status-bar">
              <span className="tuto-ios-bck-arrow">&#9664;</span>
              <span>&nbsp;Safari</span>
              <i className="fas fa-signal" style={{ marginLeft: 3 }} />
              <i className="fas fa-wifi" style={{ marginLeft: 3 }} />
            </div>
            <img
              className="twic tuto-hand-high"
              data-src={`image:/cdm/img/tutos/handhigh.png`}
            />
          </>
        )}

        <span className="tuto-title">ATTENTION</span>
        {renderSeparator()}
        <span className="tuto-text">{text}</span>

        {!props.isIOS && (
          <>
            {renderSeparator()}
            <div className="img-android-ct">
              <img
                className="twic"
                data-src={`image:/cdm/img/tutos/arrow_android.png`}
                style={{ height: '20vh' }}
              />
            </div>
          </>
        )}

        {renderSeparator()}
        <div className={'tuto-cta-ct tuto-cta-fadein'}>
          {props.renderCTA && props.renderCTA()}
        </div>

        {props.isIOS && props.isGoogle && (
          <>
            <div className="ios-tab-img-ct">
              <img
                className="twic"
                data-src={`image:/cdm/img/tutos/tabs_ios.png`}
                style={{ height: '5vh' }}
              />
            </div>
            <img
              className="twic tuto-hand-low"
              data-src={`image:/cdm/img/tutos/handlow.png`}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default Tuto
