import React, { FunctionComponent } from 'react'
import './styles.scss'

interface Props {
  id?: string
  title1: string
  title2?: string
  className?: string
  title1ClassName?: string
}

const TitleV2: FunctionComponent<Props> = (props: Props) => {
  return (
    <div className={props.className}>
      <span
        id={props.id}
        className={`d-block k-glm-title-1 ${props.title1ClassName || ''}`}
      >
        {props.title1}
      </span>
      {!!props.title2 && (
        <span className="d-block k-glm-title-2">{props.title2}</span>
      )}
    </div>
  )
}

export default TitleV2
