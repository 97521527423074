import moment from 'moment'
import * as React from 'react'
import { FunctionComponent } from 'react'
import { showPrivacyPolicyModal } from '../../redux/actions/app'
import { useDispatch } from 'react-redux'

interface Props {
  customEULA?: string
  companyName?: string
}

const EULA: FunctionComponent<Props> = (props: Props) => {
  const dispatch = useDispatch()
  if (!!props.customEULA) {
    return (
      <iframe
        title="Jeu - Conditions Générales d'Utilisation"
        src={props.customEULA}
        style={{ width: '100%', height: 500 }}
      />
    )
  }
  return (
    <>
      <div className="row">
        <div className="col-12 text-center">
          <h1>Règlement et Conditions Générales d'Utilisation</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p><small>Mis à jour le 08.03.2024</small></p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2>Article I : Organisation et contexte</h2>
          <p>
            La société Toot Sweet SAS au capital de 54614.40 euros, immatriculée
            au RCS de Paris sous le n°811 346 287, ayant son siège social au 23
            rue des Lombards 75004 Paris (ci-après dénommée la « Société
            Editrice » ou « l’éditrice ») développe des Jeux sur
            smartphone (ci-après dénommé les « Jeux ») pour le compte
            d'Enseignes souhaitant mettre en place une animation basée sur le
            jeu (restaurants, épiceries fines, salons de beauté, cinémas,
            chocolateries, hôtels, boulangeries, centres commerciaux, cinémas
            etc.). Il est expressément entendu que le rôle de la Société
            Editrice se limite à fournir la plateforme technique en marque blanche
            permettant à l'Etablissement Partenaire d'opérer le jeu.
          </p>
          <h2>Article II : Modalités de participation</h2>
          <p>
            La participation aux Jeux est réservée à toute personne exposée à
            l'animation, notamment à travers un QR code (ci-après dénommés les «
            Joueurs »). Tout Joueur qui souhaite participer au Jeu pourra être
            invité à réaliser une ou plusieurs actions prédéterminées par
            l'Enseigne (laisser un avis, s’abonner aux réseaux sociaux, etc.).
          </p>
          <h2>Article III : Dotation</h2>
          <p>
            La dotation du Jeu consiste en un cadeau offert par l'Établissement
            Partenaire et/ou des Gros Lots mis en jeu par la Société
            Editrice.
          </p>
          <p>
            Dans le cas des cadeaux offerts par l'Etablissement partenaire, la
            nature de la dotation, le stock mis en jeu, ainsi que les conditions
            de récupération sont définis
            spécifiquement par l’Etablissement et peuvent évoluer à tout moment.
          </p>
          <p>
            Dans le cas des Gros Lots, la nature de la dotation, le stock mis
            enjeu, la liste des Établissements participants ainsi que les
            conditions de récupération sont définis par la Société Editrice
            et sont annoncés explicitement au démarrage du jeu lorsqu'ils sont
            disponibles.
          </p>
          <h2>Article IV : Désignation des gagnants</h2>
          <p>
            Dans le cas des cadeaux offerts par l'Etablissement partenaire, les
            tirages au sort sont effectués automatiquement consécutivement à la
            participation grâce à un algorithme aléatoire. A chaque
            participation, l’algorithme maximisera le nombre de cadeaux
            distribués compte tenu des quantités disponibles ainsi que du nombre
            de joueur moyen par semaine. Les gagnants recevront alors un «
            coupon cadeau » par email.
          </p>
          <p>
            Dans le cas des Gros Lots, le joueur participe à une tombola avec
            des tickets matérialisés par des "Trèfles d'Or" numérotés qu'il
            reçoit par email. Chaque ticket numéroté correspond ainsi à une
            chance de gagner. Le tirage au sort est effectué de manière
            aléatoire par un algorithme le 1er du mois suivant la participation.
            Le gagnant désigné recevra un mail incluant toutes les modalités de
            récupération du Gros Lot.
          </p>
          <p>
            Si le gagnant ne se manifeste pas dans un délais d'une semaine après
            le résultat du tirage au sort, la Société Editrice se réserve
            le droit de réaliser un nouveau tirage au sort la semaine suivante
            ou encore d'annuler le tirage au sort si le Gros Lot a une date
            d'expiration.
          </p>
          <p>
            S'il s'avère qu'un participant a gagné en ne respectant pas le
            présent règlement, par des moyens frauduleux, ou par des moyens
            autres que ceux résultant du processus décrit par la Société
            Editrice ou en violant l’une des conditions de participation,
            il perdrait alors sa qualité de gagnant, sans préjudice des
            éventuelles poursuites susceptibles d'être intentées à l'encontre du
            participant par la Société Editrice.
          </p>
          <p>
            Dans ce cas, le participant recevra un email pour l’informer de la
            perte de sa qualité de gagnant incluant un rappel du présent
            règlement.
          </p>
          <h2>Article V : Conditions de participation</h2>
          <p>
            L’inscription au Jeu implique l'acceptation sans réserve et le
            respect des dispositions du présent règlement, accessible à tout
            moment durant le Jeu et au sujet duquel la Société Editrice
            statue en dernier ressort sur tout cas litigieux et toute difficulté
            d’interprétation. En cas de refus de tout ou partie du présent
            règlement, il appartient aux Joueurs de s'abstenir de participer au
            Jeu.
          </p>
          <p>
            La participation est strictement nominative et le joueur ne peut en
            aucun cas jouer sous plusieurs pseudonymes ou pour le compte
            d’autres membres. Il est entendu qu’un participant est défini comme
            une personne physique unique : toute utilisation de comptes membres
            différents par un même participant sera considérée comme une
            tentative de fraude entraînant l’élimination définitive du
            participant. Il est rigoureusement interdit pour un participant de
            jouer à partir d'un email ouvert au bénéfice d'une autre personne.
            Un seul et unique email devra être utilisé par personne physique
            participante. Il est rigoureusement interdit, par quelque procédé
            que ce soit, de modifier ou de tenter de modifier les dispositifs de
            Jeu proposés, notamment afin d'en modifier les résultats ou
            d'influencer par un moyen automatisé ou déloyal la validité du
            tirage au sort. Afin de prévenir le risque de fraude, chaque
            participant ne peut jouer qu’une seule fois par Etablissement dans
            une même journée. Si besoin, la Société Editrice s’autorise à
            faire toutes les vérifications nécessaires afin de vérifier
            l’absence de fraude.
          </p>

          <h2>Article VI : Remise des dotations</h2>

          <p>
            Les gagnants recevront systématiquement leur dotation par email.
          </p>
          <p>
            Les modalités de règlement de la dotation sont systématiquement
            précisées sur chaque coupon cadeau dès lors qu’un participant
            acquiert le statut de gagnant (exemples: coupon cadeau valide à
            partir du lendemain de la participation, code promo valable sur le
            site internet de l’Etablissement Partenaire, etc.)
          </p>
          <p>
            Dans le cas des dotations à récupérer au sein de l'Etablissement
            partenaire, les Gagnants devront soit montrer leur coupon au
            personnel de l’Etablissement soit insérer un code promotionnel
            directement sur un site web spécifié sur le coupon. Le Joueur devra
            nécessairement faire valider son coupon cadeau par le personnel de
            l’Etablissement pour recevoir sa dotation (la validation se fait à
            l’aide d’un code à quatre chiffres ou d’un QR code directement sur
            le coupon cadeau).
          </p>
          <p>
            Dans le cas où l’email du gagnant s’avérerait manifestement erroné,
            il n’appartiendrait en aucun cas à la Société Editrice
            d’effectuer des recherches de quelque nature que ce soit pour
            retrouver le gagnant. Celui-ci perdrait le bénéfice de son gain et
            ne pourrait prétendre à aucune compensation.
          </p>

          <h2>Article VII : Données personnelles</h2>
          <p>
            {props.companyName} traite les données personnelles des joueurs.
            Pour plus d'informations à ce sujet, le joueur est invité à
            consulter la{' '}
            <span
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={() => dispatch(showPrivacyPolicyModal())}
            >
              politique de confidentialité
            </span>{' '}
            accessible pendant toute l'utilisation du jeu concours
          </p>

          <h2>Article VIII : Loi applicable et juridiction</h2>

          <p>
            La participation à ce Jeu implique l'acceptation sans réserve du
            présent règlement en toutes ses stipulations. En cas de
            contestation, seul sera recevable un courriel à
            contact@cadeaudelamaison.com dans un délai de 30 (trente) jours
            maximum après la date de participation. Sauf en cas d'erreurs
            manifestes, il est convenu que les informations résultant des
            systèmes informatiques de la Société Editrice ont force
            probante dans tout litige quant aux conditions de connexion, aux
            soumissions de contributions et au traitement informatique desdites
            informations relatives au Jeu. Les participants sont soumis à la
            réglementation française applicable aux jeux et concours. Tout
            litige qui ne pourra être réglé à l'amiable sera soumis aux
            tribunaux compétents dont dépend le siège social de la Société
            Editrice, sauf dispositions d'ordre public contraires. Dans
            l'hypothèse où l'une des clauses du présent règlement serait
            déclarée nulle et non avenue, cela ne saurait en aucun cas affecter
            la validité du règlement lui-même et toutes ses autres clauses
            conserveraient leur force et leur portée.
          </p>

          <p>
            Pour toute question relative au fonctionnement du jeu :{' '}
            <a href="mailto:contact@kadow.club">contact@kadow.club</a>
          </p>

          <p>Copyright Ⓒ {moment().year()} Toot Sweet SAS</p>
        </div>
      </div>
    </>
  )
}

export default EULA
