import React from 'react'

interface Props {
  width: number
  height: number
}

export default class WheelReflection extends React.PureComponent<Props> {
  getStyles() {
    return `
    .cls-wheel-reflection-1{isolation:isolate;}
    .cls-wheel-reflection-2,.cls-wheel-reflection-3{mix - blend - mode:overlay;}
    .cls-wheel-reflection-2{fill:url(#linear-gradient);}
    .cls-wheel-reflection-3{fill:url(#linear-gradient-2);}
    `
  }

  render() {
    return (
      <svg
        width={this.props.width}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1271.56 1309.34"
      >
        <defs>
          <style>{this.getStyles()}</style>
          <linearGradient
            id="linear-gradient"
            x1="8866.74"
            y1="-4261.47"
            x2="8980.22"
            y2="-4130.19"
            gradientTransform="matrix(-0.57, 0.82, 0.82, 0.57, 9140.4, -4671.89)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#fff" stopOpacity="0.6" />
            <stop offset="1" stopColor="#fff" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="7644.92"
            y1="-3780.66"
            x2="7837.76"
            y2="-3742.73"
            gradientTransform="matrix(-0.46, 0.89, 0.89, 0.46, 7997.45, -4685.56)"
            xlinkHref="#linear-gradient"
          />
        </defs>
        <g className="cls-wheel-reflection-1">
          <g id="Layer_2" data-name="Layer 2">
            <g id="REFLET">
              <path
                className="cls-wheel-reflection-2"
                d="M233.25,415.47c59.67,36.14,127.36-83.9,354.56-100.81,271.57-20.2,408.65,109,436.86,31.79,23.34-63.84-195-262.29-465.42-216.34C353.47,165.08,136.79,357.07,233.25,415.47Z"
              />
              <path
                className="cls-wheel-reflection-3"
                d="M1016.1,422.51c0-38.95,43.19-70.26,73.39-37.14s55.53,103,25.7,120.45S1016.05,484.4,1016.1,422.51Z"
              />
              <rect
                fill={'transparent'}
                x="1208.23"
                width="63.33"
                height="82.08"
              />
              <rect
                fill={'transparent'}
                y="1222.15"
                width="87.19"
                height="87.19"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
