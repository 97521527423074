import React, { FunctionComponent, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showFooterSelector } from '../redux/selectors/app'
import { tt } from '../i18n'
import { setMarketingModalState, showMenu } from '../redux/actions/app'
import { lotteryConfigSelector } from '../redux/selectors/lottery'
import WheelButton from './cta/WheelButton'

interface Props {
  handleHelpModalOpen: () => void
}

const Footer: FunctionComponent<Props> = ({ handleHelpModalOpen }) => {
  const dispatch = useDispatch()

  const showFooter = useSelector(showFooterSelector)
  const lotteryConfig = useSelector(lotteryConfigSelector)

  const displayMenu = useCallback(() => {
    dispatch(showMenu())
  }, [dispatch])

  if (!showFooter) {
    return <></>
  }

  return (
    <div className="bottom-legal-container">
      <div className="bottom-legal-cell">
        <a className="bottom-legal" onClick={displayMenu}>
          {tt('rules')}
        </a>
      </div>
      <div className="bottom-legal-cell">
        {!lotteryConfig?.displayContactFormOnHelp && (
          <span
            className="bottom-legal"
            onClick={() => dispatch(setMarketingModalState(true, true, false))}
          >
            {tt('contact_pro')}
          </span>
        )}
      </div>
      <div className="bottom-legal-cell">
        {lotteryConfig?.displayContactFormOnHelp && (
          <span
            className="bottom-legal"
            onClick={() => dispatch(setMarketingModalState(true, false, true))}
          >
            {tt('contact')}
          </span>
        )}
        {!lotteryConfig?.displayContactFormOnHelp && (
          <span
            className="bottom-legal"
            id="help_modal"
            onClick={handleHelpModalOpen}
          >
            {tt('need_help')}
          </span>
        )}
      </div>
    </div>
  )
}

export default Footer
