import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import TSConstants from '../../utils/TSConstants'
import { renderLogo } from './renderLogo'
import { tt } from '../../i18n'
import QrCode from 'qrcode.react'
import {
  COLOR_TRANSPARENT,
  COLOR_WHITE,
} from '../../components/FortuneWheel/WheelColors'
import React from 'react'
import { useSelector } from 'react-redux'
import { skinSelector } from '../../redux/selectors/lottery'
import Skin from '../../model/Skin'

export const youNeedASmartphone = (
  hasBG_URL: boolean,
  displayFraudAlert: boolean,
  gameContainerHeight: number,
  config?: LotteryConfigurationResponse,
  skin?: Skin | undefined,
  hasNotch?: boolean
) => {
  const paramz = new URLSearchParams(window.location.search)
  const className = config?.disableCentering ? '' : 'text-center'
  return (
    <>
      {hasBG_URL && (
        <div className="game-container">
          <img
            className="twic"
            src={TSConstants.PLACEHOLDER_URL}
            data-src={`image:/cdm/custom_bgs/${config?.bgURL}`}
            data-src-transform="cover=WxH"
            style={{
              position: 'absolute',
              top: displayFraudAlert ? 44 : 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '100%',
              height: gameContainerHeight,
              zIndex: 0,
            }}
          />
        </div>
      )}
      <div
        className={`game-container ${className} ${hasBG_URL ? 'no-bg' : ''}`}
        style={{ zIndex: 1 }}
      >
        {config &&
          renderLogo(
            config,
            hasBG_URL,
            undefined,
            false,
            false,
            skin?.hasNotch || !!hasNotch
          )}
        {!config && (
          <div className="cm-pad logo-container">
            <img
              className="twic logo"
              src={TSConstants.PLACEHOLDER_URL}
              data-src="image:/cdm/kadow/img/logo_2.png"
            />
          </div>
        )}
        <div className="cm-pad w-margin-top-half w-margin-bottom-half">
          <h2 className="h2 highlight">{tt('you_need_a_smartphone_title')}</h2>
        </div>
        <div className="cm-pad w-margin-bottom-half">
          <p>
            {config?.youNeedASmartphoneText || tt('you_need_a_smartphone_text')}
          </p>
        </div>
        <div className="cm-pad w-margin-top w-margin-bottom-half d-flex align-items-center justify-content-center">
          {!!paramz.get('venueId') && (
            <QrCode
              className=""
              size={280}
              bgColor={COLOR_TRANSPARENT}
              fgColor={config?.colBodyFont || COLOR_WHITE}
              value={window.location.href}
            />
          )}
        </div>
      </div>
    </>
  )
}
