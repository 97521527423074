import moment from 'moment'
import * as React from 'react'
import { FunctionComponent } from 'react'
import { tt } from '../../i18n'

interface Props {
  customCookiesPolicy?: string
  companyName?: string
  gdprEmail: string
}

const CookiesPolicy: FunctionComponent<Props> = (props: Props) => {
  if (!!props.customCookiesPolicy) {
    return (
      <iframe
        title={tt('cookies_policy')}
        src={props.customCookiesPolicy}
        style={{ width: '100%', height: 500 }}
      />
    )
  }
  return (
    <>
      <div className="row">
        <div className="col-12 text-center">
          <h1>Politique des cookies</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2 className="mt-5 mb-3">
            Quelle est l’utilité de cette politique ?
          </h2>
          <p>
            <b>
              {props.companyName} accorde une grande importance à la
              confidentialité de vos données qui représente pour nous un gage de
              sérieux et de confiance, peu importe qu’il s’agisse de cookies ou
              de données personnelles.
            </b>
          </p>
          <p>
            La politique <b>cookies</b> vise à vous expliquer pourquoi nous
            utilisons des cookies ainsi que tout autre type de <b>traceurs</b>{' '}
            lorsque vous utilisez notre plateforme de jeux concours.
          </p>
          <p>
            En revanche, cette politique cookies ne concerne que les traitements
            relatifs au <b>dépôt de cookies et traceurs</b>. Pour les autres
            traitements que nous effectuons, merci de consulter notre{' '}
            <b>politique de confidentialité des données</b>, accessible à tout
            moment sur la plateforme de jeu concours.
          </p>
          <h2 className="mt-5 mb-3">
            Qu'est-ce qu’un cookie et quelle est son utilité ?
          </h2>
          <p>
            Les cookies et autres traceurs sont des{' '}
            <b>petits fichiers “texte”</b>
            placés et hébergés par un serveur sur votre{' '}
            <b>smartphone, tablette ou ordinateur</b> lorsque vous consultez un
            site internet.
          </p>
          <p>
            Les cookies{' '}
            <b>ne permettent pas de vous identifier personnellement</b> mais
            identifient votre terminal (ex : smartphone, tablette, etc.) via
            votre adresse IP, ainsi que différentes informations liées à votre
            navigation (ex : durée de navigation, pages consultées, etc.) afin
            de:
          </p>
          <ul>
            <li>
              mémoriser votre identifiant afin de vous reconnaître lorsque vous
              vous connectez de nouveau sur un site internet et de conserver
              votre choix (ex : langues, refus des cookies, conserver votre
              panier, etc.).
            </li>
            <li>
              tracer et analyser l'usage que vous faites d'un site internet à
              des fins statistiques afin d'améliorer votre expérience et de vous
              fournir un service adapté à vos besoins.
            </li>
            <li>
              permettre de naviguer et d'utiliser un site internet dans des
              conditions optimales.
            </li>
            <li>
              analyser l'usage que vous faites d'un site internet afin de vous
              fournir de la publicité adaptée à vos besoins soit sur ce site
              internet soit sur d'autres sites internet.
            </li>
          </ul>
          <p>
            Pour plus d’informations sur ce sujet, vous pouvez consulter les
            sites internet suivants :{' '}
          </p>
          <ul>
            <li>
              <a href="www.cnil.fr" target="_blank">
                www.cnil.fr
              </a>
            </li>
            <li>
              <a href="www.allaboutcookies.org" target="_blank">
                www.allaboutcookies.org
              </a>
            </li>
            <li>
              <a href="www.youronlinechoices.eu" target="_blank">
                www.youronlinechoices.eu
              </a>
            </li>
          </ul>
          <h2 className="mt-5 mb-3">
            Pourquoi utilisons-nous des “cookies” et pour combien de temps ?
          </h2>
          <p>
            Lorsque vous vous connectez sur notre plateforme de jeux concours,
            nous sommes amenés à déposer des cookies sur votre terminal
            <b>uniquement</b> pour les <b>raisons et les fondements</b> suivants
            :
          </p>
          <ul>
            <li>
              <b>Cookies techniques</b> qui permettent de jouer à notre jeu
              concours en toute sécurité sur la base de notre intérêt légitime à
              vous proposer un service de qualité.
            </li>
            <li>
              <b>Cookies statistiques</b> qui permettent de connaître l’usage
              que vous faites de notre jeu concours (ex : durée de visionnage
              d’une page, score, etc.) sur la base de votre consentement
              préalable, afin de vous fournir un service adapté et de qualité.
            </li>
            <li>
              <b>Cookies</b> qui permettent d'identifier la source de votre
              arrivée sur notre jeu concours afin d'améliorer notre service au
              quotidien, sur la base de votre <b>consentement préalable</b>.
            </li>
          </ul>
          <p>
            Dans tous les cas, les cookies déposés, quelle que soit leur nature,
            sont conservés pendant une <b>durée maximale de 13 mois</b> à
            compter de leur installation et ne se renouvellent qu’en cas de
            nouvelle connexion sur notre plateforme de jeux concours à
            l’expiration de ce délai. Si vous souhaitez plus de détails sur les
            durées d'utilisation de nos cookies, n'hésitez pas à nous contacter
            à l'adresse {props.gdprEmail}.
          </p>
          <p>
            Toutefois, les données collectées à l’aide de ces cookies peuvent
            être conservées plus longtemps. Pour plus d’informations à ce sujet,
            vous pouvez consulter notre politique de confidentialité accessible
            à tout moment sur la plateforme de jeu concours ou sur l’email de
            réception du cadeau.
          </p>
          <h2 className="mt-5 mb-3">
            Quel contrôle avez-vous sur ces “cookies” ?
          </h2>
          <p>
            Vous ne pouvez pas vous{' '}
            <b>opposer au dépôt de cookies techniques</b>
            puisqu’ils sont nécessaires au bon fonctionnement de notre jeu
            concours. A défaut, notre jeu concours serait potentiellement
            inutilisable.
          </p>
          <p>
            Cependant, en plus des droits détaillés dans notre politique de
            confidentialité, vous disposez bien évidemment du droit de{' '}
            <b>refuser ou de retirer votre consentement</b> à ce que tous les
            autres types de cookies soient installés sur votre terminal.
          </p>
          <p>
            Le droit de refuser des cookies s’effectue à tout moment à l’aide de
            notre bandeau cookies. Dans l’hypothèse où le bandeau cookies ne
            s’afficherait plus, vous pouvez également paramétrer les cookies
            directement sur votre navigateur en suivant les instructions
            suivantes :{' '}
            <a
              href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en%20%22Chrome%22"
              target="_blank"
            >
              Chrome
            </a>
            ,{' '}
            <a
              href="https://support.microsoft.com/fr-fr/microsoft-edge/supprimer-les-cookies-dans-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09#:~:text=Supprimez%20les%20cookies&text=S%C3%A9lectionnez%20Param%C3%A8tres%20%3E%20confidentialit%C3%A9%2C%20recherche%20et,les%20donn%C3%A9es%20de%20navigation%20maintenant."
              target="_blank"
            >
              Microsoft Edge
            </a>
            ,{' '}
            <a
              href="https://support.apple.com/fr-fr/HT201265#:~:text=Pour%20effacer%20votre%20historique%20et,Effacer%20historique%2C%20donn%C3%A9es%20de%20site."
              target="_blank"
            >
              Safari
            </a>
            ,{' '}
            <a
              href="https://support.mozilla.org/fr/kb/effacer-cookies-donnees-site-firefox"
              target="_blank"
            >
              Firefox
            </a>{' '}
            et{' '}
            <a
              href="https://www.clubic.com/tutoriels/article-859236-1-comment-supprimer-cookies-opera.html"
              target="_blank"
            >
              Opera
            </a>
            .
          </p>
          <h2 className="mt-5 mb-3">
            Qui pouvez-vous contacter pour obtenir plus d’informations ?
          </h2>
          <p>
            Notre Délégué à la protection des données (“DPO”) est toujours à
            votre disposition pour vous expliquer plus en détail la manière dont
            nous utilisons des cookies et pour répondre à vos questions sur le
            sujet des données personnelles à l’adresse {props.gdprEmail}.
          </p>
          <h2 className="mt-5 mb-3">La politique peut-elle être modifiée ?</h2>
          <p>
            Nous sommes susceptibles de modifier notre politique cookies à tout
            moment pour l’adapter aux nouvelles exigences légales ainsi qu’aux
            nouveaux traitements que nous pourrions mettre en œuvre dans le
            futur. Vous serez évidemment informés de toute modification de cette
            politique.
          </p>
          <p>Certifié conforme par Dipeeo ®</p>
        </div>
      </div>
    </>
  )
}

export default CookiesPolicy
