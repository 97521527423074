import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WheelAnalyticsManager from '../../analytics/WheelAnalyticsManager'
import {
  async_fetch_ml_config,
  nextLotteryStep,
} from '../../redux/actions/lottery'
import './styles.scss'
import WheelButton from '../../components/cta/WheelButton'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import moment from 'moment'
import { tt } from '../../i18n'
import TSConstants from '../../utils/TSConstants'
import TitleV2 from '../../components/MonthlyLottery/Title/TitleV2'
import { QUIZZ_STEP_ML_GAME } from '../../redux/reducers/quizz'
import { setQuizzStep } from '../../redux/actions/quizz'
import { mlConfigSelector } from '../../redux/selectors/lottery'

interface Props {
  isQuizz?: boolean
  skillNextStep?: () => void
}

const MonthlyLotteryIntro: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch()

  const mlConfig = useSelector(mlConfigSelector)

  useEffect(() => {
    WheelAnalyticsManager.logEvent(LotteryV2StepType.ML_Intro)
    dispatch(async_fetch_ml_config())
  }, [])

  const next = () => {
    if (props.skillNextStep) {
      props.skillNextStep()
    }
    if (props.isQuizz) {
      dispatch(setQuizzStep(QUIZZ_STEP_ML_GAME))
    }
    dispatch(nextLotteryStep())
  }

  if (!mlConfig) {
    return null
  }

  return (
    <>
      <div className="cm-pad w-margin-top w-margin-bottom-half">
        <TitleV2 id="k-ml-title" title1={mlConfig?.title} />
      </div>

      <div className="cm-pad k-glm-text">
        <p>{mlConfig?.text}</p>
      </div>

      <div
        className="k-glm-intro-bottom"
        style={{
          flex: 1,
        }}
      >
        <img
          className="twic k-glm-intro-bottom-img"
          src={undefined}
          data-src={`image:/cdm/kadow/glm/${mlConfig?.img}`}
          data-src-transform="cover=WxH"
          style={{
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
          }}
        />
        <div className="k-glm-gradient" />
        <p className="k-glm-intro-bottom-text">
          {renderRemainingDays(mlConfig?.drawTs)}
        </p>
        <WheelButton
          id="k-glm-intro-cta"
          text={tt('start_the_game')}
          containerClassName="k-glm-intro-bottom-btn"
          onClick={next}
        />
      </div>
    </>
  )
}

export default MonthlyLotteryIntro

function renderRemainingDays(drawTs: number | undefined) {
  const rem = computeRemainingDays(drawTs)
  if (rem.days < 1) {
    let hours = rem.hours
    if (hours < 1) {
      return tt('ends_at_midnight')
    }
    if (hours <= 1) {
      return `${hours} ${tt('remaining_hours_singular')}`
    }
    return `${hours} ${tt('remaining_hours_plural')}`
  }
  return (
    <>
      {rem.days} {rem.days > 1 ? tt('days') : tt('day')}{' '}
      {rem.days > 1
        ? tt('remaining_days_plural')
        : tt('remaining_days_singular')}
    </>
  )
}

function computeRemainingDays(drawTs: number | undefined) {
  const endOfGameDate = drawTs
    ? moment(drawTs, 'X')
    : moment().add(1, 'month').startOf('month')
  const today = moment()
  const diffTs = endOfGameDate.unix() - today.unix()
  const days = diffTs / TSConstants.secondsInADay
  const hours =
    (diffTs % TSConstants.secondsInADay) / TSConstants.secondsInAnHour
  return {
    days: Math.trunc(days),
    hours: Math.trunc(hours),
  }
}
