import React from 'react'
import { FoliageProps } from './FoliageFG'
import { AspectRatio } from '../../../utils/aspect_ratio'

export function FoliageBG(props: FoliageProps) {
  let viewBox = '300 500 1100 2436'

  switch (props.aspectRatio) {
    case AspectRatio._16_9:
      viewBox = '250 450 1125 2436'
      break
    case AspectRatio._19_9:
    case AspectRatio._18_9:
      viewBox = '300 500 1100 2436'
      break
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        overflow: 'hidden',
      }}
    >
      <svg
        id="foliage-bg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }}
      >
        <defs>
          <style>
            {`
          .a5ecf17b-6622-42f5-920c-4cd1d999c72f{fill:${props.colors.v2FoliageColor1};}
          .f2529f77-0ff0-4132-b8a1-b291801aa23b{fill:${props.colors.v2FoliageColor2};}
          .b8454009-e807-489f-960d-f312455eb1f7{fill:${props.colors.v2FoliageColor3};}
          `}
          </style>
        </defs>
        <g id="f157b8b7-abb7-4659-bd5b-8537ac8f985d" data-name="Layer 2">
          <g id="e57331cf-9b21-4f5f-a9cc-350937a14061" data-name="GREENERY">
            <g id="b503c76d-bb0c-459f-8d9b-91a4213eab15" data-name="BG_TOP">
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M643,805.3c1.94-6.36,3.93-12.78,6-19.15S653,773.38,655.16,767c4.15-12.75,8.53-25.41,12.86-38.08,2.16-6.34,4.45-12.63,6.67-18.94s4.44-12.63,6.72-18.92c4.63-12.54,9.18-25.12,13.87-37.63q28.1-75.11,59.94-148.2L763.15,487c1.33-3,2.62-6.11,4-9.12l4.12-9,16.48-36.16c1.35-3,2.81-6,4.24-9l4.28-8.93,8.58-17.86,8.57-17.86,8.92-17.62,17.88-35.19,18.65-34.62,9.34-17.29,9.75-17,19.49-33.92L884.85,213l-19.31,34.59-9.65,17.3-9.22,17.6-18.42,35.22-17.62,35.77-8.79,17.89-8.42,18.12L785,407.58l-4.21,9.06c-1.4,3-2.84,6-4.16,9.09l-16.16,36.65-4,9.16c-1.36,3-2.61,6.16-3.92,9.24l-7.76,18.5q-31,74-58.52,149.84c-4.57,12.64-9,25.35-13.49,38-2.22,6.35-4.35,12.74-6.53,19.1s-4.38,12.72-6.48,19.13c-4.2,12.8-8.45,25.58-12.48,38.45-2.07,6.42-4,12.87-6,19.32-1,3.23-2,6.46-2.94,9.7l-2.89,9.76Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M856.38,271.08s58.73-2,118,48.4,68.72,87,68.72,87-66.64-18.47-108.48-48C887.39,325.18,856.38,271.08,856.38,271.08Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M829.81,321.7S887.09,327,940.59,384s59.72,94.33,59.72,94.33S937,451.89,898.72,417.6C855.54,378.92,829.81,321.7,829.81,321.7Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M614.36,305.4s52.81-15,114,15.78S803.62,382,803.62,382s-63-1.13-105.34-17.57C650.57,345.85,614.36,305.4,614.36,305.4Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M866.28,268.17s-58.71,8.84-122-34-75.71-77.94-75.71-77.94S736.76,166.68,781,191C831,218.34,866.28,268.17,866.28,268.17Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M834.5,325.34s-57.62,12.23-121.89-25.9-77.92-71.79-77.92-71.79,67.81,6.08,112.5,27.19C797.6,278.64,834.5,325.34,834.5,325.34Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M961.21,518.64s-52.88-5-102.17-57.81-55-87.29-55-87.29,58.4,24.57,93.68,56.35C937.56,465.73,961.21,518.64,961.21,518.64Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M601.29,360.6s49.42-13.13,106.14,16.68S777,435.39,777,435.39s-58.78-2.13-98.05-18.22C634.61,399,601.29,360.6,601.29,360.6Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M916.31,576.56s-48.14-11.95-88.62-68.06-42.54-89.17-42.54-89.17,51.43,31,81,65.53C899.41,523.83,916.31,576.56,916.31,576.56Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M586,423.72s46.49-14.86,101.34,10.43,68,51.34,68,51.34-55.89.94-93.71-12.27C618.94,458.31,586,423.72,586,423.72Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M894.05,610s-46.66-5.44-89.56-53.12S757,478.51,757,478.51s51.29,22.86,82.07,51.66C873.81,562.67,894.05,610,894.05,610Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M737.49,532.64s43.66,5.91,83.3,51.42,43.58,74.38,43.58,74.38-47.8-22.34-76.3-49.92C755.91,577.41,737.49,532.64,737.49,532.64Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M840.38,684.55s-39.5-4.78-76.06-44.29-40.61-64.78-40.61-64.78,43.52,19.13,69.72,43C823,645.48,840.38,684.55,840.38,684.55Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M792.52,732.72s-33.73-16-58-62.87-22.88-71.13-22.88-71.13S746,629,764.32,658.59C785,691.92,792.52,732.72,792.52,732.72Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M702,631.25s24.15,27.23,35.41,75.43,5.88,67.25,5.88,67.25-22.22-39.09-31.68-70.74C701,667.48,702,631.25,702,631.25Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M687.67,664.12s14.79,34.56,14.22,83.34-9.13,64.2-9.13,64.2-10.65-44.72-12-77.57C679.17,697,687.67,664.12,687.67,664.12Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M670.29,718.51s7.84,34.48,2.93,77.78-12.69,54.88-12.69,54.88-3.83-42.48-1.85-72.09A260.74,260.74,0,0,1,670.29,718.51Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M651.31,762.77a278.4,278.4,0,0,1-9.38,76.15c-11,40.76-19.6,50.05-19.6,50.05s5.95-42.08,12.25-70.29A404.51,404.51,0,0,1,651.31,762.77Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M604.74,866.28a357,357,0,0,1,23.66-58.34C643.81,778.22,650.15,773,650.15,773s-12.16,32.81-22.23,53.7A271.28,271.28,0,0,1,604.74,866.28Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M587.69,808.41s14-33.45,41.6-63.07,39.39-32.11,39.39-32.11S647.47,749.55,629.53,771A195.32,195.32,0,0,1,587.69,808.41Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M576.5,476.84s43.4-15.18,95.39,7S736.67,530,736.67,530s-52.49,2.41-88.26-8.93C608.05,508.32,576.5,476.84,576.5,476.84Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M719.23,573.35s-43,10.88-92.27-10.57-60.45-42.59-60.45-42.59,51.1.48,85.24,11.88C690.26,544.94,719.23,573.35,719.23,573.35Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M706.17,600.53s-36.52,23-85.59,19.94S557,602.64,557,602.64s46.28-15.7,79.53-16.59C674,585.06,706.17,600.53,706.17,600.53Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M563.14,685.64s28.52-33.13,73.75-49.56,61.46-9.79,61.46-9.79-38.86,30.87-68.91,44.44A174.22,174.22,0,0,1,563.14,685.64Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M570.05,754.86s24-36.15,63.25-63,53.74-26,53.74-26-33.18,37.22-59.17,57.32C598.56,745.86,570.05,754.86,570.05,754.86Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M1274.64,1186.41c20.69,2.83,14.37-119.87,80.31-129.6,58.85-8.69,132.55,16,152.51,87s29.23,150.39,15.25,195.29c-.36-.47-39.6-68.33-107-94.28s-60.2-10.57-61.14-8.36,68.16,42.26,95.48,60.93,67.17,48,62.7,75.94-33.8,100-41,112.61c-.44.15-38.34-77.49-64.45-105s-64.75-65.63-75-55.59,51.61,47.1,71.76,76.33,62.29,85.21,41.67,112-63.1,55.56-68.39,60.14c-.86.09,3.57-32.35-6-58.45s-68.3-137.29-85.61-126.95,37.8,64.49,54.84,104.1,37.42,82.66,6.56,99.2-68.7,28-78.09,34.1c-.15,0,12.58-38.39,9-67.7s-14.57-91.82-29.64-90.07,6.56,48.14,9.69,81,11.49,69.36-22.64,75.71-34-10.15-91.24-8.24c1-.92,6.86-19.19-32-51s-43.17-56.11-24.77-82.2,97.78-73.7,88.76-88.28-70.54,34-90.86,66.39-23.14,51.49-23.14,51.49-37.83-36.31-27.35-66.45,51.85-61.55,82.7-84.24,59-32.09,55.34-39.1-76.08,26.52-106,53.36-44.75,60.83-44.75,60.83-31.6-77.2-11.8-115.17,66.37-51.52,105.75-57.57,70.43-2.24,68.36-16.5-33.65-12.65-65.29-6-107.75,49.67-107.75,49.67-2.44-107.53,44-163.47,103.73-85.55,145.83-81.12c50.44,5.31,63,61,62.55,86.41S1258.76,1184.23,1274.64,1186.41Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M1459.42,101.91c-46.75,32.13-85.17,59.26-118,86.61l13.83,40L1330,198.21a533.2,533.2,0,0,0-41.83,40.36l39.73,49.3-53.3-33.94c-6,7.1-11.8,14.42-17.46,22l34,18.33-42.18-7c-15.35,22-29.66,46.45-43.66,74.77l72.82,49.26-84.66-24.24q-4.63,10.23-9.27,21.1L1250,432.44l-71-11.66c-6.81,16.53-13.65,34.19-20.62,53.16q-5,13.71-9.72,27l85.78,8.13-90.1,4.23q-6,17.36-11.41,34l48.56,12-55.93,11.13q-7.79,25-14.15,48h49.58l-53.15,13.12c-6.77,25.41-12.19,48.78-16.32,70l60.93-7.92-64.46,27.3c-6.62,39.47-8.11,69.87-4.81,89.64,2.28,13.71-74.9,163.15-68.12,166.1,4.32,1.88,93.79-139.63,104-142.79,16.7-5.16,40.17-17,66.78-35.34L1166,763.24l37.2,22.65q11.47-8.71,23.4-18.91l-22.49-17.49,32.63,8.58A575.91,575.91,0,0,0,1280,714.66l-64.8-27.51,76.21,14.38a584.54,584.54,0,0,0,44-59.39l-52.13-26.52,61.49,11.55q5.77-9.55,11.28-19.52l-82.61-49.39,95.62,24.38q5-10.21,9.66-20.82c5.21-11.86,9.89-23.41,14.14-34.69L1352.46,508l45.09,6.22q4-11.5,7.49-22.63l-60.91-15.41h65.47c4.11-14.6,7.51-28.79,10.35-42.65l-75.82-11.15,79.57-8.77c5.1-29.77,7.85-58.17,9.8-86.22l-39.41-9.2h40c1.48-23.08,2.55-46,4.07-69.29l-40.67-24.42,41.32,14.88q1-14.17,2.35-28.61l-18.91-11h20A702,702,0,0,1,1459.42,101.91Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M266.18,76.59c-14.1,54.95-25.22,100.65-32,142.85l36.73,21.07L232,234.24a527.94,527.94,0,0,0-5,57.92l62.34,11-62.47,9.47c.15,9.29.59,18.63,1.33,28.08l37.7-8.56L229.47,354.6c2.89,26.66,8.19,54.51,16.26,85L333,428.89l-79.72,37.4q3.23,10.74,6.89,22l65.49-24.93-61.18,37.91c5.74,16.93,12.2,34.74,19.41,53.61q5.22,13.65,10.43,26.74l70-50.26-65.12,62.42q6.88,17,13.73,33.09L357.38,604l-34.83,45.16q10.56,23.94,20.9,45.48L380.81,662l-31.42,44.83q17.4,35.38,33.69,63.44l40.7-46L393.16,787.2c21,34.1,39.83,58,55.3,70.72,10.74,8.83,50.83,172.18,57.88,169.94,4.49-1.42-21.13-166.87-15.5-176C500,837,510,812.67,517.93,781.35l-38.12-13.5,42.93-7.39q2.91-14.12,5.2-29.63l-28.45,1.61,30.23-15a573.79,573.79,0,0,0,4.06-61.16l-66.91,21.87,66.88-39.26a583.21,583.21,0,0,0-5.87-73.7l-56.71,14.29,53.93-31.73q-1.94-11-4.33-22.12L426,542.73l88.09-44.49q-3-11-6.41-22c-3.88-12.37-7.94-24.15-12.16-35.44l-43,12.16,38.06-25q-4.5-11.33-9.23-22l-56,28.44,49.33-43c-6.51-13.71-13.27-26.64-20.24-38.95L390,393.89,444.19,335c-15.72-25.79-32.33-49-49.3-71.41l-35.74,19,30.16-26.31c-14.06-18.37-28.32-36.33-42.49-54.89l-46.71,8.34L341,193.72c-5.71-7.56-11.39-15.24-17-23.11l-21.51,4.12,15.1-13.17A702.48,702.48,0,0,1,266.18,76.59Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M1188.45,689.25c18,13.54,76.55-105,143.61-79.48,59.84,22.8,116.08,84.76,97.51,161.9s-51.66,156.63-88.41,191.46c-.09-.64-1.25-85-50.92-144.86s-51-41.6-53-40,41.79,75.55,57.63,107.46,37.87,80.38,19,104.32-84.37,76.18-97.78,84.18c-.49-.09,4.75-93-5.3-132.52S1184.4,846,1169.47,850s23.7,71.39,27.25,109.45,13.69,112.8-19.8,127.17-88.5,19-95.87,20.51c-.86-.36,20.37-28.5,25.11-58.05s8.08-164.89-13.62-164.28,1.58,80.46-3.26,126.63-8.33,97.33-46,96.63-79.27-9.81-91.28-9c-.17,0,32-29.45,44-58.88s34.62-93.9,19.54-100.19-19.17,48.67-33.5,81.17-25.7,71.19-61.09,59.21-26.59-27.42-81.37-55.74c1.4-.36,16.53-14.41-3.25-64.73s-11-75.42,20-90.24,130.62-17.79,129.81-36.23S902,828.21,865.86,847.94,817,884.14,817,884.14s-16.43-54,9.26-76.81,81.08-30.54,122-35.62,72.35.92,72.56-7.61S935.43,749,893.19,758.45s-74,33.59-74,33.59,10.92-89.14,49.5-114.38,89.44-13.48,129.62,1.55,67.32,34.95,72.88,20.46-25-29.58-58.18-39.94-127.34-10-127.34-10,54.27-102.29,127.29-130.41,142.44-25.79,179.65.52c44.58,31.52,27.06,90.48,13.29,114.06S1174.67,678.85,1188.45,689.25Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M347.17,628.7c.84,5.44,1.72,10.94,2.69,16.41s1.89,10.95,2.93,16.42c2,10.94,4.17,21.85,6.32,32.76,1.08,5.45,2.28,10.89,3.42,16.33s2.27,10.89,3.48,16.32c2.47,10.85,4.86,21.72,7.41,32.54,10.14,43.33,21.76,86.31,34.55,128.9l4.81,16c.82,2.65,1.57,5.33,2.44,8l2.57,7.93L428.09,952c.83,2.66,1.79,5.27,2.7,7.89l2.77,7.86,5.52,15.73,5.52,15.72,5.93,15.57,11.88,31.13,12.75,30.76,6.4,15.37,6.86,15.16,13.73,30.31-14.51,6.74-13.43-30.83L467.5,1098l-6.22-15.61-12.42-31.22-11.52-31.55-5.73-15.78-5.34-15.92L420.94,972l-2.66-8c-.88-2.65-1.81-5.29-2.6-8L405.78,924l-2.48-8c-.83-2.67-1.55-5.37-2.33-8.06l-4.6-16.13c-12.24-43-23.29-86.36-32.83-130-2.4-10.91-4.64-21.86-7-32.79-1.13-5.47-2.17-11-3.26-16.44s-2.19-11-3.19-16.46c-2-11-4.05-22-5.87-33-1-5.51-1.84-11-2.71-16.56-.44-2.76-.87-5.52-1.27-8.3l-1.21-8.34Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M469.08,1093s58.84,9.86,127.33-22.75,84.42-61.06,84.42-61.06-70.37,5.64-117.69,23.76C509.76,1053.35,469.08,1093,469.08,1093Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M451.13,1048.08s58.65,3.77,122.5-35,76.62-68.28,76.62-68.28-68.39,12.57-113,35.05C487,1005.19,451.13,1048.08,451.13,1048.08Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M231.18,1031s50.6,19.62,117.61,3.2,86.45-38.82,86.45-38.82-63.72-7.95-109.2-.53C274.73,1003.24,231.18,1031,231.18,1031Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M478.55,1096.72s-57.63-15.44-128.92,10.46-89.88,52.7-89.88,52.7,70.59,1.12,119.42-12.39C434.26,1132.26,478.55,1096.72,478.55,1096.72Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M456.49,1045.78s-55.93-18-127.34,3.91-91,47.39-91,47.39,69.39,4.6,118.1-6.27C411.16,1078.55,456.49,1045.78,456.49,1045.78Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M617.89,906.48s-54.16-3.35-113,32.62-70.6,63.22-70.6,63.22,63.13-11.76,104.22-32.62C584.83,946.16,617.89,906.48,617.89,906.48Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M227.64,984.32s47.51,17.62,109.86,1.36,80.19-37.45,80.19-37.45-59.61-6.54-102,1C267.91,957.77,227.64,984.32,227.64,984.32Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M582.74,853.09S532.15,856,481.57,895.94s-58.41,66.5-58.41,66.5,57.22-18,93-41.88C556.51,893.57,582.74,853.09,582.74,853.09Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M223.26,930.86s44.26,18.62,103.94,5.78,77.44-32.18,77.44-32.18-56.17-8.62-96.59-3.2C262.46,907.38,223.26,930.86,223.26,930.86Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M566.13,822.78s-48-2.13-99.5,30.59-61.5,57-61.5,57S460.8,899,496.84,879.93C537.49,858.4,566.13,822.78,566.13,822.78Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M394.89,863.63s45,1.33,92.9-30.08,56.89-54.33,56.89-54.33-52.06,11.44-85.6,29.84C421.25,829.83,394.89,863.63,394.89,863.63Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M525.06,754.62s-40.64-1.67-84.37,25.3-52.22,47-52.22,47,47.19-9.43,77.76-25.18C500.72,783.94,525.06,754.62,525.06,754.62Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M485.23,708.74s-36.79,8.27-69.46,42.94-35.46,54.59-35.46,54.59,40-19.79,63.57-41.24C470.49,740.83,485.23,708.74,485.23,708.74Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M376.33,778.48s29.08-18.73,48.83-56.33,17.66-53.84,17.66-53.84S413.61,697,398.55,721.36C381.57,748.88,376.33,778.48,376.33,778.48Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M367.61,749.75s20.93-26,28.86-65.75,2-53.46,2-53.46-18.53,34.85-25.66,61.35A180,180,0,0,0,367.61,749.75Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M359.57,703.09s13.92-26.92,16.52-62.81-3.21-46.4-3.21-46.4-11.27,34-14.43,58.34A174.2,174.2,0,0,0,359.57,703.09Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M348.17,664.45A186.51,186.51,0,0,0,352,601.22c-3.95-34.67-11-43.43-11-43.43s-1.34,35,.09,58.85A273.5,273.5,0,0,0,348.17,664.45Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M319.29,573.76A246.25,246.25,0,0,0,333,624.51C343.31,650.83,348.79,656,348.79,656s-6.53-28.38-13-46.78A200.19,200.19,0,0,0,319.29,573.76Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M292,618.4s8.27,29.16,30.92,57.12S357,707.16,357,707.16s-15-32.51-29.39-52.42A174.21,174.21,0,0,0,292,618.4Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M222.92,886.34s41.09,18.45,97.35,7.74,73.34-28.45,73.34-28.45-52.48-9.34-90.5-5.16C260.21,865.19,222.92,886.34,222.92,886.34Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M383.58,828s-41.39-14.88-94.82-4.39-68.34,26.12-68.34,26.12,51.58,6.8,88,2.33C349.43,847,383.58,828,383.58,828Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M375.17,804s-32.81-23.81-82.78-28.25-67.19,5.55-67.19,5.55,43.89,19.27,77.24,24.67C340.06,812.09,375.17,804,375.17,804Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M245.87,714.74s23,30.94,65.67,50.66S371.78,782,371.78,782,338,751.44,310.09,736.19C278.6,719,245.87,714.74,245.87,714.74Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M264.91,659.44s17.88,32.76,52.74,60.14,49.62,28.66,49.62,28.66-26.94-34.93-49.62-54.92A214.42,214.42,0,0,0,264.91,659.44Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1326.64,710c-2.1,6.64-4.25,13.36-6.47,20s-4.41,13.34-6.71,20c-4.49,13.31-9.2,26.54-13.87,39.78-2.33,6.62-4.79,13.19-7.18,19.78s-4.78,13.2-7.24,19.76c-5,13.1-9.87,26.24-14.91,39.3q-30.19,78.43-64.32,154.72l-8.5,19.07c-1.43,3.17-2.8,6.38-4.28,9.52l-4.41,9.44-17.63,37.73c-1.45,3.16-3,6.25-4.53,9.36l-4.58,9.32-9.17,18.63-9.16,18.63-9.54,18.37-19.09,36.71-19.9,36.09-10,18-10.39,17.68L1054,1317.23l13.09,11.11,20.58-36.06,10.29-18,9.85-18.36,19.66-36.73,18.83-37.31,9.39-18.66,9-18.91,9-18.91,4.51-9.45c1.49-3.15,3-6.29,4.44-9.49l17.32-38.25,4.32-9.56c1.45-3.18,2.8-6.42,4.2-9.64l8.33-19.31q33.27-77.25,62.84-156.46c4.91-13.2,9.67-26.47,14.52-39.7,2.39-6.63,4.69-13.3,7-19.95s4.72-13.29,7-20c4.54-13.38,9.12-26.73,13.48-40.18,2.22-6.7,4.37-13.45,6.51-20.19,1.08-3.37,2.15-6.74,3.18-10.14l3.13-10.2Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1097.55,1267.72s-61.57,1.46-123.17-51.92-71.15-91.89-71.15-91.89,69.66,20,113.22,51.41C1065.59,1210.71,1097.55,1267.72,1097.55,1267.72Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1125.91,1214.94s-60-6.11-115.49-66.38-61.63-99.47-61.63-99.47,66.08,28.32,105.86,64.64C1099.52,1154.71,1125.91,1214.94,1125.91,1214.94Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1351.55,1234.2s-55.49,15.19-119.3-17.69S1154,1152,1154,1152s66.06,1.83,110.22,19.48C1314,1191.44,1351.55,1234.2,1351.55,1234.2Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1087.14,1270.67s61.63-8.66,127.58,36.86,78.57,82.45,78.57,82.45-71.43-11.63-117.57-37.52C1123.66,1323.26,1087.14,1270.67,1087.14,1270.67Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1121,1211.08s60.51-12.23,127.49,28.37,80.94,76,80.94,76-71-7.06-117.63-29.62C1159.23,1260.4,1121,1211.08,1121,1211.08Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M990.17,1007.2s55.37,5.8,106.5,61.62,56.72,92,56.72,92-61-26.34-97.61-60C1014.43,1062.9,990.17,1007.2,990.17,1007.2Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1365.81,1176.48s-51.93,13.26-111.07-18.56-72.29-61.6-72.29-61.6,61.6,2.82,102.59,20.08C1331.28,1135.87,1365.81,1176.48,1365.81,1176.48Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1037.82,947s50.34,13,92.19,72.23,43.69,93.88,43.69,93.88-53.59-33-84.19-69.49C1055,1002.39,1037.82,947,1037.82,947Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1382.45,1110.48s-48.88,15.11-106.11-12-70.71-54.5-70.71-54.5,58.59-.41,98.1,13.82C1348.3,1073.89,1382.45,1110.48,1382.45,1110.48Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1061.49,912.14s48.85,6.18,93.33,56.59,49,82.6,49,82.6-53.53-24.48-85.5-55C1082.22,961.94,1061.49,912.14,1061.49,912.14Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1224.8,994.79s-45.7-6.64-86.79-54.74-44.92-78.4-44.92-78.4,49.86,23.9,79.47,53.1C1205.94,947.67,1224.8,994.79,1224.8,994.79Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1118.49,834.53s41.36,5.41,79.28,47.19,41.9,68.31,41.9,68.31-45.42-20.5-72.64-45.81C1136.32,875.66,1118.49,834.53,1118.49,834.53Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1169.14,784.53s35.2,17.13,60.2,66.48,23.27,74.78,23.27,74.78-35.79-32.12-54.66-63.28C1176.66,827.37,1169.14,784.53,1169.14,784.53Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1263,891.79s-25-28.78-36.35-79.41-5.49-70.55-5.49-70.55,22.9,41.2,32.5,74.47C1264.46,853.83,1263,891.79,1263,891.79Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1278.34,857.49s-15.15-36.37-14.06-87.5,10.21-67.19,10.21-67.19,10.72,47,11.84,81.42C1287.59,823.07,1278.34,857.49,1278.34,857.49Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1297.11,800.66s-7.87-36.22-2.29-81.55,13.86-57.4,13.86-57.4,3.58,44.57,1.2,75.58A273.42,273.42,0,0,1,1297.11,800.66Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1317.44,754.46a292,292,0,0,1,10.61-79.72c11.92-42.61,21-52.25,21-52.25s-6.66,44-13.55,73.54A424.15,424.15,0,0,1,1317.44,754.46Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1367.31,646.45a375.61,375.61,0,0,1-25.39,60.9c-16.46,31-23.16,36.38-23.16,36.38s13.08-34.27,23.84-56.06A284.7,284.7,0,0,1,1367.31,646.45Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1384.59,707.27s-15,34.92-44.24,65.68-41.6,33.26-41.6,33.26,22.59-37.85,41.61-60.1A204.69,204.69,0,0,1,1384.59,707.27Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1393,1054.9s-45.64,15.47-99.91-8.29-67.43-49.09-67.43-49.09,55-2,92.42,10.25C1360.21,1021.58,1393,1054.9,1393,1054.9Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1244.35,952.3s45.13-11,96.59,12,62.94,45.24,62.94,45.24-53.56-1-89.22-13.31C1274.43,982.37,1244.35,952.3,1244.35,952.3Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1258.3,924s38.52-23.7,89.92-20,66.47,19.32,66.47,19.32-48.66,16-83.52,16.58C1291.86,940.49,1258.3,924,1258.3,924Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1409.08,836.19s-30.22,34.43-77.8,51.2-64.52,9.64-64.52,9.64,41-32,72.68-45.88C1375.11,835.45,1409.08,836.19,1409.08,836.19Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1402.54,763.57S1377,801.21,1335.61,829,1279,855.66,1279,855.66s35.15-38.68,62.59-59.48C1372.56,772.71,1402.54,763.57,1402.54,763.57Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M709.13,758.17c1.42,5.42,2.87,10.92,4.36,16.36s3,10.91,4.5,16.35c3,10.89,6.16,21.73,9.28,32.57,1.55,5.43,3.19,10.81,4.78,16.22s3.18,10.81,4.82,16.19c3.3,10.75,6.55,21.52,9.89,32.25q20,64.35,42.32,127.2l5.56,15.7c.93,2.61,1.84,5.25,2.8,7.84l2.87,7.78,11.5,31.12c.94,2.61,2,5.16,2.94,7.72l3,7.7,5.95,15.39,6,15.38,6.17,15.2,12.35,30.36L861,1199.41l6.42,14.95,6.67,14.68,13.32,29.35-7.62,8.28-13.23-29.92-6.62-15-6.35-15.2-12.69-30.41-12.2-30.83-6.09-15.43-5.87-15.6-5.87-15.6-2.93-7.8c-1-2.6-2-5.18-2.9-7.82l-11.31-31.51-2.83-7.88c-.94-2.62-1.83-5.29-2.75-7.93l-5.46-15.9q-21.82-63.57-41.47-128.52c-3.27-10.83-6.44-21.7-9.67-32.55-1.59-5.43-3.13-10.89-4.7-16.34s-3.15-10.89-4.67-16.36c-3-11-6.11-21.87-9-32.88-1.5-5.48-3-11-4.39-16.51q-1.1-4.12-2.16-8.28l-2.11-8.33Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M860,1216.58s37.11,4.15,73-35.68,40.71-70.25,40.71-70.25-41.48,12.69-67,35.75C877.94,1172.4,860,1216.58,860,1216.58Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M841.72,1172.87s36-2,68-47.64,34.8-76.8,34.8-76.8-39.13,19.52-62.23,46.73C856.21,1125.84,841.72,1172.87,841.72,1172.87Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M706.31,1177.4s33.76,14.87,71.42-8.41,45.63-47.92,45.63-47.92-39.73-1.73-65.91,10.29C727.92,1144.92,706.31,1177.4,706.31,1177.4Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M866.36,1219.45s-37.31-9.93-76,23.38S745,1305.16,745,1305.16s42.73-5.87,69.91-24.4C845.59,1259.86,866.36,1219.45,866.36,1219.45Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M844.57,1170s-36.72-12.73-76.11,16.59-47,57.06-47,57.06,42.59-2.22,70.13-18.07C822.71,1207.72,844.57,1170,844.57,1170Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M918.62,1012.83s-33.2,2-62.69,44.27-32,71.07-32,71.07S860,1110,881.29,1084.78C905.31,1056.33,918.62,1012.83,918.62,1012.83Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M696.38,1130.42s31.58,13.14,66.45-9.51S804.92,1075,804.92,1075s-37-.71-61.3,11.14C716.23,1099.52,696.38,1130.42,696.38,1130.42Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M888.53,962.21s-30,8-53.83,53.46-24.12,73.18-24.12,73.18,31.5-23.85,49.07-51.65C879.47,1005.84,888.53,962.21,888.53,962.21Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M684.83,1076.68s29.78,14.48,63.61-4.45,41.31-40.29,41.31-40.29-35.29-3.17-58.75,6.33C704.54,1049,684.83,1076.68,684.83,1076.68Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M873.47,933.15S844.2,935.74,818.59,974,791,1037.89,791,1037.89s31.66-17,50.2-40C862.14,972.08,873.47,933.15,873.47,933.15Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M777.07,991.53s27.35-3.12,51-39.7,25.22-60.71,25.22-60.71-29.47,16.76-46.61,38.74C787.32,954.65,777.07,991.53,777.07,991.53Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M837.35,868.14s-24.78,2.33-46.64,34-23.64,52.76-23.64,52.76,26.87-14.24,42.67-33.23C827.57,900.26,837.35,868.14,837.35,868.14Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M805.69,825.59s-20.79,12-34.7,50.4-12.27,58.85-12.27,58.85,20.8-24,31.44-48.1C802.16,859.58,805.69,825.59,805.69,825.59Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M751.68,907.07s14.41-21.87,20-61.93,1.67-56.31,1.67-56.31S760.56,820.76,755.55,847C749.91,876.55,751.68,907.07,751.68,907.07Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M741.64,878.81s8.27-28.43,6.43-69.49-7.71-54.38-7.71-54.38-5.36,37.16-5.23,64.73A307.42,307.42,0,0,0,741.64,878.81Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M729,832.33s3.9-28.67-.51-65.3-9.68-46.7-9.68-46.7-1.12,35.57,1,60.56A299.41,299.41,0,0,0,729,832.33Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M715.7,794.29a318.53,318.53,0,0,0-8.24-64.45c-8.16-34.75-13.89-42.92-13.89-42.92s5,35.64,9.87,59.64A459.3,459.3,0,0,0,715.7,794.29Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M683.17,705.25a399.07,399.07,0,0,0,16.7,50.08c10.63,25.64,14.78,30.29,14.78,30.29S706,757.51,699,739.51A284.06,284.06,0,0,0,683.17,705.25Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M674.18,753.2S684,781.93,702.34,808s25.82,28.68,25.82,28.68-14.49-31.45-26.45-50.21C688.21,765.32,674.18,753.2,674.18,753.2Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M677.2,1031.59s27.85,14.63,60-1.81,39.46-36.1,39.46-36.1-33.19-4.26-55.41,3.74C696.15,1006.46,677.2,1031.59,677.2,1031.59Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M764.31,956.51s-27.44-11-57.89,5-36.84,33.25-36.84,33.25,32.23,1.77,53.41-6.36C746.89,979.17,764.31,956.51,764.31,956.51Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M755.24,933.09s-23.74-20.88-54.6-20.42S661.07,925,661.07,925s29.67,15.18,50.67,17.34C735.43,944.73,755.24,933.09,755.24,933.09Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M662.43,855.41s19,29.08,48,44.83,39.07,10.85,39.07,10.85-25.45-27.62-44.82-40.31C682.86,856.46,662.43,855.41,662.43,855.41Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M664.68,797.48s16.24,31.43,41.81,55.71,34.69,24.13,34.69,24.13-22.06-32.73-39.06-50.74C682.94,806.27,664.68,797.48,664.68,797.48Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1104.31,694.39c.38-5.6.74-11.27,1-16.9s.64-11.29.91-16.93c.6-11.29,1-22.56,1.52-33.84.25-5.64.4-11.26.61-16.89s.4-11.27.56-16.89c.27-11.24.61-22.49.83-33.72q1.43-67.38.17-134l-.29-16.66c-.06-2.77-.08-5.56-.17-8.32l-.26-8.29-1-33.16c-.06-2.77-.21-5.51-.34-8.26l-.38-8.24-.77-16.48-.77-16.48-1-16.37-2.09-32.71-2.68-32.43-1.35-16.21-1.67-16-3.34-32.07,9.85-5.43,3.07,32.56,1.53,16.28,1.22,16.43,2.39,32.87,1.81,33.11.88,16.55.63,16.66.62,16.66.31,8.32c.1,2.78.22,5.55.27,8.34l.74,33.48.19,8.36c.06,2.79.06,5.6.09,8.4l.15,16.8q.56,67.22-1.4,135c-.33,11.3-.76,22.62-1.14,33.93-.21,5.66-.48,11.33-.72,17s-.46,11.32-.76,17c-.58,11.34-1.13,22.68-1.84,34-.31,5.68-.69,11.37-1.06,17-.19,2.85-.38,5.69-.59,8.54l-.63,8.58Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1106.46,211.78s-33.88-15.69-80.5,10.73-60.87,53.73-60.87,53.73,43.36,1.1,74.85-12.68C1075.47,248,1106.46,211.78,1106.46,211.78Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1110,259s-34.76-9.51-79.59,23.65S973,344.49,973,344.49s43.3-6.11,73.83-24.59C1081.32,299.05,1110,259,1110,259Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1239.84,297.65s-27.32-24.8-70.41-14.65S1111,314,1111,314s37.14,14.23,65.78,11.13C1209.05,321.61,1239.84,297.65,1239.84,297.65Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1101.36,207.05s32.24,21.25,79.46,1.9,62.79-44.73,62.79-44.73-42.39-8-74,1C1133.86,175.31,1101.36,207.05,1101.36,207.05Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1106.36,260.85s30.79,23.72,77.44,8.38S1246.43,230,1246.43,230s-41.1-11.39-72.25-5.09C1139,232,1106.36,260.85,1106.36,260.85Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M986.31,386.46s32.12,8.65,73.49-22.12,52.89-57.26,52.89-57.26-40,5.8-68.17,23C1012.72,349.43,986.31,386.46,986.31,386.46Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1234.36,345.36s-25.78-22.47-66-12-54.47,30.2-54.47,30.2,34.88,12.41,61.67,8.86C1205.75,368.38,1234.36,345.36,1234.36,345.36Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M998.81,444s31,1.93,68-33.65,46.07-61.76,46.07-61.76S1075.44,361.24,1050,382C1021.23,405.5,998.81,444,998.81,444Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1228.29,400s-23.66-23.17-61.74-15.93-52,25.12-52,25.12,32.46,14.19,57.72,12.61C1200.82,420,1228.29,400,1228.29,400Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1003.89,476.35s28.58,6.82,65-21.37,46.39-51.85,46.39-51.85-35.43,6.13-60.28,22C1027,443,1003.89,476.35,1003.89,476.35Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1113.82,451.53s-26.93-5.72-60.93,21.5-43.16,49.58-43.16,49.58,33.26-6.55,56.48-22C1092.41,483.25,1113.82,451.53,1113.82,451.53Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1017.55,549.45s24.24,5.64,55-17.47,39.14-42.55,39.14-42.55-30,5-51,18C1037,522.08,1017.55,549.45,1017.55,549.45Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1034.09,599.84S1057.63,595,1083,563s30.28-51.92,30.28-51.92-27.34,16.2-45.06,35.66C1048.21,568.72,1034.09,599.84,1034.09,599.84Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1111.14,539.68s-20.6,16.17-38.64,52.39S1053.08,645,1053.08,645s22.28-26.22,35.34-49.51C1103.15,569.18,1111.14,539.68,1111.14,539.68Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1111.71,569.66s-16.86,24.34-28.12,63.87-9.92,54-9.92,54,16.85-33.54,25.47-59.73A307.81,307.81,0,0,0,1111.71,569.66Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1109,617.75s-12.78,26-20.2,62.09-5.62,47.36-5.62,47.36,12.34-33.38,18.21-57.76A298.69,298.69,0,0,0,1109,617.75Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1109.53,658a318.35,318.35,0,0,0-12.61,63.74c-3.27,35.55-.43,45.12-.43,45.12s6.52-35.41,9.54-59.69A462,462,0,0,0,1109.53,658Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1112.17,752.8a398.79,398.79,0,0,0,0-52.78c-2-27.7-4.42-33.42-4.42-33.42s-.69,29.41.24,48.7A283.25,283.25,0,0,0,1112.17,752.8Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1135.89,710.18s-.24-30.37-9.34-60.92-15.4-35.39-15.4-35.39,3.77,34.42,9.18,56C1126.42,694.23,1135.89,710.18,1135.89,710.18Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1221.24,445.17s-21.78-22.69-57.45-17.28-48.87,21.74-48.87,21.74,30.13,14.56,53.74,14C1195.3,463,1221.24,445.17,1221.24,445.17Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1114.83,488.79s22.54,19.1,56.47,13.64,45.47-19.86,45.47-19.86-30-11.9-52.67-10.89C1138.52,472.81,1114.83,488.79,1114.83,488.79Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1116,513.87s15.91,27.32,45.32,36.67,41.43.89,41.43.89-23.33-23.8-42.57-32.5C1138.49,509.11,1116,513.87,1116,513.87Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1179.42,617s-8.8-33.6-31.35-57.74-33.62-22.67-33.62-22.67,15.4,34.26,29.74,52.44C1160.38,609.49,1179.42,617,1179.42,617Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1158.93,671.19s-5.45-35-22-66.1-25.25-33.87-25.25-33.87,10.55,38,21,60.5C1144.4,657.07,1158.93,671.19,1158.93,671.19Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M825.78,739.31c-12.32-55.37-23.12-101.15-36.2-141.84l-42.29-2.13,37.56-12.06A531.38,531.38,0,0,0,763,529.38l-60.57,18.43,51.4-36.75c-4.34-8.22-9-16.34-13.91-24.44l-29.72,24.73,22.28-36.5c-14.65-22.46-32-44.87-53-68.45l-72.91,49.13,54.13-69.46Q653,378,644.6,369.59l-47.09,51.9L634.87,360c-12.79-12.49-26.61-25.44-41.59-39q-10.85-9.8-21.42-19.12l-39.61,76.52L562,293.24Q548.15,281.18,534.78,270L505.5,310.52l10.59-56q-20.28-16.56-39.25-31.07l-18.53,46,7.7-54.21c-21-15.77-40.69-29.54-58.79-41.28L391.8,233.38l-1.23-70c-34.14-20.9-61.78-33.65-81.35-38C295.65,122.41,185.89-5,180.62.15,177.27,3.46,275.07,139.34,274.18,150c-1.46,17.42.74,43.61,7.82,75.15l40.09-5.24-34.91,26Q291,259.86,296,274.72L320.6,260.4l-20.16,27.05a573.41,573.41,0,0,0,24.1,56.36L374.27,294l-41.82,65.31a584,584,0,0,0,38.63,63l44.07-38.43-33.69,52.72q6.71,8.92,13.88,17.76L472,396.23,413.68,475.8q7.6,8.44,15.7,16.74,13.59,13.89,26.89,26.08l32.83-30.33-22.62,39.5q9.15,8.06,18.19,15.41l37.05-50.74-24.47,60.72c12,9.28,23.9,17.74,35.69,25.55l38.69-66.16L550,589.65c25.7,15.87,51,29,76.3,41.32l23.27-33.11-15,37.12c20.85,10,41.7,19.55,62.74,29.67l37.86-28.59L706,668.82c8.51,4.15,17.06,8.42,25.65,12.88l17.31-13.41-7.48,18.58A702.68,702.68,0,0,1,825.78,739.31Z"
              />
            </g>
            <g id="ba560a3f-a845-43f3-9585-66d315a00c10" data-name="BG_BOTTOM">
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M1272.74,2163.65c-11.24,17.6,102.81,63.28,84,127.23-16.78,57.07-70.08,113.64-142.86,102s-148.79-36.5-183.71-68c.58-.13,78.64-7.31,130.46-57.65s34.83-50.23,33.22-52-66.94,44.17-95.34,61.15-71.69,40.89-95.23,25.1-76.66-72.61-85-84.44c0-.47,86.42-2.34,122.34-14.52s86.73-31.28,81.92-44.81-64.39,27.12-99.38,33.16-103.47,20.84-119.2-9.13-24-80.58-25.94-87.3c.28-.82,27.87,16.81,55.58,19.06S1086.84,2109,1084.7,2089s-74.39,7.28-117.5,6.15-90.73-.67-92.81-35.62,3.33-74.12,1.77-85.19c0-.16,29.58,27.51,57.71,36.51s89.47,25.26,94.19,10.84-46.45-14.23-77.58-25.14-67.8-18.64-59.26-52.29,23.47-26.61,45.72-79.39c.44,1.27,14.55,14.27,59.71-7.7s69-15.67,85,12,25.94,119.67,42.95,117.59-1.3-78.29-22.19-110.31-37.06-42.59-37.06-42.59,48.83-19.13,71.8,3,34.15,72.87,41.82,110.39,4.39,67.07,12.31,66.64,7.8-80.19-4-118.62-36.47-66.13-36.47-66.13,83.34,3.66,109.5,37.56,19,81.85,8,120.14-27.49,64.88-13.66,69,25.59-25.25,32.77-56.78.06-118.64.06-118.64,98.66,42.84,130,108.43,34.2,130,12.53,166.4c-26,43.57-81.83,31.61-104.67,20.58S1281.37,2150.14,1272.74,2163.65Z"
              />
              <path
                className="a5ecf17b-6622-42f5-920c-4cd1d999c72f"
                d="M241.61,1852.69c-14.94,5.63,34,85.84-6.8,116.4-36.41,27.28-95,37.52-134.24-2.92s-74.43-90.49-81.38-125.79c.41.19,51.51,31.54,106.31,24.44s44.34-14.83,44.16-16.65-61.23-3.6-86.4-6.21-62.64-7.78-69.83-28.23S-.27,1734.15,0,1723.06c.24-.26,54,38.14,81.59,47.13s67.45,20.56,70.7,10.07-51.86-12.88-76.07-25.2S3.31,1720.44,7.4,1694.88s22.2-60.35,24.09-65.35c.54-.38,9.38,23.06,25.32,37.12s95.94,67.46,103.8,54.2-48.9-29.6-74.79-50-55.27-42-40.54-64.32,36-43.87,40.09-51.37c.09-.09,5.53,30.4,18.64,48.79s43.23,56.44,52.73,49.78-21.94-30-36-50.94-33-42.46-12.35-59.16,26.55-5.55,64.36-27.69c-.32,1,2.37,15.4,40.09,22.62s49.48,22,46.61,46.26-38.92,85.18-27.54,91.7,35-48.56,36.92-77.74-3.2-43.06-3.2-43.06,38.67,10.65,42.6,34.73-12.45,60.27-24.93,86.77-28,43.09-23,46.46,41.5-45.55,51.86-74.51,7.95-57.2,7.95-57.2,49.37,40.4,49.88,73.15-25.87,58.82-50.15,77.23-46.55,27.16-40,36,27.24-3.75,46.08-19.77,54.36-72.65,54.36-72.65,40.82,71.42,30,125.95-38.6,95.31-68.53,107.66c-35.85,14.81-64.6-18.11-73.53-35.32S253.09,1848.36,241.61,1852.69Z"
              />
              <path
                className="b8454009-e807-489f-960d-f312455eb1f7"
                d="M1187.63,2131.7c42.07-26.76,76.66-49.38,106.37-72.38l-10.9-35.58,21.21,27.43a465.28,465.28,0,0,0,38-34.15L1309,1972.48l45.76,31.48c5.49-6,10.82-12.3,16-18.81L1341.42,1968l36.83,7.5a503.94,503.94,0,0,0,40.69-64.32l-62.42-45.54,73.6,23.93q4.39-8.83,8.8-18.24l-57-23.37,62,12.45c6.5-14.3,13.06-29.6,19.77-46q4.86-11.89,9.39-23.42L1398,1781.1l79.27-.89q5.82-15.06,11.08-29.47l-42.27-12.06,49.47-8q7.62-21.72,13.93-41.73l-43.55-1.55,47.09-9.87c6.74-22.1,12.24-42.46,16.53-60.93l-53.77,5.05,57.47-22c7-34.47,9.31-61.13,7-78.59-1.58-12.11,70.89-141,65-143.77-3.73-1.78-86.74,119.72-95.82,122.17-14.83,4-35.82,13.67-59.76,29l16.33,31.57-32-21.06c-6.9,4.87-14,10.16-21.14,15.88l19.21,16.06-28.4-8.55a505.58,505.58,0,0,0-39.36,36.78l56,26.19-66.49-15a514.74,514.74,0,0,0-40.52,50.79l45,24.92-53.66-12.06q-5.37,8.21-10.51,16.79l71,46L1282,1712.28q-4.71,8.82-9.13,18-7.43,15.39-13.51,30l34.89,18-39.41-6.87q-3.91,10-7.29,19.64l53,15.44-57.5-2c-4.07,12.7-7.51,25.06-10.43,37.14l66.25,12.17-70.17,5.21a752.09,752.09,0,0,0-11.3,75.42l34.33,9.32-35.16-1.25c-2,20.23-3.67,40.32-5.74,60.73l35,22.72L1210,2011.6c-.89,8.28-1.86,16.62-3,25.05l16.27,10.3-17.6-.63A618.12,618.12,0,0,1,1187.63,2131.7Z"
              />
              <path
                className="f2529f77-0ff0-4132-b8a1-b291801aa23b"
                d="M1186.36,1861.81c31.84-22.57,58-41.62,80.32-60.76l-9.91-27.39,17.66,20.61a364.85,364.85,0,0,0,28.37-28.18L1275,1732.58l37,22.81c4-5,8-10,11.79-15.34l-23.6-12.26,29.09,4.41a394.17,394.17,0,0,0,29.29-51.88L1308,1647.17l58.48,15.82q3.09-7.08,6.16-14.6l-45.46-16,49,7.3c4.52-11.44,9.05-23.66,13.65-36.77q3.33-9.48,6.42-18.68l-59.09-4.74,61.94-3.81q4-12,7.51-23.47l-33.52-7.76,38.36-8.22q5.1-17.27,9.25-33.17l-34.11.5L1423,1494c4.4-17.54,7.89-33.68,10.52-48.29l-41.83,6.06,44.06-19.42c4.16-27.22,4.89-48.15,2.42-61.72-1.71-9.41,49.89-113,45.19-114.94-3-1.25-63.11,97-70.12,99.26-11.44,3.72-27.46,12.09-45.58,25l14,24-25.82-15.21c-5.2,4.08-10.52,8.49-15.9,13.24l15.64,11.81L1333,1408.2a396.34,396.34,0,0,0-29.33,30.3l44.85,18.28-52.57-9.13a403.09,403.09,0,0,0-29.69,41.29l36.12,17.72-42.42-7.32q-3.87,6.62-7.56,13.53l57.33,33.16-66-15.82q-3.33,7.08-6.43,14.42-5.21,12.31-9.38,24l28,12.75-31.08-3.83q-2.67,8-4.93,15.64l42.06,10-45,.65c-2.68,10.09-4.88,19.89-6.69,29.45l52.27,6.91-54.65,6.83a585.63,585.63,0,0,0-5.88,59.41l27.2,5.93-27.53.4c-.79,15.9-1.29,31.67-2.11,47.71l28.23,16.39-28.58-9.82q-.54,9.75-1.33,19.7l13.12,7.41-13.78.2A485,485,0,0,1,1186.36,1861.81Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}
