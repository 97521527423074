import { tt } from '../../../i18n'
import FlexPicture from '../../../components/FlexPicture'
import { TSUtils } from '../../../utils/TSUtils'
import SponsorshipButton from '../../../components/SponsorshipButton'
import { renderCTAForStep } from '../helpers'
import WheelButton from '../../../components/cta/WheelButton'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  lotteryConfigSelector,
  lotteryStepSelector,
  skinSelector,
  venueSelector,
} from '../../../redux/selectors/lottery'
import { RootState } from '../../../redux/combinedReducers'
import WheelAnalyticsManager from '../../../analytics/WheelAnalyticsManager'
import {
  async_fetchSponsorshipURL,
  goToRedeem,
} from '../../../redux/actions/lottery'
import { useEffect } from 'react'
import { configSelector } from '../../../redux/selectors/config'

interface Props {
  isModal: boolean
  isBeforeWheel: boolean
  isIOS: boolean
}

export default function SponsorshipModalContent(props: Props) {
  const dispatch = useDispatch()

  const skin = useSelector(skinSelector)
  const skinId = useSelector((r: RootState) => r.lottery.skinId)
  const lotteryConfig = useSelector(lotteryConfigSelector)
  const venue = useSelector(venueSelector)
  const step = useSelector(lotteryStepSelector)
  const siteConfig = useSelector(configSelector)

  const getSponsorshipText = () => {
    if (lotteryConfig && lotteryConfig.sponsorshipText && venue?.name) {
      return TSUtils.format(lotteryConfig.sponsorshipText, [venue?.name])
    }
    return tt('sponsorship_text')
  }

  const _goToRedeem = () => {
    WheelAnalyticsManager.logEvent('sp_redeem')
    dispatch(goToRedeem())
  }

  useEffect(() => {
    dispatch(async_fetchSponsorshipURL(siteConfig))
  }, [])

  return (
    <>
      <h1 className="h1 w-margin-top-2-thirds">{tt('sponsorship_title')}</h1>

      <p>{getSponsorshipText()}</p>

      <FlexPicture
        isModal={props.isModal}
        src={undefined}
        dataSrc={
          skinId && skin?.replaceSponsorshipModalImg
            ? `${TSUtils.buildSkinBaseURL(skinId, true)}/${
                skin.replaceSponsorshipModalImg
              }`
            : `image:/cdm/img/gift_multicolor.png`
        }
        dataSrcTransform={'contain=WxH'}
        heightPc={0.5}
        hasCustomPicture={!!(skinId && skin?.replaceSponsorshipModalImg)}
      />

      {props.isModal && (
        <div className="text-center">
          <SponsorshipButton
            isFixedBottom={false}
            isIOS={props.isIOS}
            preventNextStep={false}
          />
        </div>
      )}

      {!props.isModal && (
        <div className="bottom-after-wheel w-padding-bottom">
          {renderCTAForStep(
            step,
            step?.tag || null,
            false,
            props.isIOS,
            false,
            false,
            true,
            undefined,
            undefined,
            !props.isBeforeWheel
          )}
          <div style={{ marginTop: 10 }}>
            <WheelButton
              id="go_to_gift"
              text={tt('go_to_gift')}
              isInverted={true}
              onClick={_goToRedeem}
            />
          </div>
        </div>
      )}
    </>
  )
}
