import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import {tt} from '../../../i18n'
import {
  buildAfterWheelInstagramText,
  buildBeforeWheelInstagramText,
  buildH1TextForGoogleStepAfterWheel,
  determineBeforeWheelInstagramH1,
} from '../helpers'
import * as React from 'react'

interface StepTexts {
  beforeWheelH1: string
  beforeWheelText: string | any
  afterWheelText: string
}

const emptyTexts: StepTexts = {
  beforeWheelH1: '',
  beforeWheelText: '',
  afterWheelText: '',
}

function buildTextsForGoogleCTA(step: LotteryV2Step, isIOS: boolean, finalPlaceName: string, prizeLabel: string | undefined) {
  return {
    beforeWheelH1: tt('your_opinion_matters'),
    beforeWheelText: (
      <>
        <div className="w-modal-stepper">
          <div className="w-modal-stepper-step">
            <div className="w-modal-stepper-step-number">1</div>
            <span>
                  {tt(
                    step?.alternateWording
                      ? 'tuto_iphone_google_alternate'
                      : 'tuto_iphone_google'
                  )}
                </span>
          </div>
          <div className="w-modal-stepper-step">
            <div className="w-modal-stepper-step-number">2</div>
            <span>
                  {isIOS && (
                    <span>
                      {tt('tuto_iphone_google_2')}{' '}
                      <img
                        className="twic tuto-img-ios-google"
                        data-src={`image:/cdm/img/tutos/tabs_ios.png`}
                      />
                    </span>
                  )}
              {!isIOS && <span>{tt('tuto_iphone_google_2_android')}</span>}
                </span>
          </div>
          <div className="w-modal-stepper-step">
            <div className="w-modal-stepper-step-number">3</div>
            <span>{tt('tuto_iphone_google_3')}</span>
          </div>
        </div>
        <p className="w-margin-top-half">{tt('google_disclaimer')}</p>
      </>
    ),
    afterWheelText: buildH1TextForGoogleStepAfterWheel(
      finalPlaceName,
      prizeLabel,
      step
    ),
  };
}

function buildTextsForInstagramCTA(step: LotteryV2Step, config: LotteryConfigurationResponse|null|undefined, finalPlaceName: string, isIOS: boolean, prizeLabel: string | undefined) {
  return {
    beforeWheelH1: determineBeforeWheelInstagramH1(step),
    beforeWheelText: buildBeforeWheelInstagramText(
      config?.overrideIGVenueName || finalPlaceName,
      step,
      isIOS
    ),
    afterWheelText: buildAfterWheelInstagramText(
      config?.overrideIGVenueName || finalPlaceName,
      prizeLabel,
      step
    ),
  }
}

export function determineTexts(
  config: LotteryConfigurationResponse | undefined | null,
  step: LotteryV2Step | null,
  isIOS: boolean,
  finalPlaceName: string,
  prizeLabel?: string
): typeof emptyTexts {
  if (!step || !step.stepType) {
    return emptyTexts
  }

  switch (step.stepType) {
    case LotteryV2StepType.Facebook:
      return {
        beforeWheelH1: tt('like_us'),
        beforeWheelText: tt('follow_on_facebook_before_wheel', {
          finalPlaceName: config?.overrideFBVenueName || finalPlaceName,
        }),
        afterWheelText: tt('follow_on_facebook_after_wheel'),
      }

    case LotteryV2StepType.TikTok:
      return {
        beforeWheelH1: tt('follow_us'),
        beforeWheelText: tt('follow_us_tiktok', {
          tikTokUserId: step?.tag || '',
        }),
        afterWheelText: '',
      }

    case LotteryV2StepType.Google:
      return buildTextsForGoogleCTA(step, isIOS, finalPlaceName, prizeLabel)

    case LotteryV2StepType.ThreeSocialNetworks:
      if (step?.subSteps?.[0].stepType === LotteryV2StepType.Google) {
        return buildTextsForGoogleCTA(step, isIOS, finalPlaceName, prizeLabel)
      } else if (step?.subSteps?.[0].stepType === LotteryV2StepType.Instagram) {
        return buildTextsForInstagramCTA(step, config, finalPlaceName, isIOS, prizeLabel);
      }
      break

    case LotteryV2StepType.Instagram:
      return buildTextsForInstagramCTA(step, config, finalPlaceName, isIOS, prizeLabel);

    case LotteryV2StepType.Snapchat:
      return {
        beforeWheelH1: tt('like_us'),
        beforeWheelText: tt('before_wheel_text_snapchat', { finalPlaceName }),
        afterWheelText: tt('after_wheel_text_snapchat', {
          finalPlaceName,
          prizeLabel,
        }),
      }

    case LotteryV2StepType.TwoSocialNetworks:
      return {
        beforeWheelH1: (step && step.custom2SNTitle) || tt('show_us_love'),
        beforeWheelText:
          (step && step.custom2SNText) ||
          tt('befoe_wheel_text_two_social_networks', { finalPlaceName }),
        afterWheelText: tt('after_wheel_text_two_social_networks', {
          finalPlaceName,
          prizeLabel,
        }),
      }

    case LotteryV2StepType.DownloadApp:
      return {
        beforeWheelH1:
          step.customLinkTitle || tt('before_wheel_h1_download_app'),
        beforeWheelText:
          step.customLinkText ||
          tt('before_wheel_text_download_app', {
            finalPlaceName: config?.appName || finalPlaceName,
          }),
        afterWheelText: tt('after_wheel_text_download_app', {
          finalPlaceName: config?.appName || finalPlaceName,
          prizeLabel,
        }),
      }

    case LotteryV2StepType.McDoPlus:
      return {
        beforeWheelH1: tt('before_wheel_h1_mcdonalds'),
        beforeWheelText: tt('before_wheel_text_mcdonalds'),
        afterWheelText: tt('after_wheel_text_mcdonalds'),
      }

    case LotteryV2StepType.Deliveroo:
      return {
        beforeWheelH1: tt('before_wheel_h1_deliveroo'),
        beforeWheelText: tt('before_wheel_text_deliveroo'),
        afterWheelText: tt('after_wheel_text_deliveroo'),
      }

    case LotteryV2StepType.UberEats:
      return {
        beforeWheelH1: tt('before_wheel_h1_ubereats'),
        beforeWheelText: tt('before_wheel_text_ubereats', { finalPlaceName }),
        afterWheelText: tt('after_wheel_text_ubereats', {
          finalPlaceName,
          prizeLabel,
        }),
      }

    case LotteryV2StepType.DeliverooUberEats:
      return {
        beforeWheelH1: tt('before_wheel_h1_ubereats_deliveroo'),
        beforeWheelText: '',
        afterWheelText: '',
      }

    case LotteryV2StepType.TripAdvisor:
      return {
        beforeWheelH1: tt('before_wheel_h1_tripadvisor'),
        beforeWheelText: tt('before_wheel_text_tripadvisor', {
          finalPlaceName,
        }),
        afterWheelText: tt('after_wheel_text_tripadvisor', {
          finalPlaceName,
          prizeLabel,
        }),
      }

    case LotteryV2StepType.CustomLink:
      return {
        beforeWheelH1: step.customLinkTitle || '',
        beforeWheelText: step.customLinkText || '',
        afterWheelText: '',
      }

    case LotteryV2StepType.Staffing:
      return {
        beforeWheelH1: tt('were_hiring_title'),
        beforeWheelText: tt('were_hiring_text'),
        afterWheelText: '',
      }
  }

  return emptyTexts
}
