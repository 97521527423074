import { ChristmasGarland } from '../../christmas/ChristmasGarland'
import * as React from 'react'
import WheelColorsI from '../WheelColors'
import {
  renderStandardBackground,
  renderStandardForeground,
} from './skin_standard'

export function buildSkinForChristmasWheel(colors: WheelColorsI) {
  return {
    renderBackground: () => {
      return (
        <>
          {renderStandardBackground(colors)}
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <ChristmasGarland
              id="gc-bg-O"
              width={'13%'}
              bottom={'23%'}
              right={'0%'}
              isDark={false}
              angle={'0.13'}
            />
          </div>
        </>
      )
    },
    renderForeground: () => {
      return (
        <>
          {renderStandardForeground(colors, true)}
          <ChristmasGarland
            id="gc-fg-0"
            width={'10%'}
            top={'-1%'}
            isFixed={true}
            right={'0%'}
            angle={'0.13'}
          />
          <ChristmasGarland
            id="gc-fg-1"
            width={'13%'}
            top={'-4.5%'}
            left={'0%'}
            isDark={false}
            angle={'-0.1'}
          />
        </>
      )
    },
    renderCTAForeground: () => {
      return null
    },
  }
}
