import aspect_ratio, { AspectRatio } from '../../utils/aspect_ratio'

export const ratio = aspect_ratio()

const IMG_CONTAINER_SIZE_FACTOR_16_9 = 0.15
const IMG_CONTAINER_SIZE_FACTOR = 0.20

export function getStyleForImageContainer(): any {
  switch (ratio) {
    case AspectRatio._16_9:
      return {
        width: '100%',
        height: Math.trunc(window.innerHeight * IMG_CONTAINER_SIZE_FACTOR_16_9),
      }
  }
  return {
    width: '100%',
    height: Math.trunc(window.innerHeight * IMG_CONTAINER_SIZE_FACTOR),
  }
}

export function getStyleForImage(): any {
  switch (ratio) {
    case AspectRatio._16_9:
      return {
        maxWidth: '80%',
        minHeight: Math.trunc(window.innerHeight * 0.05),
        maxHeight: Math.trunc(window.innerHeight * IMG_CONTAINER_SIZE_FACTOR_16_9),
      }
  }

  return {
    maxWidth: '80%',
    minHeight: Math.trunc(window.innerHeight * 0.05),
    maxHeight: Math.trunc(window.innerHeight * IMG_CONTAINER_SIZE_FACTOR),
  }
}
