import React from 'react'
import { SLOT_MACHINE_SPEED, TIMER_INTERVAL } from './constants'
import { TSUtils } from '../../utils/TSUtils'

interface SpinnerProps {
  timer: number
  delay: number
  initialIdx: number
  playCounter: number
  shouldWin: boolean
  winningIdx: number
  onFinished: () => void
  marginRight?: boolean
  marginLeft?: boolean
  initialDestIdx: number
  audioSource: string
  successAudioSrc: string

  width?: number
  height?: number

  /**
   * Ribbon image height
   */
  smH: number

  /**
   * Ribbon image width
   */
  smW: number

  /**
   * Item height
   */
  smIH: number
  smNItems: number

  bgUrl: string

  targetWidth?: number
  nDisplayItems: number
  giftPaddingY: number
}

interface SpinnerState {
  destIdx: number
  position: number
  initialPosition: number
  timeRemaining: number
}

// Arbitrary value
let startPosition = 0

export default class Spinner extends React.Component<
  SpinnerProps,
  SpinnerState
> {
  //region fields
  private timer: any | null = null
  private audio: HTMLAudioElement | null = null
  private successAudio: HTMLAudioElement | null = null

  private position = 0
  private remaining = 0
  private speed = 0
  private stopNextTime = false
  private stop = false
  //endregion

  constructor(props: SpinnerProps) {
    super(props)
    this.start = this.start.bind(this)
    this.loop = this.loop.bind(this)
    this.computeStartPosition = this.computeStartPosition.bind(this)
    this.computeLogicalIdx = this.computeLogicalIdx.bind(this)
    this.computePadding = this.computePadding.bind(this)

    startPosition = this.computeStartPosition()
    this.position = startPosition

    this.state = {
      timeRemaining: this.props.timer,
      initialPosition: startPosition,
      position: startPosition,
      destIdx: props.initialDestIdx,
    }
  }

  componentDidMount() {
    this.audio = new Audio(this.props.audioSource)
    this.audio.loop = true
    this.successAudio = new Audio(this.props.successAudioSrc)
  }

  componentDidUpdate(prevProps: Readonly<SpinnerProps>) {
    if (prevProps.shouldWin !== this.props.shouldWin) {
      const destIdx = this.props.shouldWin
        ? this.props.winningIdx
        : Math.trunc(TSUtils.getRandomIntInRange(0, this.props.smNItems))
      this.setState({ destIdx })
    }

    if (this.props.playCounter > prevProps.playCounter) {
      this.start()
    }
  }

  private computePadding() {
    return this.props.smIH / 2 - this.props.giftPaddingY
  }

  private computePosition(idx: number) {
    return (
      -this.props.smIH * (idx - this.nPosBeforeTargetPos()) -
      this.computePadding()
    )
  }
  private computeStartPosition() {
    return this.computePosition(this.props.initialIdx)
  }

  private computeLogicalIdx(pos: number) {
    return -(
      Math.trunc(
        (pos - this.props.smIH * this.nPosBeforeTargetPos()) / this.props.smIH
      ) % this.props.smNItems
    )
  }

  private nPosBeforeTargetPos() {
    return this.props.nDisplayItems
  }

  start() {
    const _this = this

    this.audio?.play()
    setTimeout(() => {
      if (this.timer) {
        clearInterval(this.timer)
      }

      this.remaining = this.props.timer
      this.speed = SLOT_MACHINE_SPEED

      this.timer = setInterval(() => {
        _this.loop()
      }, TIMER_INTERVAL)
    }, this.props.delay)
  }

  loop() {
    if (this.stop) {
      return
    }

    this.remaining -= TIMER_INTERVAL

    if (this.stopNextTime) {
      this.stop = true
      this.audio?.pause()
      clearInterval(this.timer)
      this.successAudio?.play()

      let logicalIndex = this.computeLogicalIdx(this.position)
      let newPos = this.position

      while (logicalIndex != this.state.destIdx) {
        newPos += this.props.smIH
        logicalIndex = this.computeLogicalIdx(newPos)
      }

      this.setState({
        position: newPos,
      })

      this.props.onFinished()
    } else {
      // normal regime
      if (this.remaining > 0) {
      }
      // slow down regime
      else if (this.remaining <= 0) {
        this.stopNextTime = true
      }

      this.position = this.position - this.speed

      this.setState({
        position: this.position,
      })
    }
  }

  render() {
    const width = Math.round(this.props.smW)
    const height = Math.round(this.props.smH)
    return (
      <>
        <div
          className={`spinner-item icons ${
            this.props.marginRight ? 'spinner-margin-right' : ''
          } ${this.props.marginLeft ? 'spinner-margin-left' : ''}`}
          style={{
            background: `#fff ${this.props.bgUrl} repeat-y`,
            backgroundPosition: `0px ${this.state.position}px`,
            backgroundSize: `${width}px ${height}px`,
            width,
            height: this.props.height,
          }}
        />
      </>
    )
  }
}
