import React, { FunctionComponent, useState } from 'react'
import { classNameForRatio } from '../../../utils/aspect_ratio'
import Modal from 'react-bootstrap/Modal'
import WheelButton from '../../../components/cta/WheelButton'
import { tt } from '../../../i18n'
import {
  SMS_AUTH_MODAL_ASK_PHONE_NUMBER,
  SMS_AUTH_MODAL_VALIDATE_CODE,
} from '../../../redux/reducers/lottery'

interface Props {
  title: () => any
  show: boolean
  mode: number
  voucherWording: string
  setMode: (mode: number) => void
  onPhoneNumberValid: (phoneNumber: string) => void
  onCodeSubmitted: (code: string) => void
  closeModal: () => void
}

const SMSAuthModal: FunctionComponent<Props> = (props) => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [code, setCode] = useState('')

  const onSubmitAskPhoneNumberForm = (e: any) => {
    if (e.preventDefault) {
      e.preventDefault()
    }

    const isCorrect =
      !!phoneNumber &&
      phoneNumber.length >= 10 &&
      phoneNumber.length <= 12 &&
      (phoneNumber.startsWith('06') ||
        phoneNumber.startsWith('07') ||
        phoneNumber.startsWith('+337') ||
        phoneNumber.startsWith('+336'))

    if (!isCorrect) {
      alert(tt('error'))
      return
    }

    props.onPhoneNumberValid(phoneNumber)
  }

  const renderValidateCode = () => {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <h1 id="sms-auth-title">{props.title()}</h1>
          </div>
        </div>
        <div className="row w-margin-bottom">
          <div className="col-12">
            <p id="sms-auth-text">Entrez le code reçu par SMS.</p>
          </div>
        </div>
        <form id="sms-auth-form" onSubmit={onCodeFormSubmitted}>
          <div className="row w-margin-bottom">
            <div className="col-12">
              <input
                id="sms-auth-code"
                className="form-control"
                type="text"
                minLength={6}
                maxLength={6}
                inputMode="numeric"
                pattern="[0-9]{6}"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required={true}
              />
            </div>
          </div>
          <div className="row w-margin-bottom">
            <div className="col-12">
              <WheelButton id="sms-auth-cta-2" text="Valider" isSubmit={true} />
            </div>
          </div>
        </form>
        <div className="row">
          <div className="col-12 text-center">
            <p
              id="sms-auth-try-again"
              style={{ textDecoration: 'underline' }}
              onClick={() => props.setMode(SMS_AUTH_MODAL_ASK_PHONE_NUMBER)}
            >
              Je n'ai pas reçu le SMS
            </p>
          </div>
        </div>
      </>
    )
  }

  const onCodeFormSubmitted = (e: any) => {
    if (e.preventDefault) {
      e.preventDefault()
    }

    props.onCodeSubmitted(code)
  }

  const renderAskPhoneNumber = () => {
    return (
      <form id="sms-auth-form" onSubmit={onSubmitAskPhoneNumberForm}>
        <div className="row">
          <div className="col-12">
            <h1 id="sms-auth-title">{props.title()}</h1>
          </div>
        </div>
        <div className="row w-margin-bottom">
          <div className="col-12">
            <p id="sms-auth-text">
              Entrez votre numéro de téléphone pour recevoir un code SMS
              permettant de débloquer votre {props.voucherWording}
            </p>
          </div>
        </div>
        <div className="row w-margin-bottom">
          <div className="col-12">
            <input
              id="sms-auth-pn"
              className="form-control"
              value={phoneNumber}
              minLength={10}
              maxLength={12}
              inputMode="tel"
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder={'06XXXXXXXX'}
              required={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <WheelButton
              id="sms-auth-cta"
              text="Recevoir le code"
              isSubmit={true}
            />
          </div>
        </div>
      </form>
    )
  }

  return (
    <Modal show={props.show} centered={true} onHide={props.closeModal}>
      <Modal.Body>
        <div className={`w-modal ${classNameForRatio()}`}>
          <div className="container">
            {props.mode === SMS_AUTH_MODAL_ASK_PHONE_NUMBER &&
              renderAskPhoneNumber()}
            {props.mode === SMS_AUTH_MODAL_VALIDATE_CODE &&
              renderValidateCode()}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SMSAuthModal
