import { useSelector } from 'react-redux'
import React, { FunctionComponent } from 'react'
import SponsorshipIntroduction from './10_Sponsorship/SponsorshipIntroduction'
import Tuto from '../../components/tuto/Tuto'
import { renderWheelContent } from '../helpers/renderWheelContent'
import VenueDTO from '@tootsweet/model/datatransfer/VenueDTO'
import {
  lotteryStepSelector,
  prizeSelector,
  showTutoSelector,
  tutoIsGoogleSelector,
  tutoIsIGSelector,
  tutoIsIGStorySelector,
  tutoIsTikTokSelector,
  tutoRenderCTASelector,
  venueSelector,
} from '../../redux/selectors/lottery'

interface Props {
  venueProp: VenueDTO | null
  validateGameId?: string | undefined | null
  qrcode: boolean
  isIOS: boolean

  /**
   * Used to display the sponsorship welcome screen
   */
  sponsorshipIdStep1?: string | null

  isOfferGift?: boolean
  offeredGiftLabel?: string | null

  isDemo?: boolean
}

const WheelApp: FunctionComponent<Props> = (props) => {
  const { validateGameId, sponsorshipIdStep1 } = props

  const venue = useSelector(venueSelector)
  const prize = useSelector(prizeSelector)
  const step = useSelector(lotteryStepSelector)
  const showTuto = useSelector(showTutoSelector)
  const tutoIsIG = useSelector(tutoIsIGSelector)
  const tutoIsIGStory = useSelector(tutoIsIGStorySelector)
  const tutoIsGoogle = useSelector(tutoIsGoogleSelector)
  const tutoIsTikTok = useSelector(tutoIsTikTokSelector)
  const tutoRenderCTA = useSelector(tutoRenderCTASelector)

  const mustRedeem = validateGameId && validateGameId.length > 0
  const prizeLabel = prize ? ` ${prize.label}` : ''

  return (
    <>
      {!!sponsorshipIdStep1 && (
        <SponsorshipIntroduction sponsorshipId={sponsorshipIdStep1} />
      )}

      {!sponsorshipIdStep1 &&
        !mustRedeem &&
        renderWheelContent(
          venue,
          prizeLabel,
          validateGameId,
          props.isIOS,
          props.qrcode,
          step
        )}

      <Tuto
        show={showTuto}
        isIOS={props.isIOS}
        isIG={tutoIsIG}
        isIGStory={tutoIsIGStory}
        isGoogle={tutoIsGoogle}
        isTikTok={tutoIsTikTok}
        renderCTA={tutoRenderCTA}
        // placeName={finalPlaceName}
      />
    </>
  )
}

export default WheelApp
