import React from 'react'
import './styles.scss'
import WheelButton from '../../../components/cta/WheelButton'
import { TSUtils } from '../../../utils/TSUtils'
import { RootState } from '../../../redux/combinedReducers'
import { setShowFooter } from '../../../redux/actions/app'
import { async_play } from '../../../redux/actions/lottery'
import { connect } from 'react-redux'
import SlotMachineLeft from '../svg/SlotMachineLeft'
import SlotMachineRight from '../svg/SlotMachineRight'
import SpinnerV1 from './SpinnerV1'
import SlotMachineProps from '../SlotMachineProps'
import { generateArrayOf3DifferentIntegers } from '../utils'
import { tt } from '../../../i18n'

interface State {
  winner: any | null
  playCounter: number

  timer1: number
  timer2: number
  timer3: number

  delay1: number
  delay2: number
  delay3: number

  isFinished: boolean
  finished: any

  shouldWin: boolean
  winningIdx: number

  showCTA: boolean
  didComputeIndices: boolean
}

let destinationIndicesIfLost: number[] = []
let startingIndices: number[] = []

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  SlotMachineProps

const SM_FRAME_WIDTH = 20
const SM_FRAME_OFFSET = -8

class SlotMachineV1 extends React.PureComponent<Props, State> {
  audioSource: string = ''
  successAudioSrc: string = ''

  constructor(props: Props) {
    super(props)
    this.play = this.play.bind(this)
    this.onFinished = this.onFinished.bind(this)
    this.renderSlotMachine = this.renderSlotMachine.bind(this)
    this.tryRecomputeIndices = this.tryRecomputeIndices.bind(this)

    this.audioSource = `${this.props.siteConfig.PUBLIC_FILES_URL_CDM}/mp3/sounds/reels.mp3`
    // this.audioSource = `${this.props.siteConfig.PUBLIC_FILES_URL}/static/audio/click_wheel.mp3`
    this.successAudioSrc = `${this.props.siteConfig.PUBLIC_FILES_URL_CDM}/mp3/sounds/success_1.mp3`

    this.state = {
      winner: null,
      playCounter: 0,
      timer1: 4000,
      timer2: 4500,
      timer3: 5000,
      delay1: 0,
      delay2: 100,
      delay3: 200,
      finished: {},
      isFinished: false,
      shouldWin: false,
      winningIdx: -1,
      showCTA: true,
      didComputeIndices: false,
    }

    this.tryRecomputeIndices()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    this.tryRecomputeIndices()

    const keys = Object.keys(this.state.finished)

    if (keys.length === 3) {
      this.setState({
        isFinished: true,
      })
    }

    if (!prevState.isFinished && this.state.isFinished) {
      setTimeout(() => {
        if (this.props.onFinished) {
          this.props.onFinished()
        }
      }, 1000)
    }

    if (!prevProps.game && this.props.game) {
      let shouldWin = this.props.game?.outcome
      let winningIdx = this.props.game?.smIdx

      if (!shouldWin || winningIdx === undefined || winningIdx === null) {
        shouldWin = false
        winningIdx = -1
      } else {
      }

      // console.log(this.props.game.prizeLabel, this.props.game.smIdx,shouldWin, winningIdx)

      this.setState({ shouldWin, winningIdx }, () => {
        this.setState({
          playCounter: this.state.playCounter + 1,
          showCTA: false,
        })
      })
    }
  }

  render() {
    return (
      <div className="slotmachine-container">
        <span className="d-block title w-margin-top w-margin-bottom text-center">
          {tt('slot_machine_title')}
        </span>
        {this.renderSlotMachine(1.3)}
        {this.state.showCTA && (
          <WheelButton
            text="Lancer la machine à sous"
            onClick={this.play}
            isFixedBottom={true}
          />
        )}
      </div>
    )
  }

  private renderSlotMachine(sizeFactor: number) {
    const { lotteryConfig } = this.props

    if (
      !lotteryConfig ||
      !lotteryConfig.smH ||
      !lotteryConfig.smW ||
      !lotteryConfig.smIH ||
      !lotteryConfig.smUrl ||
      !lotteryConfig.smNItems
    ) {
      return null
    }

    return (
      <div className="spinner-container-container">
        <SlotMachineLeft
          height="100%"
          position="absolute"
          left={SM_FRAME_OFFSET}
          width={SM_FRAME_WIDTH}
          top={0}
          zIndex={20}
        />
        <div className="spinner-container">
          <SpinnerV1
            bgUrl={`url(https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/kadow/img/slotmachine/${lotteryConfig.smUrl})`}
            timer={this.state.timer1}
            delay={this.state.delay1}
            playCounter={this.state.playCounter}
            sizeFactor={sizeFactor}
            shouldWin={this.state.shouldWin}
            winningIdx={this.state.winningIdx}
            onFinished={() => this.onFinished(1)}
            initialIdx={startingIndices[0]}
            initialDestIdx={destinationIndicesIfLost[0]}
            marginRight={true}
            audioSource={this.audioSource}
            successAudioSrc={this.successAudioSrc}
            smW={this.props.lotteryConfig.smW}
            smH={this.props.lotteryConfig.smH}
            smIH={this.props.lotteryConfig.smIH}
            smNItems={this.props.lotteryConfig.smNItems}
          />
          <div className="sm-separator" />
          <SpinnerV1
            bgUrl={`url(https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/kadow/img/slotmachine/${lotteryConfig.smUrl})`}
            timer={this.state.timer2}
            delay={this.state.delay2}
            playCounter={this.state.playCounter}
            shouldWin={this.state.shouldWin}
            winningIdx={this.state.winningIdx}
            onFinished={() => this.onFinished(2)}
            marginRight={true}
            marginLeft={true}
            initialIdx={startingIndices[1]}
            initialDestIdx={destinationIndicesIfLost[1]}
            sizeFactor={sizeFactor}
            audioSource={this.audioSource}
            successAudioSrc={this.successAudioSrc}
            smW={this.props.lotteryConfig.smW}
            smH={this.props.lotteryConfig.smH}
            smIH={this.props.lotteryConfig.smIH}
            smNItems={this.props.lotteryConfig.smNItems}
          />
          <div className="sm-separator" />
          <SpinnerV1
            bgUrl={`url(https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/kadow/img/slotmachine/${lotteryConfig.smUrl})`}
            timer={this.state.timer3}
            delay={this.state.delay3}
            playCounter={this.state.playCounter}
            shouldWin={this.state.shouldWin}
            winningIdx={this.state.winningIdx}
            onFinished={() => this.onFinished(3)}
            marginLeft={true}
            initialIdx={startingIndices[2]}
            initialDestIdx={destinationIndicesIfLost[2]}
            sizeFactor={sizeFactor}
            audioSource={this.audioSource}
            successAudioSrc={this.successAudioSrc}
            smW={this.props.lotteryConfig.smW}
            smH={this.props.lotteryConfig.smH}
            smIH={this.props.lotteryConfig.smIH}
            smNItems={this.props.lotteryConfig.smNItems}
          />
        </div>
        <SlotMachineRight
          height="100%"
          position="absolute"
          top={0}
          right={SM_FRAME_OFFSET}
          width={SM_FRAME_WIDTH}
          zIndex={20}
        />
        <div className="gradient-fade" />
      </div>
    )
  }

  private onFinished(index: number) {
    this.setState({
      finished: {
        ...this.state.finished,
        [index]: 1,
      },
    })
  }

  private play() {
    if (!this.props.isPlayStep) {
      this.props.onCTAClicked()
      return
    }

    const { user, isOptOut, siteConfig } = this.props

    if (!user) {
      return
    }

    this.props.async_play(
      siteConfig,
      user.firstName,
      user.paymentEmail,
      isOptOut,
      user.phone,
      true
    )
  }

  private tryRecomputeIndices() {
    if (this.state.didComputeIndices) {
      return
    }

    if (this.props.lotteryConfig && !this.state.didComputeIndices) {
      this.setState({
        didComputeIndices: true,
      })
      computeLostIndicesIfNeeded(this.props.lotteryConfig.smNItems)
      computeStartingIndicesIfNeeded(this.props.lotteryConfig.smNItems)
    }
  }
}

export const mapStateToProps = (state: RootState) => {
  return {
    user: state.app.user,
    status: state.lottery.status,
    siteConfig: state.config,
    prize: state.lottery.prize,
    lotteryConfig: state.lottery.lotteryConfig,
    isOptOut: state.lottery.isOptOut,
    lotteryStepIndex: state.lottery.lotteryStepIndex,
    game: state.lottery.lotteryGameConsume,
    //@ts-ignore
    currentStep: state.lottery.step,
    isDemo: state.lottery.isDemo,
  }
}

export const mapDispatchToProps: any = {
  setShowFooter,
  async_play,
}

export default connect<
  ReturnType<typeof mapStateToProps>,
  typeof mapDispatchToProps,
  SlotMachineProps
>(
  //@ts-ignore
  mapStateToProps,
  mapDispatchToProps
)(SlotMachineV1)

function computeLostIndicesIfNeeded(nItems: number) {
  if (destinationIndicesIfLost.length === 0) {
    destinationIndicesIfLost = generateArrayOf3DifferentIntegers(nItems)
  }
}

function computeStartingIndicesIfNeeded(nItems: number) {
  if (startingIndices.length === 0) {
    startingIndices = generateArrayOf3DifferentIntegers(nItems)
  }
}
