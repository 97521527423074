import * as React from 'react'
import {
  async_saveFormData,
  incrementCTAClickCount,
  setFormData,
} from '../../redux/actions/lottery'
import { useDispatch, useSelector } from 'react-redux'
import WheelButton from '../../components/cta/WheelButton'
import WheelAnalyticsManager from '../../analytics/WheelAnalyticsManager'
import { tt } from '../../i18n'
import moment from 'moment'
import { FunctionComponent, useEffect } from 'react'
import {
  formDataSelector,
  lotteryStepSelector,
} from '../../redux/selectors/lottery'

const GenderAndAgeModalContent: FunctionComponent = () => {
  const dispatch = useDispatch()

  const formData = useSelector(formDataSelector)
  const step = useSelector(lotteryStepSelector)

  useEffect(() => {
    WheelAnalyticsManager.logEvent('gender_and_age')
  }, [])

  const renderOption = (id: string, value: string | number, text: string) => {
    return (
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="gridRadios"
          id={id}
          value={value}
          checked={formData.gender === value}
          required={true}
          onChange={(e) => dispatch(setFormData('gender', e.target.value))}
        />
        <label className="form-check-label" htmlFor={id}>
          {text}
        </label>
      </div>
    )
  }

  const onSubmit = async (e: any) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    dispatch(incrementCTAClickCount())
    dispatch(async_saveFormData())
  }

  return (
    <>
      <h1>{tt('poll_title')}</h1>

      <form id="form-poll" onSubmit={onSubmit}>
        <div className="row w-margin-bottom-half w-padding-top-half">
          <div className="col-12">
            <p>{step?.pQuestion || tt('poll_question')}</p>
          </div>
        </div>

        <div className="row w-margin-bottom-half">
          <div className="col-12">
            <fieldset className="form-group">
              {renderOption('female', 'female', 'Femme')}
              {renderOption('male', 'male', 'Homme')}
              {renderOption('other', 'other', 'Autre')}
            </fieldset>
          </div>
        </div>

        <div className="row w-margin-bottom-half">
          <div className="col-12">
            <input
              className="form-control"
              type="number"
              min={1900}
              max={moment().year()}
              step={1}
              value={formData.age}
              onChange={(e) => dispatch(setFormData('age', e.target.value))}
              placeholder={tt('date_of_birth')}
              required={true}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <WheelButton
              id="cta-gender-and-age"
              text="Valider"
              isSubmit={true}
            />
          </div>
        </div>
      </form>
    </>
  )
}

export default GenderAndAgeModalContent
