import Modal from 'react-bootstrap/Modal'
import { tt } from '../../../i18n'
import WheelButton from '../../../components/cta/WheelButton'
import React, { FunctionComponent, useState } from 'react'
import LotteryGameConsume from '@tootsweet/model/lottery/LotteryGameConsume'
import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import QrCode from 'qrcode.react'

interface Props {
  show: boolean
  close: () => void
  game?: LotteryGameConsume
  onRedeem: (code: string) => void
  lotteryConfig?: LotteryConfigurationResponse
}

const GIFT_MODAL_STATE_INITIAL = 0
const GIFT_MODAL_STATE_CODE = 1
const VALIDATION_CODE_INPUT_ID = 'val-code'
const QRCODE_SIZE = 250

let disabled = false

const GetGiftModal: FunctionComponent<Props> = (props) => {
  const [state, setState] = useState(GIFT_MODAL_STATE_INITIAL)
  const [code, setCode] = useState('')
  const renderInitial = () => {
    return (
      <>
        <div className="row w-margin-bottom-half">
          <div className="col-12">
            <p>{tt('redeem_show_screen_to_staff')}</p>
          </div>
        </div>
        <div className="row w-margin-bottom-half">
          <div className="col-12 text-center">
            <p className="big">Gagné à {props.game?.placeName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <WheelButton
              text={tt('i_am_there')}
              onClick={() => setState(GIFT_MODAL_STATE_CODE)}
            />
          </div>
        </div>
      </>
    )
  }

  const renderCode = () => {
    return (
      <>
        <div className="row w-margin-bottom-half">
          <div className="col-12 text-center">
            <p className="big">Gagné à {props.game?.placeName}</p>
          </div>
        </div>
        <div className="row w-margin-bottom">
          <div className="col-12 input-number-container">
            <input
              id={VALIDATION_CODE_INPUT_ID}
              type="text"
              inputMode="numeric"
              placeholder={tt('placeholder_code')}
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="form-control"
              minLength={4}
              maxLength={4}
              pattern="[0-9]{4}"
              style={{ height: 55, textAlign: 'center', fontSize: 20 }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <WheelButton
              id="red"
              onClick={() => {
                if (disabled) {
                  return
                }
                disabled = true
                props.onRedeem(code)
                setTimeout(() => {
                  disabled = false
                }, 1000)
              }}
              disabled={disabled}
              text={tt('validate')}
            />
          </div>
        </div>
      </>
    )
  }

  const renderQRCode = () => {
    if (!props.game?.id) {
      return null
    }

    const url = `${window.location.origin}${window.location.pathname}?r_id=${props.game.id}`

    return (
      <>
        <div className="row w-margin-bottom">
          <div className="col-12">
            <p>{tt('redeem_by_qr_code')}</p>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center w-100">
          <QrCode size={QRCODE_SIZE} value={url} />
        </div>
      </>
    )
  }

  const close = () => {
    setState(GIFT_MODAL_STATE_INITIAL)
    props.close()
  }

  return (
    <Modal show={props.show} centered={true} onHide={close}>
      <Modal.Body>
        <div className="w-modal">
          <div className="container">
            <i className="fas fa-times modal-close" onClick={close} />
            <div className="row">
              <div className="col-12">
                <h1>
                  {props.game?.prizeLongName || props.game?.prizeLabel || '???'}
                </h1>
              </div>
            </div>
            {props.lotteryConfig?.redeemByQRCode && renderQRCode()}
            {!props.lotteryConfig?.redeemByQRCode &&
              state === GIFT_MODAL_STATE_INITIAL &&
              renderInitial()}
            {!props.lotteryConfig?.redeemByQRCode &&
              state === GIFT_MODAL_STATE_CODE &&
              renderCode()}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default GetGiftModal
