import { useSelector } from 'react-redux'
import { RootState } from '../../redux/combinedReducers'
import WheelButton from '../../components/cta/WheelButton'
import { tt } from '../../i18n'
import React from 'react'

interface Props {
  staticBottom?: boolean
  src: string
}
export default function ReplayButton(props: Props) {
  const canReplay = useSelector((state: RootState) => state.lottery.canReplay)
  const game = useSelector(
    (state: RootState) => state.lottery.lotteryGameConsume
  )
  const buildHref = () => {
    if (!game?.venueId) {
      return '#'
    }
    return `${window.location.origin}${window.location.pathname}?venueId=${game.venueId}&utm_source=${props.src}&utm_medium=replay`
  }

  if (!canReplay) {
    return null
  }

  function renderButton() {
    return (
      <WheelButton
        key="repeat"
        id="repeat"
        text={tt('replay')}
        href={buildHref()}
        isFixedBottom={props.staticBottom}
      />
    )
  }

  if (props.staticBottom) {
    return renderButton()
  }

  return (
    <div className="row">
      <div className="col-12 w-margin-top-half">{renderButton()}</div>
    </div>
  )
}
