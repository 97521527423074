import Modal from 'react-bootstrap/Modal'
import * as React from 'react'
import EULA from './EULA'
import { FunctionComponent } from 'react'
import PrivacyPolicy from './PrivacyPolicy'
import CookiesPolicy from './CookiesPolicy'

interface Props {
  show: boolean
  closeModal: () => void
  customCookiesPolicy?: string
  companyName?: string
  gdprEmail: string
}

const CookiesPolicyModal: FunctionComponent<Props> = (props: Props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.closeModal}
      centered={true}
      className="w-modal-eula"
      size="lg"
    >
      <Modal.Body>
        <div className="w-modal">
          <div className="container">
            <i
              className="fas fa-times modal-close"
              onClick={props.closeModal}
            />
            <CookiesPolicy
              customCookiesPolicy={props.customCookiesPolicy}
              companyName={props.companyName}
              gdprEmail={props.gdprEmail}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CookiesPolicyModal
