import { AnyAction } from 'redux'

export interface SkillTimer {
  remainingSeconds: number
  isRunning: boolean
  id: any | null
  timedOut: boolean
}

export const timerInitialState: SkillTimer = {
  id: null,
  isRunning: false,
  remainingSeconds: 0,
  timedOut: false,
}

export const timer = (
  state: SkillTimer = timerInitialState,
  action: AnyAction
): SkillTimer => {
  switch (action.type) {
    case 'timer_start':
      if (state.id) {
        clearInterval(state.id as any)
      }
      const intervalId = setInterval(action.payload.interval, 1000)
      return {
        ...state,
        id: intervalId as any,
        isRunning: true,
        remainingSeconds: action.payload.remainingSeconds
          ? action.payload.remainingSeconds
          : state.remainingSeconds,
        timedOut: false,
      }
    case 'timer_stop':
      if (state.id) {
        clearInterval(state.id as any)
      }
      return { ...state, isRunning: false, id: null }
    case 'timer_tick':
      let remainingSeconds = state.remainingSeconds - 1
      if (remainingSeconds < 0) {
        remainingSeconds = 0
      }
      if (remainingSeconds === 0) {
        if (state.id) {
          clearInterval(state.id as any)
        }
        return {
          ...state,
          remainingSeconds,
          id: null,
        }
      }
      return {
        ...state,
        remainingSeconds,
      }
    case 'timer_timeout':
      return { ...state, timedOut: true, isRunning: false }
    case 'timer_reset':
      return {
        ...state,
        isRunning: false,
        remainingSeconds: action.payload,
        timedOut: false,
      }
    default:
      return state
  }
}
