import React, {
  FunctionComponent,
  lazy,
  Suspense,
  useEffect,
  useRef,
  useState,
} from 'react'
import Modal from 'react-bootstrap/Modal'
import { tt } from '../../../i18n'
import WheelButton from '../../../components/cta/WheelButton'
import UserDTO from '@tootsweet/model/datatransfer/UserDTO'
import Signature from '@tootsweet/model/lottery/Signature'
import LotteryGameConsume from '@tootsweet/model/lottery/LotteryGameConsume'
import TSConstants from '../../../utils/TSConstants'
import { TSUtils } from '../../../utils/TSUtils'
import { Loader } from '../../../components/Loader/Loader'
const SignatureCanvas = lazy(() => import('react-signature-canvas'))

interface Props {
  show: boolean
  hide: () => void
  user?: UserDTO | null
  game?: LotteryGameConsume | null
  completeForm: (signature: Signature) => void
  percentCompleted: number | undefined | null
  sign: (blob: Blob) => void
  gdprLegalNotice?: string
}

const STEP_FORM = 0
const STEP_SIGN = 2

const SIGN_WIDTH = 246
const SIGN_HEIGHT = 113

const SigningModal: FunctionComponent<Props> = (props) => {
  const [step, setStep] = useState(-1)
  const [firstName, setFirstName] = useState(
    props.game?.signature?.firstName || props.user?.firstName || ''
  )
  const [email, setEmail] = useState(
    props.game?.signature?.email || props.user?.paymentEmail || ''
  )
  const [lastName, setLastName] = useState(
    props.game?.signature?.lastName || props.user?.lastName || ''
  )
  const [phoneNumber, setPhoneNumber] = useState(
    props.game?.signature?.phoneNumber || props.user?.phone || ''
  )
  const [address, setAddress] = useState(props.game?.signature?.address || '')
  const [postalCode, setPostalCode] = useState(
    props.game?.signature?.postalCode || ''
  )
  const [city, setCity] = useState(props.game?.signature?.city || '')
  const [civility, setCivility] = useState(
    props.game?.signature?.civility || ''
  )
  const [didSign, setDidSign] = useState(false)
  const [isOptinSignature, setIsOptinSignature] = useState(false)

  const signRef = useRef<any | null>(null)

  function onBeginSigning() {
    setDidSign(true)
  }

  //brah
  function clear() {
    signRef?.current?.clear()
    setDidSign(false)
  }

  function sign() {
    if (!didSign) {
      alert("Vous n'avez pas signé !")
      return
    }

    if (!isOptinSignature) {
      alert(
        "Vous devez cocher la case \"J'ai lu et j'accepte l'intégralité des conditions indiquées ci-dessus\""
      )
      return
    }

    const res = signRef.current?.toDataURL('image/png')

    if (!res) {
      alert(tt('error'))
      return
    }

    props.sign(TSUtils.dataURItoBlob(res))
  }

  async function onSubmit(e: any) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    await props.completeForm({
      civility,
      firstName,
      lastName,
      phoneNumber,
      email,
      address,
      postalCode,
      city,
    })
    setStep(STEP_SIGN)
  }

  function renderForm() {
    return (
      <>
        <form onSubmit={onSubmit}>
          <div className="row w-margin-bottom">
            <div className="col-12">
              Veuillez remplir le formulaire ci-dessous pour générer votre
              attestation de gain.
            </div>
          </div>
          <div className="row w-margin-bottom-half">
            <div className="col-12">
              <select
                id="civility"
                className="form-control"
                value={civility}
                onChange={(e) => setCivility(e.target.value)}
                required={true}
              >
                <option value="">{tt('placeholder_civility')}</option>
                <option value="Monsieur">Monsieur</option>
                <option value="Madame">Madame</option>
                <option value="Mademoiselle">Mademoiselle</option>
              </select>
            </div>
          </div>
          <div className="row w-margin-bottom-half">
            <div className="col-12">
              <input
                id="firstName"
                type="text"
                placeholder={tt('placeholder_first_name')}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="form-control"
                required={true}
              />
            </div>
          </div>
          <div className="row w-margin-bottom-half">
            <div className="col-12">
              <input
                id="lastName"
                type="text"
                placeholder={tt('placeholder_last_name')}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="form-control"
                required={true}
              />
            </div>
          </div>
          <div className="row w-margin-bottom-half">
            <div className="col-12">
              <input
                id="phoneNumber"
                className="form-control"
                placeholder={tt('placeholder_phone_number')}
                type="text"
                inputMode="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required={true}
              />
            </div>
          </div>
          <div className="row w-margin-bottom-half">
            <div className="col-12">
              <input
                id="email"
                type="email"
                placeholder={tt('placeholder_email')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                required={true}
              />
            </div>
          </div>
          <div className="row w-margin-bottom-half">
            <div className="col-12">
              <input
                type="text"
                className="form-control"
                placeholder={tt('placeholder_address')}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required={true}
              />
            </div>
          </div>
          <div className="row w-margin-bottom-half">
            <div className="col-12">
              <input
                id="postalCode"
                type="text"
                placeholder={tt('placeholder_postcode')}
                value={postalCode}
                inputMode="numeric"
                onChange={(e) => setPostalCode(e.target.value)}
                className="form-control"
                required={true}
              />
            </div>
          </div>
          <div className="row w-margin-bottom-half">
            <div className="col-12">
              <input
                id="city"
                type="text"
                placeholder={tt('placeholder_city')}
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className="form-control"
                required={true}
              />
            </div>
          </div>
          <div className="row w-margin-bottom-half">
            <div className="col-12">
              <WheelButton isSubmit={true} text="Générer mon attestation" />
            </div>
          </div>
          <div className="row w-margin-bottom">
            {!!props.gdprLegalNotice && (
              <p className="text-justify" style={{ fontSize: 10 }}>
                {props.gdprLegalNotice}
              </p>
            )}
          </div>
        </form>
      </>
    )
  }

  function renderSigning() {
    return (
      <>
        <div className="row w-margin-bottom">
          <div className="col-12 text-center">
            <img
              className="twic"
              src={TSConstants.PLACEHOLDER_URL}
              data-src="image:/cdm/kadow/img/logo_h2o.png"
              style={{
                width: 200,
                height: 86,
              }}
            />
          </div>
        </div>
        <div className="row w-margin-bottom">
          <div className="col-12">
            <p style={{ textAlign: 'justify', fontSize: 14 }}>
              Le représentant ci-dessous désigné par la société H2O PRODUCTIONS,
              directeur de production de l’émission VIENS ON S’AIME déclare que
              :
              <br />
              <br />
              {props.game?.signature?.civility}, (ci-après désigné « le
              Participant »)
              <br />
              <br />
              Prénom – Nom : {props.game?.signature?.firstName}{' '}
              {props.game?.signature?.lastName?.toUpperCase()}
              <br />
              Adresse – N° et Rue : {props.game?.signature?.address}
              <br />
              Code postal : {props.game?.signature?.postalCode}
              <br />
              Ville : {props.game?.signature?.city}
              <br />
              Tél : {props.game?.signature?.phoneNumber}
              <br />
              Email : {props.game?.signature?.email}
              <br />
              <br />
              <br />
              A gagné le cadeau suivant (ci-après désigné « la Dotation ») :
              <br />
              <br />
              <b>{props.game?.prizeLongName}</b>
              <br />
              <br />
              {!!props.game?.conditions && (
                <>
                  <span
                    className="font-italic"
                    style={{ wordBreak: 'break-word' }}
                  >
                    {props.game?.conditions}
                  </span>
                  <br />
                  <br />
                </>
              )}
              Lors d’un jeu interactif par système de QR Code intitulé « La Roue
              de La Chance - Viens On S’aime » diffusé dans l’émission VIENS ON
              S’AIME.
              <br />
              <br />
              Enregistrée le 1er Juin 2022
              <br />
              Diffusée le 30 juin 2022 (La date de diffusion n’est que
              prévisionnelle et donnée à titre indicatif)
              <br />
              <br />
              La Dotation ne sera définitivement acquise qu’après (1) l’envoi
              par le Participant de ses informations personnelles (telles que
              son nom, prénom, adresse postale, adresse e-mail, numéro de
              téléphone) à H2O PRODUCTIONS et (2) de la signature électronique
              par le Participant du présent document.
              <br />
              <br />
              Le Participant conservera un exemplaire de ce document.
              <br />
              <br />
              Le défaut, l’inexactitude ou le caractère illisible des
              renseignements fournis vaudra renonciation pure et simple à la
              Dotation, et la Dotation sera perdue.
              <br />
              <br />
              H2O PRODUCTIONS ne pourra être tenue responsable si les
              coordonnées ne correspondent pas à celles du Participant ou sont
              erronées, ou si le Participant est injoignable ou indisponible.
              <br />
              <br />
              Dans ces différents cas, H2O PRODUCTIONS ne sera pas tenue
              d’effectuer des recherches complémentaires afin de retrouver le
              Participant, lequel dans ce cas ne recevra pas sa Dotation et ne
              pourra prétendre à aucune contrepartie ou indemnité.
              <br />
              <br />
              H2O PRODUCTIONS ne pourra être tenue responsable de tout préjudice
              subi du fait de la jouissance de la Dotation.
              <br />
              <br />
              H2O PRODUCTIONS ne saurait être responsable en cas de survenance
              de :
              <ul>
                <li>
                  tout événement présentant les caractéristiques de la force
                  majeure,
                </li>
                <li>
                  tout fait d’un tiers, ou faute d’un Participant causant un
                  préjudice à un autre Participant ou à lui-même,
                </li>
                <li>
                  tout retard et/ou perte du fait des services postaux ou
                  destruction totale ou partielle pour tout cas fortuit, pendant
                  le transport ou l’expédition, de la Dotation. Dans ce cas, la
                  responsabilité du transporteur devra être recherchée
                  directement par les gagnants qui en feront leur affaire sans
                  recours contre la Société.
                </li>
              </ul>
              <br />
              La Dotation sera acceptée telle qu’elle est annoncée, à
              l’exclusion de tout accessoire et/ou option qui serait stipulée
              sur la notice du fabricant. Le Participant ne pourra en aucun cas
              prétendre à l’attribution d’accessoires ou matériels
              complémentaires à la Dotation. Elle ne pourra en aucun cas être
              échangée, ni faire l’objet d’aucune contrepartie en espèces ou par
              chèque. Les gagnants ne pourront en aucun cas prétendre à
              l’attribution d’une autre Dotation.
              <br />
              <br />
              Conformément à la loi n°2000-230 du 13 mars 2000 portant
              adaptation du droit de la preuve aux technologies de l'information
              et relative à la signature électronique et au décret d’application
              du 28 septembre 2017 relatif à la présomption de fiabilité de la
              signature électronique, le Participant et H2O PRODUCTIONS
              conviennent expressément de conclure le présent document sous la
              forme d’un écrit électronique. Le Participant et H2O PRODUCTIONS
              conviennent de recourir à un procédé de signature électronique mis
              en place par H2O PRODUCTIONS et reconnaissent que celui-ci
              constitue un procédé fiable d’identification, garantissant son
              lien avec l’acte auquel cette signature électronique s’attache au
              sens de l’article 1367 du code civil. Le Participant et H2O
              PRODUCTIONS déclarent que cet écrit constitue une preuve littérale
              au sens de l’article 1366 du code civil ayant la même valeur
              probante qu’un écrit sur support papier et qu’il pourra
              valablement leur être opposé. Le Participant et H2O PRODUCTIONS
              s’engagent expressément à ne pas en contester la validité, la
              recevabilité, l’opposabilité ou la force probante sur le fondement
              de sa nature électronique. En conséquence, le document signé
              électroniquement vaut preuve du contenu du document, de l’identité
              des signataires et de leurs consentements aux obligations et
              conséquences de faits et de droits qui en découlent.
              <br />
              <br />
            </p>
          </div>
        </div>
        <div className="row w-margin-bottom-third">
          <div className="col-12 text-center">
            <b>Signez ci-dessous avec le doigt</b>
          </div>
        </div>
        <div className="row w-margin-bottom-half">
          <div className="col-12 d-flex justify-content-center">
            <Suspense
              fallback={
                <Loader
                  loading={true}
                  displayText={true}
                  text={tt('loading')}
                  isFixed={true}
                />
              }
            >
              <SignatureCanvas
                ref={signRef}
                penColor="black"
                onBegin={onBeginSigning}
                canvasProps={{
                  width: SIGN_WIDTH,
                  height: SIGN_HEIGHT,
                  className: 'sigCanvas',
                }}
              />
            </Suspense>
          </div>
        </div>
        <div className="row w-margin-bottom">
          <div className="col-12 d-flex justify-content-center">
            <span style={{ textDecoration: 'underline' }} onClick={clear}>
              Effacer la signature
            </span>
          </div>
        </div>
        <div className="row w-margin-bottom">
          <div className="col-12 text-center">
            <p>
              <div className="form-check">
                <input
                  id="optin-signature"
                  className="form-check-input"
                  type="checkbox"
                  checked={isOptinSignature}
                  onChange={(e) => setIsOptinSignature(e.target.checked)}
                  required={true}
                />
                <label
                  className="form-check-label"
                  htmlFor="optin-signature"
                  style={{ fontSize: 12 }}
                >
                  J'ai lu et j'accepte l'intégralité des conditions indiquées
                  ci-dessus
                </label>
              </div>
            </p>
          </div>
        </div>
        <div className="row w-margin-bottom">
          <div className="col-12">
            <WheelButton text="Recevoir mon attestation" onClick={sign} />
          </div>
        </div>

        <div className="row w-margin-bottom">
          <div className="col-12 text-center">
            <p style={{ fontSize: 10 }}>
              H2O PRODUCTIONS - SASU au capital de 50.000€
              <br />
              Siège social : 88 Rue Thiers, 92100 Boulogne-Billancourt
              <br />
              Tél. 01.46.20.70.00
              <br />
              RCS Nanterre B 521 679 407 – APE 5911A
            </p>
          </div>
        </div>
      </>
    )
  }

  function setAppropriateStep() {
    if (formIsComplete(props)) {
      setStep(STEP_SIGN)
    } else {
      setStep(STEP_FORM)
    }
  }

  useEffect(() => {
    setAppropriateStep()
  }, [])

  useEffect(() => {
    setAppropriateStep()
  }, [props])

  return (
    <Modal
      show={props.show}
      centered={true}
      backdropClassName=""
      className="k-modal-signing"
    >
      <Modal.Body>
        <i className="fas fa-times modal-close" onClick={props.hide} />
        <div className="container">
          <div className="row w-margin-bottom">
            <div className="col-12">
              <h1>Mon attestation de gain</h1>
            </div>
          </div>
          {step === STEP_FORM && renderForm()}
          {step === STEP_SIGN && renderSigning()}
        </div>
      </Modal.Body>
    </Modal>
  )
}

function formIsComplete(props: Props) {
  return (
    !!props.game?.signature?.firstName &&
    props.game?.signature?.firstName.length > 0 &&
    !!props.game?.signature?.lastName &&
    props.game?.signature?.lastName.length > 0 &&
    !!props.game?.signature?.address &&
    props.game?.signature?.address.length > 0 &&
    !!props.game?.signature?.email &&
    props.game?.signature?.email.length > 0 &&
    !!props.game?.signature?.phoneNumber &&
    props.game?.signature?.phoneNumber.length > 0
  )
}

export default SigningModal
