import GenericGameStep from '../../components/GenericGameStep'
import * as React from 'react'
import { async_saveFormData, setFormData } from '../../redux/actions/lottery'
import { useDispatch, useSelector } from 'react-redux'
import FormCheck from 'react-bootstrap/FormCheck'
import WheelButton from '../../components/cta/WheelButton'
import WheelAnalyticsManager from '../../analytics/WheelAnalyticsManager'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import { FunctionComponent, useState, useEffect } from 'react'
import {
  formDataSelector,
  loadingSelector,
  lotteryConfigSelector,
  prizeSelector,
  venueSelector,
  wheelLabelsSelector,
} from '../../redux/selectors/lottery'

interface Props {}

const MonLogisForm: FunctionComponent<Props> = () => {
  const [showCustomerIdPicture, setShowCustomerIdPicture] = useState(false)

  const dispatch = useDispatch()

  const formData = useSelector(formDataSelector)
  const venue = useSelector(venueSelector)
  const prize = useSelector(prizeSelector)
  const wheelLabels = useSelector(wheelLabelsSelector)
  const loading = useSelector(loadingSelector)
  const lotteryConfig = useSelector(lotteryConfigSelector)

  const onSubmit = async (e: any) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    dispatch(async_saveFormData())
  }

  const toggleCustomerIdPicture = () => {
    setShowCustomerIdPicture(!showCustomerIdPicture)
  }

  const renderMainForm = (prefix: string) => {
    return (
      <>
        <input
          id={`${prefix}lastName`}
          className="form-control w-margin-bottom-half"
          type="text"
          required={true}
          placeholder="Nom*"
          value={formData[`${prefix}lastName`]}
          onChange={(e) =>
            dispatch(setFormData(`${prefix}lastName`, e.target.value))
          }
        />
        <input
          id={`${prefix}firstName`}
          className="form-control w-margin-bottom-half"
          type="text"
          required={true}
          placeholder="Prénom*"
          value={formData[`${prefix}firstName`]}
          onChange={(e) =>
            dispatch(setFormData(`${prefix}firstName`, e.target.value))
          }
        />
        <input
          id={`${prefix}customerId`}
          className="form-control w-margin-bottom-half"
          type="text"
          placeholder="Numéro de client"
          value={formData[`${prefix}customerId`]}
          onChange={(e) =>
            dispatch(setFormData(`${prefix}customerId`, e.target.value))
          }
        />
        <p className="w-margin-bottom-half" onClick={toggleCustomerIdPicture}>
          <i
            className="fas fa-info"
            style={{ marginRight: 5, cursor: 'pointer' }}
          />{' '}
          Où trouver mon numéro client ?
        </p>
        {showCustomerIdPicture && (
          <div className="row w-margin-bottom-half">
            <p className="col-12 place-option-subtitle">
              * voir quittance de loyer
            </p>
            <img
              className="twic col-12 "
              data-src="image:cdm/img/quittance2.jpg"
              width="100%"
            />
          </div>
        )}
        <input
          id={`${prefix}birthDate`}
          className="form-control w-margin-bottom-half"
          type="text"
          required={true}
          placeholder="Date de naissance*"
          value={formData[`${prefix}birthDate`]}
          onChange={(e) =>
            dispatch(setFormData(`${prefix}birthDate`, e.target.value))
          }
        />
        <input
          id={`${prefix}birthPlace`}
          className="form-control w-margin-bottom-half"
          type="text"
          required={true}
          placeholder="Lieu de naissance*"
          value={formData[`${prefix}birthPlace`]}
          onChange={(e) =>
            dispatch(setFormData(`${prefix}birthPlace`, e.target.value))
          }
        />
        <input
          id={`${prefix}phone1`}
          className="form-control w-margin-bottom-half"
          type="text"
          inputMode="tel"
          required={true}
          placeholder="Téléphone 1*"
          value={formData[`${prefix}phone1`]}
          onChange={(e) =>
            dispatch(setFormData(`${prefix}phone1`, e.target.value))
          }
        />
        <input
          id={`${prefix}phone2`}
          className="form-control w-margin-bottom-half"
          type="text"
          inputMode="tel"
          placeholder="Téléphone 2"
          value={formData[`${prefix}phone2`]}
          onChange={(e) =>
            dispatch(setFormData(`${prefix}phone2`, e.target.value))
          }
        />
        <input
          id={`${prefix}email`}
          className="form-control w-margin-bottom-half"
          type="email"
          inputMode="email"
          required={true}
          placeholder="Email*"
          value={formData[`${prefix}email`]}
          onChange={(e) =>
            dispatch(setFormData(`${prefix}email`, e.target.value))
          }
        />
        <select
          id={`${prefix}workSituation`}
          className="form-control w-margin-bottom-half"
          required={true}
          value={formData[`${prefix}workSituation`]}
          onChange={(e) =>
            dispatch(setFormData(`${prefix}workSituation`, e.target.value))
          }
        >
          <option value="">Situation professionnelle*</option>
          <option value="cdd">CDD</option>
          <option value="cdi">CDI</option>
          <option value="demandeur">Demandeur d'emploi</option>
          <option value="sansprofession">Sans profession</option>
        </select>
      </>
    )
  }

  const renderDependentForm = (dep: any, idx: number) => {
    const prefix = `dep${idx}`
    return (
      <div key={`dep-form${idx}`}>
        <select
          id={`dep-status-${idx + 1}`}
          className="form-control w-margin-bottom-half"
          value={dep.type}
          required={true}
          onChange={(e) => setDependentData(idx, 'type', e.target.value)}
        >
          <option value="">Statut*</option>
          <option value="enfant">Enfant</option>
          <option value="autre">Autre</option>
        </select>
        <input
          id={`dep-lastname-${idx + 1}`}
          className="form-control w-margin-bottom-half"
          type="text"
          required={true}
          placeholder="Nom*"
          value={dep[`${prefix}lastName`]}
          onChange={(e) =>
            setDependentData(idx, `${prefix}lastName`, e.target.value)
          }
        />
        <input
          id={`dep-firstname-${idx + 1}`}
          className="form-control w-margin-bottom-half"
          type="text"
          required={true}
          placeholder="Prénom*"
          value={dep[`${prefix}firstName`]}
          onChange={(e) =>
            setDependentData(idx, `${prefix}firstName`, e.target.value)
          }
        />
        <input
          id={`dep-birthdate-${idx + 1}`}
          className="form-control w-margin-bottom-half"
          type="text"
          required={true}
          placeholder="Date de naissance*"
          value={dep[`${prefix}birthdate`]}
          onChange={(e) =>
            setDependentData(idx, `${prefix}birthdate`, e.target.value)
          }
        />
        <input
          id={`dep-birthplace-${idx + 1}`}
          className="form-control w-margin-bottom-half"
          type="text"
          required={true}
          placeholder="Lieu de naissance*"
          value={dep[`${prefix}birthplace`]}
          onChange={(e) =>
            setDependentData(idx, `${prefix}birthplace`, e.target.value)
          }
        />
      </div>
    )
  }

  const setDependentData = (idx: number, key: string, value: any) => {
    const deps = [...formData.dependents]
    const dep = deps[idx]
    dep[key] = value
    dispatch(setFormData('dependents', deps))
  }

  const addDependent = () => {
    dispatch(setFormData('dependents', [...formData.dependents, {}]))
  }

  const deleteDependent = (idx: number) => {
    const arr = [...formData.dependents]
    arr.splice(idx, 1)
    dispatch(setFormData('dependents', arr))
  }

  useEffect(() => {
    WheelAnalyticsManager.logEvent('mon_logis')
    dispatch(setFormData('hasPerson2', true))
    dispatch(setFormData('dependents', []))
  }, [])

  if (!venue?.name) return null

  return (
    <GenericGameStep
      modalClass="w-modal-monlogis"
      stepType={LotteryV2StepType.MonLogis}
      renderModalBody={(close: () => void) => {
        return (
          <>
            <i className="fas fa-times modal-close" onClick={close} />
            <h1>Remplissez ce formulaire pour tenter votre chance 🤓</h1>
            <form onSubmit={onSubmit}>
              <div className="row w-margin-bottom-half w-padding-top-half">
                <div className="col-12">
                  <p>
                    <b>Titulaire du bail 1</b>
                  </p>
                </div>
              </div>
              <div className="row w-margin-bottom-half">
                <div className="col-12">{renderMainForm('person1')}</div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p>
                    <b>Titulaire du bail 2</b>
                  </p>
                </div>
              </div>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <p>
                    <FormCheck
                      id="check-no-person-2"
                      type="checkbox"
                      checked={!formData.hasPerson2}
                      label="Pas de titulaire 2"
                      onClick={() =>
                        dispatch(
                          setFormData('hasPerson2', !formData.hasPerson2)
                        )
                      }
                    />
                  </p>
                </div>
              </div>
              {formData.hasPerson2 && (
                <div className="row w-margin-bottom">
                  <div className="col-12">{renderMainForm('person2')}</div>
                </div>
              )}

              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <p>
                    <b>
                      Personnes à charge
                      <i
                        id="add-dependent"
                        className="fas fa-plus-circle"
                        onClick={addDependent}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                      />
                    </b>
                  </p>
                </div>
              </div>

              {formData.dependents && formData.dependents.length === 0 && (
                <div className="row w-margin-bottom-half">
                  <div className="col-12">
                    <p className="w-margin-bottom-half">
                      Vous n'avez pas encore déclaré de personnes à charge.
                    </p>
                    <p>Cliquez sur le bouton "+" ci-dessus pour ce faire.</p>
                  </div>
                </div>
              )}

              {formData.dependents &&
                formData.dependents.map((dep: any, idx: number) => {
                  return (
                    <div key={`dep${idx}`}>
                      <div className="row w-margin-bottom-half">
                        <div className="col-12">
                          <p>
                            Personne à charge {idx + 1}{' '}
                            <i
                              className="fas fa-trash"
                              onClick={() => deleteDependent(idx)}
                            />
                          </p>
                        </div>
                      </div>
                      <div className="row w-margin-bottom">
                        <div className="col-12">
                          {renderDependentForm(dep, idx)}
                        </div>
                      </div>
                    </div>
                  )
                })}

              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <WheelButton
                    id="w-cta-modal"
                    isSubmit={true}
                    text="Valider"
                  />
                </div>
              </div>
              <div className="dummy"></div>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <a
                    href="https://bit.ly/3gmqNUI"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="text-center">
                      <u>Règlement du jeu</u>
                    </p>
                  </a>
                </div>
              </div>
            </form>
          </>
        )
      }}
    />
  )
}

export default MonLogisForm
