import * as React from 'react'
import './styles.scss'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import { renderCTAForStep } from '../../app/00_Wheel/helpers'
import { tt } from '../../i18n'
import { FunctionComponent } from 'react'
import { TSUtils } from '../../utils/TSUtils'

interface Props {
  loading: boolean
  children?: any
  text?: string
  isDark?: boolean
  displayText: boolean
  loaderDisplayBottomPart?: boolean
  loaderStep?: LotteryV2Step
  isFixed: boolean
  isBlack?: boolean
}

export const Loader: FunctionComponent<Props> = (props) => {
  const { loading, children, isDark } = props
  const isIOS = TSUtils.isIOS()

  let displayLoaderText = false
  let loaderText: string | null = ''
  let bottomButton: any = null
  let displayBottomPart = false

  if (props.loaderStep) {
    displayBottomPart = true
    displayLoaderText = shouldDisplayLoaderText(props.loaderStep)

    if (displayLoaderText) {
      loaderText = determineLoaderText(props.loaderStep)
    }

    bottomButton = renderCTAForStep(
      props.loaderStep,
      props.loaderStep ? props.loaderStep.tag || null : null,
      false,
      isIOS,
      false,
      true,
      true,
      false,
      null,
      false
    )
  }

  return (
    <>
      {loading && (
        <div
          className={
            'loader-wrapper ' + (props.isFixed ? 'loader-wrapper-fixed' : '')
          }
        >
          <div className={'lds-ring ' + (isDark ? 'lds-ring-dark' : '')}>
            <div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          {displayLoaderText && (
            <h1 className={'lds-text ' + (isDark ? 'lds-text-dark' : '')}>
              {loaderText || "En attente de prise en compte de l'action..."}
            </h1>
          )}
          {props.displayText && (
            <h1 className={'lds-text ' + (isDark ? 'lds-text-dark' : '')}>
              {props.text || "En attente de prise en compte de l'action..."}
            </h1>
          )}
          {displayBottomPart && (
            <>
              <div className="loader-bottom">
                <h1 className="lds-text-down">{tt('not_done_yet')}</h1>
                {bottomButton}
              </div>
            </>
          )}
        </div>
      )}
      {!loading && children}
    </>
  )
}

const determineLoaderText = (step: LotteryV2Step | undefined) => {
  if (!step || !step.stepType) {
    return null
  }

  switch (step.stepType) {
    case LotteryV2StepType.Facebook:
    case LotteryV2StepType.Instagram:
    case LotteryV2StepType.Snapchat:
    case LotteryV2StepType.Google:
    case LotteryV2StepType.TwoSocialNetworks:
    case LotteryV2StepType.ThreeSocialNetworks:
    case LotteryV2StepType.DownloadApp:
    case LotteryV2StepType.Deliveroo:
    case LotteryV2StepType.UberEats:
    case LotteryV2StepType.DeliverooUberEats:
      return tt('waiting_for_validation')
  }

  return null
}

const shouldDisplayLoaderText = (step: LotteryV2Step | undefined) => {
  if (!step || !step.stepType) {
    return false
  }

  switch (step.stepType) {
    case LotteryV2StepType.Facebook:
    case LotteryV2StepType.Instagram:
    case LotteryV2StepType.Snapchat:
    case LotteryV2StepType.Google:
    case LotteryV2StepType.TwoSocialNetworks:
    case LotteryV2StepType.ThreeSocialNetworks:
    case LotteryV2StepType.DownloadApp:
    case LotteryV2StepType.Deliveroo:
    case LotteryV2StepType.UberEats:
    case LotteryV2StepType.DeliverooUberEats:
    case LotteryV2StepType.TripAdvisor:
      return true
  }
  return false
}
