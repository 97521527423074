import { tt } from '../../../../i18n'
import { TSUtils } from '../../../../utils/TSUtils'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/combinedReducers'

export default function Redeem_Expired() {
  const game = useSelector(
    (state: RootState) => state.lottery.lotteryGameConsume
  )

  if (!game) {
    return null
  }

  return (
    <>
      <div className="w-margin-bottom">
        <h2 className="h2 highlight">{tt('redeem_expired')}</h2>
      </div>

      <div className="w-margin-bottom">
        {tt('redeem_expired_text', {
          prizeLabel: game?.prizeLongName || game.prizeLabel,
          date: TSUtils.formatTimestampAsDate(game.expiryTs),
        })}
      </div>
    </>
  )
}
