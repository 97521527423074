import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import OfferToFriend from '../../components/OfferToFriend'
import WheelStart from '../00_Wheel/01_Start/WheelStart'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import Photo from '../00_Wheel/06_Photo/Photo'
import WheelForm from '../00_Wheel/02_Form/WheelForm'
import { WheelFormType } from '../00_Wheel/02_Form/WheelFormModalContent'
import ProspectionForm from '../00_Wheel/12_ProspectionForm/ProspectionForm'
import PlaceSelection from '../00_Wheel/13_PlaceSelector/PlaceSelection'
import Play from '../00_Wheel/04_Play/Play'
import SocialGameStep from '../00_Wheel/03_SocialNetwork/SocialGameStep'
import { getImageName } from '../00_Wheel/helpers'
import MonLogisForm from '../07_MonLogis/MonLogisForm'
import LPSponsorship from '../10_LPSponsorship/LPSponsorship'
import Sponsorship from '../00_Wheel/10_Sponsorship/Sponsorship'
import SocialPhoto from '../00_Wheel/11_SocialPhoto/SocialPhoto'
import EatalyPoll from '../08_EatalyPoll/EatalyPoll'
import Poll from '../11_Poll/Poll'
import GenderAndAge from '../12_GenderAndAge/GenderAndAge'
import CustomForm from '../13_CustomForm/CustomForm'
import WheelError from '../02_Error/Error'
import MonthlyLotteryIntro from '../14_MonthlyLotteryIntro/MonthlyLotteryIntro'
import MonthlyLotteryGame from '../14_MonthlyLotteryIntro/MonthlyLotteryGame'
import React from 'react'
import MonthlyLotteryEnd from '../14_MonthlyLotteryIntro/MonthlyLotteryEnd'
import VenueDTO from '@tootsweet/model/datatransfer/VenueDTO'
import UTMRedirection from '../24_UTMRedirection/UTMRedirection'

export function renderWheelContent(
  venue: VenueDTO | null,
  prizeLabel: string,
  validateGameId: string | null | undefined,
  isIOS: boolean,
  qrcode: boolean,
  step: LotteryV2Step | null
) {
  const lotteryStepTag = step?.tag || ''

  switch (step?.stepType) {
    case LotteryV2StepType.Offer:
      return <OfferToFriend />

    case LotteryV2StepType.MLEnd:
      return <MonthlyLotteryEnd />

    case LotteryV2StepType.Unknown:
      return null

    case LotteryV2StepType.SearchBar:
    case undefined:
      return <WheelStart />

    case LotteryV2StepType.Photo:
      return <Photo />

    case LotteryV2StepType.Email:
      return <WheelForm step={step} formType={WheelFormType.Email} />

    case LotteryV2StepType.Prospection:
      return <ProspectionForm />

    case LotteryV2StepType.PlaceSelection:
      return <PlaceSelection />

    case LotteryV2StepType.Phone:
      return <WheelForm formType={WheelFormType.Phone} />

    case LotteryV2StepType.Wheel:
    case LotteryV2StepType.ScratchCardChristmas:
    case LotteryV2StepType.ScratchCard:
    case LotteryV2StepType.AdventCalendar:
    case LotteryV2StepType.SlotMachine:
    case LotteryV2StepType.GiftMachine:
    case LotteryV2StepType.Catcher:
    case LotteryV2StepType.Fakir:
    case LotteryV2StepType.Dice:
    case LotteryV2StepType.MysteryBoxes:
      return <Play skin={step.skin} />

    case LotteryV2StepType.Facebook:
      return (
        <SocialGameStep
          key="lot-facebook"
          step={step}
          lotteryStepTag={lotteryStepTag}
          isIOS={isIOS}
        />
      )

    case LotteryV2StepType.Google:
      return (
        <SocialGameStep
          key="lot-google"
          step={step}
          lotteryStepTag={lotteryStepTag}
          isIOS={isIOS}
          imgName={getImageName(LotteryV2StepType.Google)}
          hideImg={true}
          enforceImageWidth={true}
        />
      )

    case LotteryV2StepType.Instagram:
      return (
        <SocialGameStep
          key="lot-instagram"
          step={step}
          lotteryStepTag={lotteryStepTag}
          isIOS={isIOS}
          imgName={getImageName(LotteryV2StepType.Instagram)}
        />
      )

    case LotteryV2StepType.Snapchat:
      return (
        <SocialGameStep
          key="lot-snapchat"
          step={step}
          lotteryStepTag={lotteryStepTag}
          isIOS={isIOS}
        />
      )

    case LotteryV2StepType.TikTok:
      return (
        <SocialGameStep
          key="lot-tiktok"
          step={step}
          lotteryStepTag={lotteryStepTag}
          isIOS={isIOS}
          imgName={getImageName(LotteryV2StepType.TikTok)}
        />
      )

    case LotteryV2StepType.TwoSocialNetworks:
      return (
        <SocialGameStep
          key="lot-2-social-networks"
          step={step}
          lotteryStepTag={lotteryStepTag}
          isIOS={isIOS}
          imgName={getImageName(LotteryV2StepType.TwoSocialNetworks)}
          enforceImageWidth={true}
        />
      )

    case LotteryV2StepType.ThreeSocialNetworks:
      return (
        <SocialGameStep
          key="lot-3-social-networks"
          step={step}
          lotteryStepTag={lotteryStepTag}
          isIOS={isIOS}
          hideImg={true}
          enforceImageWidth={true}
        />
      )

    case LotteryV2StepType.DownloadApp:
      return (
        <SocialGameStep
          key="lot-2-social-networks"
          step={step}
          lotteryStepTag={lotteryStepTag}
          isIOS={isIOS}
          hideImg={!step.customLinkImg}
          imgName={
            step.customLinkImg || getImageName(LotteryV2StepType.DownloadApp)
          }
        />
      )

    case LotteryV2StepType.McDoPlus:
      return (
        <SocialGameStep
          key="lot-mcdoplus"
          step={step}
          lotteryStepTag={lotteryStepTag}
          isIOS={isIOS}
          hideImg={true}
          isMcDonalds={true}
        />
      )

    case LotteryV2StepType.Deliveroo:
      return (
        <SocialGameStep
          key="lot-2-social-networks"
          step={step}
          lotteryStepTag={lotteryStepTag}
          isIOS={isIOS}
          imgName={getImageName(LotteryV2StepType.Deliveroo)}
        />
      )

    case LotteryV2StepType.UberEats:
      return (
        <SocialGameStep
          key="lot-2-social-networks"
          step={step}
          lotteryStepTag={lotteryStepTag}
          isIOS={isIOS}
          hideImg={true}
        />
      )

    case LotteryV2StepType.DeliverooUberEats:
      return (
        <SocialGameStep
          key="lot-2-social-networks"
          step={step}
          lotteryStepTag={lotteryStepTag}
          isIOS={isIOS}
          hideImg={true}
        />
      )

    case LotteryV2StepType.TripAdvisor:
      return (
        <SocialGameStep
          key="lot-2-social-networks"
          step={step}
          lotteryStepTag={lotteryStepTag}
          isIOS={isIOS}
          imgName={getImageName(LotteryV2StepType.TripAdvisor)}
          enforceImageWidth={true}
        />
      )

    case LotteryV2StepType.CustomLink:
      return (
        <SocialGameStep
          key="lot-2-social-networks"
          step={step}
          lotteryStepTag={lotteryStepTag}
          isIOS={isIOS}
          hideImg={!step.customLinkImg}
          imgName={step.customLinkImg}
        />
      )

    case LotteryV2StepType.Staffing:
      return (
        <SocialGameStep
          key="lot-2-social-networks"
          step={step}
          lotteryStepTag={lotteryStepTag}
          isIOS={isIOS}
          imgName={getImageName(LotteryV2StepType.Staffing)}
        />
      )

    case LotteryV2StepType.MonLogis:
      return <MonLogisForm />

    case LotteryV2StepType.LPSponsorship:
      return <LPSponsorship />

    case LotteryV2StepType.Sponsorship:
      return <Sponsorship isModal={false} isIOS={isIOS} />

    case LotteryV2StepType.SocialPhoto:
      return (
        <SocialPhoto
          finalPlaceName={venue?.name ?? ''}
          isModal={false}
          isIOS={isIOS}
        />
      )

    case LotteryV2StepType.EatalyPoll:
      return <EatalyPoll />

    case LotteryV2StepType.Poll:
      return <Poll />

    case LotteryV2StepType.GenderAndAge:
      return <GenderAndAge />

    case LotteryV2StepType.CustomForm:
      return <CustomForm />

    case LotteryV2StepType.Empty:
      return null

    case LotteryV2StepType.Error:
      return <WheelError />

    case LotteryV2StepType.ML_Intro:
      return <MonthlyLotteryIntro />

    case LotteryV2StepType.ML_Game:
      return <MonthlyLotteryGame />

    case LotteryV2StepType.UTMRedirection:
      return <UTMRedirection />

    default:
      return <h1>Inconnu</h1>
  }
}
