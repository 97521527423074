import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GenericGameStep from '../../../components/GenericGameStep'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import { nextLotteryStep } from '../../../redux/actions/lottery'
import renderSocialNetwork from './renderSocialNetwork'
import WheelAnalyticsManager from '../../../analytics/WheelAnalyticsManager'
import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import { FunctionComponent, useEffect, useState } from 'react'
import {
  loadingSelector,
  lotteryConfigSelector,
  lotteryGameConsumeSelector,
  lotteryStepIndexSelector,
  prizeSelector,
  secondaryOptinSelector,
  skinSelector,
  venueSelector,
  wheelLabelsSelector,
  wheelStepIndexSelector,
} from '../../../redux/selectors/lottery'
import { RootState } from '../../../redux/combinedReducers'

interface Props {
  imgName?: string

  step: LotteryV2Step
  lotteryStepTag: string
  isIOS: boolean

  hideImg?: boolean

  isMcDonalds?: boolean
  enforceImageWidth?: boolean

  link?: string
}

const SocialGameStep: FunctionComponent<Props> = (props) => {
  const [hasClickedNo, setHasClickedNo] = useState(false)
  const [forceGoogleButton, setForceGoogleButton] = useState(false)

  const { hideImg } = props

  const dispatch = useDispatch()

  const wheelStepIndex = useSelector(wheelStepIndexSelector)
  const lotteryStepIndex = useSelector(lotteryStepIndexSelector)
  const lotteryConfig = useSelector(lotteryConfigSelector)
  const venue = useSelector(venueSelector)
  const lotteryGame = useSelector(lotteryGameConsumeSelector)

  const skin = useSelector(skinSelector)
  const skinId = useSelector((s: RootState) => s.lottery.skinId)

  const secondaryOptin = useSelector(secondaryOptinSelector)

  useEffect(() => {
    WheelAnalyticsManager.logEvent(props.step?.stepType)
  }, [])

  const renderStepInModal = (
    config: LotteryConfigurationResponse | undefined | null,
    step: LotteryV2Step | null,
    isBeforeWheel: boolean,
    isModal: boolean,
    finalPlaceName: string,
    hideImg: boolean | undefined
  ) => {
    if (!step || !step.stepType) {
      return null
    }

    return (
      <GenericGameStep
        stepType={step.stepType}
        renderModalBody={() =>
          renderSocialNetwork(
            config,
            finalPlaceName,
            props.isIOS,
            isBeforeWheel,
            isModal,
            step,
            step?.tag || null,
            getPrizeLabel(),
            onNoClicked,
            hideImg,
            props.imgName,
            props.enforceImageWidth,
            forceGoogleButton,
            switchToGoogleButton,
            skin,
            skinId,
            secondaryOptin,
            dispatch
          )
        }
      />
    )
  }

  const onNoClicked = () => {
    setHasClickedNo(true)
    if (props.step?.opt) {
      dispatch(nextLotteryStep())
    }
  }

  const getPrizeLabel = () => {
    return lotteryGame?.prizeLabel || '???'
  }

  const switchToGoogleButton = () => {
    setForceGoogleButton(!forceGoogleButton)
  }

  let step: LotteryV2Step | null

  if (hasClickedNo && props.step?.backupStep) {
    step = {
      stepType: props.step.backupStep,
    }
  } else {
    step = props.step
  }

  let isBeforeWheel = wheelStepIndex === -1 || wheelStepIndex > lotteryStepIndex

  if (!venue?.name) return null

  if (isBeforeWheel) {
    return renderStepInModal(
      lotteryConfig,
      step,
      isBeforeWheel,
      true,
      venue?.name,
      hideImg
    )
  }

  return (
    <>
      <div className="w-margin-top-2-thirds"></div>
      {renderSocialNetwork(
        lotteryConfig,
        venue?.name,
        props.isIOS,
        isBeforeWheel,
        false,
        step,
        step?.tag || null,
        getPrizeLabel(),
        onNoClicked,
        hideImg,
        props.imgName,
        props.enforceImageWidth,
        forceGoogleButton,
        switchToGoogleButton,
        skin,
        skinId,
        secondaryOptin,
        dispatch
      )}
    </>
  )
}

export default SocialGameStep
