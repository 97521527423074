import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WheelButton from '../../../components/cta/WheelButton'
import WheelAnalyticsManager from '../../../analytics/WheelAnalyticsManager'
import {
  async_uploadSocialPhoto,
  setLotteryUploadProgress,
} from '../../../redux/actions/lottery'
import GenericGameStep from '../../../components/GenericGameStep'
import { tt } from '../../../i18n'
import FlexPicture from '../../../components/FlexPicture'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import { FunctionComponent, useEffect } from 'react'
import { configSelector } from '../../../redux/selectors/config'
import {
  loadingSelector,
  lotteryConfigSelector,
  percentCompletedSelector,
  prizeSelector,
  wheelLabelsSelector,
} from '../../../redux/selectors/lottery'
import { ResizeImage } from '../../../utils/ImageTools'

interface Props {
  isModal?: boolean
  isFixedBottom?: boolean
  isIOS: boolean
  finalPlaceName: string
}

const INPUT_ID = 'w-camera-input'

const SocialPhoto: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch()

  const siteConfig = useSelector(configSelector)
  const percentCompleted = useSelector(percentCompletedSelector)
  const wheelLabels = useSelector(wheelLabelsSelector)
  const prize = useSelector(prizeSelector)
  const loading = useSelector(loadingSelector)
  const lotteryConfig = useSelector(lotteryConfigSelector)

  dispatch(setLotteryUploadProgress(0))

  useEffect(() => {
    WheelAnalyticsManager.logEvent('social_photo')

    const fileInput = document.getElementById(INPUT_ID)

    if (fileInput) {
      fileInput.addEventListener('change', onPictureChosen)
    }
  }, [])

  const onPictureChosen = async (e: any) => {
    if (!e.target.files[0]) {
      return
    }

    WheelAnalyticsManager.logEvent('social_photo_chosen')

    const file = e.target.files[0]

    const compressedFile = await ResizeImage(file, 1500, 1500)

    dispatch(async_uploadSocialPhoto(siteConfig, compressedFile))
  }

  const onPressCamera = () => {
    const input = document.getElementById(INPUT_ID)
    if (input) {
      input.click()
    }
  }

  const renderSocialNetwork = () => {
    const isUploading = percentCompleted && percentCompleted > 0

    return (
      <>
        <h2 className="h2 w-margin-bottom-half">{tt('social_photo_title')}</h2>

        <p>
          {tt('social_photo_text', { finalPlaceName: props.finalPlaceName })}
        </p>

        <FlexPicture
          isModal={true}
          src={undefined}
          dataSrc={`image:/cdm/img/camera2.png`}
        />

        <WheelButton
          text={
            isUploading
              ? tt('social_photo_upload', { percentCompleted })
              : tt('social_photo_take_photo')
          }
          onClick={isUploading ? undefined : onPressCamera}
        />
      </>
    )
  }
  const { finalPlaceName } = props

  return (
    <>
      <GenericGameStep
        renderModalBody={() => renderSocialNetwork()}
        stepType={LotteryV2StepType.SocialPhoto}
      />
      <input
        id={INPUT_ID}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        // @ts-ignore
        capture="capture"
      />
    </>
  )
}

export default SocialPhoto
