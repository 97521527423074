import { Dispatch } from 'redux'
import {
  buildRedeemURL,
  setLotteryGameConsume,
  setLotteryLoading,
  setLotteryStatus,
  setNextGameMinimalTs,
} from './lottery'
import TSStoreUtils from '../../utils/TSStoreUtils'
import Axios from 'axios'
import { RootState } from '../combinedReducers'
import Quizz, { QuizzGift, QuizzQuestion } from '@tootsweet/model/lottery/Quizz'
import { TSUtils } from '../../utils/TSUtils'
import { LotteryStatus } from '../../model/LotteryStatus'

export const SET_QUIZZ = 'SET_QUIZZ'
export const SET_QUIZZ_QUESTIONS = 'SET_QUIZZ_QUESTIONS'
export const SET_QUIZZ_GIFTS = 'SET_QUIZZ_GIFTS'
export const ANSWER_QUESTION_QUIZZ = 'ANSWER_QUESTION_QUIZZ'
export const QUIZZ_NEXT_QUESTION = 'QUIZZ_NEXT_QUESTION'
export const START_QUIZZ = 'START_QUIZZ'
export const GO_TO_MARKETING_STEP = 'GO_TO_MARKETING_STEP'
export const GO_TO_END = 'GO_TO_END'
export const QUIZZ_TIME_ELAPSED = 'QUIZZ_TIME_ELAPSED'
export const QUIZZ_CHOOSE_GIFT = 'QUIZZ_CHOOSE_GIFT'
export const QUIZZ_RECOMPUTE_SCORE = 'QUIZZ_RECOMPUTE_SCORE'
export const SET_QUIZZ_STEP = 'SET_QUIZZ_STEP'
export const SET_SCORE = 'SET_SCORE'

export const async_fetchQuizz =
  () => async (dispatch: Dispatch, getState: any) => {
    try {
      const state: RootState = getState()
      const config = state.config
      const token = TSStoreUtils.getUserToken(state)
      const venue = state.lottery.venue
      const lotteryConfig = state.lottery.lotteryConfig

      if (!venue || !token || !config || !lotteryConfig) {
        return
      }

      dispatch(setLotteryLoading(true))

      const url = `${config.BACKEND_URL}/quizz`
      const res = await Axios(url, {
        method: 'GET',
        params: { venueId: venue.id },
      })

      const data: Quizz = res.data

      const questions = TSUtils.shuffleArray(
        TSUtils.shuffleArray(TSUtils.shuffleArray(data.questions ?? []))
      ).filter((elem, idx) => idx < (lotteryConfig.qQuestionsPerGame || 0))

      dispatch(setQuizz(data))
      dispatch(setQuestions(questions))
      dispatch(setGifts(data.gifts ?? []))
    } catch (err) {
      console.error(err)
      alert('Erreur quizz 1')
    } finally {
      dispatch(setLotteryLoading(false))
    }
  }

export const async_createGameForQuizz =
  () => async (dispatch: Dispatch, getState: any) => {
    try {
      const state: RootState = getState()
      const siteConfig = state.config
      const token = TSStoreUtils.getUserToken(state)
      const venue = state.lottery.venue

      if (!venue || !token || !siteConfig) {
        console.error('Erreur', venue, token, siteConfig)
        alert('Erreur quizz 2')
        return
      }

      const isOptout = state.lottery.isOptOut

      const res = await Axios({
        url: `${siteConfig.BACKEND_URL}/quizz`,
        method: 'POST',
        headers: { 'x-ts-token': token || '' },
        params: {
          venueId: venue.id,
          fingerprint: state.app.fingerprint,
          utm_campaign: state.app.utm_campaign,
          utm_source: state.app.utm_source,
          utm_medium: state.app.utm_medium,
          isOptout,
          hasEmailOptin: !isOptout && state.lottery.hasEmailOptin,
          hasPhoneOptin: !isOptout && state.lottery.hasPhoneOptin,
        },
      })

      if (res.data && res.data.game && res.data.game.id) {
        dispatch(setLotteryGameConsume(res.data.game))
      } else {
        dispatch(setLotteryStatus(LotteryStatus.GenericError))
      }
    } catch (err) {
      alert('error')
      console.error(err)
      if (err && err.response && err.response.data) {
        dispatch(setNextGameMinimalTs(err.response.data.nextGameMinimalTs))
        const payload = err.response.data
        dispatch(setLotteryStatus(payload.status))
      }
    } finally {
    }
  }

export const async_finishGame =
  () => async (dispatch: Dispatch, getState: any) => {
    try {
      const state: RootState = getState()
      const siteConfig = state.config
      const token = TSStoreUtils.getUserToken(state)

      if (
        !state.lottery.lotteryGameConsume ||
        !state.lottery.lotteryGameConsume.id
      ) {
        alert('No game')
        return
      }

      dispatch(setLotteryLoading(true))

      await Axios({
        url: `${siteConfig.BACKEND_URL}/quizz/finish`,
        method: 'POST',
        headers: { 'x-ts-token': token || '' },
        params: {
          gameId: state.lottery.lotteryGameConsume.id,
          fingerprint: state.app.fingerprint,
          score: state.quizz.score,
          giftId: state.quizz.chosenGift?.id,
        },
      })

      window.location.href = buildRedeemURL(state.lottery.lotteryGameConsume.id)
    } catch (err) {
      alert('error')
      console.error(err)
      if (err && err.response && err.response.data) {
        dispatch(setNextGameMinimalTs(err.response.data.nextGameMinimalTs))
        const payload = err.response.data
        dispatch(setLotteryStatus(payload.status))
      }
    } finally {
      dispatch(setLotteryLoading(false))
    }
  }

export const setQuizz = (quizz: Quizz) => {
  return {
    type: SET_QUIZZ,
    quizz,
  }
}

export const setQuestions = (questions: QuizzQuestion[]) => {
  return {
    type: SET_QUIZZ_QUESTIONS,
    questions,
  }
}

export const setGifts = (gifts: QuizzGift[]) => {
  return {
    type: SET_QUIZZ_GIFTS,
    gifts,
  }
}

export const answerQuestion = (index: number) => {
  return {
    type: ANSWER_QUESTION_QUIZZ,
    index,
  }
}

export const setScore = (score: number) => {
  return {
    type: SET_SCORE,
    score,
  }
}

export const nextQuestion = () => {
  return {
    type: QUIZZ_NEXT_QUESTION,
  }
}

export const recomputeScore = () => {
  return {
    type: QUIZZ_RECOMPUTE_SCORE,
  }
}

export const startQuizz = () => {
  return {
    type: START_QUIZZ,
  }
}

export const chooseGift = (gift?: QuizzGift) => {
  return {
    type: QUIZZ_CHOOSE_GIFT,
    gift,
  }
}

export const goToEnd = () => {
  return {
    type: GO_TO_END,
  }
}

export const setQuizzStep = (step: number) => {
  return {
    type: SET_QUIZZ_STEP,
    step,
  }
}
