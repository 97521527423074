import React from 'react'
import FortuneWheelCanvas from './FortuneWheelCanvas'
import WheelColorsI from '../WheelColors'
import { WheelItem } from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import MonthlyLotteryDraw from '@tootsweet/model/lottery/MonthlyLotteryDraw'

const rand = (min: number, max: number) => {
  return Math.random() * (max - min) + min
}

const MAX_SPIN = 3
const MAX_SPEED = 6
const SLOW_DOWN_MULTIPLIER = 0.993
const SLOW_DOWN_DECREASE = 0.001
const SPEED_SLOW_DOWN_SWITCH = 0.2
const SPEED_UP_INCREASE = 0.07

interface Props {
  width?: number
  height?: number
  wheelItems: WheelItem[]
  colors: WheelColorsI
  onAnimationEnd?: (index: number, value: string) => void
  audioSource?: string
  wheelDegInitial?: number
  mlDraw?: MonthlyLotteryDraw
}

export default class FortuneWheelAnimator extends React.PureComponent<Props> {
  private DIRECTION = -1

  private deg = 0 // rand(0, 360)
  private speed = 0

  private spinCount = 0
  private startSlowDownAt = 0

  private isPlaying = false
  private isSlowingDown = false

  private needleDeg = 0

  private FORTUNE_WHEEL?: FortuneWheelCanvas | null
  private interval: any | null

  constructor(props: Props) {
    super(props)
    this.deg = props.wheelDegInitial || 0
  }

  get maxSpin() {
    return MAX_SPIN
  }

  get maxSpeed() {
    return MAX_SPEED
  }

  get sliceDeg() {
    return 360 / this.props.wheelItems.length
  }

  get slicesCount() {
    return this.props.wheelItems.length
  }

  componentDidMount(): void {
    if (this.FORTUNE_WHEEL) {
      this.FORTUNE_WHEEL.drawFortuneWheel(this.deg)
      this.FORTUNE_WHEEL.drawTopShadow()
    }
  }

  public play = (
    selectedIndex: number,
    direction?: 'clockwise' | 'counter-clockwise'
  ) => {
    if (direction !== undefined) {
      if (direction === 'clockwise') {
        this.DIRECTION = -1
      } else if (direction === 'counter-clockwise') {
        this.DIRECTION = 1
      }
    }

    this.isSlowingDown = false
    this.spinCount = 0

    let speed = this.maxSpeed * this.DIRECTION
    const slowDownRand = SLOW_DOWN_MULTIPLIER

    let degLeft = 0

    while (Math.abs(speed) > SLOW_DOWN_DECREASE) {
      degLeft -= speed
      degLeft = degLeft % 360

      if (Math.abs(speed) > SPEED_SLOW_DOWN_SWITCH) {
        speed *= slowDownRand
      } else if (Math.abs(speed) > SLOW_DOWN_DECREASE) {
        speed -= SLOW_DOWN_DECREASE * this.DIRECTION
      }
    }

    const minDeg = this.sliceDeg * (selectedIndex + 1) - 1
    const maxDeg = this.sliceDeg * selectedIndex + 1

    const stopAtDeg = rand(minDeg, maxDeg)

    const startSlowDownAt = 360 - degLeft + (360 - stopAtDeg)

    this.startSlowDownAt = Math.floor(Math.abs(startSlowDownAt) % 360)
    this.isPlaying = true

    window.requestAnimationFrame(this.drawLoop)

    this.interval = setInterval(() => {
      window.requestAnimationFrame(this.drawLoop)
    }, 20)
  }

  private drawLoop = () => {
    if (!this.isPlaying) {
      return
    }

    if (this.FORTUNE_WHEEL) {
      this.FORTUNE_WHEEL.drawFortuneWheel(this.deg, this.needleDeg)
    }

    this.deg -= this.speed
    this.deg %= 360

    if (!this.isSlowingDown) {
      if (Math.abs(this.speed) < this.maxSpeed) {
        this.speed = this.speed + SPEED_UP_INCREASE * this.DIRECTION
      } else {
        this.speed = this.maxSpeed * this.DIRECTION
      }
    } else {
      if (Math.abs(this.speed) > SPEED_SLOW_DOWN_SWITCH) {
        this.speed *= SLOW_DOWN_MULTIPLIER
      } else if (Math.abs(this.speed) > SLOW_DOWN_DECREASE) {
        this.speed -= SLOW_DOWN_DECREASE * this.DIRECTION
      } else {
        this.speed = 0
        this.needleDeg = 0

        if (this.FORTUNE_WHEEL) {
          this.FORTUNE_WHEEL.drawFortuneWheel(this.deg, this.needleDeg)
        }

        let ai = Math.floor(((360 - this.deg) % 360) / this.sliceDeg)
        ai = (this.slicesCount + ai) % this.slicesCount

        if (this.props.onAnimationEnd) {
          this.props.onAnimationEnd(ai, this.props.wheelItems[ai].label)
        }

        this.isPlaying = false

        if (this.interval) {
          clearInterval(this.interval)
        }

        return
      }
    }

    this.needleLoop()

    if (
      this.deg >= 360 - this.maxSpeed &&
      Math.abs(this.speed) >= this.maxSpeed
    ) {
      this.spinCount++
    }

    if (this.spinCount > this.maxSpin) {
      if (Math.abs(this.deg - this.startSlowDownAt) < this.maxSpeed) {
        if (!this.isSlowingDown) {
          this.deg = this.startSlowDownAt
        }
        this.isSlowingDown = true
      }
    }
  }

  private needleLoop() {
    if (Math.abs(this.needleDeg) < 30) {
      this.needleDeg += this.speed
    } else {
      this.needleDeg %= 30
      this.needleDeg = 0

      // const audio = new Audio(this.props.audioSource)
      //
      // try {
      //
      //   // @ts-ignore
      //   audio && audio.play()
      //   // @ts-ignore
      //   console.log("audio.play", audio?.play)
      // } catch (err) {
      //   console.error(err)
      // }
    }
  }

  render() {
    return (
      <>
        <FortuneWheelCanvas
          ref={(ref) => (this.FORTUNE_WHEEL = ref)}
          wheelItems={this.props.wheelItems}
          colors={this.props.colors}
          width={this.props.width}
          height={this.props.height}
          mlDraw={this.props.mlDraw}
        />
        {/*{!!this.props.audioSource && (<audio id="wheelaudio" src={this.props.audioSource} preload="auto"/>)}*/}
      </>
    )
  }
}
