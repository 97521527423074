import React from 'react'
import { SVGComponentProps } from './Balloon'
import { renderStyles } from '../helpers'

type BigGiftProps = SVGComponentProps & {
  useSecondaryColor?: boolean
}

export default function BigGift(props: BigGiftProps) {
  return (
    <div
      style={{
        width: props.width,
        position: 'absolute',
        top: props.top,
        left: props.left,
        right: props.right,
        bottom: props.bottom,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 650.91 792.87"
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <defs>
          <style>{`
        ${renderStyles()}
        .bg-fill-light { fill:${props.colors.gift1ColorL}} 
        .bg-fill {fill:${props.colors.gift1Color}} 
        .bg-fill-dark{fill:${props.colors.gift1ColorD}}
        `}</style>
        </defs>
        <g id="b4455a15-634c-45dd-a6cf-041b89c2082a" data-name="Layer 2">
          <g id="f7b9443d-6bd4-4d61-971b-79485d783957" data-name="OBJETS_BG">
            <polygon
              points="650.91 657.63 444.49 786.74 242.83 260.76 449.26 131.65 650.91 657.63"
              className="bg-fill"
            />
            <polygon
              points="455.58 149.25 249.16 278.36 242.83 260.76 449.26 131.65 455.58 149.25"
              className="bg-fill-dark"
            />
            <polygon
              points="557.68 715.94 527.99 734.51 324.77 204.18 354.46 185.61 557.68 715.94"
              className="bg-fill-dark"
            />
            <polygon
              points="563.77 716.3 534.08 734.87 529.59 733.51 532.86 731.47 330.99 204.92 360.68 186.35 563.77 716.3"
              className="cs-fill-color-grey-2"
            />
            <polygon
              points="367.51 205.35 337.82 223.92 330.99 204.92 360.68 186.35 367.51 205.35"
              className="cs-fill-color-grey-1"
            />
            <polygon
              points="226.97 792.87 444.49 786.74 242.83 260.76 25.32 266.9 226.97 792.87"
              className="bg-fill-dark"
            />
            <polygon
              points="320.09 792.82 353.21 791.89 354.18 789.29 352.29 789.34 150.63 263.36 117.51 264.29 320.09 792.82"
              className="cs-fill-color-grey-1"
            />
            <polygon
              points="456.65 128.4 242.74 262.19 225.4 213.96 439.31 80.17 456.65 128.4"
              className="bg-fill"
            />
            <polygon
              points="360.04 188.82 329.28 208.07 310.31 155.33 341.08 136.08 360.04 188.82"
              className="bg-fill-dark"
            />
            <polygon
              points="366.35 189.19 335.58 208.44 330.93 207.03 334.32 204.92 316.76 156.09 347.53 136.85 366.35 189.19"
              className="cs-fill-color-grey-3"
            />
            <polygon
              points="17.34 268.55 242.74 262.19 225.4 213.96 0 220.32 17.34 268.55"
              className="bg-fill-dark"
            />
            <polygon
              points="113.83 268.5 148.15 267.53 149.16 264.83 147.2 264.89 129.86 216.65 95.54 217.62 113.83 268.5"
              className="cs-fill-color-grey-1"
            />
            <polygon
              points="213.91 86.53 439.31 80.17 225.4 213.96 0 220.32 213.91 86.53"
              className="bg-fill-light"
            />
            <polygon
              points="135.56 216.5 349.9 82.77 315.27 83.75 100.94 217.47 135.56 216.5"
              className="bg-fill"
            />
            <polygon
              points="339.94 142.32 111.86 149.89 84.53 167.45 309.25 161.52 339.94 142.32"
              className="bg-fill"
            />
            <polygon
              points="348.17 79.61 313.54 80.59 95.09 217.64 129.71 216.66 342.94 82.89 349.28 82.71 348.17 79.61"
              className="cs-fill-color-white"
            />
            <polygon
              points="317.43 156.4 348.13 137.2 119.45 143.84 88.75 163.04 88.78 164.72 91.52 162.96 317.43 156.4"
              className="cs-fill-color-white"
            />
            <path
              d="M225.33,23.25c-22,35.39-4.89,125.65-4.89,125.65,1.34-.6,12.78-.38,17-2.4C262.74,134.24,337,93,335.33,47.89,333.38-4.64,247.3-12.13,225.33,23.25ZM315,52.67c1.74,46.93-69.72,78.77-78.19,82.51,0,0-13.89-78,5.73-109.58S313.22,5.74,315,52.67Z"
              className="cs-fill-color-grey-2"
            />
            <path
              d="M242.29,20.86c-22,35.38-4.89,125.64-4.89,125.64,9.48-4.19,102.38-45.95,100.42-98.48S264.26-14.53,242.29,20.86Zm89.64,29.41c1.74,46.93-81.25,84.24-89.72,88,0,0-15.25-80.63,4.37-112.24S330.18,3.34,331.93,50.27Z"
              className="cs-fill-color-white"
            />
            <path
              d="M65.51,135.42C92.81,172.1,177.78,161,205.62,155.84c4.58-.86,13.5-7.77,14.94-8.09,0,0-43.78-81.48-83.63-96.56S33.7,92.69,65.51,135.42Zm59.31-72.26c35.6,13.47,74.1,83.36,74.1,83.36-9.08,2-85.85,18.87-114.26-19.3S89.23,49.69,124.82,63.16Z"
              className="cs-fill-color-white"
            />
            <path
              d="M63.62,137c31.8,42.72,131.83,21.11,142,18.85,0,0-43.79-81.49-83.63-96.57S31.81,94.26,63.62,137Zm58.24-71.12c35.6,13.48,74.71,86.27,74.71,86.27-9.08,2-98.45,21.33-126.86-16.84S86.26,52.4,121.86,65.87Z"
              className="cs-fill-color-grey-2"
            />
          </g>
        </g>
      </svg>
    </div>
  )
}
