import React from 'react'
import { SMSVGProps } from './SlotMachineLeft'

export default function SlotMachineRight(props: SMSVGProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 13.95 101.11"
      style={props}
    >
      <defs>
        <style>{`
        .cls-1{fill:url(#Dégradé_sans_nom_24);}
       `}</style>
        <linearGradient
          id="Dégradé_sans_nom_24"
          x1="6.98"
          y1="101.11"
          x2="6.98"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.03" stopColor="#696d6d" />
          <stop offset="0.09" stopColor="#808381" />
          <stop offset="0.19" stopColor="#a1a39e" />
          <stop offset="0.3" stopColor="#b9b9b3" />
          <stop offset="0.39" stopColor="#c7c7c0" />
          <stop offset="0.47" stopColor="#ccccc4" />
          <stop offset="0.6" stopColor="#c9c9c1" />
          <stop offset="0.71" stopColor="#bfc0b9" />
          <stop offset="0.8" stopColor="#afb0ab" />
          <stop offset="0.88" stopColor="#989a97" />
          <stop offset="0.96" stopColor="#7b7e7d" />
          <stop offset="1" stopColor="#696d6d" />
        </linearGradient>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Calque_1" data-name="Calque 1">
          <path
            className="cls-1"
            d="M7.57,0,0,0C3.92,10.17,6.69,25.24,7.27,45,8,68.36,4.76,89.89.64,100.83c1.92,0,5.49.28,7.42.28,4.61-19,8.49-48.09,3.68-78.47C10.44,14.4,9.6,7,7.57,0Z"
          />
        </g>
      </g>
    </svg>
  )
}
