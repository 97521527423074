import { STATUS_GAMES_LIMIT_REACHED } from '../utils/StatusCodes'

export enum LotteryStatus {
  Unknown = -1,
  Success = 0,
  AlreadyPlayed = 1,
  NoSuchEvent = 2,
  UploadError = 3,
  NoSuchUser = 4,
  NoConf = 12,
  NoStock = 13,
  GenericError = 14,
  UserUpdateError = 15,

  AlreadyRedeemed = 19,
  GameNotFound = 20,
  GameNotRedeemable = 21,
  GameHasNoRedemptionCode = 22,
  EmptyOrNullCode = 23,
  BadRedemptionCode = 24,
  GameLost = 25,

  SPONSORSHIP_USED = 29,
  TooEarlyToRedeem = 32,
  FrontEndError = 999,
  GamesLimitReached = 39,
}
