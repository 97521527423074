import { RootState } from '../combinedReducers'

export const loadingSelector = (state: RootState) => state.lottery.loading

export const loaderStepSelector = (state: RootState) => state.lottery.loaderStep

export const wheelLabelsSelector = (state: RootState) =>
  state.lottery.wheelLabels

export const prizeSelector = (state: RootState) => state.lottery.prize

export const lotteryStepSelector = (state: RootState) => state.lottery.step

export const validateSelector = (state: RootState) => state.lottery.validate

export const mustValidateSelector = (state: RootState) =>
  state.lottery.mustValidate

export const isMoneySelector = (state: RootState) => state.lottery.isMoney

export const statusSelector = (state: RootState) => state.lottery.status

export const pictureSelector = (state: RootState) => state.lottery.picture

export const lotteryModalStateSelector = (state: RootState) =>
  state.lottery.lotteryModalState

export const percentCompletedSelector = (state: RootState) =>
  state.lottery.percentCompleted

export const lotteryConfigSelector = (state: RootState) =>
  state.lottery.lotteryConfig

export const likesCountSelector = (state: RootState) => state.lottery.likesCount

export const lotteryGameConsumeSelector = (state: RootState) =>
  state.lottery.lotteryGameConsume

export const lotteryStepIndexSelector = (state: RootState) =>
  state.lottery.lotteryStepIndex

export const isOptOutSelector = (state: RootState) => state.lottery.isOptOut

export const wheelStepIndexSelector = (state: RootState) =>
  state.lottery.wheelStepIndex

export const venueSelector = (state: RootState) => state.lottery.venue

export const placeNameSelector = (state: RootState) => state.lottery.placeName

export const hasEmailOptinSelector = (state: RootState) =>
  state.lottery.hasEmailOptin

export const hasPhoneOptinSelector = (state: RootState) =>
  state.lottery.hasPhoneOptin

export const nextGameMinimalTsSelector = (state: RootState) =>
  state.lottery.nextGameMinimalTs

export const last3WinnersSelector = (state: RootState) =>
  state.lottery.last3Winners

export const helpModalErrorSelector = (state: RootState) =>
  state.lottery.helpModalError

export const sponsorshipIdStep2Selector = (state: RootState) =>
  state.lottery.sponsorshipIdStep2

export const sponsorshipURLSelector = (state: RootState) =>
  state.lottery.sponsorshipURL

export const isDemoSelector = (state: RootState) => state.lottery.isDemo

export const adventCalendarShownSelector = (state: RootState) =>
  state.lottery.adventCalendarShown

export const offerToFriendURLSelector = (state: RootState) =>
  state.lottery.offerToFriendURL

export const isBeforeWheelSelector = (state: RootState) =>
  state.lottery.isBeforeWheel

export const showTutoSelector = (state: RootState) => state.lottery.showTuto

export const tutoIsIGSelector = (state: RootState) => state.lottery.tutoIsIG

export const tutoIsIGStorySelector = (state: RootState) =>
  state.lottery.tutoIsIGStory

export const tutoIsGoogleSelector = (state: RootState) =>
  state.lottery.tutoIsGoogle

export const tutoIsTikTokSelector = (state: RootState) =>
  state.lottery.tutoIsTikTok

export const tutoRenderCTASelector = (state: RootState) =>
  state.lottery.tutoRenderCTA

export const formDataSelector = (state: RootState) => state.lottery.formData

export const ctaClickCountSelector = (state: RootState) =>
  state.lottery.ctaClickCount

export const currentSessionFirstNameSelector = (state: RootState) =>
  state.lottery.currentSessionFirstName

export const currentSessionEmailSelector = (state: RootState) =>
  state.lottery.currentSessionEmail

export const smsAuthModalModeSelector = (state: RootState) =>
  state.lottery.smsAuthModalMode

export const showSMSAuthModalSelector = (state: RootState) =>
  state.lottery.showSMSAuthModal

export const skinSelector = (state: RootState) => state.lottery.skin

export const mlConfigSelector = (state: RootState) => state.lottery.mlConfig

export const drawSelector = (state: RootState) => state.lottery.draw

export const showMLOptinModalSelector = (state: RootState) =>
  state.lottery.showMLOptinModal

export const secondaryOptinSelector = (state: RootState) =>
  state.lottery.secondaryOptin
