export interface SkillGame {
  step: GameSteps
  score: number
  gameCreated: boolean
}

export enum GameSteps {
  HOME = 'home',
  ML_INTRO = 'ml_intro',
  ML_GAME = 'ml_game',
  PLAY = 'play',
  END = 'end',
}

export enum GameActions {
  START = 'game_start',
  STOP = 'game_stop',
  UPDATE_SCORE = 'update_score',
  UPDATE_STEP = 'update_step',
}

export const gameInitialState: SkillGame = {
  step: GameSteps.HOME,
  score: 0,
  gameCreated: false,
}

export type SKILL_GAME_ACTIONTYPE =
  | { type: 'game_start' }
  | {
      type: 'update_step'
      payload: GameSteps
    }
  | { type: 'game_stop' }
  | { type: 'update_score'; payload: number }

export const gameReducer = (
  state: SkillGame,
  action: SKILL_GAME_ACTIONTYPE
): SkillGame => {
  switch (action.type) {
    case 'game_start':
      return { ...state, step: GameSteps.PLAY, gameCreated: true }
    case 'game_stop':
      return { ...state, step: GameSteps.END }
    case 'update_score':
      return { ...state, score: action.payload }
    case 'update_step':
      return { ...state, step: action.payload }
    default:
      return state
  }
}
