import React, { FunctionComponent, useEffect, useState } from 'react'
import FlexPicture from '../../../components/FlexPicture'
import { tt } from '../../../i18n'
import OptinOptout from '../../../components/optinoptout/OptinOptout'
import WheelButton from '../../../components/cta/WheelButton'
import {
  async_updateUserInfo,
  incrementCTAClickCount,
  nextLotteryStep,
  setHasEmailOptin,
  setHasPhoneOptin,
  setLotteryLoading,
} from '../../../redux/actions/lottery'
import { useDispatch, useSelector } from 'react-redux'
import WheelAnalyticsManager from '../../../analytics/WheelAnalyticsManager'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import { userSelector } from '../../../redux/selectors/app'
import {
  lotteryConfigSelector,
  lotteryStepSelector,
} from '../../../redux/selectors/lottery'
import UserDTO from '@tootsweet/model/datatransfer/UserDTO'
import {
  showCookesPolicyModal,
  showPrivacyPolicyModal,
} from '../../../redux/actions/app'
import GDPRReminder from '../../../components/GDPRReminder'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'

export enum WheelFormType {
  Email = 'email',
  Phone = 'phone',
}

interface Props {
  formType: WheelFormType
  finalPlaceName: string
  className?: string
}

interface FormValues {
  firstName: string
  lastName?: string
  email: string
  phone: string
  secondaryOptin: boolean
}

const WheelFormModalContent: FunctionComponent<Props> = (props) => {
  const [initialUser, setInitialUser] = useState<UserDTO | null>(null)

  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const step = useSelector(lotteryStepSelector)
  const lotteryConfig = useSelector(lotteryConfigSelector)

  const { formType, finalPlaceName } = props

  useEffect(() => {
    if (user && initialUser == null) {
      setInitialUser(user)
    }
  }, [user, initialUser])

  let className = ''
  let classNameFirstName = ''

  let title = step?.customLinkTitle || tt('become_a_vip')
  let text = ''
  let article = tt('article_de_fr')
  let finalPlaceName2 = finalPlaceName

  if (finalPlaceName.startsWith('Le ') || finalPlaceName.startsWith('le ')) {
    article = tt('article_du_fr')
    finalPlaceName2 = finalPlaceName2.substring(3)
  }

  if (formType === WheelFormType.Email) {
    text =
      step?.customLinkText ||
      tt('enter_email_vip', {
        finalPlaceName: finalPlaceName2,
        article,
      })
  } else if (formType === WheelFormType.Phone) {
    text =
      step?.customLinkText ||
      tt('enter_phone_vip', {
        finalPlaceName,
        article,
      })
  }

  let ctaText = step?.customLinkCTA || tt('_continue')
  const hasCustomPicture = !!step?.customLinkImg

  const onSubmit = async (values: FormValues) => {
    const { firstName, lastName, email, phone, secondaryOptin } = values
    const { formType } = props
    if (
      step?.hasSecondaryOptin &&
      !!step?.secondaryOptinText &&
      !secondaryOptin
    ) {
      alert(`Vous devez cocher la case "${step.secondaryOptinText}" !`)
      return
    }
    if (formType === WheelFormType.Email) {
      dispatch(setLotteryLoading(true))
      await dispatch(
        async_updateUserInfo(
          firstName,
          email,
          step?.askForPhoneNumber ? phone : undefined,
          lastName
        )
      )
      dispatch(setLotteryLoading(false))
      dispatch(setHasEmailOptin(true))
      WheelAnalyticsManager.logEvent('email_filled')
    } else if (formType === WheelFormType.Phone) {
      dispatch(setLotteryLoading(true))
      await dispatch(async_updateUserInfo(firstName, null, phone, lastName))
      dispatch(setLotteryLoading(false))
      dispatch(setHasPhoneOptin(true))
      WheelAnalyticsManager.logEvent('phone_filled')
    }

    dispatch(incrementCTAClickCount())
    dispatch(nextLotteryStep())
  }

  if (!initialUser) {
    return null
  }

  return (
    <>
      <h1>{title}</h1>

      <p>{text}</p>

      <FlexPicture
        isModal={true}
        src={undefined}
        dataSrc={
          step?.customLinkImg
            ? `image:/cdm/${step?.customLinkImg}`
            : 'image:/cdm/envelope.png'
        }
        hasCustomPicture={hasCustomPicture}
      />

      <Formik<FormValues>
        initialValues={{
          firstName: initialUser?.firstName || '',
          lastName: initialUser?.lastName || '',
          email: initialUser?.paymentEmail || '',
          phone: initialUser?.phone || '',
          secondaryOptin: false,
        }}
        onSubmit={onSubmit}
        validationSchema={yup.object({
          firstName: yup.string().required('Required'),
          lastName: yup.string().nullable(),
          email: yup.string().email('Invalid email address'),
          phone: yup.number(),
          secondaryOptin: yup.boolean().required('Required'),
        })}
      >
        {({ setFieldValue, values }) => (
          <Form id="optin-form" className="lottery-form">
            {/*<FormErrorNotification />*/}
            <Field
              id="firstName"
              name={'firstName'}
              placeholder={tt('placeholder_first_name')}
              className={`form-control w-margin-bottom-half ${classNameFirstName}`}
              required={true}
            />
            {step?.askForLastName && (
              <Field
                id="lastName"
                name={'lastName'}
                placeholder={tt('placeholder_last_name')}
                className={`form-control w-margin-bottom-half ${className}`}
                required={true}
              />
            )}
            {formType === WheelFormType.Email && (
              <Field
                id="email"
                name={'email'}
                type={'email'}
                placeholder={tt('placeholder_email')}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]+$"
                className={`form-control w-margin-bottom-half ${className}`}
                required={true}
                onChange={(e: any) => {
                  setFieldValue('email', (e.target.value || '').toLowerCase())
                }}
              />
            )}
            {(formType === WheelFormType.Phone || step?.askForPhoneNumber) && (
              <Field
                id="phone"
                name={'phone'}
                type={'tel'}
                placeholder={tt('placeholder_phone_number')}
                inputMode="tel"
                className={`form-control w-margin-bottom-half ${className}`}
                required={true}
              />
            )}
            {renderSecondaryOptin(
              step,
              props.className,
              values.secondaryOptin,
              () => {
                setFieldValue('secondaryOptin', !values.secondaryOptin)
              }
            )}
            {lotteryConfig?.useOptin && <OptinOptout />}
            <GDPRReminder ctaText={ctaText} />
            <WheelButton id="w-cta-modal" text={ctaText} isSubmit={true} />
            {!lotteryConfig?.useOptin && <OptinOptout />}
          </Form>
        )}
      </Formik>

      {!!lotteryConfig?.gdprLegalNotice && (
        <p className="w-margin-top-half text-justify" style={{ fontSize: 10 }}>
          {lotteryConfig?.gdprLegalNotice}
        </p>
      )}

      <div id="dummy-email" style={{ height: 1 }} />
    </>
  )
}

/*
const FormErrorNotification = () => {
  const { isValid, isValidating, isSubmitting, errors } = useFormikContext()

  useEffect(() => {
    if (!isValid && !isValidating && isSubmitting) {
      console.log(errors)
    }
  }, [isSubmitting, isValid, isValidating])

  return null
}
*/
export default WheelFormModalContent

export function renderSecondaryOptin(
  step: LotteryV2Step | null | undefined,
  className: string | undefined,
  value: boolean | undefined,
  onClick?: () => void
) {
  return (
    <>
      {step?.hasSecondaryOptin && !!step?.secondaryOptinText && (
        <>
          <div className="text-center checkbox">
            <table
              id="w-optin-secondary"
              className="checkbox-table w-optin"
              onClick={onClick}
            >
              <tbody>
                <tr className={className || ''}>
                  <td className={`checkbox-td`}>
                    <img
                      src={
                        'https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/lite-gray-check.png'
                      }
                      className={`checkbox-img ${
                        value ? 'checkbox-img-checked' : ''
                      }`}
                    />
                  </td>
                  <td className="checkbox-text">{step?.secondaryOptinText}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  )
}
