import React from 'react'

interface Props {
  shadowColor: string
  color: string
  width: number
  height: number
}

export default class WheelCursor extends React.PureComponent<Props> {
  svg?: SVGElement | null

  getStyles() {
    const color1 = this.props.shadowColor
    const color2 = this.props.color
    return `.cls-wheel-cursor-1{fill:${color1};}.cls-wheel-cursor-2{fill:${color2};}`
  }

  setDeg(deg: number) {
    if (this.svg) {
      this.svg.style.transform = `rotateZ(${deg}deg)`
    }
  }

  render() {
    return (
      <>
        <svg
          ref={(ref) => (this.svg = ref)}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 159.56 94.41"
          width={this.props.width}
          height={this.props.height}
          style={{ transformOrigin: 'center' }}
        >
          <defs>
            <style>{this.getStyles()}</style>
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="CURSEUR">
              <path
                className="cls-wheel-cursor-1"
                d="M150.08,94.41,0,51.12V43.29L150.08,7.83s9.48,20.7,9.48,43.29S150.08,94.41,150.08,94.41Z"
              />
              <path
                className="cls-wheel-cursor-1"
                d="M150.08,86.58,0,43.29,150.08,0s9.48,20.7,9.48,43.29S150.08,86.58,150.08,86.58Z"
              />
              <path
                className="cls-wheel-cursor-2"
                d="M144.22,78.75,14.43,43.29,144.22,7.83s8.2,17,8.2,35.46S144.22,78.75,144.22,78.75Z"
              />
            </g>
          </g>
        </svg>
      </>
    )
  }
}
