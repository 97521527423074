import React, { FC } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import './ProgressBar.scss'

interface ProgressBarProps {
  progress: number
}

const Progress: FC<ProgressBarProps> = ({ progress }) => {
  return (
    <div className="progress-bar">
      <div className="skill-progress-bar-top">
        <ProgressBar now={progress} animated={false} />
      </div>
    </div>
  )
}

export default Progress
