import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { COLOR_CHRISTMAS_DARK_RED } from '../FortuneWheel/WheelColors'
import chroma from 'chroma-js'

interface Props {
  id: string
  width: number | string
  isDark?: boolean
  top?: number | string
  left?: number | string
  bottom?: number | string
  right?: number | string
  isFixed?: boolean
  angle?: string
}

const _ChristmasGarland: FunctionComponent<Props> = (props) => {
  const divRef = useRef(null)
  const [color, setColor] = useState(COLOR_CHRISTMAS_DARK_RED)
  const [destinationColor] = useState(
    chroma(COLOR_CHRISTMAS_DARK_RED).brighten(2)
  )

  useEffect(() => {
    const newColor = props.isDark ? '#9e1919' : '#c63c3c'
    setColor(newColor)
    chroma(newColor).brighten(3)
  }, [props])

  return (
    <div
      id={props.id}
      ref={divRef}
      style={{
        width: props.width,
        position: props.isFixed ? 'fixed' : 'absolute',
        top: props.top,
        left: props.left,
        right: props.right,
        bottom: props.bottom,
        transform: props.angle ? `rotate(${props.angle}turn)` : undefined,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 217.51 288.22"
      >
        <defs>
          <style>
            {`
          @keyframes cg-animation-${props.id} {
              from {
                fill: ${color};
              }
              to {
                fill: ${destinationColor};
              }
            }
            
          .cls-${props.id}-1{isolation:isolate;}
          .cls-${props.id}-2{fill:none;stroke:#234b5f;stroke-miterlimit:10;stroke-width:4px;}
          .cls-${props.id}-3{fill:${color};animation: cg-animation-${props.id} 1.5s linear 1.5s infinite alternate;}
          .cls-${props.id}-4,.cls-${props.id}-6{mix - blend - mode:overlay;}
          .cls-${props.id}-4{fill:url(#linear-gradient);}
          .cls-${props.id}-5{fill:url(#linear-gradient-2);}
          .cls-${props.id}-6{opacity:0.45;fill:url(#linear-gradient-3);}
          .cls-${props.id}-7{fill:#416986;}
          .cls-${props.id}-8{opacity:0.27;}
          
          `}
          </style>
          <linearGradient
            id="linear-gradient"
            x1="944.29"
            y1="280.56"
            x2="1005.61"
            y2="280.56"
            gradientTransform="matrix(0.55, -0.21, 0.2, 0.52, 34.25, 1222.1)"
            gradientUnits="userSpaceOnUse"
          >
            {/*<stop offset="0" stop-color="#fff"/>*/}
            <stop offset="0" stopColor="#fff" />
            <stop offset="1" stopColor="#fff" stopOpacity="0.45" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="954.38"
            y1="245.31"
            x2="1025.04"
            y2="245.31"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-3"
            x1="697.11"
            y1="142.52"
            x2="916.56"
            y2="142.52"
            xlinkHref="#linear-gradient"
          />
        </defs>
        <g className={`cls-${props.id}-1`}>
          <g id="OBJETS_top">
            <path
              className={`cls-${props.id}-2`}
              d="M516.44,1074.55a129.42,129.42,0,0,1-3.21-28.35c0-14.08,2.29-20.56-2.16-25.42-5.44-5.92-16.69-4.81-23.59-2-6.18,2.52-14.6,8.73-15.43,17.34-.42,4.27,1.14,8,13.15,23.51,6.44,8.32,12,15,16,19.66Z"
              transform="translate(-448.86 -1014.5)"
            />
            <ellipse
              className={`cls-${props.id}-3`}
              cx="557.61"
              cy="1195.26"
              rx="108.95"
              ry="107.2"
              transform="translate(-844.64 -730.62) rotate(-21.28)"
            />
            <path
              className={`cls-${props.id}-4`}
              d="M600.08,1121.13l-3.51,14.42a38.33,38.33,0,0,1,13.08,10.14c6.73,8.25,7.46,17,8.83,23.76,1.82,9,6.25,21.78,18.06,36.48,4.83-5.15,10.72-15.41,15.56-20.56-.63-8.29-4.8-29.37-26.72-49.07A78.77,78.77,0,0,0,600.08,1121.13Z"
              transform="translate(-448.86 -1014.5)"
            />
            <path
              className={`cls-${props.id}-5`}
              d="M587.89,1096.88s9.43,12.72,8.19,15.38c7.69,3,24.87,8.8,35.13,18.5,19.47,18.41,25.42,44.12,27,53.28,0-4-.19-14.5-.22-18.48-1.79-8.61-8.38-33.58-33-51.61C613.79,1105.71,596,1098.89,587.89,1096.88Z"
              transform="translate(-448.86 -1014.5)"
            />
            <path
              className={`cls-${props.id}-6`}
              d="M490.59,1136.53c-5.78,5.21-9.6,16.18-17.08,25.92-4.63,6-13.59,15.41-16.65,13.84-3.52-1.81,1.62-17.85,6.66-28.16,2.46-5,4.88-10,9.8-15.38,5.95-6.53,10.26-7.94,16.49-15.18a58.36,58.36,0,0,0,7.68-11.4l-2.64-7.73c29.32-17.51,40.31-19.39,43.72-16,1.61,1.6,1.31,4.16,4,6.49,4.24,3.68,11.39,2.91,17.54,1.3l1.37.1a66.62,66.62,0,0,1-17.69,14.94c-17.68,10.17-29.69,5.66-42.17,17.13"
              transform="translate(-448.86 -1014.5)"
            />
            <path
              className={`cls-${props.id}-7`}
              d="M546.83,1087.65a122.47,122.47,0,0,0-5.24-14.42c-5.05-11.54-7.67-12.69-8.76-13-1.46-.48-2.89-.47-14.22,3.09-5.68,1.78-8.52,2.67-11.08,3.61-16.28,6-24.42,9-25.8,13.91-.54,1.92,0,3,3.07,10.71s5.34,13.61,6.75,17.34Z"
              transform="translate(-448.86 -1014.5)"
            />
            <path
              className={`cls-${props.id}-8`}
              d="M533,1060.22a23.72,23.72,0,0,1,2.56,2.16c3.27,3.21,4.72,6.57,7,12.86,1.71,4.64,3.13,8.82,4.29,12.41l-55.28,21.19c-.32-1.25-1.48-2.71-2.27-4.76-.15-.41-.15-.38-.16-.42-1-3.86,34.85-11.69,42.6-30.49A23.59,23.59,0,0,0,533,1060.22Z"
              transform="translate(-448.86 -1014.5)"
            />
          </g>
        </g>
      </svg>
    </div>
  )
}

const ChristmasGarland = _ChristmasGarland

export { ChristmasGarland }
