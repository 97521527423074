import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TSUtils } from '../../../utils/TSUtils'
import WheelButton from '../../../components/cta/WheelButton'
import TSConstants from '../../../utils/TSConstants'
import { setMarketingModalState } from '../../../redux/actions/app'
import { tt } from '../../../i18n'
import { renderChromeOnIOS } from '../../helpers/renderChromeOnIOS'

interface Props {
  isModal?: boolean
  userToken?: string
}

const WheelStart: FunctionComponent<Props> = () => {
  const [isChromeOnIOS, setIsChromeOnIOS] = useState(false)
  const [isIOS, setIsIOS] = useState(false)

  const dispatch = useDispatch()

  const renderBackgroundImage = () => {
    return (
      <img
        id="gift-img"
        className="twic gift-image"
        src={TSConstants.PLACEHOLDER_URL}
        data-src={`image:/cdm/gift.png`}
      />
    )
  }

  useEffect(() => {
    setIsIOS(TSUtils.isIOS())
    setIsChromeOnIOS(TSUtils.isChrome() && TSUtils.isIOS())
  }, [])

  if (isChromeOnIOS) {
    return renderChromeOnIOS()
  }

  return (
    <>
      <h2 className="h2 cm-pad w-margin-bottom-half w-margin-top-2-thirds">
        <span className="highlight">{tt('scan_the_qr_code_1')}</span>
        <br /> {tt('scan_the_qr_code_2')}
      </h2>

      <p className="cm-pad w-margin-bottom">{tt('qr_code_not_supported')}</p>
      <p className="cm-pad">{tt('download_qr_code_app')}</p>

      {renderBackgroundImage()}

      <div className="bottom-start-page">
        <WheelButton
          text={tt('download_app')}
          href={
            isIOS
              ? 'https://apps.apple.com/us/app/qr-code-reader/id1200318119'
              : 'https://play.google.com/store/apps/details?id=app.qrcode&hl=fr&gl=fr'
          }
        />
        <div style={{ marginTop: 10 }}>
          <WheelButton
            text={tt('contact_pros')}
            isInverted={true}
            onClick={() => dispatch(setMarketingModalState(true, true))}
          />
        </div>
      </div>
    </>
  )
}

export default WheelStart
