import React, {Component} from 'react'

type Point = {
  x: number
  y: number
}

type CustomBrush = {
  image: any
  width: number
  height: number
}

type CustomCheckZone = {
  x: number
  y: number
  width: number
  height: number
}

interface Props {
  width: number
  height: number
  image: any
  finishPercent?: number
  onComplete?: () => void
  brushSize?: number
  fadeOutOnComplete: boolean
  children?: any
  customBrush?: CustomBrush
  customCheckZone?: CustomCheckZone

  callback1Percent?: number
  callback1?: () => void

  debug?: boolean

  phase?: number
  style?: any
}

interface State {
  loaded: boolean
  finished: boolean
  hasHitCallback1: boolean
}

class Scratch extends Component<Props, State> {
  isDrawing = false
  lastPoint: Point | null = null
  ctx?: any
  canvas!: HTMLCanvasElement
  brushImage?: any

  constructor(props: Props) {
    super(props)
    this.resetCanvas = this.resetCanvas.bind(this)
    this.state = {loaded: false, finished: false, hasHitCallback1: false}
  }

  componentDidMount() {
    this.resetCanvas()
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.phase !== this.props.phase) {
      this.setState({
        finished: false,
      })
    }
  }

  private getFilledInPixels(stride: number) {
    if (!stride || stride < 1) {
      stride = 1
    }

    let x = 0
    let y = 0
    let width = this.canvas.width
    let height = this.canvas.height

    if (this.props.customCheckZone) {
      x = this.props.customCheckZone.x
      y = this.props.customCheckZone.y
      width = this.props.customCheckZone.width
      height = this.props.customCheckZone.height
    }

    const pixels = this.ctx.getImageData(x, y, width, height)

    const total = pixels.data.length / stride
    let count = 0

    for (let i = 0; i < pixels.data.length; i += stride) {
      if (parseInt(pixels.data[i], 10) === 0) {
        count++
      }
    }

    return Math.round((count / total) * 100)
  }

  private getMouse(e: any, canvas: HTMLCanvasElement) {
    const {top, left} = canvas.getBoundingClientRect()
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft

    let x = 0
    let y = 0

    if (e && e.pageX && e.pageY) {
      x = e.pageX - left - scrollLeft
      y = e.pageY - top - scrollTop
    } else if (e && e.touches) {
      x = e.touches[0].clientX - left - scrollLeft
      y = e.touches[0].clientY - top - scrollTop
    }

    return {x, y}
  }

  private distanceBetween(point1: Point | null, point2: Point | null) {
    if (point1 && point2) {
      return Math.sqrt(
        Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2)
      )
    }

    return 0
  }

  private angleBetween(point1: Point | null, point2: Point | null) {
    if (point1 && point2) {
      return Math.atan2(point2.x - point1.x, point2.y - point1.y)
    }
    return 0
  }

  private handlePercentage(filledInPixels = 0) {
    let finishPercent = 70

    if (this.props.finishPercent !== undefined) {
      finishPercent = this.props.finishPercent
    }

    if (filledInPixels > finishPercent) {
      if (this.props.fadeOutOnComplete) {
        this.canvas.style.transition = '1s'
        this.canvas.style.opacity = '0'
      }

      if (!this.state.finished && this.props.onComplete) {
        this.props.onComplete()
      }
      this.setState({finished: true})
      return
    }

    if (
      !this.state.hasHitCallback1 &&
      this.props.callback1Percent &&
      filledInPixels > this.props.callback1Percent &&
      this.props.callback1
    ) {
      this.setState({
        hasHitCallback1: true,
      })
      this.props.callback1()
    }
  }

  private handleMouseDown = (e: any) => {
    this.isDrawing = true
    this.lastPoint = this.getMouse(e, this.canvas)
  }

  private handleMouseMove = (e: any) => {
    if (!this.isDrawing) {
      return
    }

    const currentPoint = this.getMouse(e, this.canvas)
    const distance = this.distanceBetween(this.lastPoint, currentPoint)
    const angle = this.angleBetween(this.lastPoint, currentPoint)

    let x, y

    for (let i = 0; i < distance; i++) {
      x = this.lastPoint ? this.lastPoint.x + Math.sin(angle) * i : 0
      y = this.lastPoint ? this.lastPoint.y + Math.cos(angle) * i : 0

      this.ctx.globalCompositeOperation = 'destination-out'

      if (this.brushImage && this.props.customBrush) {
        this.ctx.drawImage(
          this.brushImage,
          x,
          y,
          this.props.customBrush.width,
          this.props.customBrush.height
        )
      } else {
        this.ctx.beginPath()
        this.ctx.arc(x, y, this.props.brushSize || 20, 0, 2 * Math.PI, false)
        this.ctx.fill()
      }
    }

    this.lastPoint = currentPoint
    this.handlePercentage(this.getFilledInPixels(32))
  }

  private handleMouseUp = () => {
    this.isDrawing = false
  }

  private resetCanvas() {
    this.isDrawing = false
    this.lastPoint = null

    this.canvas.width = Math.trunc(this.props.width * window.devicePixelRatio)
    this.canvas.height = Math.trunc(this.props.height * window.devicePixelRatio)
    this.canvas.style.width = `${this.props.width}px`
    this.canvas.style.height = `${this.props.height}px`
    this.ctx = this.canvas.getContext('2d', {willReadFrequently: true})
    this.ctx.scale(window.devicePixelRatio, window.devicePixelRatio)
    this.ctx.globalCompositeOperation = 'source-over'

    this.canvas.style.transition = ''
    this.canvas.style.opacity = '1'

    if (this.props.debug && this.props.customCheckZone) {
      this.ctx.fillStyle = '#FF0000'
      this.ctx.fillRect(
        this.props.customCheckZone.x,
        this.props.customCheckZone.y,
        this.props.customCheckZone.width,
        this.props.customCheckZone.height
      )
    }

    const image = new Image()
    image.crossOrigin = 'Anonymous'
    image.onload = () => {
      this.ctx.drawImage(image, 0, 0, this.props.width, this.props.height)
      this.setState({loaded: true})
    }

    image.src = this.props.image

    if (this.props.customBrush) {
      this.brushImage = new Image(
        this.props.customBrush.width,
        this.props.customBrush.height
      )
      this.brushImage.src = this.props.customBrush.image
    }
  }

  render() {
    let containerStyle = {
      width: this.props.width + 'px',
      height: this.props.height + 'px',
      position: 'relative' as const,
      WebkitUserSelect: 'none' as const,
      MozUserSelect: 'none' as const,
      msUserSelect: 'none' as const,
      userSelect: 'none' as const,
    }
    if (this.props.style) {
      containerStyle = {
        ...containerStyle,
        ...this.props.style,
      }
    }

    const canvasStyle = {
      position: 'absolute' as const,
      top: 0,
      zIndex: 1,
    }

    const resultStyle = {
      visibility: this.state.loaded
        ? ('visible' as const)
        : ('hidden' as const),
      width: '100%',
      height: '100%',
    }

    return (
        <div className="ScratchCard__Container" style={containerStyle}>
          <canvas
            ref={(ref: any) => {
              this.canvas = ref
            }}
            className="ScratchCard__Canvas"
            style={canvasStyle}
            width={this.props.width}
            height={this.props.height}
            onMouseDown={this.handleMouseDown}
            onTouchStart={this.handleMouseDown}
            onMouseMove={this.handleMouseMove}
            onTouchMove={this.handleMouseMove}
            onMouseUp={this.handleMouseUp}
            onTouchEnd={this.handleMouseUp}
          />
          <div className="ScratchCard__Result" style={resultStyle}>
            {this.props.children}
          </div>
        </div>
    )
  }
}

export default Scratch
