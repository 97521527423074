import * as React from 'react'
import {
  async_saveFormData,
  incrementCTAClickCount,
  setFormDataObj,
} from '../../redux/actions/lottery'
import WheelAnalyticsManager from '../../analytics/WheelAnalyticsManager'
import Form, { AjvError, IChangeEvent } from '@rjsf/core'
import { useDispatch, useSelector } from 'react-redux'
import WheelButton from '../../components/cta/WheelButton'
import { tt } from '../../i18n'
import './styles.scss'
import { FunctionComponent, useEffect, useState } from 'react'
import { lotteryStepSelector } from '../../redux/selectors/lottery'
import { RootState } from '../../redux/combinedReducers'
import { userSelector } from '../../redux/selectors/app'

const CustomCheckbox = function (props: any) {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        checked={props.value}
        id={props.id}
        onChange={() => props.onChange(!props.value)}
        required={props.required}
      />
      <label className="form-check-label" htmlFor={props.id}>
        {props.label}
      </label>
    </div>
  )
}

const widgets = {
  CheckboxWidget: CustomCheckbox,
}

interface Props {}

const CustomFormModalContent: FunctionComponent<Props> = () => {
  const dispatch = useDispatch()

  const step = useSelector(lotteryStepSelector)
  const user = useSelector(userSelector)

  const [errors, setErrors] = useState<AjvError[]>()
  const formData = useSelector((state: RootState) => state.lottery.formData)

  useEffect(() => {
    if (!user) {
      return
    }
    dispatch(
      setFormDataObj({
        ...(formData || {}),
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        email: user.paymentEmail || '',
        phone: user.phone || '',
      })
    )
  }, [user])

  const onSubmit = async (
    e: any,
    nativeEvent: React.FormEvent<HTMLFormElement>
  ) => {
    if (nativeEvent && nativeEvent.preventDefault) {
      nativeEvent.preventDefault()
    }

    WheelAnalyticsManager.logEvent('custom_form_submit')
    dispatch(async_saveFormData())
    dispatch(incrementCTAClickCount())
  }

  if (!step) return null

  if (!step.tag || !step.tag2) {
    return null
  }

  function transformErrors(errors: AjvError[]) {
    const res = errors.map((error) => {
      if (error.name === 'pattern') {
        switch (error.property) {
          case '.phoneNumber':
            error.message =
              'Le numéro de téléphone doit commencer par "+" ou "00" et n\'être composé que de chiffres.'
            break
          case '.dateOfBirth':
            error.message =
              'La date de naissance doit être au format DD/MM/AAAA.'
        }
      }
      return error
    })
    setErrors(errors)
    return res
  }

  const onChange = (e: IChangeEvent, es?: any) => {
    dispatch(setFormDataObj(e.formData))
  }

  const schema = JSON.parse(step.tag)
  const uiSchema = JSON.parse(step.tag2)

  return (
    <>
      <h1>{step.customLinkTitle || tt('poll_title')}</h1>

      <Form
        schema={schema}
        uiSchema={uiSchema}
        widgets={widgets}
        formData={formData}
        onChange={onChange}
        onSubmit={onSubmit}
        showErrorList={false}
        transformErrors={transformErrors}
      >
        <span className="k-customform-errors d-block w-margin-bottom-half">
          {errors?.map((error, idx) => {
            return (
              <span key={`err-${idx}`} className="d-block">
                {error.message}
              </span>
            )
          })}
        </span>

        <WheelButton isSubmit={true} text={tt('play')} />
      </Form>
    </>
  )
}

export default CustomFormModalContent
