import React from 'react'
import { RootState } from '../../redux/combinedReducers'
import { setShowFooter } from '../../redux/actions/app'
import { async_ml_draw, async_play } from '../../redux/actions/lottery'
import { connect } from 'react-redux'
import { TSUtils } from '../../utils/TSUtils'
import Spinner from '../16_GiftMachine/Spinner'
import generateGiftMachineImage from './generateImage'
import './styles.scss'
import WheelButton from '../../components/cta/WheelButton'
import { tt } from '../../i18n'
import TSConstants from '../../utils/TSConstants'
import { WheelItem } from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import logo from './token.png'
import TitleV2 from '../../components/MonthlyLottery/Title/TitleV2'
import { renderSkin } from '../../components/FortuneWheel/render_skin'
import { GiftMachineImageParameters } from './giftMachineImageParameters'
import GiftMachineWireframeRight, {
  SVG_WIREFRAME_HEIGHT,
  SVG_WIREFRAME_WIDTH,
} from './svg/GiftMachineWireframeRight'

interface SlotMachineProps {
  finalPlaceName?: string
  isPlayStep: boolean
  onCTAClicked?: () => void
  onFinished?: () => void
}

interface State {
  winner: any | null
  playCounter: number
  timer1: number
  delay1: number
  isFinished: boolean
  finished: any
  shouldWin: boolean
  winningIdx: number
  showCTA: boolean
  didComputeIndices: boolean
  updateCounter: number
  width: number
  height: number
  didGenerateImage: boolean
  imageReady: boolean
  imgParamz: GiftMachineImageParameters | null
}

let destinationIndexIfLost: number
let startingIndex: number

let nDisplayItems = 3

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  SlotMachineProps

const ITEM_HEIGHT = 80
const HEIGHT_FACTOR = SVG_WIREFRAME_WIDTH / SVG_WIREFRAME_HEIGHT

class GiftMachine extends React.PureComponent<Props, State> {
  audioSource: string = ''
  successAudioSrc: string = ''
  CONTAINER?: HTMLElement | null

  constructor(props: Props) {
    super(props)
    this.play = this.play.bind(this)
    this.renderGiftMachine = this.renderGiftMachine.bind(this)
    this.tryRecomputeIndices = this.tryRecomputeIndices.bind(this)
    this.tryGenerateImage = this.tryGenerateImage.bind(this)
    this.onFinished = this.onFinished.bind(this)

    this.audioSource = `${this.props.siteConfig.PUBLIC_FILES_URL_CDM}/mp3/sounds/reels.mp3`
    // this.audioSource = `${this.props.siteConfig.PUBLIC_FILES_URL}/static/audio/click_wheel.mp3`
    this.successAudioSrc = `${this.props.siteConfig.PUBLIC_FILES_URL_CDM}/mp3/sounds/success_1.mp3`

    this.state = {
      winner: null,
      playCounter: 0,
      timer1: 4000,
      delay1: 0,
      finished: {},
      isFinished: false,
      shouldWin: false,
      winningIdx: -1,
      showCTA: true,
      didComputeIndices: false,
      updateCounter: 1,
      width: 0,
      height: 0,
      didGenerateImage: false,
      imgParamz: null,
      imageReady: false,
    }

    this.tryRecomputeIndices()
  }

  componentDidMount() {}

  componentDidUpdate(prevProps: Readonly<Props>) {
    this.tryGenerateImage()

    if (!prevProps.game && this.props.game) {
      let shouldWin = this.props.game?.outcome
      let winningIdx = this.props.lotteryConfig?.wheelItems?.findIndex(
        (wi: WheelItem) => wi.id === this.props.game.giftId
      )
      if (!shouldWin) {
        winningIdx = this.props.lotteryConfig?.wheelItems?.findIndex(
          (wi: WheelItem) => wi.isLost && wi.isGC
        )
      }
      this.setState({ shouldWin, winningIdx }, () => {
        this.setState({
          playCounter: this.state.playCounter + 1,
          showCTA: false,
        })
      })
    }
  }

  private tryGenerateImage() {
    if (
      this.state.width <= 0 ||
      this.state.height <= 0 ||
      this.state.didGenerateImage ||
      // !this.props.mlDraw ||
      !this.state.imageReady
    ) {
      return
    }

    const imgParamz = generateGiftMachineImage(
      this.state.width,
      this.state.height,
      ITEM_HEIGHT,
      3.5,
      this.props.mlDraw,
      this.props.lotteryConfig,
      7
    )

    this.setState({
      didGenerateImage: true,
      imgParamz,
    })
  }

  private computeGiftMachineDimensions() {
    if (this.CONTAINER) {
      const width = Math.trunc(this.CONTAINER.clientWidth * 0.8)
      const height = Math.trunc(width / HEIGHT_FACTOR)
      this.setState({
        width,
        height,
      })
    }
  }

  render() {
    return (
      <>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 1,
          }}
        >
          {renderSkin(
            'bg',
            this.props.skin,
            undefined,
            undefined,
            window.innerHeight
          )}
        </div>

        <div
          id="gm-container"
          ref={(ref) => {
            if (this.CONTAINER) {
              return
            }

            this.CONTAINER = ref
            this.computeGiftMachineDimensions()
          }}
          className="giftmachine-container"
        >
          <div className="w-margin-top w-margin-bottom">
            {!this.props.isPlayStep && (
              <TitleV2
                title1={tt('gift_machine_1')}
                title2={tt('gift_machine_2')}
              />
            )}
            {this.props.isPlayStep && <TitleV2 title1={tt('its_good')} />}
          </div>

          <img
            id="img-gc"
            src={
              !!this.props.lotteryConfig?.mlToken
                ? `https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/kadow/glm/${this.props.lotteryConfig?.mlToken}`
                : logo
            }
            crossOrigin="anonymous"
            style={{
              display: 'none',
              height: TSConstants.goldenCloverDimension,
            }}
            onLoad={() =>
              this.setState({
                imageReady: true,
              })
            }
          />
          {this.renderGiftMachine()}
          <div
            className="w-margin-top k-glm-cta-container"
            style={{
              width: this.state.imgParamz?.width,
            }}
          >
            {this.state.showCTA && (
              <WheelButton
                id={this.props.isPlayStep ? 'gm-cta-play' : 'gm-cta'}
                text={tt('start_machine')}
                onClick={this.play}
                isFixedBottom={true}
              />
            )}
          </div>
        </div>

        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 1,
          }}
        >
          {renderSkin(
            'fg',
            this.props.skin,
            undefined,
            undefined,
            window.innerHeight
          )}
        </div>
      </>
    )
  }

  private renderGiftMachine() {
    return (
      <div
        className="spinner-container-container"
        style={{
          height: this.state.height,
          width: this.state.width,
        }}
      >
        <div
          className="spinner-container"
          style={{
            position: 'absolute',
            zIndex: -1,
            height: this.state.height,
            width: this.state.width,
          }}
        >
          {!!this.state.imgParamz && (
            <Spinner
              timer={this.state.timer1}
              delay={this.state.delay1}
              playCounter={this.state.playCounter}
              shouldWin={this.state.shouldWin}
              winningIdx={this.state.winningIdx}
              onFinished={this.onFinished}
              initialIdx={startingIndex}
              initialDestIdx={destinationIndexIfLost}
              marginRight={false}
              audioSource={this.audioSource}
              successAudioSrc={this.successAudioSrc}
              smW={this.state.imgParamz.width}
              smH={this.state.imgParamz.imgHeight}
              smIH={ITEM_HEIGHT}
              smNItems={this.state.imgParamz.nItems}
              bgUrl={`url(${this.state.imgParamz.img})`}
              targetWidth={this.state.imgParamz.width}
              width={this.state.width}
              height={this.state.height}
              nDisplayItems={nDisplayItems}
              giftPaddingY={16}
            />
          )}
        </div>

        {!!this.state.width && (
          <>
            <GiftMachineWireframeRight
              width={this.state.width}
              height={Math.trunc(this.state.height * 1.03)}
              position="absolute"
              transform="scaleX(-1)"
              left={-47}
              color={this.props.lotteryConfig?.colCTABack}
              color2={this.props.lotteryConfig?.colCTABack}
            />
            <GiftMachineWireframeRight
              width={this.state.width}
              height={Math.trunc(this.state.height * 1.03)}
              position="absolute"
              right={-47}
              color={this.props.lotteryConfig?.colCTABack}
              color2={this.props.lotteryConfig?.colCTABack}
            />
          </>
        )}
      </div>
    )
  }

  private onFinished() {
    setTimeout(() => {
      this.props.onFinished()
    }, 2000)
  }

  private play() {
    if (!this.props.isPlayStep) {
      this.props.onCTAClicked()
      return
    }

    const { user, isOptOut, siteConfig } = this.props

    if (!user) {
      return
    }

    this.props.async_play(
      siteConfig,
      user.firstName,
      user.paymentEmail,
      isOptOut,
      user.phone,
      true
    )
  }

  private tryRecomputeIndices() {
    if (this.state.didComputeIndices) {
      return
    }

    if (
      this.props.lotteryConfig &&
      this.props.lotteryConfig?.wheelItems &&
      !this.state.didComputeIndices
    ) {
      const nItems = this.props.lotteryConfig.wheelItems.length || 0

      startingIndex = Math.trunc(TSUtils.getRandomIntInRange(0, nItems))

      destinationIndexIfLost = this.props.lotteryConfig.wheelItems.findIndex(
        (wi: WheelItem) => wi.isLost && wi.isGC
      )
      this.setState({
        didComputeIndices: true,
      })
    }
  }
}

export const mapStateToProps = (state: RootState) => {
  return {
    user: state.app.user,
    status: state.lottery.status,
    siteConfig: state.config,
    prize: state.lottery.prize,
    lotteryConfig: state.lottery.lotteryConfig,
    isOptOut: state.lottery.isOptOut,
    lotteryStepIndex: state.lottery.lotteryStepIndex,
    game: state.lottery.lotteryGameConsume,
    currentStep: state.lottery.step,
    isDemo: state.lottery.isDemo,
    mlConfig: state.lottery.mlConfig,
    mlDraw: state.lottery.draw,
    skin: state.lottery.skin,
  }
}

export const mapDispatchToProps: any = {
  setShowFooter,
  async_play,
  async_ml_draw,
}

export default connect<
  ReturnType<typeof mapStateToProps>,
  typeof mapDispatchToProps,
  SlotMachineProps
>(
  //@ts-ignore
  mapStateToProps,
  mapDispatchToProps
)(GiftMachine)
