import * as React from 'react'
import { useSelector } from 'react-redux'
import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import { tt } from '../../i18n'
import WheelAnalyticsManager from '../../analytics/WheelAnalyticsManager'
import { LotteryStatus } from '../../model/LotteryStatus'
import { FunctionComponent, useEffect } from 'react'
import {
  lotteryConfigSelector,
  statusSelector,
} from '../../redux/selectors/lottery'

interface Props {}

const WheelError: FunctionComponent<Props> = () => {
  const status = useSelector(statusSelector)
  const lotteryConfig = useSelector(lotteryConfigSelector)

  useEffect(() => {
    switch (status) {
      case LotteryStatus.AlreadyPlayed:
        WheelAnalyticsManager.logEvent('already_played')
        break
      default:
        WheelAnalyticsManager.logEvent('error')
        break
    }
  }, [status])

  // if (!lotteryConfig) return null
  return renderError(status, lotteryConfig)
}

export const renderError = (
  status: number,
  config?: LotteryConfigurationResponse
) => {
  let displayReplayIntervalSentence = false
  let replayIntervalSentence = ''

  if (config) {
    switch (config.replayInterval) {
      // 24 hours
      case 86400:
        displayReplayIntervalSentence = true
        replayIntervalSentence = tt('error_cannot_play_more_than_once_per_day')
        break
      case 86400 * 3:
        displayReplayIntervalSentence = true
        replayIntervalSentence = tt(
          'error_cannot_play_more_than_once_every_3_days'
        )
        break
      // 1 week
      case 604800:
        displayReplayIntervalSentence = true
        replayIntervalSentence = tt('error_cannot_play_more_than_once_per_week')
        break
      // 2 weeks
      case 1209600:
        displayReplayIntervalSentence = true
        replayIntervalSentence = tt(
          'error_cannot_play_more_than_once_every_two_weeks'
        )
        break
      // 1 month
      case 2592000:
        displayReplayIntervalSentence = true
        replayIntervalSentence = tt(
          'error_cannot_play_more_than_once_per_month'
        )
        break
      // 1 year
      case 31536000:
        displayReplayIntervalSentence = true
        replayIntervalSentence = tt('error_cannot_play_more_than_once_per_year')
        break
      default:
        break
    }
  }

  switch (status) {
    case undefined:
      return (
        <>
          <h2 className="h2 highlight w-margin-bottom w-margin-top-2-thirds">
            {tt('oops')}
          </h2>
          <p className="w-margin-bottom-half">{tt('technical_error')}</p>
          <p className="w-margin-bottom-half">
            {tt('technical_info')} {tt('unknown')}
          </p>
        </>
      )

    case LotteryStatus.AlreadyPlayed:
      return (
        <>
          <h2 className="h2 highlight w-margin-bottom w-margin-top-2-thirds">
            {config?.blockedTitle || tt('rules_of_the_game')}
          </h2>
          {!config?.blockedText && displayReplayIntervalSentence && (
            <>
              {/*<p className="w-margin-bottom-half">*/}
              {/*  {tt('you_cant_play_many_times')}*/}
              {/*</p>*/}
              <p id="cannot-play" className="w-margin-bottom-half">
                {replayIntervalSentence}
              </p>
            </>
          )}
          {!!config?.blockedText && (
            <p
              id="cannot-play"
              className="w-margin-bottom-half"
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {config?.blockedText}
            </p>
          )}
          {/*{ nextGameMinimalTs && (<p className="w-margin-bottom-half">*/}
          {/*  Vous pourrez retenter votre chance à partir du {date} à {time}, ou jouer dans*/}
          {/*  un autre établissement.*/}
          {/*</p>)}*/}
          {/*{ !nextGameMinimalTs && (<p className="w-margin-bottom-half">*/}
          {/*  Vous pouvez retenter votre chance un autre jour ou jouer dans*/}
          {/*  un autre établissement.*/}
          {/*</p>)}*/}
        </>
      )

    case LotteryStatus.GameNotRedeemable:
      return (
        <>
          <h2 className="h2 highlight w-margin-bottom w-margin-top-2-thirds">
            {tt('oops')}
          </h2>
          <p className="w-margin-bottom-half">{tt('no_need_for_validation')}</p>
          <p className="w-margin-bottom-half">
            {tt('technical_info')}
            {status}
          </p>
        </>
      )

    case LotteryStatus.SPONSORSHIP_USED:
      return (
        <>
          <h2 className="h2 highlight w-margin-bottom w-margin-top-2-thirds">
            {tt('oops')}
          </h2>
          <p>{tt('error_sponsorship_code_already_used')}</p>
        </>
      )

    case LotteryStatus.GamesLimitReached:
      return (
        <>
          <h2 className="h2 highlight w-margin-bottom w-margin-top-2-thirds">
            {tt('oops')}
          </h2>
          <p>
            {tt('games_limit_reached', {
              limit: config?.gamesLimit,
            })}
          </p>
        </>
      )

    case LotteryStatus.NoSuchEvent:
    case LotteryStatus.UploadError:
    case LotteryStatus.NoSuchUser:
    case LotteryStatus.NoConf:
    case LotteryStatus.NoStock:
    case LotteryStatus.GenericError:
    case LotteryStatus.UserUpdateError:
    case LotteryStatus.GameNotFound:
    case LotteryStatus.GameHasNoRedemptionCode:
    case LotteryStatus.EmptyOrNullCode:
    default:
      return (
        <>
          <h2 className="h2 highlight w-margin-bottom w-margin-top-2-thirds">
            {tt('oops')}
          </h2>
          <p className="w-margin-bottom-half">{tt('technical_error')}</p>
          <p className="w-margin-bottom-half">
            {tt('technical_info')}
            {status}
          </p>
        </>
      )
  }
}

export default WheelError
