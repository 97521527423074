import React, { FunctionComponent } from 'react'
import './styles.scss'

interface Props {
  id?: string
  text: string
  isFixedBottom?: boolean
  onClick?: () => void
  className?: string
  containerClassName?: string
  img?: string
  href?: string
  isYellow?: boolean
  disabled?: boolean
  isWhite?: boolean
  textOnLeft?: boolean
  isSecondaryStoryButton?: boolean
  isTertiaryStoryButton?: boolean
  isInverted?: boolean
  forceTargetSelf?: boolean
  isSubmit?: boolean
  isAnwsers?: boolean
  isDisplay?: boolean
  noReferrer?: boolean
  style?: any | null
}

const WheelButton: FunctionComponent<Props> = (props) => {
  let styleOfAnswers = {}
  const hasImage = !!props.img
  const containerClassName = `w-btn-container ${
    props.isFixedBottom ? 'w-button-bottom' : ''
  } ${props.containerClassName || ''}`
  const className = `w-button ${props.className || 'w-button-normal'} \
            ${props.isYellow ? 'w-btn-yellow' : ''} \
              ${props.disabled ? 'w-btn-disabled' : ''} \
              ${props.isWhite ? 'w-btn-white' : ''} \
              ${props.textOnLeft ? 'w-btn-text-left' : ''} \
              ${props.isSecondaryStoryButton ? 'w-btn-story-secondary' : ''} \
              ${props.isTertiaryStoryButton ? 'w-btn-story-tertiary' : ''} \
              ${props.isInverted ? 'w-btn-inverted' : ''}`
  let target = !props.disabled ? '_blank' : '_self'
  if (props.forceTargetSelf) {
    target = '_self'
  }

  if (props.isAnwsers) {
    if (props.isDisplay) {
      styleOfAnswers = {}
    } else {
      styleOfAnswers = { display: 'none' }
    }
  }

  return (
    <div
      id="cta"
      className={containerClassName}
      style={{
        ...styleOfAnswers,
        ...(props.style || {}),
      }}
    >
      <a
        id={props.id || 'w-cta'}
        className={className}
        onClick={!props.disabled ? props.onClick : undefined}
        href={!props.disabled ? props.href : undefined}
        target={target}
        rel={props.noReferrer ? 'noreferrer' : undefined}
      >
        {props.isSubmit && (
          <button id="w-btn-submit" type="submit">
            {hasImage && <img className="w-btn-img" src={props.img} />}
            <span className={hasImage ? 'with-img' : ''}>{props.text}</span>
          </button>
        )}
        {!props.isSubmit && (
          <>
            {hasImage && <img className="w-btn-img" src={props.img} />}
            <span className={hasImage ? 'with-img' : ''}>{props.text}</span>
          </>
        )}
      </a>
    </div>
  )
}

export default WheelButton
