import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import LotteryType from '@tootsweet/model/lottery/LotteryType'

export default function shouldDisplayLogo(
  step: LotteryV2Step | null,
  mustRedeem: boolean,
  mustRedeemByQRCode: boolean,
  lotteryConfig: LotteryConfigurationResponse | undefined,
  isDisabled: boolean,
  isOfferGift: boolean,
  isBeforeWheel: boolean,
  isSkillGameWithPlaceSelector: boolean
) {
  if (isSkillGameWithPlaceSelector) {
    return true
  }

  if (!mustRedeem && isDisabled) {
    return true
  }

  if (!mustRedeemByQRCode && isDisabled) {
    return true
  }

  let displayLogo =
    mustRedeem || step?.stepType === LotteryV2StepType.SearchBar || isOfferGift

  if (lotteryConfig?.type) {
    switch (lotteryConfig.type) {
      case LotteryType.Wheel:
      case LotteryType.ScratchCard:
      case LotteryType.SlotMachine:
      case LotteryType.GiftMachine:
      case LotteryType.Fakir:
      case LotteryType.MysteryBoxes:
      case LotteryType.Dice:
      case LotteryType.AdventCalendar:
        return true

      case LotteryType.ScratchCardChristmas:
        displayLogo = displayLogo || !isBeforeWheel
        break
    }
  }

  if (step) {
    switch (step?.stepType) {
      case LotteryV2StepType.ScratchCardChristmas:
        return false

      case LotteryV2StepType.Lost:
      case LotteryV2StepType.Error:
      case LotteryV2StepType.Offer:
      case LotteryV2StepType.AdventCalendar:
        return true
    }
  }

  return displayLogo
}
