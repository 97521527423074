import LotteryConfigurationResponse, {
  WheelItem,
} from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import twemoji from 'twemoji'

const WIDTH_FACTOR = 0.6
const MARGIN_FACTOR = (1 - WIDTH_FACTOR) / 2

export interface SlotMachineRibbonParams {
  width: number
  imgHeight: number
  itemHeight: number
  imgDataURL: string
  nItems: number
}

export default async function generateSlotMachineRibbon(
  width: number,
  itemHeight: number,
  config?: LotteryConfigurationResponse | null
): Promise<SlotMachineRibbonParams | null> {
  if (!config?.wheelItems?.length) {
    return null
  }

  const imgHeight = itemHeight * config.wheelItems.length

  // @ts-ignore
  const canvas: HTMLCanvasElement | null = document.createElement('canvas')

  if (!canvas) {
    return null
  }

  const dpr = window.devicePixelRatio
  canvas.width = Math.trunc(width * dpr)
  canvas.height = Math.trunc(imgHeight * dpr)
  canvas.style.width = `${width}px`
  canvas.style.height = `${imgHeight}px`
  canvas.style.border = '1px solid black'

  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  ctx.scale(dpr, dpr)
  ctx.fillStyle = 'white'
  ctx.fillRect(0, 0, width, imgHeight)

  const marginH = Math.trunc(MARGIN_FACTOR * width)
  const imgWidth = Math.trunc(WIDTH_FACTOR * width)
  const marginV = Math.trunc((itemHeight - imgWidth) / 2)

  const promises: Promise<void>[] = []

  for (let idx = 0; idx < config.wheelItems.length; idx++) {
    const item = config.wheelItems[idx]

    // This code colors the background of every item (for debug purposes)
    // const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    // ctx.fillStyle = "#" + randomColor;
    // ctx.fillRect(0, idx * itemHeight, width, itemHeight);

    promises.push(
      drawGiftEmoji(item, marginH, marginV, itemHeight, idx, imgWidth, ctx)
    )
  }

  await Promise.all(promises)

  return {
    width,
    imgDataURL: canvas.toDataURL('image/png', 1),
    imgHeight,
    nItems: config.wheelItems.length,
    itemHeight,
  }
}

export function drawImageToCanvas(
  marginH: number,
  marginV: number,
  itemHeight: number,
  idx: number,
  imgWidth: number,
  ctx: CanvasRenderingContext2D,
  img: HTMLImageElement
) {
  ctx.drawImage(img, marginH, idx * itemHeight + marginV, imgWidth, imgWidth)
}

export function drawGiftEmoji(
  item: WheelItem,
  marginH: number,
  marginV: number,
  itemHeight: number,
  idx: number,
  imgWidth: number,
  ctx: CanvasRenderingContext2D
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    let url: string
    const img = document.createElement('img')
    if (item.img) {
      url = `https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/kadow/${item.img}`
    } else {
      // @ts-ignore
      const rawUrl: string = twemoji.parse(
        item.isLost ? '😭' : item.e || '🎁',
        {
          base: 'https://d19y19rxbpc628.cloudfront.net/jetpack/twemoji/14.0.1/',
          folder: 'svg',
          ext: '.svg',
        }
      )
      const startIdx = rawUrl.indexOf('https://')
      const endIdx = rawUrl.indexOf('"/>')
      url = rawUrl
        .substring(startIdx, endIdx)
        /**
         * Substitute CloudFlare for our in-house CDN because the latter
         * does a 301 redirection, which breaks the crossOrigin='anonymous'
         * policy because the redirection does not have CORS headers
         */
        .replace(
          'twemoji.maxcdn.com/v',
          "d19y19rxbpc628.cloudfront.net/jetpack/twemoji"
        )
    }
    img.crossOrigin = 'anonymous'
    img.src = url

    img.width = imgWidth
    img.height = imgWidth
    img.style.width = `${imgWidth}px`
    img.style.height = `${imgWidth}px`

    if (img.complete) {
      drawImageToCanvas(marginH, marginV, itemHeight, idx, imgWidth, ctx, img)
      resolve()
    } else {
      img.onload = () => {
        drawImageToCanvas(marginH, marginV, itemHeight, idx, imgWidth, ctx, img)
        resolve()
      }
    }
  })
}
