import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GenericGameStep from '../../../components/GenericGameStep'
import WheelButton from '../../../components/cta/WheelButton'
import { setFormData, async_saveFormData } from '../../../redux/actions/lottery'
import { tt } from '../../../i18n'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import { FunctionComponent } from 'react'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import {
  loadingSelector,
  lotteryConfigSelector,
  prizeSelector,
  venueSelector,
  wheelLabelsSelector,
} from '../../../redux/selectors/lottery'

interface Props {}

interface FormValues {
  name: string
  phone: string
  companyName: string
}

const ProspectionForm: FunctionComponent<Props> = () => {
  const dispatch = useDispatch()

  const venue = useSelector(venueSelector)
  const wheelLabels = useSelector(wheelLabelsSelector)
  const prize = useSelector(prizeSelector)
  const loading = useSelector(loadingSelector)
  const lotteryConfig = useSelector(lotteryConfigSelector)

  const initialValues: FormValues = {
    name: '',
    phone: '',
    companyName: '',
  }

  const validationSchema = yup.object({
    name: yup
      .string()
      .required('Votre nom est requis')
      .min(2, 'Le nom doit faire au moins 2 caractères'),
    phone: yup
      .string()
      .required('Votre numéro de téléphone est requis')
      .min(10, 'Le numéro de téléphone doit être valide'),
    companyName: yup
      .string()
      .required('Le nom de votre entreprise est requis')
      .min(5, "Le nom de l'entreprise doit faire au moins 5 caractères"),
  })

  const onSubmit = (values: FormValues) => {
    for (const [key, value] of Object.entries(values)) {
      dispatch(setFormData(key, value))
    }

    dispatch(async_saveFormData())
  }

  if (!venue?.name) return null

  return (
    <GenericGameStep
      stepType={LotteryV2StepType.Prospection}
      renderModalBody={() => {
        return (
          <>
            <h1>{tt('prospection_form_title')}</h1>

            <p className="w-margin-bottom-half">
              {tt('prospection_form_text')}
            </p>

            <div className="lottery-form">
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {({ isSubmitting, errors, touched }) => (
                  <Form>
                    <Field
                      name={'name'}
                      placeholder={tt('placeholder_first_name_and_name')}
                      className={`form-control w-margin-bottom-half ${
                        touched.name && errors.name
                          ? 'input-error lot-input-red'
                          : ''
                      }`}
                    />
                    <Field
                      name={'companyName'}
                      placeholder={tt('placeholder_company_name')}
                      className={`form-control w-margin-bottom-half ${
                        touched.companyName && errors.companyName
                          ? 'input-error lot-input-red'
                          : ''
                      }`}
                    />
                    <Field
                      name={'phone'}
                      placeholder={tt('placeholder_phone_number')}
                      className={`form-control w-margin-bottom-half ${
                        touched.phone && errors.phone
                          ? 'input-error lot-input-red'
                          : ''
                      }`}
                    />
                    <div className="text-center">
                      <WheelButton
                        id="w-cta-modal"
                        isSubmit
                        disabled={isSubmitting}
                        text={tt('_continue')}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        )
      }}
    />
  )
}

export default ProspectionForm
