import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WheelAnalyticsManager from '../../analytics/WheelAnalyticsManager'
import {
  async_ml_advance_and_optin_if_needded,
  setShowMLOptinModal,
} from '../../redux/actions/lottery'
import './styles.scss'
import moment from 'moment'
import { tt } from '../../i18n'
import WheelButton from '../../components/cta/WheelButton'
import OptinOptout from '../../components/optinoptout/OptinOptout'
import aspectRatio, { AspectRatio } from '../../utils/aspect_ratio'
import Modal from 'react-bootstrap/Modal'
import { QUIZZ_STEP_HOME } from '../../redux/reducers/quizz'
import { setQuizzStep } from '../../redux/actions/quizz'
import LotteryType from '@tootsweet/model/lottery/LotteryType'
import { userSelector } from '../../redux/selectors/app'
import {
  drawSelector,
  isOptOutSelector,
  lotteryConfigSelector,
  mlConfigSelector,
} from '../../redux/selectors/lottery'

const aspectRatio1 = aspectRatio()

interface Props {
  show: boolean
  closeModal: () => void
}

const MonthlyLotteryOptinModal: FunctionComponent<Props> = (props: Props) => {
  const dispatch = useDispatch()

  const user = useSelector(userSelector)
  const mlConfig = useSelector(mlConfigSelector)
  const draw = useSelector(drawSelector)
  const lotteryConfig = useSelector(lotteryConfigSelector)
  const isOptOut = useSelector(isOptOutSelector)

  const [firstName, setFirstName] = useState<string>(user?.firstName || '')
  const [email, setEmail] = useState<string>(
    user?.paymentEmail?.toLowerCase() || ''
  )
  const [phone, setPhone] = useState<string | undefined>(
    user?.phone || undefined
  )
  const [wasOptOutOnMount, setWasOptOutOnMount] = useState<boolean>(false)
  const [containerWidth, setContainerWidth] = useState<number | null>(null)

  const renderInner = () => {
    if (!mlConfig || !draw || !draw.nTokens) {
      return null
    }

    let dimW = Math.trunc(window.innerHeight * 0.08)

    switch (aspectRatio1) {
      case AspectRatio._16_9:
        dimW = Math.trunc(window.innerHeight * 0.05)
        break
    }

    let dimH = dimW

    if (lotteryConfig?.mlTokenW && lotteryConfig?.mlTokenH) {
      dimW = lotteryConfig?.mlTokenW
      dimH = lotteryConfig?.mlTokenH
    }

    const nTokens = draw.nTokens
    const length = nTokens * (dimW / 2) + dimW / 2
    const width = containerWidth
    const left = 15 + (width ? (width - length) / 2 : 0)

    return (
      <>
        <h1 id="k-ml-title">
          {draw.nTokens > 1
            ? tt('ml_optin_title_plural', {
                nTokens: draw.nTokens,
                prize: mlConfig.prize,
                goldenClover:
                  lotteryConfig?.customMLTokenNameSingular ||
                  tt('golden_clover_plural'),
              })
            : tt('ml_optin_title_singular', {
                nTokens: draw.nTokens,
                prize: mlConfig.prize,
                goldenClover:
                  lotteryConfig?.customMLTokenNamePlural ||
                  tt('golden_clover_plural'),
              })}
        </h1>

        <p className="w-margin-bottom-half">
          {draw.nTokens === 1 && (
            <span>
              {tt('ml_optin_text_singular', {
                date: moment(mlConfig.drawTs, 'X').format(
                  tt('date_format_winner')
                ),
                goldenClover:
                  lotteryConfig?.customMLTokenNameSingular ||
                  tt('golden_clover_singular'),
              })}
            </span>
          )}
          {draw.nTokens > 1 && (
            <span>
              {tt('ml_optin_text_plural', {
                nTokens: draw.nTokens,
                date: moment(mlConfig.drawTs, 'X').format(
                  tt('date_format_winner')
                ),
                goldenClover:
                  lotteryConfig?.customMLTokenNamePlural ||
                  tt('golden_clover_plural'),
              })}
            </span>
          )}
        </p>

        <div
          className="d-flex align-items-center justify-content-center w-margin-bottom"
          style={{ height: dimH }}
        >
          {!!width &&
            Array.from(Array(nTokens).keys()).map((elem, idx) => {
              return (
                <img
                  key={idx}
                  className="twic k-glm-token-optin"
                  data-src={`image:/cdm/kadow/glm/${
                    lotteryConfig?.mlToken || 'token.png'
                  }`}
                  data-src-transform="contain=WxH"
                  style={{
                    position: 'absolute',
                    width: dimW,
                    height: dimH,
                    // top: `calc(50% - ${dim / 2}px)`,
                    left: idx === 0 ? left : left + idx * (dimW / 2),
                  }}
                />
              )
            })}
        </div>

        <div className=" k-glm-optin">
          <form id="glm-form" onSubmit={onSubmit}>
            <div className="row">
              <div className="col-12">
                <input
                  id="firstName"
                  type="text"
                  placeholder={tt('placeholder_first_name')}
                  value={firstName}
                  onChange={handleFirstNameChange}
                  className="form-control w-margin-bottom-third"
                  required
                />
              </div>
            </div>

            {lotteryConfig?.phoneOptinInML && (
              <div className="row">
                <div className="col-12">
                  <input
                    id="phone"
                    type="text"
                    placeholder={tt('placeholder_phone_number')}
                    value={phone}
                    onChange={handlePhoneChange}
                    className="form-control w-margin-bottom-third"
                    required
                  />
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-12">
                <input
                  id="email"
                  type="email"
                  placeholder={tt('placeholder_email')}
                  value={email}
                  onChange={handleEmailChange}
                  className="form-control w-margin-bottom-third"
                  required
                />
              </div>
            </div>

            {wasOptOutOnMount && (
              <div className="row">
                <div className="col-12 checkbox">
                  <OptinOptout />
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-12">
                <WheelButton
                  id="k-glm-optin-btn"
                  text={tt('receive_my_tokens')}
                  isSubmit={true}
                />
              </div>
            </div>
          </form>

          {lotteryConfig?.type === LotteryType.Quizz && (
            <div id="k-ml-optin-skip" className="row w-margin-top-half">
              <div className="col-12">
                <span
                  className="d-block text-center k-skip"
                  onClick={skip}
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                >
                  {tt('skip')}
                </span>
              </div>
            </div>
          )}
        </div>
      </>
    )
  }

  const skip = () => {
    dispatch(setShowMLOptinModal(false))
    dispatch(setQuizzStep(QUIZZ_STEP_HOME))
  }

  const handleFirstNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    setFirstName(event.currentTarget.value)
  }

  const handlePhoneChange = (event: React.FormEvent<HTMLInputElement>) => {
    setPhone(event.currentTarget.value)
  }

  const handleEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
    setEmail((e.currentTarget.value + '').toLowerCase())
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()
    dispatch(async_ml_advance_and_optin_if_needded(firstName, email, phone))
  }

  useEffect(() => {
    if (props.show) {
      WheelAnalyticsManager.logEvent('ml_optin_modal')
      setEmail(user?.paymentEmail?.toLowerCase() || '')
      setFirstName(user?.firstName || '')
      setPhone(user?.phone)
      setWasOptOutOnMount(isOptOut)
    }
  }, [props.show])

  return (
    <Modal
      show={props.show}
      onHide={props.closeModal}
      centered={true}
      backdrop="static"
    >
      <Modal.Body id="ml-optin-modal">
        <div className="w-modal">
          <div
            className="container"
            ref={(ref) => {
              if (ref && !containerWidth) {
                setContainerWidth(ref.clientWidth)
              }
            }}
          >
            {renderInner()}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default MonthlyLotteryOptinModal
