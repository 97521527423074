import React, { ForwardedRef, forwardRef } from 'react'
import Skin from '../../model/Skin'

interface Props {
  skin?: Skin
  onClick?: () => void
  margin: number
}

const BALL = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <div
      style={{
        backgroundImage: !!props.skin?.main_sprite
          ? `url(https://tootsweet.twic.pics/cdm/kadow/img/fakir/${props.skin?.main_sprite})`
          : `linear-gradient(240deg, rgba(254, 217, 228, 0.4) 0%, transparent 100%)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        position: 'absolute',
        top: `calc(0% - ${props.margin}px)`,
        left: '100px',
        width: props.skin?.fakir?.ballWidth || '9.1%',
        backgroundColor: props.skin?.main_sprite ? '' : '#e91c73',
        borderRadius: !!props.skin?.main_sprite ? undefined : '50%',
        boxShadow: !!props.skin?.main_sprite
          ? ''
          : 'inset 10px -13px 13px rgba(0, 0, 0, 0.5)',
        transform: 'translate(-50%, -50%)',
        zIndex: 10,
        aspectRatio: '1/1',
      }}
      ref={ref}
      onClick={props.onClick}
    />
  )
})
export default BALL
