import { combineReducers } from 'redux'
import lottery, { LotteryI } from './reducers/lottery'
import config from './reducers/config'
import quizz, { QuizzI } from './reducers/quizz'
import app, { AppStateI } from './reducers/app'
import Config from '../model/config'
import { SkillTimer, timer } from './reducers/timer'
import {
  countdown,
  CountdownState,
} from '../app/17_Catcher/reducers/countdownReducer'

export interface RootState {
  app: AppStateI
  config: Config
  lottery: LotteryI
  quizz: QuizzI
  timer: SkillTimer
  countdown: CountdownState
}

export default combineReducers<RootState>({
  app,
  config,
  lottery,
  quizz,
  timer,
  countdown,
})
