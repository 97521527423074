import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  top?: number | string
  left?: number | string
  bottom?: number | string
  right?: number | string
}

export default function ValentinesDayHearts(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 233.14 1318.09"
      style={{
        position: 'absolute',
        width: props.width,
        height: props.height,
        bottom: props.bottom,
        top: props.top,
        right: props.right,
        left: props.left,
      }}
    >
      <defs>
        <style>
          {`
            .cls-hearts-1{fill:url(#Dégradé_sans_nom_187);}
            .cls-hearts-2{fill:url(#Dégradé_sans_nom_187-2);}
            .cls-hearts-3{opacity:0.67;fill:url(#Dégradé_sans_nom_187-3);}
            .cls-hearts-4{fill:url(#Dégradé_sans_nom_187-4);}
            .cls-hearts-5{fill:url(#Dégradé_sans_nom_187-5);}
            .cls-hearts-6{fill:url(#Dégradé_sans_nom_187-6);}
            .cls-hearts-7{fill:url(#Dégradé_sans_nom_187-7);}
            .cls-hearts-8{fill:#fff;}
            .cls-hearts-9{fill:url(#Dégradé_sans_nom_187-8);}
            .cls-hearts-10{fill:url(#Dégradé_sans_nom_187-9);}
            .cls-hearts-11{fill:url(#Dégradé_sans_nom_207);}
            .cls-hearts-12{fill:url(#Dégradé_sans_nom_187-10);}
            .cls-hearts-13{fill:url(#Dégradé_sans_nom_207);}
            .cls-hearts-14{fill:url(#Dégradé_sans_nom_187-10);
            `}
        </style>
        <linearGradient
          id="Dégradé_sans_nom_169"
          x1="77.14"
          y1="225.41"
          x2="120.15"
          y2="225.41"
          gradientTransform="translate(17.71 -9.33) rotate(10.11)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#a9272d" stopOpacity="0.8" />
          <stop offset="0" stopColor="#a10330" stopOpacity="0.8" />
          <stop offset="1" stopColor="#cb4f77" />
          <stop offset="1" stopColor="#ff3fb5" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id="Dégradé_sans_nom_187"
          x1="-267.04"
          y1="714.89"
          x2="-219.08"
          y2="714.89"
          gradientTransform="matrix(0.3, 0.68, -0.67, 0.33, 572.04, 199.47)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f66688" />
          <stop offset="1" stopColor="#fec6c2" />
        </linearGradient>
        <linearGradient
          id="Dégradé_sans_nom_187-2"
          x1="457.59"
          y1="746.68"
          x2="505.55"
          y2="746.68"
          gradientTransform="matrix(1.27, 0.26, -0.06, 1.35, -417.24, -681.66)"
          xlinkHref="#Dégradé_sans_nom_187"
        />
        <linearGradient
          id="Dégradé_sans_nom_187-3"
          x1="56.51"
          y1="1226.73"
          x2="233.14"
          y2="1226.73"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
          xlinkHref="#Dégradé_sans_nom_187"
        />
        <linearGradient
          id="Dégradé_sans_nom_187-4"
          x1="49.4"
          y1="736.43"
          x2="101.31"
          y2="736.43"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
          xlinkHref="#Dégradé_sans_nom_187"
        />
        <linearGradient
          id="Dégradé_sans_nom_187-5"
          x1="118.26"
          y1="917.1"
          x2="193.44"
          y2="917.1"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
          xlinkHref="#Dégradé_sans_nom_187"
        />
        <linearGradient
          id="Dégradé_sans_nom_187-6"
          x1="106.42"
          y1="798.34"
          x2="154.38"
          y2="798.34"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
          xlinkHref="#Dégradé_sans_nom_187"
        />
        <linearGradient
          id="Dégradé_sans_nom_187-7"
          x1="97.24"
          y1="668.27"
          x2="169.44"
          y2="668.27"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
          xlinkHref="#Dégradé_sans_nom_187"
        />
        <linearGradient
          id="Dégradé_sans_nom_187-8"
          x1="58.87"
          y1="149.96"
          x2="58.87"
          y2="-1.27"
          gradientTransform="translate(17.71 -9.33) rotate(10.11)"
          xlinkHref="#Dégradé_sans_nom_187"
        />
        <linearGradient
          id="Dégradé_sans_nom_187-9"
          x1="141.87"
          y1="149.96"
          x2="141.87"
          y2="-2.43"
          gradientTransform="translate(17.71 -9.33) rotate(10.11)"
          xlinkHref="#Dégradé_sans_nom_187"
        />
        <linearGradient
          id="Dégradé_sans_nom_207"
          x1="72.93"
          y1="239.63"
          x2="125.97"
          y2="239.63"
          gradientTransform="matrix(0.98, 0.17, -0.2, 1.15, 28.14, -74.31)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#a9272d" stopOpacity="0.8" />
          <stop offset="0" stopColor="#790330" stopOpacity="0.8" />
          <stop offset="1" stopColor="#cb4f77" />
          <stop offset="1" stopColor="#a50040" />
          <stop offset="1" stopColor="#9b0031" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id="Dégradé_sans_nom_187-10"
          x1="96.55"
          y1="325.46"
          x2="144.83"
          y2="325.46"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
          xlinkHref="#Dégradé_sans_nom_187"
        />
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="BOUTON">
          <polyline
            className="cls-hearts-1"
            points="52.97 206.7 104.63 216.76 100.25 225.37 53.69 216.63"
          />
          <polygon
            className="cls-hearts-2"
            points="98.37 222.85 92.35 244.45 54.61 237.33 56.09 214.95 98.37 222.85"
          />
          <path
            className="cls-hearts-3"
            d="M20.65,267.83c0-.58-.25-6.13-4.88-9.2a10.06,10.06,0,0,0-7.53-1.57c-5.89,1.28-7.83,8.07-7.92,8.42-.78,2.89-.16,5.08,2.26,12.36,1.88,5.64,3.6,10.62,5.08,14.81,5.91-1.34,10.79-2.78,14.38-3.93,8.26-2.65,10.33-4,12-6,.8-.95,4.19-5,3-9.9a10.07,10.07,0,0,0-4.82-6.11A11,11,0,0,0,20.65,267.83Z"
          />
          <path
            className="cls-hearts-4"
            d="M145.12,445.9c-.89-.59-9.43-6.09-17.92-2.43a15.35,15.35,0,0,0-8.66,9.55c-3,10.13,5.59,20.16,6,20.67,3.7,4.2,7.51,5.59,20.48,9.65,10.05,3.15,19,5.83,26.52,8,2.92-10.21,4.84-19,6.11-25.54,2.92-15.09,2.59-19.62,1-24.09-.77-2.2-4-11.53-12.39-14.87a15.75,15.75,0,0,0-13.22.73C147.33,430.83,144.26,438,145.12,445.9Z"
          />
          <path
            className="cls-hearts-5"
            d="M156.6,1192.76c.25-3,2.25-31.39-18.45-47.64-3.66-2.88-17.25-12.6-35.51-8.94-29.22,5.85-42.38,40.34-43,42.1-5.38,14.71-3.61,26,4,63.48,5.92,29.08,11.47,54.75,16.29,76.33,29.33-6.15,53.7-12.89,71.7-18.35,41.44-12.58,52.21-19.34,61.16-29.08,4.42-4.81,23.11-25.15,20-50.28-2.53-20.43-17.81-30.52-19.88-31.83C197,1178.46,175.13,1180.22,156.6,1192.76Z"
          />
          <path
            className="cls-hearts-6"
            d="M77.33,725.41c-.14-.75-1.62-8-8.5-11.19a14.3,14.3,0,0,0-10.46-.77c-7.66,2.69-8.84,11.9-8.89,12.37-.44,3.91.85,6.67,5.64,15.77,3.71,7.06,7.08,13.28,9.95,18.49,7.68-2.76,13.93-5.47,18.52-7.59,10.57-4.88,13.06-7,14.84-9.85.88-1.39,4.6-7.28,1.94-13.46A14,14,0,0,0,92.6,722,15.05,15.05,0,0,0,77.33,725.41Z"
          />
          <path
            className="cls-hearts-7"
            d="M160.93,903.12c.12-1.25,1.07-13.3-7.7-20.34a19,19,0,0,0-15.1-4c-12.48,2.27-18.21,16.81-18.49,17.56-2.34,6.2-1.63,11,1.5,27,2.42,12.38,4.69,23.32,6.67,32.51,12.53-2.4,22.94-5.09,30.63-7.28,17.71-5,22.32-7.83,26.17-11.9,1.9-2,9.94-10.5,8.7-21.19a19.38,19.38,0,0,0-8.37-13.65C178.21,897.35,168.87,897.93,160.93,903.12Z"
          />
          <path
            className="cls-hearts-8"
            d="M127.18,792.38c-.7-.3-7.55-3-14,1a14.27,14.27,0,0,0-6.39,8.31c-2,7.88,5.08,13.93,5.45,14.23,3,2.52,6,3,16.28,4,7.93.78,15,1.38,20.92,1.85,1.9-7.93,3.08-14.65,3.83-19.65,1.72-11.51,1.3-14.77-.08-17.81-.68-1.5-3.55-7.84-10.18-9a14,14,0,0,0-10.26,2.56A15.06,15.06,0,0,0,127.18,792.38Z"
          />
          <path
            className="cls-hearts-9"
            d="M136.1,652.86c-.2-1.05-2.23-11.13-11.8-15.62-1.69-.79-7.88-3.4-14.55-1C99.09,640,97.43,652.83,97.36,653.48c-.62,5.48,1.17,9.33,7.81,22,5.15,9.85,9.82,18.53,13.8,25.81,10.68-3.88,19.39-7.67,25.78-10.65,14.7-6.84,18.18-9.84,20.66-13.78,1.23-2,6.41-10.18,2.73-18.81-3-7-9.86-9.65-10.79-10C150.24,645.48,142,647.38,136.1,652.86Z"
          />

          <polygon
            className="cls-hearts-10"
            points="138.75 130.13 91.03 216.55 130.94 134.24 138.75 130.13"
          />
          <polyline
            className="cls-hearts-10"
            points="107.6 145.71 114.56 141.53 82.73 215.91"
          />
          <polygon
            className="cls-hearts-10"
            points="51.94 113.85 65.61 210.74 57.78 120.28 51.94 113.85"
          />
          <polyline
            className="cls-hearts-10"
            points="75.57 138.77 70.54 132.55 73.64 212.89"
          />
          <path
            className="cls-hearts-11"
            d="M116.91,23.39C115,20.78,99.09.1,73.24,0,51.83-.08,35.47,14,29,24.33c-17.8,28.39,5.14,82.39,60,131.37Q103,89.55,116.91,23.39Z"
          />
          <path
            className="cls-hearts-12"
            d="M111.26,21.79c2.75-1.8,25.1-16,49.4-7.51,20.13,7,30.42,25.62,32.78,37.46,6.49,32.53-35.25,76.14-104.41,104C96.19,111.23,104.1,66.26,111.26,21.79Z"
          />
          <polyline
            className="cls-hearts-13"
            points="52.49 208 104.56 218.02 100.15 226.75 53.23 218.06"
          />
          <path
            className="cls-hearts-14"
            d="M123.15,315c0-.73-.56-7.75-6.65-11a12.69,12.69,0,0,0-9.76-.89c-7.55,2.49-9.77,11.4-9.87,11.86-.89,3.79,0,6.48,3.41,15.37,2.64,6.89,5.07,13,7.14,18.07,7.56-2.58,13.79-5.11,18.36-7.1,10.54-4.59,13.15-6.63,15.17-9.34,1-1.33,5.21-7,3.45-13A11.44,11.44,0,0,0,138,311.9C133.36,310,127.61,311.24,123.15,315Z"
          />
        </g>
      </g>
    </svg>
  )
}
