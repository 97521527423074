export const shuffleMap = [
  {
    translateX: '100%',
    translateY: '100%',
  },
  {
    translateY: '100%',
  },
  {
    translateX: '-100%',
    translateY: '100%',
  },
  {
    translateX: '100%',
  },
  {},
  {
    translateX: '-100%',
  },
  {
    translateX: '100%',
    translateY: '-100%',
  },
  {
    translateY: '-100%',
  },
  {
    translateX: '-100%',
    translateY: '-100%',
  },
]
