import React from 'react'

export interface GMProps {
  width: number
  height: number
  position?: any | undefined
  color?: string
  color2?: string
  top?: number
  left?: number
  right?: number
  transform?: string
}

export const SVG_WIREFRAME_WIDTH = 707
export const SVG_WIREFRAME_HEIGHT = 1153

export default function GiftMachineWireframeRight(props: GMProps) {
  return (
    <svg
      id="Calque_2"
      data-name="Calque 2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 251.68 1153"
      style={{
        height: props.height,
        position: props.position,
        top: props.top,
        left: props.left,
        right: props.right,
        transform: props.transform,
      }}
    >
      <defs>
        <style>
          {`
          .cls-1 {
          fill: none;
          filter: url(#drop-shadow-1);
          stroke: url(#Dégradé_sans_nom_207);
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 36.19px;
        }

          .cls-2 {
          fill: #fff;
        }

          .cls-3 {
          fill: #2d82be;
        }

          .cls-4 {
          fill: url(#Dégradé_sans_nom_207-2);
          filter: url(#drop-shadow-2);
        }
        `}
        </style>
        <linearGradient
          id="Dégradé_sans_nom_207"
          data-name="Dégradé sans nom 207"
          x1="44.1"
          y1="573.82"
          x2="153.41"
          y2="573.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#41abff" />
          <stop offset=".23" stopColor="#47b5ff" />
          <stop offset=".63" stopColor="#57d2ff" />
          <stop offset=".64" stopColor="#58d3ff" />
          <stop offset="1" stopColor="#41abff" />
        </linearGradient>
        <filter id="drop-shadow-1" filterUnits="userSpaceOnUse">
          <feOffset dx="-6.11" dy="2.62" />
          <feGaussianBlur result="blur" stdDeviation="2.62" />
          <feFlood floodColor="#0f2e3c" floodOpacity=".3" />
          <feComposite in2="blur" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
        <linearGradient
          id="Dégradé_sans_nom_207-2"
          data-name="Dégradé sans nom 207"
          x1="22.31"
          y1="580.05"
          x2="189.2"
          y2="580.05"
          xlinkHref="#Dégradé_sans_nom_207"
        />
        <filter id="drop-shadow-2" filterUnits="userSpaceOnUse">
          <feOffset dx="-7" dy="3" />
          <feGaussianBlur result="blur-2" stdDeviation="5" />
          <feFlood floodColor="#0f2e3c" floodOpacity=".75" />
          <feComposite in2="blur-2" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="CTA">
        <g>
          <path
            className="cls-1"
            d="m62.19,1123.42c40.79-152.07,72.95-337.13,73.12-547.91.17-212.23-32.13-398.47-73.12-551.29"
          />
          <g>
            <rect
              className="cls-3"
              x="149.81"
              y="483.69"
              width="39.39"
              height="192.71"
              transform="translate(339.01 1160.1) rotate(180)"
            />
            <polyline
              className="cls-4"
              points="189.2 483.69 105.75 531.87 22.31 580.05 105.75 628.23 189.2 676.4"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
