import * as React from 'react'
import { useSelector } from 'react-redux'
import GenericGameStep from '../../../components/GenericGameStep'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import WheelFormModalContent, { WheelFormType } from './WheelFormModalContent'
import { FunctionComponent, useEffect } from 'react'
import {
  loadingSelector,
  lotteryConfigSelector,
  prizeSelector,
  venueSelector,
  wheelLabelsSelector,
} from '../../../redux/selectors/lottery'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'

interface Props {
  formType: WheelFormType
  step?: LotteryV2Step | null
}

const WheelForm: FunctionComponent<Props> = (props) => {
  const { formType } = props
  const venue = useSelector(venueSelector)
  const isEmail = formType === WheelFormType.Email

  let finalPlaceName = venue?.name || ''

  if (venue?.name.startsWith('Le ') || venue?.name.startsWith('le ')) {
    finalPlaceName = finalPlaceName.substring(3)
  }

  return (
    <GenericGameStep
      stepType={isEmail ? LotteryV2StepType.Email : LotteryV2StepType.Phone}
      displayModalImmediately={props.step?.displayImmediately}
      renderModalBody={() => {
        return (
          <WheelFormModalContent
            formType={formType}
            finalPlaceName={finalPlaceName}
          />
        )
      }}
    />
  )
}

export default WheelForm
