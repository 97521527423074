export const timerStart = (
  interval: () => void,
  remainingSeconds?: number
) => ({
  type: 'timer_start',
  payload: {
    interval,
    remainingSeconds,
  },
})

export const timerStop = () => ({
  type: 'timer_stop',
})

export const timerTick = () => ({
  type: 'timer_tick',
})

export const timerTimeout = () => ({
  type: 'timer_timeout',
})

export const timerReset = (remainingSeconds: number) => ({
  type: 'timer_reset',
  payload: remainingSeconds,
})
