import './styles/global.scss'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './app/App'
import { Provider } from 'react-redux'
import CONFIG from './model/TSConfig'
import { setConfig } from './redux/actions/config'
import { setUser, setUTMs } from './redux/actions/app'
import { getOrCreateStore } from './redux/store'
import './i18n'
import { setIsDemo } from './redux/actions/lottery'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

require('bootstrap/dist/js/bootstrap.min')

const store = getOrCreateStore()
store.dispatch(setConfig(CONFIG))

const loaderUserFromLocalStorage = () => {
  const userString = localStorage.getItem('user')
  if (userString) {
    const _user = JSON.parse(userString)
    // console.log("user from local storage", _user)
    store.dispatch(setUser(_user))
  } else {
    // console.log("no user in local storage")
  }
}

loaderUserFromLocalStorage()

const search = window.location.search
const params = new URLSearchParams(search)

/**
 * If the "v" parameter is present, rename it to "venueId"
 */
const vParam = params.get('v')

if (vParam) {
  const sp = new URLSearchParams(window.location.search)
  sp.delete('v')
  sp.set('venueId', vParam)
  window.location.search = sp.toString()
}

const venueId = params.get('venueId')
const eventId = params.get('eventId')
const userToken = params.get('userToken')
const validateGameId = params.get('v_id')
const redeemGameId = params.get('r_id')
const sponsorshipIdStep1 = params.get('spid1')
const sponsorshipIdStep2 = params.get('spid2')

const utm_campaign = params.get('utm_campaign')
const utm_source = params.get('utm_source')
const utm_medium = params.get('utm_medium')
const lng = params.get('lng')
const draftID = params.get('did')
// disable Hotjar
// const dh = params.get("dh")

store.dispatch(setUTMs(utm_campaign, utm_source, utm_medium))

const qrcodeStr = params.get('qrcode')
const qrcode = (qrcodeStr && qrcodeStr === 'true') || false

let displayEULA

try {
  const displayEULAStr = params.get('displayEULA')
  displayEULA = !!displayEULAStr
} catch (err) {
  displayEULA = false
}

let displayPP

try {
  const displayPPStr = params.get('displayPP')
  displayPP = !!displayPPStr
} catch (err) {
  displayPP = false
}

const isOfferGiftStr = params.get('g')
const isOfferGift = (isOfferGiftStr && isOfferGiftStr === 'true') || false
const offeredGiftLabel = params.get('l')

const demoStr = params.get('d')
const demo = (demoStr && demoStr === 'true') || false
store.dispatch(setIsDemo(demo))

if (CONFIG.ENABLE_SENTRY) {
  Sentry.init({
    dsn: 'https://24003ebde26341599b1dc0e60eb09e9e@o1178175.ingest.sentry.io/6289110',
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,
    normalizeDepth: 10,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App
        venueId={venueId}
        eventId={eventId}
        userToken={userToken}
        validateGameId={validateGameId}
        displayEULA={displayEULA}
        displayPP={displayPP}
        qrcode={qrcode}
        sponsorshipIdStep1={sponsorshipIdStep1}
        sponsorshipIdStep2={sponsorshipIdStep2}
        isOfferGift={isOfferGift}
        offeredGiftLabel={offeredGiftLabel}
        lng={lng}
        redeemGameId={redeemGameId}
        draftID={draftID}
      />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
