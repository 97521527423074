import * as React from 'react'
import WheelButton from './cta/WheelButton'
import {
  goToRedeem,
  incrementCTAClickCount,
  nextLotteryStep,
  setLotteryLoading,
} from '../redux/actions/lottery'
import { useDispatch, useSelector } from 'react-redux'
import WheelAnalyticsManager from '../analytics/WheelAnalyticsManager'
import TSConstants from '../utils/TSConstants'
import { tt } from '../i18n'
import { FunctionComponent } from 'react'
import { configSelector } from '../redux/selectors/config'
import {
  lotteryStepSelector,
  sponsorshipURLSelector,
} from '../redux/selectors/lottery'

interface Props {
  isFixedBottom?: boolean
  isIOS: boolean
  preventNextStep: boolean
  isAfterWheel?: boolean
  textOnLeft?: boolean
}

const SponsorshipButton: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch()

  const siteConfig = useSelector(configSelector)
  const sponsorshipURL = useSelector(sponsorshipURLSelector)
  const step = useSelector(lotteryStepSelector)

  const { isFixedBottom, textOnLeft } = props

  // useEffect(() => {
  //   props.async_fetchSponsorshipURL(props.siteConfig)
  // }, [])

  if (!sponsorshipURL) {
    return null
  }

  let url = ''

  if (!!sponsorshipURL) {
    if (props.isIOS) {
      url = `sms://?&body=${encodeURIComponent(sponsorshipURL)}`
    } else {
      url = `sms:?body=${encodeURIComponent(sponsorshipURL)}`
    }
  }

  const onSponsorClick = () => {
    WheelAnalyticsManager.logEvent('sponsor_click')

    if (props.isAfterWheel) {
      setTimeout(() => {
        dispatch(goToRedeem())
      }, 1000)
      return
    }

    dispatch(setLotteryLoading(true, step || undefined))

    setTimeout(() => {
      dispatch(incrementCTAClickCount())
      if (!props.preventNextStep) {
        dispatch(nextLotteryStep())
      }
      dispatch(setLotteryLoading(false))
    }, TSConstants.LOADER_TIMEOUT_SHORT)
  }

  return (
    <WheelButton
      id="sponsorship"
      text={tt('cta_sponsorship')}
      onClick={onSponsorClick}
      href={url}
      isFixedBottom={isFixedBottom}
      img={`${siteConfig.PUBLIC_FILES_URL_CDM}/placeholder.png`}
      textOnLeft={textOnLeft}
    />
  )
}

export default SponsorshipButton
