import LotteryGameConsume from '@tootsweet/model/lottery/LotteryGameConsume'
import WheelButton from '../../../../components/cta/WheelButton'
import React from 'react'

const MustSignRequest = (props: {
  game: LotteryGameConsume
  onClick: () => void
}) => {
  return (
    <>
      <h2
        id="k-redeem-title"
        className="h2 w-margin-bottom cm-pad w-margin-top-2-thirds"
      >
        <span className="highlight">Félicitations !</span>
      </h2>

      <p className="w-margin-bottom cm-pad">
        Vous avez bien gagné{' '}
        {props.game.prizeLongName || props.game.prizeLabel || '???'}.
      </p>
      <p className="w-margin-bottom cm-pad">
        Pour profiter de votre cadeau, merci de bien vouloir remplir et signer
        votre attestation de gain nominative.
      </p>

      <WheelButton
        isFixedBottom={true}
        text="Remplir mon attestation"
        onClick={props.onClick}
      />
    </>
  )
}

export default MustSignRequest
