import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import gsap from 'gsap'
import Box from './Box'
import './styles/MysteryBoxes.scss'
import WheelButton from '../../components/cta/WheelButton'
import { tt } from '../../i18n'
import { async_play } from '../../redux/actions/lottery'
import { useDispatch, useSelector } from 'react-redux'
import { configSelector } from '../../redux/selectors/config'
import { userSelector } from '../../redux/selectors/app'
import {
  isOptOutSelector,
  lotteryConfigSelector,
  prizeSelector,
  skinSelector,
} from '../../redux/selectors/lottery'
import { WheelItem } from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import { getSvgEmoji, shuffle, uid } from './utils/utils'
import { renderTitle } from '../helpers/renderTitle'
import { renderSkin } from '../18_Fakir/render_skin'
import { BoxHandle } from './utils/BoxHandle'

interface Props {
  isPlayStep: boolean
  onFinished?: () => void
  performMarketingStep?: () => void
  hideCTA?: boolean
  title?: () => ReactNode
}

interface BoxesRefs {
  [key: number]: BoxHandle
}

const SHOW_GIFTS_TIME = 3.5
let boxesAnimationPlayed = false

const MysteryBoxes = ({
  isPlayStep,
  onFinished,
  performMarketingStep,
  title,
  hideCTA,
}: Props) => {
  const gridRef = useRef<HTMLDivElement>(null)
  const [boxWidth, setBoxWidth] = useState(0)
  const [boxHeight, setBoxHeight] = useState(0)

  const timeline = useRef<gsap.core.Timeline>(
    gsap.timeline({
      defaults: {
        duration: 1,
        ease: 'power2.out',
      },
    })
  )
  const fadeInTimeline = useRef<gsap.core.Timeline>(
    gsap.timeline({
      defaults: {
        duration: 1,
        ease: 'power2.out',
      },
    })
  )

  const boxesHandleRef = useRef<BoxesRefs>({} as BoxesRefs)
  const overlayRef = useRef<HTMLDivElement>(null)
  const ctaRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLDivElement>(null)
  const isRunAvailableRef = useRef<boolean>(true)
  const [gifts, setGifts] = useState<WheelItem[]>([])
  const [winningIndex, setWinningIndex] = useState<number>(-1)
  const [step, setStep] = useState('start')
  const [phase2OfMarketingStep, setPhase2OfMarketingStep] = useState(false)
  const [buttonClicked, setButtonClicked] = useState(false)
  const [displayButton, setDisplayButton] = useState(false)

  const dispatch = useDispatch()

  const siteConfig = useSelector(configSelector)
  const user = useSelector(userSelector)
  const isOptOut = useSelector(isOptOutSelector)
  const lotteryConfig = useSelector(lotteryConfigSelector)
  const prize = useSelector(prizeSelector)
  const skin = useSelector(skinSelector)

  const renderBackground = () => {
    if (skin) {
      return renderSkin('bg', skin)
    }
  }

  const renderForeground = () => {
    if (skin) {
      return renderSkin('fg', skin)
    }
  }

  const boxes = useMemo(
    () =>
      gifts?.map((gift, index) => ({
        uid: uid(),
        index,
        content: gift,
      })),
    [gifts]
  )

  useEffect(() => {
    if (gridRef?.current) {
      const height = Math.trunc(gridRef.current?.clientHeight / 3)
      if (height !== boxWidth) {
        setBoxWidth(height)
        setBoxHeight(height)
      }
    }
  }, [gridRef])

  const openBox = (index: number) => {
    if (!isRunAvailableRef.current) {
      return
    }

    if (!isPlayStep && phase2OfMarketingStep) {
      performMarketingStep?.()
      return
    }

    if (!isPlayStep || !gifts || !onFinished) {
      return
    }

    if (displayButton) {
      return
    }

    isRunAvailableRef.current = false

    timeline.current.to(overlayRef.current, {
      zIndex: 3,
      opacity: 1,
      background: 'rgba(46, 46, 47, 0.5)',
    })
    boxesHandleRef.current[index].open({
      content: getEmojiForIndex(winningIndex),
      toCenter: true,
      light: true,
    })
    timeline.current
      .to(
        overlayRef.current,
        {
          opacity: 1,
          background: 'rgba(184, 184, 184, 0.5)',
        },
        '>'
      )
      .then(() => {
        isRunAvailableRef.current = true
        onFinished()
      })
  }

  const animateBoxes = useCallback(() => {
    if (!gifts) {
      return
    }

    if (!isRunAvailableRef.current) {
      return
    }

    isRunAvailableRef.current = false

    if (boxesAnimationPlayed) {
      return
    }

    boxesAnimationPlayed = true

    if (!isPlayStep) {
      fadeInTimeline.current.clear()
      fadeInTimeline.current
        .fromTo(
          titleRef.current,
          { opacity: 1 },
          { opacity: 0, duration: 1 },
          '<'
        )
        .fromTo(
          ctaRef.current,
          { opacity: 1 },
          { opacity: 0, duration: 1 },
          '<'
        )
        .then(() => setPhase2OfMarketingStep(true))
    }

    timeline.current.clear()
    const timelineLabel = 'openAll'
    timeline.current.addLabel(timelineLabel)

    boxes?.forEach(({ index }) =>
      boxesHandleRef.current[index].open({
        content: getEmojiForIndex(index),
        timelineLabel,
      })
    )
    timeline.current
      .then(
        () =>
          new Promise((resolve) => {
            setTimeout(
              () => resolve(timeline.current.reverse()),
              SHOW_GIFTS_TIME * 1000
            )
          })
      )
      .then(() => {
        boxes?.forEach(({ index }) => boxesHandleRef.current[index].close())
        // clear prev animations
        timeline.current.clear()
        // toggle reverse
        timeline.current.play()

        return timeline.current.then
      })
      .then(() => {
        boxes?.forEach(({ index }) =>
          boxesHandleRef.current[index].moveCenter()
        )

        return new Promise((resolve) => {
          // toggle reverse
          timeline.current.then((tl) => resolve(tl))
        })
      })
      .then(
        () =>
          new Promise((resolve) => {
            if (!boxes) return
            const boxesCopy = shuffle([...boxes])
            timeline.current.clear()

            boxesCopy.forEach(({ index }) =>
              boxesHandleRef.current[index].moveBack()
            )

            timeline.current.then((tl) => resolve(tl))
          })
      )
      .then(() => {
        if (!isPlayStep) {
          fadeInTimeline.current.clear()
          fadeInTimeline.current.fromTo(
            titleRef.current,
            { opacity: 0 },
            { opacity: 1, duration: 1.5 },
            '<'
          )
        }
        setStep('choice')
        timeline.current.clear()
        isRunAvailableRef.current = true
        return true
      })
  }, [boxes, gifts])

  useEffect(() => {
    setDisplayButton(!buttonClicked && (!isPlayStep || !boxesAnimationPlayed))
  }, [buttonClicked, isPlayStep])

  useEffect(() => {
    if (isPlayStep) {
      isRunAvailableRef.current = true
    }
  }, [isPlayStep])

  useEffect(() => {
    if (!isPlayStep) {
      return
    }
    dispatch(
      async_play(
        siteConfig,
        user?.firstName,
        user?.paymentEmail,
        isOptOut,
        user?.phone,
        true
      )
    )
  }, [])

  useEffect(() => {
    if (!lotteryConfig?.wheelItems) return
    const newGifts = shuffle(lotteryConfig?.wheelItems)
    setGifts(newGifts)
  }, [lotteryConfig?.wheelItems])

  useEffect(() => {
    if (!prize) return
    let winningIndex
    if (prize.win) {
      winningIndex = gifts.findIndex((gift) => gift.id === prize.giftId)
    } else {
      winningIndex = gifts.findIndex((gift) => gift.isLost)
    }
    setWinningIndex(winningIndex)
  }, [prize, gifts])

  const openBoxOnClick = isPlayStep || phase2OfMarketingStep

  function getEmojiForIndex(index: number) {
    let content = lotteryConfig?.hasML
      ? 'http://cadeaudelamaison.com.s3.amazonaws.com/kadow/glm/token.png'
      : getSvgEmoji('😭', true)
    if (gifts && index < gifts.length) {
      if (gifts[index].img) {
        content = `https://tootsweet.twic.pics/cdm/kadow/${gifts[index].img}`
      } else {
        content = getSvgEmoji(
          // @ts-ignore
          gifts[index].e,
          gifts[index]?.isLost || false
        )
      }
    }
    return content
  }

  return (
    <>
      <div ref={titleRef} className="cm-pad text-center w-margin-top-half">
        {title
          ? title()
          : renderTitle(
              isPlayStep || phase2OfMarketingStep,
              false,
              lotteryConfig,
              skin
            )}
      </div>
      {renderBackground()}
      <div className="mb-root" style={{ flex: 1 }}>
        <div
          className={'overlay'}
          ref={overlayRef}
          style={{
            pointerEvents: 'none',
          }}
        />
        <div ref={gridRef} className="grid">
          {!!boxWidth &&
            gifts?.length > 0 &&
            boxes?.map(({ index }) => {
              return (
                <Box
                  id={`box-${index}`}
                  key={`box-${index}`}
                  ref={(ref) => {
                    if (!ref) {
                      return
                    }
                    boxesHandleRef.current[index] = ref
                  }}
                  index={index}
                  timeline={timeline.current}
                  onClick={openBoxOnClick ? () => openBox(index) : undefined}
                  boxWidth={boxWidth}
                  boxHeight={boxHeight}
                  content={getEmojiForIndex(index)}
                />
              )
            })}
        </div>
        <div className="mb-padding">&nbsp;</div>
        <div ref={ctaRef}>
          {!hideCTA && displayButton && (
            <WheelButton
              text={tt('discover_gift')}
              isFixedBottom={true}
              onClick={() => {
                setButtonClicked(true)
                animateBoxes()
              }}
            />
          )}
        </div>
      </div>
      {renderForeground()}
    </>
  )
}

export default MysteryBoxes
