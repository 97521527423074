import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WheelButton from './cta/WheelButton'
import WheelAnalyticsManager from '../analytics/WheelAnalyticsManager'
import {
  incrementCTAClickCount,
  nextLotteryStep,
  setLotteryLoading,
  setShowTuto,
} from '../redux/actions/lottery'
import TSConstants from '../utils/TSConstants'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import { tt } from '../i18n'
import { FunctionComponent } from 'react'
import { configSelector } from '../redux/selectors/config'

interface Props {
  tikTokUserId: string | undefined | null
  isFixedBottom?: boolean
  textOnLeft?: boolean
  preventNextStep: boolean
  step: LotteryV2Step | null
  style?: any | null
  showTuto?: boolean
  onClick?: () => void
}

const TikTokButton: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch()
  const siteConfig = useSelector(configSelector)
  const { tikTokUserId, isFixedBottom, textOnLeft } = props

  const link =
    tikTokUserId?.includes('https:') || tikTokUserId?.includes('http:')
      ? tikTokUserId
      : `https://www.tiktok.com/@${tikTokUserId}`

  const onClickPreTutorial = () => {
    if (props.showTuto) {
      dispatch(
        setShowTuto(true, false, false, false, true, () => {
          return (
            <TikTokButton
              {...props}
              onClick={onClickPostTutorial}
              showTuto={false}
            />
          )
        })
      )
    } else {
      onClickPostTutorial()
    }
  }

  const onClickPostTutorial = () => {
    WheelAnalyticsManager.tiktokClicked()

    dispatch(setLotteryLoading(true, props.step || undefined))
    dispatch(setShowTuto(false, false, false, false, false))

    setTimeout(() => {
      dispatch(incrementCTAClickCount())
      if (!props.preventNextStep) {
        dispatch(nextLotteryStep())
      }
      dispatch(setLotteryLoading(false))
    }, TSConstants.LOADER_TIMEOUT_SHORT)
  }

  return (
    <WheelButton
      text={tt('cta_tiktok')}
      onClick={props.onClick || onClickPreTutorial}
      className="w-btn-tiktok"
      img={`${siteConfig.PUBLIC_FILES_URL_CDM}/img/tiktok.jpeg`}
      href={props.showTuto ? undefined : link}
      isFixedBottom={isFixedBottom}
      textOnLeft={textOnLeft}
      style={props.style}
    />
  )
}

export default TikTokButton
