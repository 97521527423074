enum LotteryType {
  Wheel = "Wheel",
  Quizz = "Quizz",
  ScratchCardChristmas = "ScratchCardChristmas",
  ScratchCard = "ScratchCard",
  AdventCalendar = "AdventCalendar",
  SlotMachine = "SlotMachine",
  GiftMachine = "GiftMachine",
  Catcher = "Catcher",
  TwoSocialNetworks = "TwoSocialNetworks",
  Random = "Random",
  Dice = "Dice",
  MysteryBoxes = "MysteryBoxes",
  Fakir = "Fakir",
}

export default LotteryType;
