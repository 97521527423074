import React, { FC } from 'react'

interface ScoreProps {
  score: number
  className?: string
}

const Score: FC<ScoreProps> = ({ score, className }) => {
  return (
    <div id={'skill-score'} className={className}>
      {score} {`point${score > 1 ? 's' : ''}`}
    </div>
  )
}

export default Score
