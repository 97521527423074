import { useState } from 'react'

interface Props {
  initialValue?: boolean
  onCloseModal?: () => void
  onOpenModal?: () => void
}

export interface ModalControlsI {
  isOpen: boolean
  openModal: () => void
  closeModal: () => void
}

const useModalControls = ({
  initialValue,
  onCloseModal,
  onOpenModal,
}: Props): ModalControlsI => {
  const [isOpen, setIsOpen] = useState(initialValue || false)

  const openModal = () => {
    setIsOpen(true)
    onOpenModal && onOpenModal()
  }

  const closeModal = () => {
    setIsOpen(false)
    onCloseModal && onCloseModal()
  }

  return { isOpen, openModal, closeModal }
}

export default useModalControls
