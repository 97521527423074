import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import { renderLogo } from './renderLogo'
import TSConstants from '../../utils/TSConstants'
import { tt } from '../../i18n'
import WheelButton from '../../components/cta/WheelButton'
import copyTextToClipboard from '../../utils/clipboard'
import React from 'react'

export function renderBrowserNotSupported(
  gameContainerHeight: number,
  hasBG_URL?: boolean,
  config?: LotteryConfigurationResponse
) {
  return (
    <>
      {hasBG_URL && (
        <img
          className="twic"
          src={TSConstants.PLACEHOLDER_URL}
          data-src={`image:/cdm/custom_bgs/${config?.bgURL}`}
          data-src-transform="cover=WxH"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: gameContainerHeight,
            zIndex: 0,
          }}
        />
      )}
      <div
        className={`game-container ${hasBG_URL ? 'no-bg' : ''}`}
        style={{ zIndex: 1 }}
      >
        {config && renderLogo(config)}
        {!config && (
          <div className="cm-pad logo-container">
            <img
              className="twic logo"
              src={TSConstants.PLACEHOLDER_URL}
              data-src="image:/cdm/kadow/img/logo_2.png"
            />
          </div>
        )}
        <div className="cm-pad w-margin-top-half w-margin-bottom-half">
          <h2 className="h2 highlight">{tt('unsupported_browser')}</h2>
        </div>
        <div className="cm-pad w-margin-bottom-half d-flex align-items-center justify-content-center">
          <p style={{ overflowWrap: 'anywhere' }}>{tt('samsung_internet')}</p>
        </div>
        <WheelButton
          text={tt('copy_url')}
          isFixedBottom={true}
          onClick={() => copyTextToClipboard(window.location.href)}
        />
      </div>
    </>
  )
}
