import Skin, { SkinStyle } from '../../model/Skin'
import WheelColorsI from './WheelColors'
import TSConstants from '../../utils/TSConstants'
import React from 'react'
import aspectRatio from '../../utils/aspect_ratio'

const aspectRatio1 = aspectRatio()

export function renderSkin(
  key: keyof Skin,
  skin: Skin,
  colors?: WheelColorsI,
  width?: number,
  height?: number
) {
  if (!skin) {
    return null
  }
  if (
    key === 'name' ||
    key === 'hasNotch' ||
    key === 'main_sprite' ||
    key === 'primary' ||
    key === 'secondary'
  ) {
    return
  }
  const plane = skin[key]
  // @ts-ignore
  if (!plane || plane.length === 0) {
    return null
  }
  return (
    <div style={{ width: '100%', height }}>
      {
        // @ts-ignore
        plane.map((item) => {
          const style: SkinStyle = item.details[aspectRatio1]
          return (
            <img
              className="twic"
              src={TSConstants.PLACEHOLDER_URL}
              data-src={`image:${item.src}`}
              style={{ ...style }}
            />
          )
        })
      }
    </div>
  )
}
