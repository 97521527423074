import TSConstants from '../../utils/TSConstants'
import { tt } from '../../i18n'

export function computeRemainingText(remaining: number, absoluteTs: number) {
  const days = Math.trunc(remaining / TSConstants.secondsInADay)
  let hours = Math.trunc(remaining / TSConstants.secondsInAnHour)
  let minutes: any = Math.trunc(
    (remaining % TSConstants.secondsInAnHour) / TSConstants.secondsInAMinute
  )
  let seconds: any = Math.trunc(
    (remaining % TSConstants.secondsInAnHour) % TSConstants.secondsInAMinute
  )

  if (minutes < 10) {
    minutes = `0${minutes}`
  }
  if (seconds < 10) {
    seconds = `0${seconds}`
  }
  if (days > 1) {
    hours = Math.trunc(hours % TSConstants.hoursInADay)
    return tt('date_format_long_time_with_days_string', {
      days,
      hours,
      minutes,
      seconds,
    })
  }
  return tt('date_format_long_time_string', {
    hours,
    minutes,
    seconds,
  })
}
