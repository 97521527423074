import * as React from 'react'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import WheelButton from './cta/WheelButton'
import {
  incrementCTAClickCount,
  nextLotteryStep,
  setLotteryLoading,
} from '../redux/actions/lottery'
import { useDispatch, useSelector } from 'react-redux'
import WheelAnalyticsManager from '../analytics/WheelAnalyticsManager'
import TSConstants from '../utils/TSConstants'
import { tt } from '../i18n'
import { FunctionComponent } from 'react'
import { configSelector } from '../redux/selectors/config'

interface Props {
  link: string | undefined | null
  isFixedBottom?: boolean
  textOnLeft?: boolean
  step: LotteryV2Step | null
  preventNextStep: boolean
}

const FnacButton: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch()

  const siteConfig = useSelector(configSelector)

  const onGoToFnacClick = () => {
    WheelAnalyticsManager.fnacClicked()

    dispatch(setLotteryLoading(true, props.step || undefined))

    setTimeout(() => {
      dispatch(incrementCTAClickCount())
      if (!props.preventNextStep) {
        dispatch(nextLotteryStep())
      }
      dispatch(setLotteryLoading(false))
    }, TSConstants.LOADER_TIMEOUT_SHORT)
  }

  const { link, isFixedBottom, textOnLeft } = props

  return (
    <WheelButton
      text={tt('cta_fnac')}
      onClick={onGoToFnacClick}
      className="w-btn-fnac"
      img={`${siteConfig.PUBLIC_FILES_URL_CDM}/img/fnac.png`}
      href={link || undefined}
      isFixedBottom={isFixedBottom}
      textOnLeft={textOnLeft}
    />
  )
}

export default FnacButton
