import React, { useEffect, useRef, useState } from 'react'
import styles from './SlotMachine.module.sass'
import generateSlotMachineRibbon, {
  SlotMachineRibbonParams,
} from './generateImage'
import { useDispatch, useSelector } from 'react-redux'
import {
  isOptOutSelector,
  lotteryConfigSelector,
  lotteryGameConsumeSelector,
} from '../../../redux/selectors/lottery'
import SpinnerV2 from './SpinnerV2'
import { async_play, setLotteryLoading } from '../../../redux/actions/lottery'
import TSConstants from '../../../utils/TSConstants'
import SlotMachineProps from '../SlotMachineProps'
import { configSelector } from '../../../redux/selectors/config'
import { userSelector } from '../../../redux/selectors/app'
import WheelButton from '../../../components/cta/WheelButton'
import {generateArrayOf3DifferentIntegers} from "../utils";

let isFinished: any = {}
const DURATION = 3000

const SlotMachineV2: React.FC<SlotMachineProps> = (props: SlotMachineProps) => {
  const [shouldWin, setShouldWin] = useState<boolean>(false)
  const [winningIdx, setWinningIdx] = useState(-1)
  const slotRef = useRef<HTMLDivElement>(null)
  const lotteryConfig = useSelector(lotteryConfigSelector)
  const dispatch = useDispatch()
  const isOptOut = useSelector(isOptOutSelector)
  const siteConfig = useSelector(configSelector)
  const user = useSelector(userSelector)
  const [showCTA, setShowCTA] = useState(true)
  const [playCounter, setPlayCounter] = useState(0)
  const lotteryGame = useSelector(lotteryGameConsumeSelector)

  const [imgParamz, setImgParamz] = useState<SlotMachineRibbonParams | null>(
    null
  )
  const [lostIndices, setLostIndices] = useState([-1, -1, -1])

  const onSpinnerFinished = (idx: number) => {
    isFinished[idx] = true
    if (isFinished[0] && isFinished[1] && isFinished[2]) {
      setTimeout(() => {
        isFinished[0] = false
        isFinished[1] = false
        isFinished[2] = false
        if (props.onFinished) {
          props.onFinished()
        }
      }, 1000)
    }
  }

  const play = () => {
    if (!props.isPlayStep) {
      props.onCTAClicked?.()
      return
    }

    if (!user) {
      return
    }

    setPlayCounter(playCounter + 1)

    dispatch(
      async_play(
        siteConfig,
        user.firstName,
        user.paymentEmail,
        isOptOut,
        user.phone,
        true
      )
    )
  }

  useEffect(() => {
    if (!slotRef.current || !lotteryConfig?.wheelItems?.length) {
      return
    }

    dispatch(setLotteryLoading(true))

    setTimeout(async () => {
      const slot1 = document.getElementById('slot1')
      if (!slot1) {
        return
      }
      const imgParamz = await generateSlotMachineRibbon(
        slot1.clientWidth,
        slot1.clientHeight,
        lotteryConfig
      )
      setImgParamz(imgParamz)

      setTimeout(() => {
        dispatch(setLotteryLoading(false))
      }, 500)
    }, 500)
  }, [slotRef.current, lotteryConfig])

  useEffect(() => {
    if (lotteryGame) {
      setShowCTA(false)
    }

    if (lotteryGame?.outcome && lotteryGame?.giftId) {
      setShouldWin(true)
      const index = lotteryConfig?.wheelItems?.findIndex(
        (it) => it.id === lotteryGame.giftId
      )
      if (index !== undefined) {
        setWinningIdx(index)
      }
    } else {
      setShouldWin(false)
    }
  }, [lotteryGame])

  useEffect(() => {
    if (!lotteryConfig?.wheelItems?.length) {
      return
    }
    const lostIndices = generateArrayOf3DifferentIntegers(lotteryConfig.wheelItems.length)
    setLostIndices(lostIndices)
  }, [lotteryConfig])

  if (!lostIndices) {
    return null
  }

  return (
    <>
      <div className={styles.slotContainerContainer}>
        <div className={styles.slotContainer}>
          <img
            data-src={`image:/cdm/slot-machine.svg`}
            src={TSConstants.PLACEHOLDER_URL}
            alt="Machine"
            className={`twic ${styles.slotMachine}`}
          />
          <div id="slot1" ref={slotRef} className={styles.slot}>
            {!!imgParamz && (
              <SpinnerV2
                idx={0}
                duration={DURATION}
                playCounter={playCounter}
                shouldWin={shouldWin}
                winningIdx={winningIdx}
                onFinished={(idx: number) => onSpinnerFinished(idx)}
                paramz={imgParamz}
                lostIdx={lostIndices[0]}
              />
            )}
          </div>
          <div id="slot2" className={styles.slot}>
            {!!imgParamz && (
              <SpinnerV2
                idx={1}
                duration={DURATION + 1000}
                playCounter={playCounter}
                shouldWin={shouldWin}
                winningIdx={winningIdx}
                onFinished={(idx: number) => onSpinnerFinished(idx)}
                paramz={imgParamz}
                lostIdx={lostIndices[1]}
              />
            )}
          </div>
          <div id="slot3" className={styles.slot}>
            {!!imgParamz && (
              <SpinnerV2
                idx={2}
                duration={DURATION + 2000}
                playCounter={playCounter}
                shouldWin={shouldWin}
                winningIdx={winningIdx}
                onFinished={(idx: number) => onSpinnerFinished(idx)}
                paramz={imgParamz}
                lostIdx={lostIndices[2]}
              />
            )}
          </div>
        </div>
      </div>

      {showCTA && (
        <WheelButton
          text="Lancer la machine à sous"
          onClick={play}
          isFixedBottom={true}
        />
      )}
    </>
  )
}

export default SlotMachineV2
