import * as React from 'react'
import { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GenericGameStep from '../../../components/GenericGameStep'
import { async_fetchSponsorshipURL } from '../../../redux/actions/lottery'
import WheelAnalyticsManager from '../../../analytics/WheelAnalyticsManager'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import { configSelector } from '../../../redux/selectors/config'
import {
  loadingSelector,
  lotteryConfigSelector,
  lotteryStepIndexSelector,
  prizeSelector,
  venueSelector,
  wheelLabelsSelector,
  wheelStepIndexSelector,
} from '../../../redux/selectors/lottery'
import SponsorshipModalContent from './SponsorshipModalContent'

interface Props {
  isModal: boolean
  isIOS: boolean
}

const Sponsorship: FunctionComponent<Props> = (props) => {
  const venue = useSelector(venueSelector)
  const wheelStepIndex = useSelector(wheelStepIndexSelector)
  const lotteryStepIndex = useSelector(lotteryStepIndexSelector)

  useEffect(() => {
    WheelAnalyticsManager.logEvent(LotteryV2StepType.Sponsorship)
  }, [])

  const renderStepInModal = (
    finalPlaceName: string,
    isBeforeWheel: boolean
  ) => {
    return (
      <GenericGameStep
        renderModalBody={() => (
          <SponsorshipModalContent
            isModal={true}
            isBeforeWheel={isBeforeWheel}
            isIOS={props.isIOS}
          />
        )}
        stepType={LotteryV2StepType.Sponsorship}
      />
    )
  }

  let isBeforeWheel = wheelStepIndex === -1 || wheelStepIndex > lotteryStepIndex

  if (isBeforeWheel && venue?.name) {
    return renderStepInModal(venue?.name, isBeforeWheel)
  }

  return (
    <div className="d-flex flex-column flex-grow-1 cm-pad">
      <SponsorshipModalContent
        isModal={false}
        isBeforeWheel={isBeforeWheel}
        isIOS={props.isIOS}
      />
    </div>
  )
}

export default Sponsorship
