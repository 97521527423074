import Modal from 'react-bootstrap/Modal'
import WheelButton from '../../components/cta/WheelButton'
import * as React from 'react'
import * as EmailValidator from 'email-validator'
import {
  async_updateUserInfo,
  registerOptinIfNeeded,
} from '../../redux/actions/lottery'
import { useDispatch, useSelector } from 'react-redux'
import { tt } from '../../i18n'
import FlexPicture from '../../components/FlexPicture'
import OptinOptout from '../../components/optinoptout/OptinOptout'
import WheelAnalyticsManager from '../../analytics/WheelAnalyticsManager'
import { share } from '../helpers/share'
import { FunctionComponent, useEffect, useState } from 'react'
import { userSelector } from '../../redux/selectors/app'
import {
  currentSessionEmailSelector,
  currentSessionFirstNameSelector,
  lotteryConfigSelector,
  lotteryGameConsumeSelector,
  skinSelector,
} from '../../redux/selectors/lottery'
import { configSelector } from '../../redux/selectors/config'
import { TSUtils } from '../../utils/TSUtils'
import { RootState } from '../../redux/combinedReducers'
import GDPRReminder from '../../components/GDPRReminder'

interface Props {
  show: boolean
  closeModal: () => void
  onFinished: () => void
  prizeLabel?: string
  offerToFriend?: (url: string) => void
  forceKeepGift?: boolean
}

const STEP_INITIAL = 0
const STEP_KEEP = 1

const EmailAddressModal: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch()

  const user = useSelector(userSelector)
  const config = useSelector(lotteryConfigSelector)
  const lotteryGame = useSelector(lotteryGameConsumeSelector)
  const currentSessionFirstName = useSelector(currentSessionFirstNameSelector)
  const currentSessionEmail = useSelector(currentSessionEmailSelector)
  const siteConfig = useSelector(configSelector)

  const skin = useSelector(skinSelector)
  const skinId = useSelector((s: RootState) => s.lottery.skinId)

  const [email, setEmail] = useState<string | undefined>(
    user?.paymentEmail?.toLowerCase() || ''
  )
  const [firstName, setFirstName] = useState<string | undefined>(
    user?.firstName || ''
  )
  const [phone, setPhone] = useState<string | undefined>(user?.phone || '')

  const [hasFirstNameModalError, setHasFirstNameModalError] =
    useState<boolean>(false)
  const [hasEmailModalError, setHasEmailModalError] = useState<boolean>(false)
  const [hasPhoneError, setHasPhoneError] = useState<boolean>(false)

  const [step, setStep] = useState<number>(
    props.forceKeepGift || config?.hideOfferToFriend || config?.hasML
      ? STEP_KEEP
      : STEP_INITIAL
  )
  const [show, setShow] = useState<boolean>(true)

  const onOfferToFriendClicked = async () => {
    WheelAnalyticsManager.logEvent('offer_to_friend')

    if (!lotteryGame) return

    if (!lotteryGame.id) {
      alert(tt('error'))
      return
    }

    props.closeModal()

    let url = buildShareURL()

    await share(url)

    if (props.offerToFriend) {
      props.offerToFriend(url ?? '')
    }
  }

  const renderInitialStep = () => {
    return (
      <>
        {renderBravo()}
        <div className="row w-margin-bottom">
          <div className="col-12">
            <p>{tt('win_modal_offer_to_friend_text')}</p>
          </div>
        </div>
        <div className="row w-margin-bottom-half">
          <div className="col-12">
            <WheelButton
              id="keep-gift"
              text={tt('win_modal_keep_gift')}
              onClick={onKeepGiftClicked}
            />
          </div>
        </div>
        {!config?.hideOfferToFriend && (
          <div className="row">
            <div className="col-12" id="test-keep-gift">
              <WheelButton
                id="offer-to-fried"
                text={tt('win_modal_offer_to_friend')}
                onClick={onOfferToFriendClicked}
              />
            </div>
          </div>
        )}
      </>
    )
  }

  const renderBravo = () => {
    return (
      <div className="row">
        <div className="col-12">
          {(step !== STEP_KEEP ||
            !config ||
            !config.askWinnersForPhoneNumber) && (
            <h1>{tt('win_modal_you_won', { prizeLabel: getPrizeLabel() })}</h1>
          )}
          {step === STEP_KEEP && config && config.askWinnersForPhoneNumber && (
            <h1>{tt('win_modal_enter_email')}</h1>
          )}
        </div>
      </div>
    )
  }

  const goStraightToRedeemIfNeeded = async () => {
    const goStraight =
      currentSessionFirstName && currentSessionEmail && config?.type !== 'Quizz'

    if (goStraight) {
      setShow(false)
      await afterUpdateUserInfo()
      return true
    }

    return false
  }

  const onKeepGiftClicked = () => {
    WheelAnalyticsManager.logEvent('keep_gift')

    goStraightToRedeemIfNeeded().then((goStraight) => {
      if (!goStraight) {
        setStep(STEP_KEEP)
      }
    })
  }

  const handlePhoneChange = (e: any) => {
    setPhone(e.target.value)
    removeErrorIfPossible()
  }

  const handleEmailChange = (e: any) => {
    setEmail((e.target.value + '').toLowerCase())
    removeErrorIfPossible()
  }

  const handleFirstNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    setFirstName(event.currentTarget.value)
    removeErrorIfPossible()
  }

  const removeErrorIfPossible = () => {
    if (firstName) {
      setHasFirstNameModalError(false)
    }

    if (email && EmailValidator.validate(email)) {
      setHasEmailModalError(false)
    }

    if (config && config.askWinnersForPhoneNumber && phone) {
      setHasPhoneError(false)
    }
  }

  const onValidateForm = async (e: any) => {
    if (e.preventDefault) {
      e.preventDefault()
    }

    if (!firstName) {
      setHasFirstNameModalError(true)
      return
    }

    if (!email) {
      setHasEmailModalError(true)
      return
    }

    if (!EmailValidator.validate(email)) {
      setHasEmailModalError(true)
      return
    }

    if (config?.askWinnersForPhoneNumber && !phone) {
      setHasPhoneError(true)
      return
    }

    WheelAnalyticsManager.logEvent('win_email_filled')

    setHasFirstNameModalError(false)
    setHasEmailModalError(false)
    setHasPhoneError(false)

    if (firstName && email) {
      await dispatch(
        async_updateUserInfo(
          firstName,
          email,
          config?.askWinnersForPhoneNumber ? phone : null,
          undefined
        )
      )
    }

    await afterUpdateUserInfo()
  }

  const afterUpdateUserInfo = async () => {
    if (config?.askWinnersForPhoneNumber || config?.askWinnersForOptin) {
      await dispatch(registerOptinIfNeeded(siteConfig, true, true))
    }

    props.onFinished()
  }

  const getPrizeLabel = () => {
    return (
      props.prizeLabel ||
      lotteryGame?.prizeLongName ||
      lotteryGame?.prizeLabel ||
      '???'
    )
  }

  const buildShareURL = () => {
    if (!lotteryGame) return
    return `${siteConfig.OFFER_GIFT_SHARE_URL}/?id=${lotteryGame.id}&p=${
      lotteryGame.placeName
    }&l=${getPrizeLabel()}`
  }

  const renderKeepTheGiftForm = () => {
    const askWinnersForPhoneNumber = config && config.askWinnersForPhoneNumber
    const askWinnersForOptin = config && config.askWinnersForOptin

    const askForOptin = askWinnersForPhoneNumber || askWinnersForOptin

    return (
      <>
        {renderBravo()}

        <div className="row">
          <div className="col-12">
            {!!config?.customWinTxt && <p>{config.customWinTxt}</p>}
            {!config?.customWinTxt && <p>{tt('win_modal_spam')}</p>}
          </div>
        </div>

        <FlexPicture
          isModal={true}
          src={undefined}
          dataSrc={
            skinId && skin?.replaceWinModalImg
              ? `${TSUtils.buildSkinBaseURL(skinId, true)}/${
                  skin.replaceWinModalImg
                }`
              : config?.customWinImg
              ? `image:/cdm/${config?.customWinImg}`
              : 'image:/cdm/envelope.png'
          }
          hasCustomPicture={
            !!(skinId && skin?.replaceWinModalImg) || !!config?.customWinImg
          }
        />

        <form id="vic-form" onSubmit={onValidateForm}>
          <div className="row">
            <div className="col-12">
              <div className="lottery-form">
                <input
                  id="firstName"
                  type="text"
                  placeholder={tt('placeholder_first_name')}
                  value={firstName}
                  onChange={handleFirstNameChange}
                  className={`form-control w-margin-bottom-third ${
                    hasFirstNameModalError ? 'input-error' : ''
                  }`}
                  required
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="lottery-form">
                <input
                  id="email"
                  type="email"
                  placeholder={tt('placeholder_email')}
                  value={email}
                  onChange={handleEmailChange}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]+$"
                  className={`form-control w-margin-bottom-third ${
                    hasEmailModalError ? 'input-error' : ''
                  }`}
                  required
                />
              </div>
            </div>
          </div>

          {askWinnersForPhoneNumber && (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="lottery-form">
                    <input
                      id="phone"
                      type="tel"
                      inputMode="tel"
                      placeholder={tt('placeholder_phone_number')}
                      minLength={10}
                      value={phone}
                      onChange={handlePhoneChange}
                      className={`form-control w-margin-bottom-third ${
                        hasPhoneError ? 'input-error' : ''
                      }`}
                      required
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {askForOptin && config?.useOptin && (
            <>
              <div className="row">
                <div className="col-12 text-center checkbox">
                  <OptinOptout />
                </div>
              </div>
            </>
          )}

          <GDPRReminder
            ctaText={config?.hasML ? tt('next') : tt('receive_my_gift')}
          />

          <div className="row">
            <div className="col-12">
              <WheelButton
                id="w-cta-modal"
                text={config?.hasML ? tt('next') : tt('receive_my_gift')}
                isSubmit={true}
              />
            </div>
          </div>

          {askForOptin && !config?.useOptin && (
            <>
              <div className="row">
                <div className="col-12 text-center checkbox">
                  <OptinOptout />
                </div>
              </div>
            </>
          )}
        </form>

        <div id="dummy-ema" style={{ height: 1 }} />
      </>
    )
  }

  const handleHideOfferToFriend = () => {
    if (config?.hideOfferToFriend && step === STEP_INITIAL) {
      setStep(STEP_KEEP)
    }
  }

  useEffect(() => {
    // @ts-ignore
    confetti.start()
    WheelAnalyticsManager.logEvent('win_modal')
    setTimeout(() => {
      // @ts-ignore
      confetti.stop()
    }, 1000)
  }, [step])

  useEffect(() => {
    handleHideOfferToFriend()
  }, [props, handleHideOfferToFriend])

  return (
    <Modal
      show={show}
      onHide={props.closeModal}
      centered={true}
      backdrop="static"
    >
      <Modal.Body id="email-modal">
        <div className="w-modal">
          <div className="container">
            {step === STEP_INITIAL && renderInitialStep()}
            {step === STEP_KEEP && renderKeepTheGiftForm()}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default EmailAddressModal
