import * as React from 'react'
import {
  async_recordPoll_v2,
  incrementCTAClickCount,
  nextLotteryStep,
} from '../../redux/actions/lottery'
import { useDispatch, useSelector } from 'react-redux'
import WheelButton from '../../components/cta/WheelButton'
import WheelAnalyticsManager from '../../analytics/WheelAnalyticsManager'
import { tt } from '../../i18n'
import { TSUtils } from '../../utils/TSUtils'
import { FunctionComponent, useEffect, useState } from 'react'
import { configSelector } from '../../redux/selectors/config'
import {
  lotteryStepSelector,
  venueSelector,
} from '../../redux/selectors/lottery'

const PollModalContent: FunctionComponent = () => {
  const [answer, setAnswer] = useState<string | undefined>(undefined)

  const dispatch = useDispatch()
  const siteConfig = useSelector(configSelector)
  const step = useSelector(lotteryStepSelector)

  useEffect(() => {
    WheelAnalyticsManager.logEvent('poll')
  }, [])

  const renderOption = (id: string, value: string | number, text: string) => {
    return (
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="gridRadios"
          id={id}
          value={value}
          checked={answer === value}
          required={true}
          onChange={(e) => setAnswer(e.target.value)}
        />
        <label className="form-check-label" htmlFor={id}>
          {text}
        </label>
      </div>
    )
  }

  const onSubmit = async (e: any) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    if (!answer) {
      alert('Erreur 14')
      return
    }

    dispatch(async_recordPoll_v2(siteConfig, answer))
    dispatch(incrementCTAClickCount())
    dispatch(nextLotteryStep())
  }

  return (
    <>
      <h1>{tt('poll_title')}</h1>

      <form id="form-poll" onSubmit={onSubmit}>
        <div className="row w-margin-bottom-half w-padding-top-half">
          <div className="col-12">
            <p>{step?.pQuestion || tt('poll_question')}</p>
          </div>
        </div>

        <div className="row w-margin-bottom-half">
          <div className="col-12">
            <fieldset className="form-group">
              {step &&
                step.pList &&
                step.pList.map((answer: string, idx: number) => {
                  const key = TSUtils.stripSpacesAndDiacritics(answer)
                  return renderOption(`${key}${idx}`, key, answer)
                })}
            </fieldset>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <WheelButton
              id="cta-poll"
              containerClassName="cta-poll-cont"
              text="Valider"
              isSubmit={true}
            />
          </div>
        </div>
      </form>
    </>
  )
}

export default PollModalContent
