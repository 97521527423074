import WheelColorsI from '../WheelColors'
import React from 'react'
import './nature.scss'
import aspectRatio, { AspectRatio } from '../../../utils/aspect_ratio'
import Balloon from '../SVG_objects/Balloon'
import StPatricksDayFilledClover from '../SVG_objects/StPatricksDayFilledClover'
import StPatricksDayEmptyClover from '../SVG_objects/StPatricksDayEmptyClover'

const aspectRatio1 = aspectRatio()

export function buildSkinForStPatricksDay(colors: WheelColorsI) {
  return {
    renderBackground: () => {
      let fcWidth = '30%'
      let fcTop = '15%'
      let fcRight = '10%'

      let ecBottom = '40%'
      let ecRight = '5%'
      let ecHeight = '15%'

      switch (aspectRatio1) {
        case AspectRatio._16_9:
          fcWidth = '30%'
          fcTop = '10%'
          fcRight = '10%'
          ecBottom = '45%'
          ecRight = '5%'
          ecHeight = '15%'
          break
        case AspectRatio._19_9:
        case AspectRatio._18_9:
          break
      }

      return (
        <>
          <StPatricksDayFilledClover
            idx={0}
            width={fcWidth}
            top={fcTop}
            right={fcRight}
          />
          <StPatricksDayEmptyClover
            idx={1}
            bottom={ecBottom}
            right={ecRight}
            height={ecHeight}
            transform={'rotate(60deg)'}
          />
        </>
      )
    },
    renderForeground: (
      width: number,
      height: number,
      wheelDimension: number,
      wheelLeft: number,
      wheelBottom: number,
      animateCounter?: number
    ) => {
      let fcWidth = '30%'
      let fcBottom = '8%'
      let fcRight = '3%'

      let ecTop = '0%'
      let ecRight = '0%'
      let ecHeight = '10%'

      switch (aspectRatio1) {
        case AspectRatio._16_9:
          fcWidth = '30%'
          fcBottom = '8%'
          fcRight = '6%'

          ecTop = '0%'
          ecRight = '0%'
          ecHeight = '10%'
          break
        case AspectRatio._19_9:
        case AspectRatio._18_9:
          break
      }

      return (
        <>
          <StPatricksDayFilledClover
            idx={0}
            width={fcWidth}
            bottom={fcBottom}
            right={fcRight}
          />
          <StPatricksDayEmptyClover
            idx={0}
            top={ecTop}
            right={ecRight}
            height={ecHeight}
          />
          <Balloon
            colors={colors}
            color={'#FAA21D'}
            colorLight={'#fef236'}
            id="bal-2"
            width={'25%'}
            right={'-5%'}
            bottom={'40%'}
            left={undefined}
            animateCounter={animateCounter}
          />
        </>
      )
    },
    renderCTAForeground: () => {
      return null
    },
  }
}
