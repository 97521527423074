import React from 'react'
import { SVGComponentProps } from './Balloon'
import { renderStyles } from '../helpers'

export type StarProps = SVGComponentProps

export default function DoubleStripedStar(props: StarProps) {
  return (
    <div
      style={{
        width: props.width,
        position: 'absolute',
        top: props.top,
        left: props.left,
        right: props.right,
        bottom: props.bottom,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 286.92 286.22"
        style={{ height: '100%', width: '100%' }}
      >
        <defs>
          <style>{`
          ${renderStyles()}
          .cs-fill-star {fill:${props.colors.starColor}}
          `}</style>
        </defs>
        <g className="cs-fill-isolate">
          <g id="b578b686-e5e2-43d2-bcdd-83438f58d59b" data-name="Layer 2">
            <g id="a2841bf5-8e20-463f-ba4d-ca201c5e566e" data-name="OBJETS_BG">
              <polygon
                points="127.85 0 184.59 85.19 286.92 82.68 223.42 163.01 257.38 259.57 161.4 224.03 80.06 286.22 84.23 183.92 0 125.79 98.56 98.11 127.85 0"
                className="cs-fill-star"
              />
              <g className="cs-mix-blend-opacity">
                <polygon
                  points="247 230.04 223.42 163.01 252.65 126.03 216.86 84.39 184.59 85.19 127.85 0 107.58 67.9 247 230.04"
                  className="cs-fill-color-white"
                />
              </g>
              <g className="cs-mix-blend-opacity">
                <polygon
                  points="98.59 98.02 98.56 98.11 59.19 109.17 159.32 225.62 161.4 224.03 228.22 248.77 98.59 98.02"
                  className="cs-fill-color-white"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}
