import { AnyAction, Reducer } from 'redux'
import { SET_CONFIG } from '../actions/config'
import Config from '../../model/config'

const defaultState: Config = {
  BACKEND_URL: '',
  AUTH_URL: '',
  IMAGES_URL: '',
  IMAGES_URL_PATH: '',
  GMAP_API_KEY: '',
  FB_APP_ID: '',
  GENERATOR_URL: '',
  SITE_ROOT: '',
  PUBLIC_FILES_URL: '',
  PUBLIC_FILES_URL_CDM: '',
  PUBLIC_FILES_URL_PYJAMA: '',
  CUSTOMER_LOGO_FILES_URL: '',
  CUSTOMER_LOGO_FILES_FOLDER: '',
  OFFER_GIFT_SHARE_URL: '',
  ENABLE_HOTJAR: false,
  ENABLE_SENTRY: false,
  ENABLE_AMPLITUDE: false,
}

const reducer: Reducer<Config> = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case SET_CONFIG:
      return action.config
  }
  return state
}

export default reducer
