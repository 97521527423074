import React from 'react'

const MustSignConfirmation = () => {
  return (
    <>
      <h2
        id="k-redeem-title"
        className="h2 w-margin-bottom cm-pad w-margin-top-2-thirds"
      >
        <span className="highlight">C'est tout bon !</span>
      </h2>
      <p className="w-margin-bottom cm-pad">
        Vos informations et votre signature ont bien été enregistrées.
      </p>
      <p className="w-margin-bottom cm-pad">
        Notre service cadeaux reviendra vers vous sous 7 jours pour valider
        votre gain. Vous recevrez par email l'exemplaire de votre attestation de
        gain signée au format PDF. Merci de bien vérifier vos spams.
      </p>
      <p className="w-margin-bottom-half cm-pad">
        Pour toutes questions liées à votre gain, vous pouvez contacter le
        service cadeaux :
      </p>
      <p className="w-margin-bottom cm-pad">
        <i className="fas fa-envelope" /> service.cadeaux@h2o-productions.com
        <br />
        <i className="fas fa-phone" /> 01.46.20.70.19 ou 01.46.20.70.31
      </p>
      <p className="w-margin-bottom cm-pad">Merci et à bientôt :)</p>
    </>
  )
}

export default MustSignConfirmation
