import chroma from 'chroma-js'
import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'

export default interface WheelColorsI {
  sector1BGColor: string
  sector1FGColor: string

  sector2BGColor: string
  sector2FGColor: string

  sector3BGColor: string
  sector3FGColor: string

  sector4BGColor: string
  sector4FGColor: string

  wheelText?: string

  structureFillColor: string
  structureTopStrokeColor: string
  structureFillColor2: string
  structureLinesColor: string
  shadowColor: string
  cursorColor: string
  cursorShadowColor: string

  primaryLight: string
  primary: string
  primaryDark: string

  secondaryLight: string
  secondary: string
  secondaryDark: string

  starColor: string

  gift1Color: string
  gift1ColorL: string
  gift1ColorD: string

  gift2Color: string
  gift2ColorL: string
  gift2ColorD: string

  balloon1Color: string
  balloon1ColorL: string

  balloon2Color: string
  balloon2ColorL: string

  v2FoliageColor1: string
  v2FoliageColor2: string
  v2FoliageColor3: string

  hideBalloon?: boolean
  hideStars?: boolean
  hideGifts?: boolean
}

export const COLOR_BLACK = '#000000'
export const COLOR_WHITE = '#FFF'
export const COLOR_KADOW_BLUE_LIGHTER = '#2F297C'
export const COLOR_KADOW_BLUE = '#0e0074'
export const COLOR_TRANSPARENT = '#00000000'
export const COLOR_KADOW_BLUE_DARKER = '#0D084C'
export const COLOR_KADOW_LIGHT_GREEN = '#4FFBB1'
export const COLOR_KADOW_RED = '#FF2C81'

export const DEFAULT_COLOR_LIGHT_BLUE = chroma(COLOR_KADOW_BLUE)
  .brighten(1)
  .hex()
export const DEFAULT_COLOR_BLUE = COLOR_KADOW_BLUE
export const DEFAULT_COLOR_DARK_BLUE = chroma(COLOR_KADOW_BLUE)
  .darken(0.5)
  .hex()

export const DEFAULT_COLOR_LIGHT_YELLOW = chroma(COLOR_KADOW_LIGHT_GREEN)
  .brighten(1)
  .hex()
export const DEFAULT_COLOR_YELLOW = COLOR_KADOW_LIGHT_GREEN
export const DEFAULT_COLOR_DARK_YELLOW = chroma(COLOR_KADOW_LIGHT_GREEN)
  .darken(1)
  .hex()

export const DEFAULT_GREY_1 = '#aaaaba'
export const DEFAULT_GREY_2 = '#d6dbe6'
export const DEFAULT_GREY_3 = '#edefff'
export const DEFAULT_RED = '#b14152'
export const COLOR_CHRISTMAS_DARK_RED = '#9e1919'
export const FOLIAGE_COLOR_DEFAULT_1 = '#387479'
export const FOLIAGE_COLOR_DEFAULT_2 = '#4ea283'
export const FOLIAGE_COLOR_DEFAULT_3 = '#388c7e'

export function setDefaultColorsIfNeeded(
  lotteryConfig?: LotteryConfigurationResponse | null
) {
  if (!lotteryConfig) {
    return
  }
  lotteryConfig.customizeColors = true
  lotteryConfig.colBodyBack = lotteryConfig.colBodyBack || COLOR_KADOW_BLUE
  lotteryConfig.colBodyFont = lotteryConfig.colBodyFont || COLOR_WHITE
  lotteryConfig.colHighlight = lotteryConfig.colHighlight || COLOR_WHITE
  lotteryConfig.colFooterFont = lotteryConfig.colFooterFont || COLOR_WHITE
  lotteryConfig.colFooterBack = lotteryConfig.colFooterBack || COLOR_KADOW_BLUE
  lotteryConfig.colCTAFont = lotteryConfig.colCTAFont || COLOR_WHITE
  lotteryConfig.colCTABack = lotteryConfig.colCTABack || COLOR_KADOW_RED
  lotteryConfig.colLoader = lotteryConfig.colLoader || COLOR_KADOW_RED
  lotteryConfig.colModalFont = lotteryConfig.colModalFont || COLOR_KADOW_BLUE
  lotteryConfig.colV2Sector1BG =
    lotteryConfig.colV2Sector1BG || COLOR_KADOW_BLUE
  lotteryConfig.colV2Sector1FG = lotteryConfig.colV2Sector1FG || COLOR_WHITE
  lotteryConfig.colV2Sector2BG = lotteryConfig.colV2Sector2BG || COLOR_WHITE
  lotteryConfig.colV2Sector2FG =
    lotteryConfig.colV2Sector2FG || COLOR_KADOW_BLUE
  lotteryConfig.colV2Sector3BG =
    lotteryConfig.colV2Sector3BG || COLOR_KADOW_BLUE
  lotteryConfig.colV2Sector3FG = lotteryConfig.colV2Sector3FG || COLOR_WHITE
  lotteryConfig.colV2Sector4BG = lotteryConfig.colV2Sector4BG || COLOR_WHITE
  lotteryConfig.colV2Sector4FG =
    lotteryConfig.colV2Sector4FG || COLOR_KADOW_BLUE
  lotteryConfig.colV2ShadowColor = lotteryConfig.colV2ShadowColor || COLOR_BLACK
  lotteryConfig.colV2PL = lotteryConfig.colV2PL || COLOR_KADOW_BLUE_LIGHTER
  lotteryConfig.colV2P = lotteryConfig.colV2P || COLOR_KADOW_BLUE
  lotteryConfig.colV2PD = lotteryConfig.colV2PD || COLOR_KADOW_BLUE_DARKER
  lotteryConfig.colV2WheelStructTop =
    lotteryConfig.colV2WheelStructTop || COLOR_KADOW_RED
  lotteryConfig.colV2WheelStructLines =
    lotteryConfig.colV2WheelStructLines || COLOR_KADOW_BLUE
  lotteryConfig.colV2SL = lotteryConfig.colV2SL || ''
  lotteryConfig.colV2S = lotteryConfig.colV2S || ''
  lotteryConfig.v2Gift1ColorIdx =
    lotteryConfig.v2Gift1ColorIdx !== undefined
      ? lotteryConfig.v2Gift1ColorIdx
      : 0
  lotteryConfig.v2Gift2ColorIdx =
    lotteryConfig.v2Gift2ColorIdx !== undefined
      ? lotteryConfig.v2Gift2ColorIdx
      : 0
  lotteryConfig.v2Balloon1ColorIdx =
    lotteryConfig.v2Balloon1ColorIdx !== undefined
      ? lotteryConfig.v2Balloon1ColorIdx
      : 1
  lotteryConfig.v2Balloon2ColorIdx =
    lotteryConfig.v2Balloon2ColorIdx !== undefined
      ? lotteryConfig.v2Balloon2ColorIdx
      : 0
  lotteryConfig.v2HideStars = lotteryConfig.v2HideStars || false
  lotteryConfig.v2HideBalloon = lotteryConfig.v2HideBalloon || false
  // do not set header color, default is undefined
  // lotteryConfig.colHeader = lotteryConfig.colHeader || COLOR_KADOW_BLUE;
  lotteryConfig.logoHeight = lotteryConfig.logoHeight || 80
}
