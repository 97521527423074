import gsap from 'gsap'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from 'react'
import './styles/Box.scss'
import { BOX_SVG_WIDTH, BOX_SVG_HEIGHT } from './utils/utils'
import { skinSelector } from '../../redux/selectors/lottery'
import { useSelector } from 'react-redux'
import {
  MYSTERY_BOXES_BODY,
  MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_1,
  MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_2,
  MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_3,
  MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_4,
  MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_5,
  MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_6,
  MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_7,
  MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_END,
  MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_START,
  MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_1,
  MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_2,
  MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_3,
  MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_4,
  MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_END,
  MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_START,
  MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_1,
  MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_2,
  MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_3,
  MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_4,
  MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_5,
  MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_6,
  MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_END,
  MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_START,
  MYSTERY_BOXES_BODY_INSIDE_COLOR_0,
  MYSTERY_BOXES_BODY_INSIDE_COLOR_1,
  MYSTERY_BOXES_BODY_INSIDE_COLOR_2,
  MYSTERY_BOXES_BODY_INSIDE_COLOR_3,
  MYSTERY_BOXES_COVER,
  MYSTERY_BOXES_COVER_RIBBON,
  MYSTERY_BOXES_GRADIENT_COLOR_1,
  MYSTERY_BOXES_GRADIENT_LEFT_KNOT_LEFT_COLOR_1_START,
  MYSTERY_BOXES_GRADIENT_LEFT_KNOT_LEFT_COLOR_2,
  MYSTERY_BOXES_GRADIENT_LEFT_KNOT_LEFT_COLOR_3,
  MYSTERY_BOXES_GRADIENT_LEFT_KNOT_LEFT_COLOR_END,
  MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_2,
  MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_3,
  MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_4,
  MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_5,
  MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_6,
  MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_7,
  MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_8,
  MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_9,
  MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_END,
  MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_START,
  MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_LEFT_COLOR_2,
  MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_LEFT_COLOR_3,
  MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_LEFT_COLOR_4,
  MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_LEFT_COLOR_END,
  MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_LEFT_COLOR_START,
  MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_2,
  MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_3,
  MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_4,
  MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_5,
  MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_END,
  MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_START,
  MYSTERY_BOXES_MOVING_LID_COLOR_1,
  MYSTERY_BOXES_MOVING_LID_COLOR_2,
  MYSTERY_BOXES_MOVING_LID_COLOR_3,
  MYSTERY_BOXES_MOVING_LID_COLOR_END,
  MYSTERY_BOXES_MOVING_LID_COLOR_START,
  MYSTERY_BOXES_RIBBON,
} from './utils/default_colors'
import { BoxHandle } from './utils/BoxHandle'
import { shuffleMap } from './utils/ShuffleMap'

interface Props {
  index: number
  timeline: gsap.core.Timeline
  onClick?: () => void
  id: string
  boxWidth: number
  boxHeight: number
  content: string
}

const duration = 1.1

const Box = forwardRef<BoxHandle, Props>(
  (
    { index, timeline, onClick, id, boxHeight, boxWidth, content }: Props,
    ref
  ): JSX.Element => {
    const lightRef = useRef<SVGAElement>(null)
    const coverClosedRef = useRef<SVGAElement>(null)
    const coverOpenRef = useRef<SVGAElement>(null)
    const boxRef = useRef<HTMLDivElement>(null)
    const giftRef = useRef<SVGAElement>(null)
    const imageRef = useRef<SVGImageElement>(null)
    const skin = useSelector(skinSelector)

    useLayoutEffect(() => {
      gsap.set(lightRef.current, {
        scale: 0,
        transformOrigin: 'bottom center',
      })
    }, [])

    useEffect(() => {
      if (!content) {
        return
      }
      imageRef.current?.setAttribute('href', content)
    }, [content, imageRef])

    useImperativeHandle(
      ref,
      () => {
        const api = {
          open({ content, timelineLabel, toCenter = false, light = false }) {
            imageRef.current?.setAttribute('href', content)
            // change z-index
            timeline.set(
              boxRef.current,
              { zIndex: 3 },
              timelineLabel ?? 'default'
            )

            // move to the center
            toCenter &&
              timeline.to(
                boxRef.current,
                {
                  ...shuffleMap[index],
                  scale: 1.5,
                  duration,
                },
                '<'
              )

            timeline.set(coverClosedRef.current, { opacity: 0 }, '<')
            // open the box
            timeline.fromTo(
              coverOpenRef.current,
              {
                y: 0,
                transformOrigin: 'top center',
              },
              {
                y: -100,
                x: 70,
                scale: 1.1,
                rotation: 60,
                transformOrigin: 'center right',
              },
              '<'
            )

            // show the light
            light &&
              timeline.fromTo(
                lightRef.current,
                {
                  y: 0,
                },
                {
                  y: 0,
                  scale: 1,
                },
                '<30%'
              )

            // show gift
            timeline.set(
              giftRef.current,
              {
                transformOrigin: 'bottom center',
                zIndex: 7,
              },
              '<'
            )

            // hack to center image
            imageRef.current &&
              timeline.set(
                imageRef.current,
                {
                  translateX: '-3px',
                  translateY: '-7px',
                },
                '<'
              )

            timeline.to(
              giftRef.current,
              {
                translateY: '-100px',
                scale: 5,
              },
              '<'
            )
          },
          close() {
            imageRef.current?.setAttribute('href', '')
          },
          moveCenter() {
            // to center
            timeline.to(
              boxRef.current,
              {
                ...shuffleMap[index],
                duration,
              },
              '<10%'
            )
          },
          moveBack() {
            timeline.to(
              boxRef.current,
              {
                translateX: '0%',
                translateY: '0%',
                duration,
              },
              '<10%'
            )
          },
        } as BoxHandle

        return api
      },
      [index, timeline]
    )

    function renderKnot() {
      return (
        <g id="knot">
          {/**
           *
           * Right knot
           *
           **/}
          <g id="group 437">
            <path
              id="path 505"
              d="M250.914 215.26C243.697 229.031 232.086 239.997 217.926 246.417C218.613 246.417 233.732 249.853 233.732 249.853C233.732 249.853 264.432 235.878 270.158 213.427C270.16 213.434 256.641 209.083 250.914 215.26Z"
              fill="url(#right_knot_right_gradient)"
            />
            <g id="group 436">
              <path
                id="path 508"
                d="M206.46 245.275C206.46 244.627 216.35 193.667 252.257 196.083C253.733 196.199 255.167 196.625 256.466 197.332C257.766 198.038 258.903 199.011 259.802 200.186L270.147 213.434C270.147 213.434 231.659 210.685 217.915 246.424L206.46 245.275Z"
                fill="url(#right_knot_left_gradient)"
              />
            </g>
          </g>
          {/**
           *
           * Left knot
           *
           **/}
          <g id="group 439">
            <path
              id="path 509"
              d="M181.55 215.26C188.121 228.781 198.993 239.739 212.461 246.417C211.813 246.417 197.65 249.853 197.65 249.853C197.65 249.853 168.883 235.878 163.517 213.427C163.517 213.434 176.182 209.083 181.55 215.26Z"
              fill="url(#left_knot_left_gradient)"
            />
            <g id="group 438">
              <path
                id="path 511"
                d="M223.195 245.275C223.195 244.627 213.929 193.667 180.282 196.083C178.877 196.213 177.516 196.647 176.295 197.356C175.073 198.064 174.021 199.03 173.211 200.186L163.517 213.434C163.517 213.434 199.578 210.685 212.461 246.424L223.195 245.275Z"
                fill="url(#left_knot_right_gradient)"
              />
            </g>
          </g>
        </g>
      )
    }

    function renderCoverBody() {
      return (
        <g id="cover-closed" ref={coverClosedRef}>
          <g id="lid">
            <path
              id="rect 214"
              d="M329 243.477H101V298.082H329V243.477Z"
              fill={
                skin?.mysteryboxes?.MYSTERY_BOXES_COVER || MYSTERY_BOXES_COVER
              }
            />
            <path
              id="rect 215"
              d="M189.545 243.481V298.277H240.092V243.481H189.545Z"
              fill={
                skin?.mysteryboxes?.MYSTERY_BOXES_COVER_RIBBON ||
                MYSTERY_BOXES_COVER_RIBBON
              }
            />
          </g>
        </g>
      )
    }

    function renderMovingLid() {
      return (
        <g id="group 363">
          <g id="group 362">
            <path
              id="rect 176"
              d="M117.448 294.574H313.108V270.967H117.448V294.574Z"
              fill={
                skin?.mysteryboxes?.MYSTERY_BOXES_MOVING_LID_COLOR_START ||
                MYSTERY_BOXES_MOVING_LID_COLOR_START
              }
            />
            <path
              id="path  418"
              d="M119.131 293.914L119.543 248.275L107.576 275.919L105.781 276.869L119.131 293.914Z"
              fill={
                skin?.mysteryboxes?.MYSTERY_BOXES_MOVING_LID_COLOR_1 ||
                MYSTERY_BOXES_MOVING_LID_COLOR_1
              }
            />
            <path
              id="path 419"
              d="M310.715 295.506V263.494L325.374 253.554L326.272 275.316L310.715 295.506Z"
              fill={
                skin?.mysteryboxes?.MYSTERY_BOXES_MOVING_LID_COLOR_2 ||
                MYSTERY_BOXES_MOVING_LID_COLOR_2
              }
            />
            <g id="group 360">
              <g id="path 420">
                <path
                  id="Vector_2"
                  d="M109.163 277.97L119.469 293.571H310.865L321.171 277.97H109.163ZM101.667 274.103H328.667L313.252 297.438H117.082L101.667 274.103Z"
                  fill={
                    skin?.mysteryboxes?.MYSTERY_BOXES_MOVING_LID_COLOR_3 ||
                    MYSTERY_BOXES_MOVING_LID_COLOR_3
                  }
                />
              </g>
            </g>
            <g id="group 361">
              <path
                id="path 421"
                d="M101.667 274.103L102.589 244.449H327.745L328.667 274.103H101.667Z"
                fill={
                  skin?.mysteryboxes?.MYSTERY_BOXES_MOVING_LID_COLOR_END ||
                  MYSTERY_BOXES_MOVING_LID_COLOR_END
                }
              />
            </g>
          </g>
          <path
            id="rect 177"
            d="M234.344 274.304H196.117V277.625H234.344V274.304Z"
            fill={
              skin?.mysteryboxes?.MYSTERY_BOXES_GRADIENT_COLOR_1 ||
              MYSTERY_BOXES_GRADIENT_COLOR_1
            }
          />
        </g>
      )
    }

    return (
      <div
        //key={`box-${index}`}
        id={id}
        className="box-root"
        onClick={onClick}
        ref={boxRef}
      >
        <div
          className="box"
          style={{
            height: boxHeight,
            width: boxWidth,
          }}
        >
          <svg
            viewBox={`0 0 ${BOX_SVG_WIDTH} ${BOX_SVG_HEIGHT}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="boxTwoLids">
              <g id="box-bottom">
                {/**
                 *
                 * The bottom box' top
                 *
                 **/}
                <path
                  id="rect 173"
                  d="M309.224 254.369H121.734V279.111H309.224V254.369Z"
                  fill={
                    skin?.mysteryboxes?.MYSTERY_BOXES_BODY_INSIDE_COLOR_0 ||
                    MYSTERY_BOXES_BODY_INSIDE_COLOR_0
                  }
                />
                <path
                  id="Vector_2_2"
                  d=" M 121.597 251.367 H 309.403 L 307.289 255.42 H 124 Z"
                  fill={
                    skin?.mysteryboxes?.MYSTERY_BOXES_BODY_INSIDE_COLOR_1 ||
                    MYSTERY_BOXES_BODY_INSIDE_COLOR_1
                  }
                />
                <path
                  id="path 409"
                  d="M307.612 255.064L307.218 408.357L318.685 379.383L320.405 272.928L307.612 255.064Z"
                  fill={
                    skin?.mysteryboxes?.MYSTERY_BOXES_BODY_INSIDE_COLOR_2 ||
                    MYSTERY_BOXES_BODY_INSIDE_COLOR_2
                  }
                />
                <path
                  id="path 410"
                  d="M124.028 253.392V392.402L109.981 402.82L109.121 274.554L124.028 253.392Z"
                  fill={
                    skin?.mysteryboxes?.MYSTERY_BOXES_BODY_INSIDE_COLOR_3 ||
                    MYSTERY_BOXES_BODY_INSIDE_COLOR_3
                  }
                />
                {/**
                 *
                 * Seems useless
                 *
                 **/}
                <g id="box-light" ref={lightRef}>
                  <path
                    id="path 415"
                    opacity="0.59"
                    d="M215.042 428.796L429.188 1.539L0.107056 0.729004L215.042 428.796L429.977 0.728027H0.107056L215.042 428.796Z"
                    fill="url(#paint0_linear_11_141)"
                  />
                  <path
                    id="path 416"
                    style={{ mixBlendMode: 'overlay' }}
                    opacity="0.59"
                    d="M215.042 428.796L307.468 0.728027H122.616L215.042 428.796L307.468 0.728027H122.616L215.042 428.796Z"
                    fill="url(#paint1_linear_11_141)"
                  />
                </g>
                <g ref={giftRef} textAnchor="middle">
                  <g textAnchor="middle">
                    <image
                      ref={imageRef}
                      x="50%"
                      y="80%"
                      className="imgGift"
                      href={content}
                    />
                  </g>
                </g>
                {/**
                 *
                 * Bottom body of the box
                 *
                 **/}
                <path
                  id="path 412"
                  d="M324.347 275.824L323.464 429.514H107.708L106.825 275.824H324.347Z"
                  fill={
                    skin?.mysteryboxes?.MYSTERY_BOXES_BODY || MYSTERY_BOXES_BODY
                  }
                />
                {/**
                 *
                 * Gradient of the bottom body
                 *
                 **/}
                <path
                  id="path 413"
                  opacity="0.44"
                  d="M107.407 374.993C107.407 374.993 107.448 374.96 108.853 374.993C128.43 375.45 261.845 383.648 324.347 298.04L323.438 429.414H107.986"
                  fill="url(#paint2_linear_11_141)"
                />
                {/**
                 *
                 * Ribbon
                 *
                 **/}
                <path
                  id="rect 174"
                  d="M195.032 275.799V429.414H236.141V275.799H195.032Z"
                  fill={
                    skin?.mysteryboxes?.MYSTERY_BOXES_RIBBON ||
                    MYSTERY_BOXES_RIBBON
                  }
                />
                <path
                  id="Vector_2_1"
                  d="M 317.164 271.771 M 324.347 275.824 H 106.826 L 121.597 251.367 L 124 255.42 L 114 271.771 H 317.164 L 307.289 255.42 L 309.403 251.367 L 324.347 275.824 Z"
                  fill={
                    skin?.mysteryboxes?.MYSTERY_BOXES_RIBBON ||
                    MYSTERY_BOXES_RIBBON
                  }
                />
                <path
                  id="rect 175"
                  d="M236.05 271.37H194.987V275.67H236.05V271.37Z"
                  fill={
                    skin?.mysteryboxes?.MYSTERY_BOXES_RIBBON ||
                    MYSTERY_BOXES_RIBBON
                  }
                />
              </g>
              <g id="cover-open" ref={coverOpenRef}>
                {renderKnot()}
                {renderMovingLid()}
                <path
                  id="rect 179"
                  d="M191.536 244.068V274.196H237.452V244.068H191.536Z"
                  fill={
                    skin?.mysteryboxes?.MYSTERY_BOXES_COVER_RIBBON ||
                    MYSTERY_BOXES_COVER_RIBBON
                  }
                />
              </g>
              {renderCoverBody()}
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_11_141"
                x1="215.042"
                y1="428.796"
                x2="215.042"
                y2="0.728027"
                gradientUnits="userSpaceOnUse"
              >
                <stop
                  offset="0.331"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_START ||
                    MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_START
                  }
                />
                <stop
                  offset="0.472"
                  stopColor={
                    skin?.mysteryboxes?.MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_1 ||
                    MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_1
                  }
                  stopOpacity="0.753"
                />
                <stop
                  offset="0.664"
                  stopColor={
                    skin?.mysteryboxes?.MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_2 ||
                    MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_2
                  }
                  stopOpacity="0.431"
                />
                <stop
                  offset="0.823"
                  stopColor={
                    skin?.mysteryboxes?.MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_3 ||
                    MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_3
                  }
                  stopOpacity="0.2"
                />
                <stop
                  offset="0.939"
                  stopColor={
                    skin?.mysteryboxes?.MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_4 ||
                    MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_4
                  }
                  stopOpacity="0.055"
                />
                <stop
                  offset="1"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_END ||
                    MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_END
                  }
                  stopOpacity="0"
                />
              </linearGradient>
              <linearGradient
                id="paint1_linear_11_141"
                x1="215.042"
                y1="428.796"
                x2="215.042"
                y2="0.728027"
                gradientUnits="userSpaceOnUse"
              >
                <stop
                  offset="0.458"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_START ||
                    MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_START
                  }
                />
                <stop
                  offset="0.505"
                  stopColor={
                    skin?.mysteryboxes?.MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_1 ||
                    MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_1
                  }
                  stopOpacity="0.969"
                />
                <stop
                  offset="0.576"
                  stopColor={
                    skin?.mysteryboxes?.MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_2 ||
                    MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_2
                  }
                  stopOpacity="0.882"
                />
                <stop
                  offset="0.664"
                  stopColor={
                    skin?.mysteryboxes?.MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_3 ||
                    MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_3
                  }
                  stopOpacity="0.745"
                />
                <stop
                  offset="0.765"
                  stopColor={
                    skin?.mysteryboxes?.MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_4 ||
                    MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_4
                  }
                  stopOpacity="0.553"
                />
                <stop
                  offset="0.877"
                  stopColor={
                    skin?.mysteryboxes?.MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_5 ||
                    MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_5
                  }
                  stopOpacity="0.306"
                />
                <stop
                  offset="0.997"
                  stopColor={
                    skin?.mysteryboxes?.MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_6 ||
                    MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_6
                  }
                  stopOpacity="0.01"
                />
                <stop
                  offset="1"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_END ||
                    MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_END
                  }
                  stopOpacity="0"
                />
              </linearGradient>
              <linearGradient
                id="paint2_linear_11_141"
                x1="104.544"
                y1="363.727"
                x2="324.347"
                y2="363.727"
                gradientUnits="userSpaceOnUse"
              >
                <stop
                  offset="0.019"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_START ||
                    MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_START
                  }
                />
                <stop
                  offset="0.254"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_1 ||
                    MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_1
                  }
                  stopOpacity="0.984"
                />
                <stop
                  offset="0.408"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_2 ||
                    MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_2
                  }
                  stopOpacity="0.925"
                />
                <stop
                  offset="0.539"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_3 ||
                    MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_3
                  }
                />
                <stop
                  offset="0.658"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_4 ||
                    MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_4
                  }
                />
                <stop
                  offset="0.767"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_5 ||
                    MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_5
                  }
                />
                <stop
                  offset="0.87"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_6 ||
                    MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_6
                  }
                />
                <stop
                  offset="0.967"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_7 ||
                    MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_7
                  }
                />
                <stop
                  offset="0.989"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_END ||
                    MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_END
                  }
                />
              </linearGradient>
              <linearGradient
                id="right_knot_right_gradient"
                x1="217.926"
                y1="230.899"
                x2="270.158"
                y2="230.899"
                gradientUnits="userSpaceOnUse"
              >
                <stop
                  offset="0.021"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_START ||
                    MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_START
                  }
                />
                <stop
                  offset="0.101"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_2 ||
                    MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_2
                  }
                />
                <stop
                  offset="0.229"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_3 ||
                    MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_3
                  }
                />
                <stop
                  offset="0.295"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_4 ||
                    MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_4
                  }
                />
                <stop
                  offset="0.37"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_5 ||
                    MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_5
                  }
                />
                <stop
                  offset="0.604"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_END ||
                    MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_END
                  }
                />
              </linearGradient>
              <linearGradient
                id="right_knot_left_gradient"
                x1="201.811"
                y1="237.196"
                x2="252.787"
                y2="190.207"
                gradientUnits="userSpaceOnUse"
              >
                <stop
                  offset="0.179"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_LEFT_COLOR_START ||
                    MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_LEFT_COLOR_START
                  }
                />
                <stop
                  offset="0.418"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_LEFT_COLOR_2 ||
                    MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_LEFT_COLOR_2
                  }
                />
                <stop
                  offset="0.505"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_LEFT_COLOR_3 ||
                    MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_LEFT_COLOR_3
                  }
                />
                <stop
                  offset="0.688"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_LEFT_COLOR_4 ||
                    MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_LEFT_COLOR_4
                  }
                />
                <stop
                  offset="1"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_LEFT_COLOR_END ||
                    MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_LEFT_COLOR_END
                  }
                />
              </linearGradient>
              <linearGradient
                id="left_knot_left_gradient"
                x1="-17060.7"
                y1="230.899"
                x2="-17008.5"
                y2="230.899"
                gradientUnits="userSpaceOnUse"
              >
                <stop
                  offset="0.021"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_LEFT_KNOT_LEFT_COLOR_1_START ||
                    MYSTERY_BOXES_GRADIENT_LEFT_KNOT_LEFT_COLOR_1_START
                  }
                />
                <stop
                  offset="0.13"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_LEFT_KNOT_LEFT_COLOR_2 ||
                    MYSTERY_BOXES_GRADIENT_LEFT_KNOT_LEFT_COLOR_2
                  }
                />
                <stop
                  offset="0.239"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_LEFT_KNOT_LEFT_COLOR_3 ||
                    MYSTERY_BOXES_GRADIENT_LEFT_KNOT_LEFT_COLOR_3
                  }
                />
                <stop
                  offset="0.295"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_LEFT_KNOT_LEFT_COLOR_END ||
                    MYSTERY_BOXES_GRADIENT_LEFT_KNOT_LEFT_COLOR_END
                  }
                />
                <stop
                  offset="0.604"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_LEFT_KNOT_LEFT_COLOR_END ||
                    MYSTERY_BOXES_GRADIENT_LEFT_KNOT_LEFT_COLOR_END
                  }
                />
              </linearGradient>
              <linearGradient
                id="left_knot_right_gradient"
                x1="-17014.4"
                y1="202.505"
                x2="-17070.4"
                y2="245.005"
                gradientUnits="userSpaceOnUse"
              >
                <stop
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_END ||
                    MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_END
                  }
                />
                <stop
                  offset="0.01"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_START ||
                    MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_START
                  }
                />
                <stop
                  offset="0.133"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_2 ||
                    MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_2
                  }
                />
                <stop
                  offset="0.256"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_3 ||
                    MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_3
                  }
                />
                <stop
                  offset="0.38"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_4 ||
                    MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_4
                  }
                />
                <stop
                  offset="0.505"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_5 ||
                    MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_5
                  }
                />
                <stop
                  offset="0.78"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_6 ||
                    MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_6
                  }
                />
                <stop
                  offset="0.846"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_7 ||
                    MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_7
                  }
                />
                <stop
                  offset="0.903"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_8 ||
                    MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_8
                  }
                />
                <stop
                  offset="0.981"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_9 ||
                    MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_9
                  }
                />
                <stop
                  offset="1"
                  stopColor={
                    skin?.mysteryboxes
                      ?.MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_END ||
                    MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_END
                  }
                />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="box-shadow"></div>
      </div>
    )
  }
)

Box.displayName = 'Box'

export default Box
