import React, { FunctionComponent } from 'react'
import GenericGameStep from '../../components/GenericGameStep'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import GenderAndAgeModalContent from './GenderAndAgeModalContent'

interface Props {}

const GenderAndAge: FunctionComponent<Props> = () => {
  return (
    <GenericGameStep
      stepType={LotteryV2StepType.Poll}
      renderModalBody={() => {
        return <GenderAndAgeModalContent />
      }}
    />
  )
}

export default GenderAndAge
