import React, { FC } from 'react'
import TSConstants from '../../../utils/TSConstants'
import './Logo.scss'

interface LogoProps {
  hasNotch?: boolean
  notchBackground?: string
  logoSrc?: string
  logoHeight?: number | string
  useWidth?: boolean
}

const Logo: FC<LogoProps> = ({
  hasNotch,
  notchBackground,
  logoSrc,
  logoHeight,
  useWidth,
}) => {
  if (hasNotch) {
    return (
      <div id="logo-notch-container">
        <div
          id="logo-notch"
          style={{
            backgroundColor: notchBackground,
          }}
        >
          <img
            id="notch-logo"
            className="twic"
            src={TSConstants.PLACEHOLDER_URL}
            data-src={logoSrc}
            data-src-transform="contain"
          />
        </div>
      </div>
    )
  }

  let style: any
  if (useWidth) {
    style = {
      width: '50%',
      height: 'auto',
    }
  } else {
    style = {
      height: logoHeight,
      width: 'auto',
    }
  }

  return (
    <img
      className="twic"
      src={TSConstants.PLACEHOLDER_URL}
      data-src={logoSrc}
      style={style}
    />
  )
}

export default Logo
