import WheelColorsI from '../WheelColors'
import React from 'react'
import './nature.scss'
import aspectRatio, { AspectRatio } from '../../../utils/aspect_ratio'
import WallaceFountain from '../SVG_objects/otcp/WallaceFountain'
import Flowers from '../SVG_objects/otcp/Flowers'
import TSConstants from '../../../utils/TSConstants'

const aspectRatio1 = aspectRatio()

export function buildOTCPSkin(colors: WheelColorsI) {
  return {
    renderBackground: (width: number, height: number) => {
      let fountainHeight = '85%'
      let fountainRight = '-20%'
      let fountainBottom = '30%'

      switch (aspectRatio1) {
        case AspectRatio._16_9:
          break
        case AspectRatio._19_9:
        case AspectRatio._18_9:
          fountainHeight = '75%'
          fountainRight = '-20%'
          fountainBottom = '25%'
          break
      }

      return (
        <div style={{ width: '100%', height }}>
          <WallaceFountain
            colors={colors}
            height={fountainHeight}
            right={fountainRight}
            bottom={fountainBottom}
          />
        </div>
      )
    },
    renderForeground: (width: number, height: number) => {
      let bikeHeight = '30%'
      let bikeBottom = '10%'
      let bikeRight = '-15%'

      let flowersTop = '4%'
      let flowersWidth = '70%'
      let flowersLeft = '-20%'

      switch (aspectRatio1) {
        case AspectRatio._16_9:
          break
        case AspectRatio._19_9:
        case AspectRatio._18_9:
          bikeHeight = '30%'
          bikeBottom = '10%'
          bikeRight = '-25%'
          flowersTop = '4%'
          flowersWidth = '80%'
          flowersLeft = '-15%'
          break
      }

      return (
        <div style={{ width: '100%', height, overflow: 'hidden' }}>
          <img
            className="twic"
            src={TSConstants.PLACEHOLDER_URL}
            data-src={`image:/cdm/img/otcp/bike_2.png`}
            style={{
              position: 'absolute',
              height: bikeHeight,
              bottom: bikeBottom,
              right: bikeRight,
            }}
          />
          <Flowers
            colors={colors}
            top={flowersTop}
            width={flowersWidth}
            left={flowersLeft}
          />
        </div>
      )
    },
    renderCTAForeground: () => {
      return null
    },
  }
}
