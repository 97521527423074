export const gameInitialState = {
  gameCreated: false,
  answered: false,
  answeredIndex: -1,
  animatePostAnswer: false,
  isDisplayAnswers: true,
  hasTimedOut: false,
}

type ACTIONTYPE =
  | { type: 'game_start' }
  | { type: 'game_stop' }
  | { type: 'question_start' }
  | { type: 'question_hide' }
  | { type: 'question_answer'; payload: number }
  | { type: 'question_answer_no_payload' }
  | { type: 'question_next' }
  | { type: 'question_timeout' }

export const quizReducer = (
  state: typeof gameInitialState,
  action: ACTIONTYPE
) => {
  switch (action.type) {
    case 'game_start':
      return { ...state, gameCreated: true }
    case 'game_stop':
      return { ...state }
    case 'question_start':
      return {
        ...state,
        answered: false,
        answeredIndex: -1,
        isDisplayAnswers: true,
        animatePostAnswer: false,
      }
    case 'question_answer':
      return {
        ...state,
        answered: true,
        answeredIndex: action.payload,
        animatePostAnswer: true,
      }
    case 'question_answer_no_payload':
      return {
        ...state,
        answered: true,
        animatePostAnswer: true,
      }
    case 'question_next':
      return {
        ...state,
        answered: false,
        animatePostAnswer: false,
        hasTimedOut: false,
      }
    case 'question_timeout':
      return {
        ...state,
        answered: true,
        animatePostAnswer: true,
        answeredIndex: -1,
        hasTimedOut: true,
      }
    case 'question_hide':
      return {
        ...state,
        isDisplayAnswers: false,
      }
    default:
      return state
  }
}
