/**
 * This box template can be modified by generating gradients with N offsets between the start and end colors.
 * Site: https://colordesigner.io/gradient-generator
 *
 */
// region default colors
export const MYSTERY_BOXES_BODY_INSIDE_COLOR_0 = '#C2C1C1'
export const MYSTERY_BOXES_BODY_INSIDE_COLOR_1 = 'white'
export const MYSTERY_BOXES_BODY_INSIDE_COLOR_2 = '#888787'
export const MYSTERY_BOXES_BODY_INSIDE_COLOR_3 = '#888787'
export const MYSTERY_BOXES_BODY = '#DEDEDE'
export const MYSTERY_BOXES_BODY_INSIDE_COLOR_5 = 'white'
export const MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_START = 'white'
export const MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_1 = '#FEFEFE'
export const MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_2 = '#FCFCFC'
export const MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_3 = '#FAFAFA'
export const MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_4 = '#F6F6F6'
export const MYSTERY_BOXES_BODY_GRADIENT_0_COLOR_END = '#F1F1F2'
export const MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_START = 'white'
export const MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_1 = '#FEFEFE'
export const MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_2 = '#FCFCFC'
export const MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_3 = '#FAFAFA'
export const MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_4 = '#F6F6F6'
export const MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_5 = '#F1F1F2'
export const MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_6 = '#EDEDED'
export const MYSTERY_BOXES_BODY_GRADIENT_1_COLOR_END = '#ECECED'
export const MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_START = '#9E9E9E'
export const MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_1 = '#9E9E9E'
export const MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_2 = '#9F9F9F'
export const MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_3 = '#A0A0A0'
export const MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_4 = '#A2A2A2'
export const MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_5 = '#A5A5A5'
export const MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_6 = '#A8A8A8'
export const MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_7 = '#ACACAC'
export const MYSTERY_BOXES_BODY_BOTTOM_BOX_GRADIENT_COLOR_END = '#ADADAD'
export const MYSTERY_BOXES_GRADIENT_COLOR_1 = '#981011'
export const MYSTERY_BOXES_GRADIENT_COLOR_2 = '#D8121D'

export const MYSTERY_BOXES_RIBBON = '#D8121D'
export const MYSTERY_BOXES_COVER = '#BBBABA'
export const MYSTERY_BOXES_COVER_RIBBON = '#D8121D'
export const MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_START = '#C76D29'
export const MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_2 = '#AE5027'
export const MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_3 = '#8A2726'
export const MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_4 = '#7D1826'
export const MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_5 = '#75141E'
export const MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_RIGHT_COLOR_END = '#600C0B'
export const MYSTERY_BOXES_GRADIENT_LEFT_KNOT_LEFT_COLOR_1_START = '#C76D29'
export const MYSTERY_BOXES_GRADIENT_LEFT_KNOT_LEFT_COLOR_2 = '#98411B'
export const MYSTERY_BOXES_GRADIENT_LEFT_KNOT_LEFT_COLOR_3 = '#6F1A0F'
export const MYSTERY_BOXES_GRADIENT_LEFT_KNOT_LEFT_COLOR_END = '#600C0B'
export const MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_START = '#D7060F'
export const MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_2 = '#BB0A0F'
export const MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_3 = '#A70D10'
export const MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_4 = '#9B0F10'
export const MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_5 = '#981011'
export const MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_6 = '#821621'
export const MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_7 = '#7D1826'
export const MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_8 = '#761520'
export const MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_9 = '#650E10'
export const MYSTERY_BOXES_GRADIENT_LEFT_KNOT_RIGHT_COLOR_END = '#600C0B'
export const MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_LEFT_COLOR_START = '#7D1826'
export const MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_LEFT_COLOR_2 = '#8F1217'
export const MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_LEFT_COLOR_3 = '#981011'
export const MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_LEFT_COLOR_4 = '#AD0C10'
export const MYSTERY_BOXES_GRADIENT_RIGHT_KNOT_LEFT_COLOR_END = '#DA060F'

export const MYSTERY_BOXES_MOVING_LID_COLOR_START = '#C2C1C1'
export const MYSTERY_BOXES_MOVING_LID_COLOR_1 = '#888787'
export const MYSTERY_BOXES_MOVING_LID_COLOR_2 = '#888787'
export const MYSTERY_BOXES_MOVING_LID_COLOR_3 = '#white'
export const MYSTERY_BOXES_MOVING_LID_COLOR_END = '#DEDEDE'
