import * as React from 'react'
import { FunctionComponent } from 'react'

export enum GarlandColor {
  Blue,
  Yellow,
  Red,
}

interface Props {
  color: GarlandColor
  zIndex?: number
  bottomPc?: number
  rightPc?: number
  leftPc?: number

  top?: number
  left?: number
  right?: number
  bottom?: number
}

const _Garland: FunctionComponent<Props> = (props) => {
  let div1ClassName = ''
  let div2ClassName = ''

  switch (props.color) {
    case GarlandColor.Blue:
      div1ClassName = 'sc-light-blue'
      div2ClassName = 'sc-light-anim-blue'
      break
    case GarlandColor.Red:
      div1ClassName = 'sc-light-red'
      div2ClassName = 'sc-light-anim-red'
      break
    case GarlandColor.Yellow:
      div1ClassName = 'sc-light-yellow'
      div2ClassName = 'sc-light-anim-yellow'
      break
  }

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: props.zIndex,
        top: props.top,
        bottom: props.bottomPc ? `${props.bottomPc}%` : props.bottom,
        right: props.rightPc ? `${props.rightPc}%` : props.right,
        left: props.leftPc ? `${props.leftPc}%` : props.left,
      }}
    >
      <div className={`sc-garland ${div1ClassName}`} />
      <div className={`sc-garland ${div2ClassName}`} />
    </div>
  )
}

const Garland = _Garland

export { Garland }
