import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { async_redeem } from '../../redux/actions/lottery'
import { TSUtils } from '../../utils/TSUtils'
import { tt } from '../../i18n'
import WheelButton from '../../components/cta/WheelButton'
import { lotteryGameConsumeSelector } from '../../redux/selectors/lottery'
import { configSelector } from '../../redux/selectors/config'

interface Props {
  gameId: string
}

const RedeemByQRCode: FunctionComponent<Props> = () => {
  const dispatch = useDispatch()

  const game = useSelector(lotteryGameConsumeSelector)
  const siteConfig = useSelector(configSelector)

  const redeem = () => {
    if (!game?.id) return
    dispatch(async_redeem(siteConfig, game.id, undefined, true))
  }

  if (!game) {
    return null
  }

  const isRedeemed = TSUtils.gameIsRedeemed(game)
  const prizeLabel = TSUtils.getGamePrizeLabel(game)

  if (isRedeemed) {
    return (
      <>
        <h2 className="h2 w-margin-bottom cm-pad w-margin-top-2-thirds">
          {tt('redeem_title_1')}
          <br />
          <span className="highlight">{prizeLabel}</span>
        </h2>
        <p className="cm-pad">
          {tt('redeem_by_qr_code_consumed', {
            prizeLabel: prizeLabel,
            date: TSUtils.formatTimestampAsDate(game.redeemedByOwnerTs),
          })}
        </p>
      </>
    )
  }

  return (
    <>
      <h2 className="h2 w-margin-bottom cm-pad w-margin-top-2-thirds">
        {tt('redeem_title_1')}
        <br />
        <span className="highlight">{prizeLabel}</span>
      </h2>
      <p className="cm-pad w-margin-bottom">{tt('gift_not_redeemed')}</p>
      {!!game?.conditions && (
        <div
          className={`w-margin-bottom cm-pad`}
          style={{ whiteSpace: 'pre-wrap' }}
        >
          <b className="d-block">
            <u>{tt('redeem_conditions')}</u>
          </b>
          <p>{game?.conditions}</p>
        </div>
      )}
      <p className="cm-pad w-margin-bottom">
        {tt('redeem_by_qr_code_not_staff', {
          placeName: game.placeName,
        })}
      </p>
      <p className="cm-pad w-margin-bottom">{tt('redeem_deactivate')}</p>
      <WheelButton
        text={tt('i_gave_this_gift')}
        isFixedBottom={true}
        onClick={redeem}
      />
    </>
  )
}

export default RedeemByQRCode
