import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import { tt } from '../../i18n'
import React, { FC } from 'react'
import { renderScratchCardTitle } from './renderScratchCardTitle'
import LotteryType from '@tootsweet/model/lottery/LotteryType'
import TitleV2 from '../../components/MonthlyLottery/Title/TitleV2'
import Skin from '../../model/Skin'

export const renderTitle = (
  isPlayStep?: boolean,
  isWheel?: boolean,
  config?: LotteryConfigurationResponse,
  skin?: Skin
) => {
  const res = getTitleAndSubTitle(config, skin)
  return (
    <>
      {!!res.title && (
        <div className="w-margin-bottom-half w-margin-top-half">
          <TitleV2 title1={res.title} title2={res.subtitle} />
        </div>
      )}
      {renderTitleInner(res, isPlayStep, config, skin)}
    </>
  )
}

const renderTitleInner = (
  res: TitleAndSubTitle,
  isPlayStep?: boolean,
  config?: LotteryConfigurationResponse,
  skin?: Skin
) => {
  if (isPlayStep) {
    return renderTitleForPlayStep(config, skin)
  }

  switch (config?.type) {
    case LotteryType.MysteryBoxes:
      return (
        <h2
          className="h2 cm-pad"
          style={{
            whiteSpace: 'nowrap',
            color: skin?.colors?.bodyFont,
          }}
        >
          <span>{tt('gift_hidden_in_box_1')}</span>
          <br />
          <span
            className="highlight"
            style={{
              color: skin?.colors?.bodyFont,
            }}
          >
            {tt('gift_hidden_in_box_2')}
          </span>
        </h2>
      )
    case LotteryType.ScratchCard:
      if (config?.scTitle) {
        return renderScratchCardTitle(config.scTitle)
      }
      let text1 = config?.sc1
        ? tt('scratch_to_discover_1_1_card')
        : tt('scratch_to_discover_1')
      let text2 = config?.sc1
        ? tt('scratch_to_discover_2_1_card')
        : tt('scratch_to_discover_2')

      return renderScratchCardTitle(
        <>
          <span>{text1}</span>
          <br />
          <span className="highlight">{text2}</span>
        </>
      )
  }

  return (
    <h2 className="h2 cm-pad" id="lot-p-4">
      <span>{tt('spin_to_discover_1')}</span>
      <br />
      <span className="highlight">{tt('spin_to_discover_2')}</span>
    </h2>
  )
}

function renderTitleForPlayStep(
  lotteryConfig?: LotteryConfigurationResponse,
  skin?: Skin
) {
  switch (lotteryConfig?.type) {
    case LotteryType.ScratchCard:
      return renderScratchCardTitle(
        lotteryConfig?.scTitle || (
          <>
            <span>{tt('its_all_good')}</span>
            <br />
            <span className="highlight">{tt('good_luck')}</span>
          </>
        )
      )
    case LotteryType.MysteryBoxes:
      return (
        <h2
          className="h2 cm-pad highlight"
          style={{
            whiteSpace: 'nowrap',
            color: skin?.colors?.bodyFont,
          }}
        >
          <span>{tt('choose_gift')}</span>
          <br />
          &nbsp;
        </h2>
      )
  }
  return (
    <h2 className="h2 cm-pad" style={{ flex: 1 }}>
      <span>{tt('its_all_good')}</span>
      <br />
      <span className="highlight">{tt('good_luck')}</span>
    </h2>
  )
}

interface TitleAndSubTitle {
  title: string
  subtitle: string
}

function getTitleAndSubTitle(
  config?: LotteryConfigurationResponse,
  skin?: Skin
): TitleAndSubTitle {
  let title = ''
  let subtitle = ''
  const has = skinHasAdditionalHeader(skin)
  if (!has) {
    return { title, subtitle }
  }
  if (!!skin?.scratchCard?.additionalHeader?.title) {
    // @ts-ignore
    title = tt(skin?.scratchCard?.additionalHeader?.title)
  } else if (!!skin?.mysteryboxes?.additionalHeader?.title) {
    // @ts-ignore
    title = tt(skin?.mysteryboxes?.additionalHeader?.title)
  }

  if (!!skin?.scratchCard?.additionalHeader?.subtitle) {
    // @ts-ignore
    subtitle = tt(skin?.scratchCard?.additionalHeader?.subtitle)
  } else if (!!skin?.mysteryboxes?.additionalHeader?.subtitle) {
    // @ts-ignore
    subtitle = tt(skin?.mysteryboxes?.additionalHeader?.subtitle)
  }
  return { title, subtitle }
}

function skinHasAdditionalHeader(skin?: Skin) {
  return (
    !!skin?.scratchCard?.additionalHeader?.title ||
    !!skin?.mysteryboxes?.additionalHeader?.title
  )
}
