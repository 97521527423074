import React, { useEffect, useRef, useState } from 'react'
import WheelColorsI, { COLOR_WHITE } from '../WheelColors'
import { renderStyles } from '../helpers'

export interface SVGComponentProps {
  colors: WheelColorsI
  width?: number | string
  height?: number | string
  top?: number | string
  left?: number | string
  bottom?: number | string
  right?: number | string
}

export type SVGBalloonProps = SVGComponentProps & {
  id: string
  animateCounter?: number
  color: string
  colorLight: string
}

export default function Balloon(props: SVGBalloonProps) {
  const [didAnimate, setDidAnimate] = useState(false)
  const [styles, setStyles] = useState('')

  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!props.animateCounter) {
      return
    }

    if (didAnimate) {
      return
    }

    if (!divRef || !divRef.current || !divRef.current?.animate) {
      return
    }

    setDidAnimate(true)

    // @ts-ignore
    const anim = divRef.current.animate(
      [
        { transform: 'translateY(0) rotateZ(0)' },
        { transform: `translateY(-600%) rotateZ(0)` },
      ],
      {
        duration: 6 * 1000,
        iterations: 1,
      }
    )

    anim.onfinish = () => {
      // @ts-ignore
      divRef.current.remove()
    }
  }, [props.animateCounter])

  useEffect(() => {
    const styles = `
        ${renderStyles()}
        .bl-fill-left-${props.id} { fill:${props.color} } 
        .bl-fill-right-${props.id} { fill:${props.colorLight} } 
        .bl-fill-thread-${props.id} { fill:none;stroke:${
      props.colorLight
    };stroke-linecap:round;stroke-linejoin:round;stroke-width:7.98608170546581px }
        .cs-path-1-${
          props.id
        } {mix-blend-mode:overlay;opacity:0.6000000000000001;fill:url(#bd5131d1-bc8f-4635-a6b5-5291c20eb867${
      props.id
    })}
        .cs-path-2-${
          props.id
        } {mix-blend-mode:overlay;opacity:0.6000000000000001;fill:url(#a4894548-6ae6-47a5-b07e-93e158fdd6b2${
      props.id
    })}
        `
    setStyles(styles)
  }, [props])

  return (
    <div
      id={props.id}
      ref={divRef}
      style={{
        width: props.width,
        height: props.height,
        position: 'absolute',
        top: props.top,
        left: props.left,
        right: props.right,
        bottom: props.bottom,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 334.28 594.07"
        style={{ height: '100%', width: '100%' }}
      >
        <defs>
          <linearGradient
            id={`bd5131d1-bc8f-4635-a6b5-5291c20eb867${props.id}`}
            x1="-108.6"
            y1="-309.44"
            x2="-30.56"
            y2="-247.21"
            gradientTransform="translate(208.45 357.57) rotate(-16.01)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor={COLOR_WHITE} />
            <stop offset="1" stopColor={COLOR_WHITE} stopOpacity="0.45" />
          </linearGradient>
          <linearGradient
            id={`a4894548-6ae6-47a5-b07e-93e158fdd6b2${props.id}`}
            x1="-184.69"
            y1="-214.04"
            x2="-106.64"
            y2="-151.8"
            xlinkHref={`#bd5131d1-bc8f-4635-a6b5-5291c20eb867${props.id}`}
          />
          <style>{styles}</style>
        </defs>
        <g className="cs-isolate">
          <g id="e452dae4-ab6e-4d10-8067-18c25e7391b3" data-name="Layer 2">
            <g id="e741a3cf-65f0-4bed-8582-4879ded9f68b" data-name="OBJETS_top">
              <path
                d="M240.32,421.11c21,39.21,16.79,62.25,9.44,97.51-10.15,48.64,19.12,68.42,46.36,72"
                className={`bl-fill-thread-${props.id}`}
              />
              <path
                d="M328.53,121.33a167.49,167.49,0,0,0-322,92.41c18.22,63.47,73.63,99.17,125.29,136.93,34.82,25.44,108.9,71.87,108.9,71.87s42.62-77.12,55.89-113.68C317.73,250.67,346.75,184.8,328.53,121.33Z"
                className={`bl-fill-left-${props.id}`}
              />
              <path
                d="M15.12,184.47c-7.47-64.62,31.82-129,91.51-155.38a16.8,16.8,0,0,1,22.52,9.12l.31.79a20.68,20.68,0,0,1-10.72,26.38A123.16,123.16,0,0,0,47.23,176,20.76,20.76,0,0,1,32.17,195.8h0A13.43,13.43,0,0,1,15.12,184.47Z"
                className={`cs-path-1-${props.id}`}
              />
              <path
                d="M27,227.14A145.17,145.17,0,0,0,42.09,253.8,13.3,13.3,0,0,0,63,254.88l.5-.58a13,13,0,0,0,1.33-15,140,140,0,0,1-9.78-20.94,14.82,14.82,0,0,0-15.57-9.52l-2,.23A13,13,0,0,0,27,227.14Z"
                className={`cs-path-2-${props.id}`}
              />
              <path
                d="M128.53,4.65c55-3.88,99.85,44.83,127.58,98.62C287.62,164.4,278.49,241.6,273.24,296c-2.55,26.35-32.51,126.5-32.51,126.5s42.62-77.12,55.89-113.68c21.11-58.19,50.13-124.06,31.91-187.53A167.48,167.48,0,0,0,128.53,4.65Z"
                className={`bl-fill-right-${props.id}`}
              />
              <path
                d="M229.59,408.41l-.4,24.08a3.46,3.46,0,0,0,6.51,1.67l4-7.5a3.46,3.46,0,0,1,4.86-1.33l7.09,4.34a3.45,3.45,0,0,0,4.69-4.83l-13.21-20.31a3.46,3.46,0,0,0-3.85-1.43l-7.14,2A3.44,3.44,0,0,0,229.59,408.41Z"
                className={`bl-fill-left-${props.id}`}
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}
