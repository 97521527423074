import GenericGameStep from '../../components/GenericGameStep'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WheelButton from '../../components/cta/WheelButton'
import WheelAnalyticsManager from '../../analytics/WheelAnalyticsManager'
import './styles.scss'
import axios from 'axios'
import { tt } from '../../i18n'
import OptinOptout from '../../components/optinoptout/OptinOptout'
import * as EmailValidator from 'email-validator'
import { async_lp_save } from '../../redux/actions/lottery'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import {
  loadingSelector,
  lotteryConfigSelector,
  lotteryStepSelector,
  prizeSelector,
  venueSelector,
  wheelLabelsSelector,
} from '../../redux/selectors/lottery'
import { configSelector } from '../../redux/selectors/config'

interface Props {}

// TODO: Change form to Formik

const LPSponsorship: React.FunctionComponent<Props> = () => {
  const createdScript = false
  const [firstName, setFirstName] = React.useState<string>('')
  const [email, setEmail] = React.useState<string>('')
  const [friendEmail, setFriendEmail] = React.useState<string>('')
  const [conjuredId, setConjuredId] = React.useState<string>('')
  const [hasEmailError, setHasEmailError] = React.useState<boolean>(false)
  const [hasFirstNameError, setHasFirstNameError] =
    React.useState<boolean>(false)
  const [hasFriendEmailError, setHasFriendEmailError] =
    React.useState<boolean>(false)

  const dispatch = useDispatch()

  const step = useSelector(lotteryStepSelector)
  const siteConfig = useSelector(configSelector)
  const venue = useSelector(venueSelector)
  const wheelLabels = useSelector(wheelLabelsSelector)
  const prize = useSelector(prizeSelector)
  const loading = useSelector(loadingSelector)
  const lotteryConfig = useSelector(lotteryConfigSelector)

  React.useEffect(() => {
    WheelAnalyticsManager.logEvent('lp_sponsorship')
    fetchConjuredId()
  })

  const fetchConjuredId = async () => {
    if (!createdScript && step && step.tag) {
      const res = await axios({
        method: 'get',
        url: `${siteConfig.BACKEND_URL}/lp/cnjrd`,
        params: {
          shop: step.lpShop,
          cmp: step.lpCmp,
        },
      })

      setConjuredId(res.data)
    }
  }

  const onValidateForm = async () => {
    WheelAnalyticsManager.logEvent('lpsponsorship_filled')

    if (!firstName) {
      setHasFirstNameError(true)
      return
    }

    if (!EmailValidator.validate(email)) {
      setHasEmailError(true)
      return
    }

    if (!EmailValidator.validate(friendEmail)) {
      setHasFriendEmailError(true)
      return
    }

    if (email === friendEmail) {
      alert(tt('emails_cannot_be_identical'))
      return
    }

    if (!step || !step.lpShop || !step.lpCmp) return

    dispatch(
      async_lp_save(
        siteConfig,
        firstName,
        email,
        conjuredId,
        step.lpShop,
        step.lpCmp,
        friendEmail
      )
    )
  }

  const handleFirstNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    setFirstName(event.currentTarget.value)
    removeErrorIfPossible()
  }

  const handleEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value)
    removeErrorIfPossible()
  }

  const handleFriendEmailChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    setFriendEmail(event.currentTarget.value)
    removeErrorIfPossible()
  }

  const removeErrorIfPossible = () => {
    if (firstName) {
      setHasFirstNameError(false)
    }

    if (friendEmail && EmailValidator.validate(friendEmail)) {
      setHasFriendEmailError(false)
    }

    if (email && EmailValidator.validate(email)) {
      setHasEmailError(false)
    }
  }

  if (!venue?.name) return null
  return (
    <GenericGameStep
      modalClass="w-modal-lp"
      stepType={LotteryV2StepType.LPSponsorship}
      renderModalBody={() => {
        let classNameEmail = ''
        let classNameFirstName = ''
        let classNameFriendEmail = ''

        if (hasEmailError) {
          classNameEmail = 'lot-input-red'
        }

        if (hasFirstNameError) {
          classNameFirstName = 'lot-input-red'
        }

        if (hasFriendEmailError) {
          classNameFriendEmail = 'lot-input-red'
        }

        return (
          <>
            <h1>Condition de participation</h1>

            <p>Parrainez un(e) ami(e) pour pouvoir jouer.</p>

            <div className="lottery-form w-margin-top">
              <input
                id="firstName"
                type="text"
                placeholder={tt('placeholder_first_name')}
                value={firstName}
                onChange={handleFirstNameChange}
                className={`form-control w-margin-bottom-half ${classNameFirstName} ${
                  hasFirstNameError ? 'input-error' : ''
                }`}
                required
              />

              <input
                id="email"
                type="email"
                placeholder={tt('your_email')}
                value={email}
                onChange={handleEmailChange}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]+$"
                className={`form-control w-margin-bottom-half ${classNameEmail} ${
                  hasEmailError ? 'input-error' : ''
                }`}
                required
              />

              <input
                id="friend-email"
                type="email"
                placeholder={tt('placeholder_friend_email')}
                value={friendEmail}
                onChange={handleFriendEmailChange}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]+$"
                className={`form-control w-margin-bottom-half ${classNameFriendEmail} ${
                  hasFriendEmailError ? 'input-error' : ''
                }`}
                required
              />
            </div>

            <WheelButton
              id="w-cta-modal"
              text={tt('placeholder_sponsoree')}
              onClick={onValidateForm}
            />

            <OptinOptout />
          </>
        )
      }}
    />
  )
}

export default LPSponsorship
