import * as React from 'react'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import WheelButton from './cta/WheelButton'
import {
  goToRedeem,
  incrementCTAClickCount,
  nextLotteryStep,
  setLotteryLoading,
} from '../redux/actions/lottery'
import { useDispatch } from 'react-redux'
import WheelAnalyticsManager from '../analytics/WheelAnalyticsManager'
import TSConstants from '../utils/TSConstants'
import { FunctionComponent } from 'react'

interface Props {
  isFixedBottom?: boolean
  step: LotteryV2Step | null
  isIOS: boolean
  preventNextStep: boolean
  isAfterWheel?: boolean
}

const CustomLinkButton: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch()

  const onCLick = () => {
    WheelAnalyticsManager.logEvent('custom_link_click')

    if (props.isAfterWheel) {
      setTimeout(() => {
        dispatch(goToRedeem())
      }, 1000)
      return
    }

    dispatch(setLotteryLoading(true, props.step || undefined))

    setTimeout(() => {
      if (!props.preventNextStep) {
        dispatch(nextLotteryStep())
      }
      dispatch(setLotteryLoading(false))
    }, TSConstants.LOADER_TIMEOUT_SHORT)

    setTimeout(() => {
      dispatch(incrementCTAClickCount())
    }, 1000)
  }

  const { step, isFixedBottom } = props

  if (!step) {
    return null
  }

  return (
    <WheelButton
      text={step.customLinkCTA || ''}
      onClick={onCLick}
      href={step.customLink}
      isFixedBottom={isFixedBottom}
    />
  )
}

export default CustomLinkButton
