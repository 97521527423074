import { SVGComponentProps } from '../Balloon'
import React from 'react'
import TSConstants from '../../../../utils/TSConstants'

export default function WallaceFountain(props: SVGComponentProps) {
  return (
    <div
      style={{
        width: props.width,
        height: props.height,
        position: 'absolute',
        top: props.top,
        left: props.left,
        right: props.right,
        bottom: props.bottom,
      }}
    >
      <img
        className="twic"
        src={TSConstants.PLACEHOLDER_URL}
        data-src={`image:/cdm/img/otcp/wallace.png`}
        style={{
          width: props.width,
          height: props.height,
        }}
      />
    </div>
  )
}
