import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/combinedReducers'
import { useCallback } from 'react'
import {
  hideMenu,
  showPrivacyPolicyModal,
  showCookesPolicyModal,
} from '../../redux/actions/app'
import React from 'react'
import { tt } from '../../i18n'
import WheelButton from '../../components/cta/WheelButton'
import { ModalControlsI } from '../../hooks/useModalControls'
import { lotteryConfigSelector } from '../../redux/selectors/lottery'

interface Props {
  eulaModalControls: ModalControlsI
  legalMentionsModalControls: ModalControlsI
}

export default function Menu(props: Props) {
  const menuShown = useSelector((s: RootState) => s.app.menuShown)
  const dispatch = useDispatch()
  const config = useSelector(lotteryConfigSelector)

  const hide = useCallback(() => {
    dispatch(hideMenu())
  }, [dispatch])

  const eulaClicked = useCallback(() => {
    hide()
    if (config?.rulesURL) {
      window.open(config?.rulesURL, '_blank')
    } else {
      props.eulaModalControls.openModal()
    }
  }, [props.eulaModalControls])

  const privacyPolicyClicked = useCallback(() => {
    hide()
    if (config?.ppURL) {
      window.open(config?.ppURL, '_blank')
    } else {
      dispatch(showPrivacyPolicyModal())
    }
  }, [dispatch])

  const cookiesPolicyClicked = useCallback(() => {
    hide()
    if (config?.pcURL) {
      window.open(config?.pcURL, '_blank')
    } else {
      dispatch(showCookesPolicyModal())
    }
  }, [dispatch])

  const legalMentionsModalClicked = useCallback(() => {
    hide()
    props.legalMentionsModalControls.openModal()
  }, [props.legalMentionsModalControls])

  return (
    <Modal
      show={menuShown}
      onHide={hide}
      centered={true}
      size="lg"
      className="w-modal-menu"
    >
      <Modal.Body>
        <i className="fas fa-times modal-close" onClick={hide} />
        <div className="d-flex flex-column w-modal">
          <WheelButton
            containerClassName="w-margin-top w-margin-bottom-half"
            text={tt('rules')}
            onClick={eulaClicked}
          />
          <WheelButton
            containerClassName="w-margin-bottom-half"
            text={tt('privacy_policy')}
            onClick={privacyPolicyClicked}
          />
          <WheelButton
            containerClassName="w-margin-bottom-half"
            text={tt('cookies_policy')}
            onClick={cookiesPolicyClicked}
          />
          <WheelButton
            containerClassName="w-margin-bottom-half"
            text={tt('legal')}
            onClick={legalMentionsModalClicked}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}
