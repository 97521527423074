import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import WheelButton from './cta/WheelButton'
import { TSUtils } from '../utils/TSUtils'
import WheelAnalyticsManager from '../analytics/WheelAnalyticsManager'
import {
  goToRedeem,
  incrementCTAClickCount,
  nextLotteryStep,
  setLotteryLoading,
  setShowTuto,
} from '../redux/actions/lottery'
import TSConstants from '../utils/TSConstants'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import { tt } from '../i18n'
import { FunctionComponent } from 'react'
import { lotteryConfigSelector } from '../redux/selectors/lottery'
import { configSelector } from '../redux/selectors/config'

interface Props {
  instagramProfileId: string | undefined | null
  isFixedBottom?: boolean
  isStory?: boolean
  textOnLeft?: boolean
  preventNextStep: boolean
  isAfterWheel?: boolean
  step: LotteryV2Step | null
  disabled?: boolean
  showTuto?: boolean
  onClick?: () => void
  style?: any | null
}

const INPUT_ID = 'copy-paste'

const InstagramButton: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch()

  const config = useSelector(lotteryConfigSelector)
  const siteConfig = useSelector(configSelector)

  const {
    instagramProfileId,
    isFixedBottom,
    isStory,
    textOnLeft,
    disabled,
    onClick,
    showTuto,
  } = props

  const onClickPostTutorial = () => {
    WheelAnalyticsManager.instagramClicked()

    dispatch(setLotteryLoading(true, props.step || undefined))
    dispatch(setShowTuto(false, false, false, false, false))

    if (props.isStory) {
      const input: HTMLElement | null = document.getElementById(INPUT_ID)
      // @ts-ignore
      input.select()
      document.execCommand('copy')
    }

    const timeout = props.isStory
      ? TSConstants.LOADER_TIMEOUT_LONG
      : TSConstants.LOADER_TIMEOUT_SHORT

    setTimeout(() => {
      dispatch(incrementCTAClickCount())
      if (!props.preventNextStep) {
        dispatch(nextLotteryStep())
      }
      dispatch(setLotteryLoading(false))
    }, timeout)
  }

  const onClickPreTutorial = () => {
    WheelAnalyticsManager.instagramClickedPreTutorial()

    if (props.isAfterWheel) {
      setTimeout(() => {
        dispatch(goToRedeem())
      }, 1000)
      return
    }

    if (props.showTuto) {
      dispatch(
        setShowTuto(
          true,
          !props.isStory,
          props.isStory || false,
          false,
          false,
          () => {
            return (
              <InstagramButton
                {...props}
                onClick={onClickPostTutorial}
                showTuto={false}
              />
            )
          }
        )
      )
    } else {
      onClickPostTutorial()
    }
  }

  const buildInstagramProfileLink = (
    config: LotteryConfigurationResponse | undefined,
    instagramProfileId: string | null,
    isStory: boolean
  ) => {
    if (!config) {
      return ''
    }
    if (
      instagramProfileId?.includes('http:') ||
      instagramProfileId?.includes('https:')
    ) {
      return instagramProfileId
    }
    let link: string
    const finalInstagramProfileId =
      instagramProfileId || config.instagramProfileId

    if (isStory) {
      if (TSUtils.isMobile()) {
        link = `instagram://story-camera`
      } else {
        link = `https://instagram.com/${finalInstagramProfileId}`
      }

      return link
    }

    return buildInstagramLink(finalInstagramProfileId)
  }

  const link = buildInstagramProfileLink(
    config,
    instagramProfileId || null,
    isStory || false
  )

  const text = isStory ? tt('cta_ig_story') : tt('cta_ig')

  return (
    <>
      <WheelButton
        text={text}
        onClick={onClick || onClickPreTutorial}
        className="w-btn-ig"
        img={`${siteConfig.PUBLIC_FILES_URL}/static/img/ig-icon@2x.png`}
        href={showTuto ? undefined : link}
        isFixedBottom={isFixedBottom}
        textOnLeft={textOnLeft}
        disabled={disabled}
        style={props.style}
      />
      {isStory && (
        <input
          id={INPUT_ID}
          style={{ position: 'fixed', right: -2000 }}
          value={`@cadeaudelamaison @${instagramProfileId}`}
        />
      )}
    </>
  )
}

export function buildInstagramLink(
  finalInstagramProfileId: string | undefined
) {
  if (TSUtils.isMobile()) {
    if (TSUtils.isIOS()) {
      return `instagram://user?username=${finalInstagramProfileId}`
    } else if (TSUtils.isAndroid()) {
      return `instagram://user?username=${finalInstagramProfileId}`
    } else {
      return `https://instagram.com/${finalInstagramProfileId}`
    }
  }
  return `https://instagram.com/${finalInstagramProfileId}`
}

export default InstagramButton
