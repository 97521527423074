import React from 'react'

export interface SMSVGProps {
  height?: number | string
  width?: number | string
  position?: any | undefined
  top?: number | string
  right?: number | string
  left?: number | string
  bottom?: number | string
  zIndex?: number
}

export default function SlotMachineLeft(props: SMSVGProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 12.79 100.98"
      style={props}
    >
      <defs>
        <style>{`
          .cls-1{fill:url(#Dégradé_sans_nom_24);}
        `}</style>
        <linearGradient
          id="Dégradé_sans_nom_24"
          x1="6.39"
          y1="100.98"
          x2="6.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.03" stopColor="#696d6d" />
          <stop offset="0.09" stopColor="#808381" />
          <stop offset="0.19" stopColor="#a1a39e" />
          <stop offset="0.3" stopColor="#b9b9b3" />
          <stop offset="0.39" stopColor="#c7c7c0" />
          <stop offset="0.47" stopColor="#ccccc4" />
          <stop offset="0.6" stopColor="#c9c9c1" />
          <stop offset="0.71" stopColor="#bfc0b9" />
          <stop offset="0.8" stopColor="#afb0ab" />
          <stop offset="0.88" stopColor="#989a97" />
          <stop offset="0.96" stopColor="#7b7e7d" />
          <stop offset="1" stopColor="#696d6d" />
        </linearGradient>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Calque_1" data-name="Calque 1">
          <path
            className="cls-1"
            d="M5.51.17,12.79,0c-3.92,10.17-6.4,25.39-7,45.11C5.12,68.51,8.33,90,12.45,101c-1.93,0-5.18-.52-7.11-.52A203.06,203.06,0,0,1,2.41,23C3.71,14.75,3.49,7.13,5.51.17Z"
          />
        </g>
      </g>
    </svg>
  )
}
