import { RootState } from '../combinedReducers'

export const quizzSelector = (state: RootState) => state.quizz.quizz

export const quizzStepSelector = (state: RootState) => state.quizz.step

export const questionsSelector = (state: RootState) => state.quizz.questions

export const currentQuestionSelector = (state: RootState) =>
  state.quizz.currentQuestion

export const currentQuestionIdxSelector = (state: RootState) =>
  state.quizz.currentQuestionIdx

export const scoreSelector = (state: RootState) => state.quizz.score

export const remainingSecondsSelector = (state: RootState) =>
  state.quizz.remainingSeconds

export const giftsSelector = (state: RootState) => state.quizz.gifts

export const chosenGiftSelector = (state: RootState) => state.quizz.chosenGift

export const hasReachedTerminalScoreSelector = (state: RootState) =>
  state.quizz.hasReachedTerminalScore
