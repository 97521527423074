import React, { FunctionComponent, useState } from 'react'
import { async_setMcDonaldsID } from '../../../redux/actions/lottery'
import { useDispatch, useSelector } from 'react-redux'
import WheelButton from '../../../components/cta/WheelButton'
import './styles.scss'
import { tt } from '../../../i18n'
import { configSelector } from '../../../redux/selectors/config'

interface Props {}

const McDonaldsPopupContent: FunctionComponent<Props> = () => {
  const [mcDonaldsID, setMcDonaldsID] = useState('')

  const dispatch = useDispatch()

  const siteConfig = useSelector(configSelector)

  const handleCodeChange = (e: any) => {
    if (!e || !e.target || !e.target.value) {
      setMcDonaldsID('')
      return
    }

    const mcDonaldsID = e.target.value.replaceAll('-', '').replaceAll('_', '')

    setMcDonaldsID(mcDonaldsID.toUpperCase())
  }

  const onClick = () => {
    if (!mcDonaldsID || mcDonaldsID.length !== 8) {
      alert(tt('mcdonalds_error'))
      return
    }

    if (/^\d+$/.test(mcDonaldsID)) {
      alert(tt('mcdonalds_error'))
      return
    }

    if (/^[a-zA-Z]+$/.test(mcDonaldsID)) {
      alert(tt('mcdonalds_error'))
      return
    }

    if (!/^[a-zA-Z0-9]+$/.test(mcDonaldsID)) {
      alert(tt('mcdonalds_error'))
      return
    }

    dispatch(async_setMcDonaldsID(siteConfig, mcDonaldsID))
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="form-group input-number-container">
            <input
              className="form-control"
              placeholder={tt('mcdonalds_id')}
              value={mcDonaldsID}
              onChange={handleCodeChange}
              style={{ height: 55, fontSize: 20 }}
            />
            {/*<InputMask*/}
            {/*  className="form-control"*/}
            {/*  mask="****-****"*/}
            {/*  placeholder="Identifiant McDo+"*/}
            {/*  value={state.mcDonaldsID}*/}
            {/*  onChange={handleCodeChange}*/}
            {/*  style={{height: 55, fontSize: 20}}*/}
            {/*/>*/}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <WheelButton onClick={onClick} text={tt('ok')} isInverted={true} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div style={{ borderBottom: '1px solid grey' }}></div>
        </div>
      </div>

      <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
        <div className="col-12 text-center">
          <p>{tt('mcdonalds_no_account')}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <WheelButton
            text={tt('mcdonalds_create_account')}
            href="https://m.mcdonalds.fr/commande/inscription"
          />
        </div>
      </div>
    </>
  )
}

export default McDonaldsPopupContent
