import * as React from 'react'
import WheelButton from './cta/WheelButton'
import {
  incrementCTAClickCount,
  nextLotteryStep,
  setLotteryLoading,
} from '../redux/actions/lottery'
import { useDispatch } from 'react-redux'
import TSConstants from '../utils/TSConstants'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import { tt } from '../i18n'
import { FunctionComponent } from 'react'

interface Props {
  isIOS: boolean
  step: LotteryV2Step | null
  isFixedBottom?: boolean
  onClick?: () => void
  enableHref?: boolean
}

const DeliverooButton: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch()

  const onClick = () => {
    dispatch(setLotteryLoading(true, props.step || undefined))
    setTimeout(() => {
      dispatch(incrementCTAClickCount())
      dispatch(nextLotteryStep())
      dispatch(setLotteryLoading(false))
    }, TSConstants.LOADER_TIMEOUT_SHORT)
  }

  return (
    <WheelButton
      text={tt('cta_deliveroo')}
      className="w-btn-deliveroo"
      onClick={props.onClick || onClick}
      href={
        props.enableHref
          ? props.isIOS
            ? 'deliveroo://orders'
            : 'deliveroo://account'
          : undefined
      }
      isFixedBottom={props.isFixedBottom}
      noReferrer={true}
    />
  )
}

export default DeliverooButton
