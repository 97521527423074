import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  top?: number | string
  left?: number | string
  bottom?: number | string
  right?: number | string
}

export default function ValentinesDayClouds(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1115.93 562.86"
      style={{
        position: 'absolute',
        width: props.width,
        height: props.height,
        bottom: props.bottom,
        top: props.top,
        right: props.right,
        left: props.left,
      }}
    >
      <defs>
        <style>
          {`
          .cls-clouds-1{opacity:0.98;fill:url(#Dégradé_sans_nom_103);}
          .cls-clouds-2{fill:url(#Dégradé_sans_nom_283);}
          .cls-clouds-3{fill:url(#Dégradé_sans_nom_87);}
        `}
        </style>
        <linearGradient
          id="Dégradé_sans_nom_103"
          x1="830.07"
          x2="830.07"
          y2="328.85"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0.9" />
          <stop offset="0.42" stopColor="#ffd5ff" stopOpacity="0.51" />
          <stop offset="0.82" stopColor="#fffbff" stopOpacity="0.59" />
          <stop offset="0.9" stopColor="#fffeff" stopOpacity="0.6" />
          <stop offset="1" stopColor="#ff6aa4" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id="Dégradé_sans_nom_283"
          x1="459.13"
          y1="12.31"
          x2="459.13"
          y2="402.84"
          gradientTransform="matrix(1.05, 0.25, -0.26, 1.1, 47.79, -99.69)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.04" stopColor="#f6cddb" />
          <stop offset="0.44" stopColor="#ffaacd" />
          <stop offset="1" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="Dégradé_sans_nom_87"
          x1="752.73"
          y1="98.27"
          x2="752.73"
          y2="391.44"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0.9" />
          <stop offset="0.69" stopColor="#fff" stopOpacity="0.5" />
          <stop offset="1" stopColor="#ffd5ff" stopOpacity="0.51" />
        </linearGradient>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="BG">
          <path
            className="cls-clouds-1"
            d="M1113.67,273.86c-130.46,122.83-203.36,6.29-294.27-27-31.29-11.46-41,40.31-52.88-8-5.61-22.76-129.28,46.71-146.32,35-15.74-10.8-32.29,3.71-50-6-23.11-12.68-38.76-61.55-11.89-85.5,19.08-17,54.59-17,79.72,4.72.53-3.48,4.65-28.13,25.83-41.37,17.52-11,39.88-9.84,57.56,2.77-9.25-21.33-8.66-45.55,2.76-61.81C740.84,63,779.54,57.54,809,82.6c16.63-54.22,62.32-87.14,103-82.09C963.91,6.94,1009.49,75.2,990,149.78a89,89,0,0,1,46.54-5.81c26.24,3.82,42.44,18.51,48.28,23.81C1126.08,205.22,1114.82,268,1113.67,273.86Z"
          />
          <path
            className="cls-clouds-2"
            d="M859.57,562.86,1.15,358.31c-6-45.59,11.88-92.12,42.77-114.73,39.17-28.67,94.16-15.14,119,36.91C165.15,274.72,181.46,234,219,219c31-12.4,64.5-2.42,86.19,25.55-5.33-39.85,5.44-81.1,29.4-104.8,34.88-34.49,95.82-29.85,130.33,23.7C512.25,76.66,595,36.83,654.69,60.18,730.74,89.93,772,223.26,712,343.86c55.2-11.06,108,14,136.51,65.08C886,476.2,862.3,554.32,859.57,562.86Z"
          />
        </g>
        <g id="ROUE_structure">
          <path
            className="cls-clouds-3"
            d="M978.1,289.53c-40.57,72.16-80,93.51-108.52,99.71-73.44,16-110.73-60.82-209-48.39-51.75,6.54-87.22,33.61-100.33,16.26-10.8-14.29,2.28-47.15,15.92-74-27-6.54-46.77-30-49-56.66-3.16-36.94,28-71.45,62.1-70.54,27.93.75,53.84,27,58.93,63.82-28.52-31.74-34.36-68.62-22-85.12,10.5-14,32-10.51,33.9-10.17,22.85,4,41.94,28.14,47.26,61.44-5.83-19.91-4.72-42.28,3.7-57.05,0,0,5.92-9.65,15.58-15,16.15-8.88,40.79-.77,61.09,20.7,37.63-45.23,89.13-44,107.68-19.09,13,17.49,11.59,49.08-1.11,77.46,23.22-11.65,48.55-4.48,65.51,18.69C982.11,242.12,978.55,284.84,978.1,289.53Z"
          />
        </g>
      </g>
    </svg>
  )
}
