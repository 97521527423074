import WheelColorsI from '../WheelColors'
import BigGift from '../SVG_objects/BigGift'
import DoubleStripedStar from '../SVG_objects/DoubleStripedStar'
import React from 'react'
import SmallGift from '../SVG_objects/SmallGift'
import Balloon from '../SVG_objects/Balloon'
import aspectRatio, { AspectRatio } from '../../../utils/aspect_ratio'

const aspectRatio1 = aspectRatio()

export function renderStandardBackground(colors: WheelColorsI) {
  let bigStarTop = '-8%'
  let smallStarTop = '15%'
  let smallStarLeft = '40%'
  let bigStarWidth = '25%'
  let bigGiftTop = '10%'

  switch (aspectRatio1) {
    case AspectRatio._16_9:
      bigStarTop = '-12%'
      smallStarTop = '18%'
      bigGiftTop = '15%'
      break
    case AspectRatio._19_9:
    case AspectRatio._18_9:
      smallStarLeft = '50%'
      bigStarWidth = '20%'
      break
  }

  return (
    <>
      {!colors.hideGifts && (
        <BigGift
          colors={colors}
          width={'75%'}
          right={'-36%'}
          top={bigGiftTop}
          useSecondaryColor={true}
        />
      )}
      {!colors.hideStars && (
        <>
          <DoubleStripedStar
            colors={colors}
            width={bigStarWidth}
            top={bigStarTop}
            right={'0%'}
          />
          <DoubleStripedStar
            colors={colors}
            width={'10%'}
            top={smallStarTop}
            left={smallStarLeft}
          />
          <DoubleStripedStar
            colors={colors}
            width={'10%'}
            left={'40%'}
            bottom={'0%'}
          />
        </>
      )}
    </>
  )
}

export function renderStandardForeground(
  colors: WheelColorsI,
  hideBalloon: boolean,
  animateCounter?: number
) {
  let balloonBottom = '6%'
  let starRight = '0%'

  switch (aspectRatio1) {
    case AspectRatio._16_9:
      starRight = '25%'
      break
    case AspectRatio._19_9:
    case AspectRatio._18_9:
      balloonBottom = '8%'
      break
  }

  return (
    <>
      {!colors.hideGifts && (
        <SmallGift colors={colors} width="60%" bottom="-10vh" right={'-10%'} />
      )}
      {!colors.hideStars && (
        <DoubleStripedStar
          colors={colors}
          width={'15%'}
          bottom={'45%'}
          right={starRight}
        />
      )}
      {!hideBalloon && !colors.hideBalloon && (
        <Balloon
          colors={colors}
          color={colors.balloon1Color}
          colorLight={colors.balloon1ColorL}
          id="bal-1"
          width={'25%'}
          right={'3%'}
          bottom={balloonBottom}
          animateCounter={animateCounter}
        />
      )}
    </>
  )
}
