import Skin, { SkinStyle } from '../../model/Skin'
import TSConstants from '../../utils/TSConstants'
import React from 'react'
import aspectRatio from '../../utils/aspect_ratio'

const aspectRatio1 = aspectRatio()

export function renderSkin(key: keyof Skin, skin: Skin) {
  if (!skin) {
    return null
  }
  if (
    key === 'name' ||
    key === 'hasNotch' ||
    key === 'main_sprite' ||
    key === 'primary' ||
    key === 'secondary'
  )
    return
  const plane = skin[key]
  // @ts-ignore
  if (!plane || plane.length === 0) {
    return null
  }
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: '30px',
        zIndex: 3,
        pointerEvents: 'none',
      }}
    >
      {
        // @ts-ignore
        plane.map((item) => {
          const style: SkinStyle = item.details[aspectRatio1]
          return (
            <img
              className="twic"
              src={TSConstants.PLACEHOLDER_URL}
              data-src={`image:${item.src}`}
              style={{ ...style }}
              key={item.name}
            />
          )
        })
      }
    </div>
  )
}
