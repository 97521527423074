import WheelColorsI from '../WheelColors'
import React from 'react'
import './nature.scss'
import aspectRatio, { AspectRatio } from '../../../utils/aspect_ratio'
import SmallGift from '../SVG_objects/SmallGift'
import ValentinesDayClouds from '../SVG_objects/ValentinesDayClouds'
import ValentinesDayHearts from '../SVG_objects/ValentinesDayHearts'

const aspectRatio1 = aspectRatio()

export function buildSkinForValentinesDay(colors: WheelColorsI) {
  return {
    renderBackground: () => {
      let left = '-15%'
      let cloudsWidth = '90%'

      let heartsHeight = '65%'
      let heartsRight = '1%'

      switch (aspectRatio1) {
        case AspectRatio._16_9:
          cloudsWidth = '100%'
          left = '-30%'
          heartsHeight = '80%'
          heartsRight = '1%'
          break
        case AspectRatio._19_9:
        case AspectRatio._18_9:
          break
      }

      return (
        <>
          <ValentinesDayClouds width={cloudsWidth} left={left} top={'2%'} />
          <ValentinesDayHearts
            height={heartsHeight}
            top={'2%'}
            right={heartsRight}
          />
        </>
      )
    },
    renderForeground: () => {
      return (
        <>
          <SmallGift
            colors={colors}
            width="60%"
            bottom="-10vh"
            right={'-10%'}
          />
        </>
      )
    },
    renderCTAForeground: () => {
      return null
    },
  }
}
