import { tt } from '../../i18n'
import * as React from 'react'
import { FunctionComponent, useEffect, useMemo } from 'react'
import { setIsOptOut } from '../../redux/actions/lottery'
import { useDispatch, useSelector } from 'react-redux'
import {
  isOptOutSelector,
  lotteryConfigSelector,
  venueSelector,
} from '../../redux/selectors/lottery'

interface Props {
  className?: string
}

const OptinOptout: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch()

  const isOptOut = useSelector(isOptOutSelector)
  const lotteryConfig = useSelector(lotteryConfigSelector)
  const venue = useSelector(venueSelector)

  const placeName = useMemo(() => {
    return lotteryConfig?.isEcommerce
      ? lotteryConfig?.site || venue?.name
      : venue?.name
  }, [lotteryConfig, venue])

  const text = useMemo(() => {
    if (lotteryConfig?.optinTxt) {
      return lotteryConfig?.optinTxt
    } else if (lotteryConfig?.isEcommerce && lotteryConfig?.site) {
      return tt('optin_ecom', { site: lotteryConfig?.site })
    }
    return tt('optin', { placeName })
  }, [lotteryConfig])

  const toggle = () => {
    dispatch(setIsOptOut(!isOptOut))
  }

  if (lotteryConfig?.hideOptin) {
    return null
  }

  return (
    <div className="text-center checkbox">
      <table id="w-optin" className={`checkbox-table w-optin`} onClick={toggle}>
        <tbody>
        <tr className={props.className || ''}>
          <td className={`checkbox-td`}>
            <img
              src={
                'https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/lite-gray-check.png'
              }
              className={`checkbox-img ${
                isOptOut ? '' : 'checkbox-img-checked'
              }`}
            />
          </td>
          <td className="checkbox-text">{text}</td>
        </tr>
        </tbody>
      </table>
    </div>
  )
}

export default OptinOptout
