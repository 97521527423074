import {
  goToRedeem,
  INC_CTA_CLICK_COUNT,
  NEXT_LOTTERY_STEP,
  OFFER_TO_FRIEND,
  SET_ADVENT_CALENDAR_SHOWN,
  SET_CAN_REPLAY,
  SET_CURRENT_SESSION_FIRST_NAME_AND_EMAIL,
  SET_FORM_DATA,
  SET_FORM_DATA_OBJ,
  SET_HAS_EMAIL_OPTIN,
  SET_HAS_PHONE_OPTIN,
  SET_HELP_MODAL_ERROR,
  SET_IS_DEMO,
  SET_IS_OPTOUT,
  SET_LAST_3_WINNERS,
  SET_LOTTERY_DATA,
  SET_LOTTERY_GAME_FOR_MANUAL_CONSUME,
  SET_LOTTERY_LIKES_COUNT,
  SET_LOTTERY_LOADING,
  SET_LOTTERY_MODAL_STATE,
  SET_LOTTERY_PICTURE,
  SET_LOTTERY_PRIZE,
  SET_LOTTERY_RESET,
  SET_LOTTERY_STATUS,
  SET_LOTTERY_STEP,
  SET_LOTTERY_UPLOAD_PROGRESS,
  SET_LOTTERY_VALIDATE,
  SET_ML_CONFIG,
  SET_ML_DRAW,
  SET_NEXT_GAME_MINIMAL_TS,
  SET_SECONDARY_OPTIN,
  SET_SHOW_ML_OPTIN_MODAL,
  SET_SHOW_TUTO,
  SET_SKIN,
  SET_SMS_AUTH_MODAL_MODE,
  SET_SPONSORSHIP_ID_STEP_2,
  SET_SPONSORSHIP_URL,
  SET_VENUE,
  SHOW_SMS_AUTH_MODAL,
} from '../actions/lottery'
import LotteryGameConsume from '@tootsweet/model/lottery/LotteryGameConsume'
import LotteryConfigurationResponse, {
  WheelItem,
} from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import VenueDTO from '@tootsweet/model/datatransfer/VenueDTO'
import GameWinner from '@tootsweet/model/lottery/GameWinner'
import Skin from '../../model/Skin'
import { LotteryStatus } from '../../model/LotteryStatus'
import MonthlyLottery from '@tootsweet/model/lottery/MonthlyLottery'
import MonthlyLotteryDraw from '@tootsweet/model/lottery/MonthlyLotteryDraw'
import { AnyAction } from 'redux'
import moment from 'moment-timezone'
import {TSUtils} from "../../utils/TSUtils";

export const SMS_AUTH_MODAL_ASK_PHONE_NUMBER = 0
export const SMS_AUTH_MODAL_VALIDATE_CODE = 1

const statusIsError = (status: LotteryStatus) => {
  switch (status) {
    case LotteryStatus.Success:
    case LotteryStatus.Unknown:
      return false
    default:
      return true
  }
}

export interface LotteryI {
  loading: boolean
  loaderStep?: LotteryV2Step | undefined
  wheelLabels: string[]
  prize: {
    index: number
    win: boolean
    label: string
    prizeLongDesc: string
    giftId: string
  } | null
  step: LotteryV2Step | null
  validate: boolean
  mustValidate: boolean
  isMoney: boolean
  status: number
  picture: string | null
  lotteryModalState: boolean
  percentCompleted: number
  lotteryConfig: LotteryConfigurationResponse | undefined
  likesCount: number
  lotteryGameConsume: LotteryGameConsume | null
  lotteryStepIndex: number
  isOptOut: boolean
  wheelStepIndex: number
  venue: VenueDTO | null
  placeName: string | null
  hasEmailOptin?: string
  hasPhoneOptin?: string
  nextGameMinimalTs?: number
  last3Winners: GameWinner[]
  helpModalError: number
  sponsorshipIdStep2?: string
  sponsorshipURL?: string

  isDemo: boolean

  adventCalendarShown: boolean
  offerToFriendURL: string | null

  isBeforeWheel: boolean

  showTuto: boolean
  tutoIsIG: boolean
  tutoIsIGStory: boolean
  tutoIsGoogle: boolean
  tutoIsTikTok: boolean
  tutoRenderCTA?: () => any

  formData: any

  ctaClickCount: number

  /**
   * If the first step of the game is to ask for the user's first name and email,
   * there's no need to ask for them later. To determine that they already have been asked,
   * they must be stored in the state.
   */
  currentSessionFirstName?: string
  currentSessionEmail?: string

  smsAuthModalMode: number
  showSMSAuthModal: boolean
  skin?: Skin
  skinId?: string

  mlConfig: MonthlyLottery | null

  draw: MonthlyLotteryDraw | null
  showMLOptinModal: boolean
  canReplay: boolean
  secondaryOptin: boolean
}

const initialLottery: LotteryI = {
  loading: false,

  wheelLabels: [],
  prize: null,
  step: { stepType: LotteryV2StepType.Unknown },
  validate: false,
  mustValidate: false,
  isMoney: false,
  status: LotteryStatus.Unknown,
  picture: null,
  lotteryModalState: false,
  percentCompleted: 100,
  lotteryConfig: undefined,
  likesCount: 0,
  lotteryGameConsume: null,
  lotteryStepIndex: -1,
  isOptOut: false,
  wheelStepIndex: -1,
  venue: null,
  placeName: null,
  last3Winners: [],
  helpModalError: -1,
  isDemo: false,
  adventCalendarShown: moment().date() > 24,
  offerToFriendURL: null,
  isBeforeWheel: false,
  showTuto: false,
  tutoIsIG: false,
  tutoIsIGStory: false,
  tutoIsGoogle: false,
  tutoIsTikTok: false,

  formData: {},

  ctaClickCount: 0,

  smsAuthModalMode: SMS_AUTH_MODAL_ASK_PHONE_NUMBER,
  showSMSAuthModal: false,

  skin: undefined,

  mlConfig: null,

  draw: null,

  showMLOptinModal: false,
  canReplay: false,
  secondaryOptin: false,
}

export const lottery = (
  state = initialLottery,
  action: AnyAction
): LotteryI => {
  switch (action.type) {
    case SET_LOTTERY_DATA:
      let wheelLabels: string[] = []

      if (
        action.lotteryConfig &&
        action.lotteryConfig.wheelItems &&
        action.lotteryConfig.wheelItems.length > 0
      ) {
        wheelLabels = action.lotteryConfig.wheelItems.filter(
          (item: WheelItem) => !!item.label
        )
        wheelLabels = action.lotteryConfig.wheelItems.map((item: WheelItem) => {
          if (item.isLost && action.lotteryConfig?.capitalizeLost) {
            return item.label.toUpperCase()
          }
          if (item.preserveCase || !item.label) {
            return item.label
          }
          return item.label.toLocaleLowerCase()
        })
      }

      let wheelStepIndex = -1
      const lotteryConfig: LotteryConfigurationResponse = action.lotteryConfig
      if (
        lotteryConfig &&
        lotteryConfig.steps &&
        lotteryConfig.steps.length > 0
      ) {
        if (state.isDemo) {
          lotteryConfig.steps = lotteryConfig.steps.filter(
            (step) => !!step.stepType && TSUtils.isGameStep(step)
          )
        }

        lotteryConfig.steps.forEach((step: LotteryV2Step, index: number) => {
          if (
            step &&
            step.stepType &&
            TSUtils.isGameStep(step)
          ) {
            wheelStepIndex = index
          }
        })
      }

      let isOptOut = false

      if (lotteryConfig?.hideOptin) {
        isOptOut = false
      } else if (lotteryConfig?.useOptin) {
        isOptOut = true
      }

      return {
        ...state,
        lotteryConfig: action.lotteryConfig,
        wheelLabels,
        wheelStepIndex,
        loading: false,
        isOptOut,
      }

    case SET_LOTTERY_PRIZE:
      return {
        ...state,
        prize: {
          index: action.index,
          win: action.win,
          label: action.label,
          prizeLongDesc: action.prizeLongDesc,
          giftId: action.giftId,
        },
        mustValidate: action.mustValidate,
        isMoney: action.isMoney,
      }

    case SET_LOTTERY_LOADING:
      return {
        ...state,
        loading: action.loading,
        loaderStep: action.loaderStep,
      }

    case SET_LOTTERY_STEP:
      return {
        ...state,
        step: {
          stepType: action.step,
        },
      }

    case SET_LOTTERY_VALIDATE:
      return { ...state, validate: action.validate }

    case SET_LOTTERY_RESET:
      return initialLottery

    case SET_LOTTERY_STATUS:
      const newState = {
        ...state,
        status: action.status,
      }

      const hasError = statusIsError(action.status)
      if (hasError) {
        newState.step = { stepType: LotteryV2StepType.Error }
      }
      return newState

    case SET_LOTTERY_PICTURE:
      return { ...state, picture: action.picture }

    case SET_LOTTERY_MODAL_STATE:
      return { ...state, lotteryModalState: action.lotteryModalState }

    case SET_LOTTERY_UPLOAD_PROGRESS:
      return { ...state, percentCompleted: action.percentCompleted }

    case SET_LOTTERY_LIKES_COUNT:
      return { ...state, likesCount: action.likesCount }

    case SET_LOTTERY_GAME_FOR_MANUAL_CONSUME:
      return { ...state, lotteryGameConsume: action.lotteryGameForConsume }

    case NEXT_LOTTERY_STEP:
      if (state.step?.stepType === LotteryV2StepType.Unknown) {
        return {
          ...state,
          step: { stepType: LotteryV2StepType.SearchBar },
        }
      }

      let newIndex = state.lotteryStepIndex + 1
      let step: LotteryV2Step | null = null

      while (
        step === null &&
        state.lotteryConfig &&
        state.lotteryConfig.steps &&
        newIndex < state.lotteryConfig.steps.length
      ) {
        step = state.lotteryConfig.steps[newIndex]


        if (step && step.onlyOnWin && (!state.prize || !state.prize.win)) {
          step = null
        }

        if (step === null) {
          newIndex += 1
        }
      }

      if (
        !state.lotteryConfig?.hasML &&
        state.lotteryGameConsume &&
        !state.lotteryGameConsume.outcome
      ) {
        step = { stepType: LotteryV2StepType.Lost }
      } else if (step && step.stepType) {
        // Do nothing do not delete
        // Manage exception case when stepType is a game type here
      } else {
        // @ts-ignore
        if (state.lotteryGameConsume) {
          if (state.lotteryGameConsume.outcome) {
            goToRedeem(state.lotteryGameConsume.id)
          } else if (!state.lotteryConfig?.hasML) {
            step = { stepType: LotteryV2StepType.Lost }
          }
        } else {
          step = { stepType: LotteryV2StepType.Error }
        }
      }

      return {
        ...state,
        lotteryStepIndex: newIndex,
        step,
        isBeforeWheel: newIndex < state.wheelStepIndex,
      }

    case SET_IS_OPTOUT:
      return {
        ...state,
        isOptOut: action.isOptOut,
      }

    case SET_VENUE:
      return {
        ...state,
        venue: action.venue,
        placeName: action.venue.name,
      }

    case SET_HAS_EMAIL_OPTIN:
      return {
        ...state,
        hasEmailOptin: action.hasEmailOptin,
      }

    case SET_HAS_PHONE_OPTIN:
      return {
        ...state,
        hasPhoneOptin: action.hasPhoneOptin,
      }

    case SET_NEXT_GAME_MINIMAL_TS:
      return {
        ...state,
        nextGameMinimalTs: action.nextGameMinimalTs || undefined,
      }

    case SET_LAST_3_WINNERS:
      return {
        ...state,
        last3Winners: action.last3Winners,
      }

    case SET_HELP_MODAL_ERROR:
      return {
        ...state,
        helpModalError: action.error,
      }

    case SET_SPONSORSHIP_ID_STEP_2:
      return {
        ...state,
        sponsorshipIdStep2: action.id,
      }

    case SET_SPONSORSHIP_URL:
      return {
        ...state,
        sponsorshipURL: action.url,
      }

    case SET_IS_DEMO:
      return {
        ...state,
        isDemo: action.isDemo,
      }

    case SET_ADVENT_CALENDAR_SHOWN:
      return {
        ...state,
        adventCalendarShown: action.adventCalendarShown,
      }

    case OFFER_TO_FRIEND:
      return {
        ...state,
        step: { stepType: LotteryV2StepType.Offer },
        offerToFriendURL: action.offerToFriendURL,
      }

    case SET_SHOW_TUTO:
      return {
        ...state,
        showTuto: action.showTuto,
        tutoIsGoogle: action.tutoIsGoogle,
        tutoIsIG: action.tutoIsIG,
        tutoIsIGStory: action.tutoIsIGStory,
        tutoIsTikTok: action.tutoIsTikTok,
        tutoRenderCTA: action.tutoRenderCTA,
      }

    case SET_FORM_DATA:
      const formData = { ...state.formData }
      formData[action.key] = action.value
      return {
        ...state,
        formData,
      }

    case INC_CTA_CLICK_COUNT:
      return {
        ...state,
        ctaClickCount: state.ctaClickCount + 1,
      }

    case SET_CURRENT_SESSION_FIRST_NAME_AND_EMAIL:
      return {
        ...state,
        currentSessionFirstName: action.firstName,
        currentSessionEmail: action.email,
      }

    case SET_SMS_AUTH_MODAL_MODE:
      return {
        ...state,
        smsAuthModalMode: action.mode,
      }

    case SHOW_SMS_AUTH_MODAL:
      return {
        ...state,
        showSMSAuthModal: action.show,
      }

    case SET_FORM_DATA_OBJ:
      return {
        ...state,
        formData: action.obj,
      }

    case SET_SKIN:
      return {
        ...state,
        skin: action.skin,
        skinId: action.skinId,
      }

    case SET_ML_CONFIG:
      return {
        ...state,
        mlConfig: action.mlConfig,
      }

    case SET_ML_DRAW:
      return {
        ...state,
        draw: action.draw,
      }

    case SET_SHOW_ML_OPTIN_MODAL:
      return {
        ...state,
        showMLOptinModal: action.show,
      }

    case SET_CAN_REPLAY:
      return {
        ...state,
        canReplay: action.canReplay,
      }

    case SET_SECONDARY_OPTIN:
      return {
        ...state,
        secondaryOptin: action.secondaryOptin,
      }

    default:
      return state
  }
}

export default lottery
