import React from 'react'
import { FoliageProps } from './FoliageFG'

export function ThinFoliage(props: FoliageProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 763.48 803.49"
      style={{
        position: 'absolute',
        height: props.height,
        bottom: props.bottom,
        top: props.top,
        right: props.right,
        left: props.left,
      }}
    >
      <defs>
        <style>{`
      .a841b1ef-c799-4a2d-b53b-6ebc172c59ab { fill:${props.colors.v2FoliageColor3}; }
      `}</style>
      </defs>
      <g id="b972e932-f719-49f6-9fa9-f25980ab6afe" data-name="Layer 2">
        <g
          id="f97fc7c6-6e4e-4810-9cec-d03629639b8a"
          data-name="PLANTES_au_dessus"
        >
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M80.07,83.44c4.91,6.14,9.91,12.33,15,18.42s10.12,12.22,15.28,18.25c10.21,12.16,20.68,24.11,31.09,36.1,5.21,6,10.56,11.87,15.83,17.81s10.54,11.87,15.89,17.74c10.77,11.68,21.44,23.45,32.29,35.05q65,69.72,134.3,135.13L357,378.31c2.89,2.72,5.73,5.49,8.67,8.15l8.82,8,35.28,32c2.91,2.69,5.94,5.25,8.94,7.85l9,7.78,18,15.55,18,15.56,18.4,15.08L519,518.38l37.64,29,18.83,14.5,19.26,13.9,38.52,27.8L620,622.29l-38.52-28.67-19.25-14.33-18.8-14.91-37.54-29.86-36.66-30.9L451,488.14l-17.91-15.93-17.9-15.93-8.95-8c-3-2.66-6-5.28-8.88-8l-35-32.69-8.75-8.17c-2.93-2.72-5.74-5.56-8.61-8.33l-17.15-16.71q-68.55-66.9-133-137.77c-10.72-11.82-21.27-23.8-31.91-35.68-5.28-6-10.47-12-15.71-18.06s-10.49-12-15.63-18.12c-10.29-12.2-20.63-24.35-30.71-36.73-5.1-6.13-10.11-12.35-15.11-18.58-2.5-3.11-5-6.22-7.47-9.38L70.84,90.6Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M560.67,572s79.89-29.56,141.58-118.57,61.23-135.57,61.23-135.57-83.16,56.94-128.92,113C583,494,560.67,572,560.67,572Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M506.58,529.13s75.32-37,126.87-130.35,46.45-139,46.45-139-75.81,64.12-115,123.61C520.61,450.51,506.58,529.13,506.58,529.13Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M222,664.15s76.6-11.61,148-79.51,79.53-109.47,79.53-109.47S364.94,510.56,313.85,552C256.23,598.82,222,664.15,222,664.15Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M575.08,569.92S492.73,591.71,422.8,674.4s-73.93,129.09-73.93,129.09S437.11,754.77,488,703.37C545.44,645.39,575.08,569.92,575.08,569.92Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M511.59,522.48s-82.09,17.36-155,95.24-79.13,123.34-79.13,123.34,89.22-43.57,141.85-91.61C478.7,595.25,511.59,522.48,511.59,522.48Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M612.62,235.34S543.15,269.65,495.76,356s-42.58,128.43-42.58,128.43S523,425.05,559.13,370C599.84,308,612.62,235.34,612.62,235.34Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M184.46,608.81s71.37-11.9,137.07-76.3,72.75-103.32,72.75-103.32-78.49,34.22-125.61,73.66C215.51,547.33,184.46,608.81,184.46,608.81Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M531.18,194.16s-60.59,39.55-94.9,124.91S411.13,442.9,411.13,442.9,469.27,380.05,496.6,325C527.43,262.93,531.18,194.16,531.18,194.16Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M141.09,545.71s68-8.36,132.85-66.64S347,384.24,347,384.24s-75.69,29.19-121.91,64.6C173,488.78,141.09,545.71,141.09,545.71Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M489.09,168.41s-60.93,31.41-101.54,108.53-35.71,114.28-35.71,114.28,60.89-53.6,92-102.83C478.9,232.86,489.09,168.41,489.09,168.41Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M306,340.58s56.71-30.31,93.73-103.21,31.89-107.69,31.89-107.69-56.37,51.12-84.84,97.73C314.63,280,306,340.58,306,340.58Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M389.83,113.14s-51.53,26.78-86.56,91.23-31.35,95.23-31.35,95.23,51.76-45.19,78.46-86.39C380.49,166.73,389.83,113.14,389.83,113.14Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M307.91,84.57s-39.69,36.37-55.55,102.5-5.17,92.8-5.17,92.8,35.48-52.9,49.47-96.22C312.44,134.8,307.91,84.57,307.91,84.57Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M222.52,248.28S245.26,204.43,243,143.83s-16.35-79.22-16.35-79.22-15.84,56.22-17.17,97.13C208,207.89,222.52,248.28,222.52,248.28Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M191.33,218.87S198.79,171.8,180.41,117,144.93,49.3,144.93,49.3s1.78,56.32,11.77,94.22C168,186.26,191.33,218.87,191.33,218.87Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M148.25,166.79s-1.87-43.23-24.12-89.54S87.2,22.06,87.2,22.06s10.18,50.11,23.54,82.51A248.86,248.86,0,0,0,148.25,166.79Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M106.7,127S96.06,86.13,66.55,46,22.06,0,22.06,0,45.27,44.36,64,72.85A389.62,389.62,0,0,0,106.7,127Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M6.54,35.18a351.53,351.53,0,0,0,53,53.16C91,113.59,101.43,116,101.43,116S73.19,85.52,52.07,67.35A284.73,284.73,0,0,0,6.54,35.18Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M4.45,109.83s30.94,30.25,78.85,48.8,64.69,15,64.69,15S106.28,144,74.37,129.55A248.81,248.81,0,0,0,4.45,109.83Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M109.07,490.79s64-6.31,126.08-59.52S305.8,344,305.8,344,234.17,369.65,190,401.82C140.25,438.12,109.07,490.79,109.07,490.79Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M266.65,304.43s-61.84,10.95-120.57,61.88S80,447.18,80,447.18,148.68,419,190.58,387.62C237.84,352.24,266.65,304.43,266.65,304.43Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M239.23,280.76s-57.53-6.2-122.59,23.78-79.3,54.57-79.3,54.57,68.06-7.29,113.2-24.28C201.46,315.66,239.23,280.76,239.23,280.76Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M15.68,261.93S66.08,284,133,278.06s86.4-22.19,86.4-22.19-63.54-13.88-108.95-13C59.23,244,15.68,261.93,15.68,261.93Z"
          />
          <path
            className="a841b1ef-c799-4a2d-b53b-6ebc172c59ab"
            d="M0,179.92S45.41,207.8,108,217s81.81.27,81.81.27-58.17-24.14-100.45-32.8C41.69,174.67,0,179.92,0,179.92Z"
          />
        </g>
      </g>
    </svg>
  )
}
