// DICES

export const randomBetween = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1) + min)

export const formatRotate = (angle: number): string => `${angle}deg`

export const uid = () =>
  Date.now().toString(36) + Math.random().toString(36).substring(2)

// MYSTIC BOXES
export const shuffle = (array: Array<any>) => {
  let currentIndex = array.length,
    randomIndex

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

// Available for movement cels for even and odd rows of the playfield
export const rowsPattern = [
  [2, 4, 6, 8],
  [1, 3, 5, 7, 9],
]

export const getPath = (
  start: number,
  winningIndex: number
): Array<boolean> => {
  let currentCol = start
  const finish = rowsPattern[1][winningIndex]
  const path = []

  for (let i = 0; i < 7; i++) {
    const right = finish > currentCol
    currentCol += right ? 1 : -1
    path.push(right)
  }

  return path
}

export const testBoundsOfPath = (
  path: Array<boolean>,
  start: number,
  leftBorder: number,
  rightBorder: number
) => {
  let valid = true
  // TODO - refactor this, dont know how
  path.reduce((current, right) => {
    if (current < leftBorder) {
      valid = false
    }

    if (current > rightBorder) {
      valid = false
    }

    return (current += right ? 1 : -1)
  }, start)

  return valid
}
