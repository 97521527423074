import amplitude from 'amplitude-js'

const TAG = 'WHEEL_ANALYTICS'
const enableLogs = false

export const VERSION = '2.0.64'

export default class WheelAnalyticsManager {
  private static key: string = 'nokey'

  public static setKey(key: string) {
    enableLogs && console.log(TAG, 'setKey', key)
    WheelAnalyticsManager.key = key
  }

  private static logEventWithProps(ev?: string | null) {
    if (!ev) {
      return
    }
    amplitude.getInstance().logEvent(ev.toLocaleLowerCase(), {
      v: VERSION,
      key: this.key,
    })
  }

  public static gameLoaded() {
    enableLogs && console.log(TAG, 'loaded')
    WheelAnalyticsManager.logEventWithProps('loaded')
  }

  public static logEvent(ev?: string | null) {
    if (!ev) {
      return
    }
    enableLogs && console.log(TAG, ev.toLowerCase())
    WheelAnalyticsManager.logEventWithProps(ev)
  }

  public static facebookClicked() {
    enableLogs && console.log(TAG, 'facebook_click')
    WheelAnalyticsManager.logEventWithProps('facebook_click')
  }

  public static instagramClicked() {
    enableLogs && console.log(TAG, 'instagram_click')
    WheelAnalyticsManager.logEventWithProps('instagram_click')
  }

  public static instagramClickedPreTutorial() {
    enableLogs && console.log(TAG, 'instagram_click_pre_tuto')
    WheelAnalyticsManager.logEventWithProps('instagram_click_pre_tuto')
  }

  public static google() {
    enableLogs && console.log(TAG, 'google')
    this.logEventWithProps('google')
  }

  public static googleClicked() {
    enableLogs && console.log(TAG, 'google_click')
    WheelAnalyticsManager.logEventWithProps('google_click')
  }

  public static snapchatClicked() {
    enableLogs && console.log(TAG, 'snapchat_click')
    WheelAnalyticsManager.logEventWithProps('snapchat_click')
  }

  public static downloadAppClicked() {
    enableLogs && console.log(TAG, 'download_app_click')
    WheelAnalyticsManager.logEventWithProps('download_app_click')
  }

  public static fnacClicked() {
    enableLogs && console.log(TAG, 'fnac_click')
    WheelAnalyticsManager.logEventWithProps('fnac_click')
  }

  public static cDiscountClicked() {
    enableLogs && console.log(TAG, 'cdiscount_click')
    WheelAnalyticsManager.logEventWithProps('cdiscount_click')
  }

  public static tiktokClicked() {
    enableLogs && console.log(TAG, 'titok_click')
    WheelAnalyticsManager.logEventWithProps('titok_click')
  }
}
