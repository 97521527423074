const TSConstants = {
  DefaultWindowHeight: 667,
  IMG_MARGIN_MODAL: 30,
  WHEEL_MARGIN_DESKTOP: 30,
  WHEEL_MARGIN_MOBILE: 10,
  PLACEHOLDER_URL:
    'https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/placeholder.png',
  IMG_YAHOO: 'image:/cdm/yahoo.png',
  LOADER_TIMEOUT_SHORT: 10000,
  LOADER_TIMEOUT_LONG: 20000,
  secondsInAMillisecond: 1000,
  secondsInADay: 86400,
  secondsInAnHour: 3600,
  secondsInAMinute: 60,
  minutesInAnHour: 60,
  hoursInADay: 24,
  WHEEL_SKIN_DEFAULT: '0',
  WHEEL_SKIN_V2_NEUTRAL: '1',
  WHEEL_SKIN_V2_NATURE: '2',
  WHEEL_SKIN_V2_CHRISTMAS: '3',
  WHEEL_SKIN_V2_VALENTINES_DAY: '4',
  WHEEL_SKIN_ST_PATRICKS: '5',
  WHEEL_SKIN_OTCP: '6',
  WHEEL_SKIN_EASTER: '7',
  QUIZZ_SKIN_NORMAL: 0,
  QUIZZ_SKIN_LEADER_PRICE_ANNIVERSARY: 1,
  SKIN_NO_SKIN: '-1',
  SKIN_DEFAULT: '0',
  SCRATCH_CARD_CHRISTMAS_SKIN_DEFAULT: 0,
  ADVENT_CALENDAR_SKIN_DEFAULT: '0',
  SCRATCH_CARD_SKIN_DEFAULT: 0,
  goldenCloverDimension: 20,
  CoinSVG_URL:
    'https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/kadow/svg/coin.svg',
}

export const FRAUD_ALERT_HEIGHT = 44
export const FOOTER_HEIGHT = 30

export default TSConstants
