import WheelAnalyticsManager from '../../analytics/WheelAnalyticsManager'

export const share = async (url?: string) => {
  WheelAnalyticsManager.logEvent('share')

  if (!url) {
    alert('Erreur 13')
    return
  }

  // @ts-ignore
  if (!window.navigator.share) {
    alert('Erreur 12')
    return
  }

  // @ts-ignore{
  await window.navigator.share({ url })
}
