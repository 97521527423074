import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import * as React from 'react'
import {
  incrementCTAClickCount,
  nextLotteryStep,
  setLotteryLoading,
} from '../redux/actions/lottery'
import { useDispatch, useSelector } from 'react-redux'
import WheelButton from './cta/WheelButton'
import WheelAnalyticsManager from '../analytics/WheelAnalyticsManager'
import TSConstants from '../utils/TSConstants'
import { tt } from '../i18n'
import { FunctionComponent } from 'react'
import { configSelector } from '../redux/selectors/config'

interface Props {
  isFixedBottom?: boolean
  isIOS?: boolean
  step: LotteryV2Step | undefined
  link: string | undefined
  disableClick?: boolean
  text?: string
  preventNextStep: boolean
  onClick?: () => void
}

const TripAdvisorButton: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch()

  const siteConfig = useSelector(configSelector)

  const onGoToTripAdvisorClick = () => {
    WheelAnalyticsManager.logEvent('tripad_click')

    if (props.onClick) {
      props.onClick()
    }

    if (props.disableClick) {
      return
    }

    dispatch(setLotteryLoading(true, props.step))

    setTimeout(() => {
      dispatch(incrementCTAClickCount())
      if (!props.preventNextStep) {
        dispatch(nextLotteryStep())
      }
      dispatch(setLotteryLoading(false))
    }, TSConstants.LOADER_TIMEOUT_SHORT)
  }

  return (
    <>
      <WheelButton
        id="k-tripad-btn"
        text={props.text || tt('cta_tripadvisor')}
        onClick={onGoToTripAdvisorClick}
        className="w-btn-tripad"
        href={props.link}
        isFixedBottom={props.isFixedBottom}
        img={`${siteConfig.PUBLIC_FILES_URL_CDM}/img/tripadvisor.png`}
        textOnLeft={false}
        noReferrer={true}
      />
    </>
  )
}

export default TripAdvisorButton
