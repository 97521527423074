import React, { FunctionComponent } from 'react'
import GenericGameStep from '../../components/GenericGameStep'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import PollModalContent from './PollModalContent'
import { useSelector } from 'react-redux'
import {
  loadingSelector,
  lotteryConfigSelector,
  prizeSelector,
  venueSelector,
  wheelLabelsSelector,
} from '../../redux/selectors/lottery'

interface Props {}

const Poll: FunctionComponent<Props> = () => {
  return (
    <GenericGameStep
      stepType={LotteryV2StepType.Poll}
      renderModalBody={() => {
        return <PollModalContent />
      }}
    />
  )
}

export default Poll
