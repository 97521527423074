import React, { FunctionComponent, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import WheelAnalyticsManager from '../analytics/WheelAnalyticsManager'
import LotteryType from '@tootsweet/model/lottery/LotteryType'
import { useSelector } from 'react-redux'
import {
  lotteryConfigSelector,
  venueSelector,
} from '../redux/selectors/lottery'
import renderBackgroundGame from '../app/helpers/renderBackgroundGame'

interface Props {
  renderModalBody: (close: () => void) => any
  modalClass?: string
  stepType: LotteryV2StepType
  hideCTA?: boolean
  displayModalImmediately?: boolean
}

const GenericGameStep: FunctionComponent<Props> = (props) => {
  const [modal, setModal] = useState(false)
  const lotteryConfig = useSelector(lotteryConfigSelector)
  const venue = useSelector(venueSelector)

  const showModal = () => {
    WheelAnalyticsManager.logEvent(
      `${(props.stepType || '').toLowerCase()}_modal`
    )
    setModal(true)
  }

  const closeModal = () => {
    setModal(false)
  }

  useEffect(() => {
    if (props.displayModalImmediately) {
      showModal()
    }
  }, [props.displayModalImmediately])

  let displayCloseButton = false

  if (lotteryConfig) {
    switch (lotteryConfig.type) {
      case LotteryType.ScratchCard:
      case LotteryType.ScratchCardChristmas:
        displayCloseButton = false
        break
      case LotteryType.Quizz:
      case LotteryType.Wheel:
      case LotteryType.Fakir:
      case LotteryType.Dice:
      case LotteryType.MysteryBoxes:
        displayCloseButton = true
        break
    }
  }

  if (!!props.displayModalImmediately) {
    displayCloseButton = false
  }

  return (
    <>
      {renderBackgroundGame(
        lotteryConfig,
        venue,
        props.hideCTA,
        false,
        showModal
      )}
      <Modal
        show={modal}
        onHide={closeModal}
        centered={true}
        backdrop="static"
        className={props.modalClass}
      >
        <Modal.Body>
          <div className="w-modal">
            <div className="container">
              {displayCloseButton && (
                <i className="fas fa-times modal-close" onClick={closeModal} />
              )}
              {props.renderModalBody(closeModal)}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default GenericGameStep
