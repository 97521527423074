import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FortuneWheelWrapper from '../../../components/FortuneWheel/FortuneWheelWrapper'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import {
  async_wheelFinishedSpinning,
  goToRedeem,
  nextLotteryStep,
  offerToFriend,
  setShowMLOptinModal,
} from '../../../redux/actions/lottery'
import EmailAddressModal from '../../03_EmailAddressModal/EmailAddressModal'
import ScratchCardChristmas from '../../05_ScratchCard/ScratchCardChristmas'
import AdventCalendar from '../../06_AdventCalendar/AdventCalendar'
import ScratchCard from '../../05_ScratchCard/ScratchCard'
import SlotMachine from '../../09_SlotMachine/SlotMachine'
import { renderTitle } from '../../helpers/renderTitle'
import GiftMachine from '../../16_GiftMachine/GiftMachine'
import {
  FunctionComponent,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  lotteryConfigSelector,
  lotteryGameConsumeSelector,
  venueSelector,
} from '../../../redux/selectors/lottery'
import { configSelector } from '../../../redux/selectors/config'
import Fakir from '../../18_Fakir/Fakir'
import LotteryType from '@tootsweet/model/lottery/LotteryType'
import MysteryBoxes from '../../20_MysteryBoxes/MysteryBoxes'
import { lazy } from 'react'
import { Loader } from '../../../components/Loader/Loader'
import { tt } from '../../../i18n'
const DiceGame3D = lazy(() => import('../../22_Dice/DiceGame3D'))

interface Props {
  isModal?: boolean
  isMobileApp?: boolean
  skin?: string
}

const Play: FunctionComponent<Props> = () => {
  const [showEmailModal, setShowEmailModal] = useState(false)

  const dispatch = useDispatch()

  const lotteryConfig = useSelector(lotteryConfigSelector)
  const venue = useSelector(venueSelector)
  const siteConfig = useSelector(configSelector)
  const lotteryGame = useSelector(lotteryGameConsumeSelector)
  const [isFinished, setIsFinished] = useState(false)

  const onGameCompletedDeferred = () => {
    setTimeout(() => {
      onGameCompleted()
    }, 1200)
  }

  const renderGame = () => {
    if (!lotteryConfig) return null

    if (!venue?.name) return null

    switch (lotteryConfig.type) {
      case LotteryType.SlotMachine:
        return (
          <SlotMachine
            finalPlaceName={venue?.name}
            isPlayStep={true}
            onFinished={onGameCompleted}
          />
        )

      case LotteryType.Wheel:
        return (
          <FortuneWheelWrapper
            onFinishedSpinning={onGameCompleted}
            finalPlaceName={venue?.name}
            isPlayStep={true}
          />
        )

      case LotteryType.AdventCalendar:
        return (
          <AdventCalendar
            finalPlaceName={venue?.name}
            onScratchComplete={onGameCompletedDeferred}
            play={true}
          />
        )

      case LotteryType.ScratchCardChristmas:
        return (
          <ScratchCardChristmas
            finalPlaceName={venue?.name}
            gameStepType={LotteryV2StepType.ScratchCardChristmas}
            play={true}
            onGameComplete={onGameCompletedDeferred}
          />
        )

      case LotteryType.ScratchCard:
        return (
          <ScratchCard
            finalPlaceName={venue?.name}
            gameStepType={LotteryV2StepType.ScratchCardChristmas}
            play={true}
            onScratchComplete={onGameCompletedDeferred}
            renderTitle={renderTitle}
          />
        )

      case LotteryType.GiftMachine:
        return (
          <GiftMachine
            finalPlaceName={venue?.name}
            isPlayStep={true}
            onFinished={onGameCompleted}
          />
        )

      case LotteryType.Fakir:
        return <Fakir isPlayStep={true} onFinished={onGameCompleted} />

      case LotteryType.MysteryBoxes:
        return <MysteryBoxes isPlayStep={true} onFinished={onGameCompleted} />

      case LotteryType.Dice:
        return (
          <Suspense
            fallback={
              <Loader
                loading={true}
                displayText={true}
                text={tt('loading')}
                isFixed={true}
              />
            }
          >
            <DiceGame3D isDisabled={false} onFinished={onGameCompleted} />
          </Suspense>
        )
    }
  }

  const _offerToFriend = (url: string) => {
    dispatch(offerToFriend(url))
  }

  const afterEmailFilled = async () => {
    setShowEmailModal(false)

    await dispatch(async_wheelFinishedSpinning(siteConfig))
  }

  const closeModal = () => {
    setShowEmailModal(false)
  }

  function onGameCompleted() {
    setIsFinished(true)
  }

  useEffect(() => {
    if (lotteryGame && isFinished) {
      if (!lotteryGame.outcome) {
        setTimeout(() => {
          if (lotteryConfig?.hasML) {
            dispatch(setShowMLOptinModal(true))
          } else {
            dispatch(nextLotteryStep())
          }
        }, 2000)
        return
      }

      if (lotteryConfig?.goToRedeemAfterWin) {
        setTimeout(() => {
          dispatch(goToRedeem(lotteryGame.id))
        }, 1500)
      } else {
        setShowEmailModal(true)
      }
    }
  }, [lotteryGame, isFinished])

  return (
    <>
      {renderGame()}
      {showEmailModal && (
        <EmailAddressModal
          show={true}
          closeModal={closeModal}
          onFinished={afterEmailFilled}
          offerToFriend={_offerToFriend}
        />
      )}
    </>
  )
}

export default Play
