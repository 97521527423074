import combinedReducers from './combinedReducers'
import { applyMiddleware, compose, createStore, Store } from 'redux'
import thunkMiddleware from 'redux-thunk'
import * as Sentry from '@sentry/react'
import { ActionTypes } from './actions/app'

let store: Store | null = null

export function getOrCreateStore() {
  if (store) {
    return store
  }
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer: (action) => {
      switch (action.type) {
        case ActionTypes.SET_USER_PHONE_NUMBER:
          return {
            ...action,
            phoneNumber: null,
            firstName: null,
          }
        case ActionTypes.SET_USER_PAYMENT_EMAIL:
          return {
            ...action,
            email: null,
            firstName: null,
          }
        case ActionTypes.SET_USER:
          return {
            ...action,
            user: null,
          }
      }
      return action
    },
  })
  store = createStore(
    combinedReducers,
    {},
    compose(applyMiddleware(thunkMiddleware), sentryReduxEnhancer)
  )
  return store
}
