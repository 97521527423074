import MobileDetect from 'mobile-detect'
import moment from 'moment-timezone'
import TSConstants from './TSConstants'
import { tt } from '../i18n'
import LotteryGameConsume from '@tootsweet/model/lottery/LotteryGameConsume'

import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'

if (
  window.navigator.language &&
  window.navigator.language.indexOf('fr') !== -1
) {
  require(`moment/locale/fr`)
}

export class TSUtils {
  static isDefinedAndNotNull(p: any) {
    return p !== undefined && p !== null
  }

  static isMobile() {
    const md = new MobileDetect(window.navigator.userAgent)
    return !!(md.mobile() || md.phone())
  }

  static isTablet() {
    const md = new MobileDetect(window.navigator.userAgent)
    return !!md.tablet()
  }

  static isFirefoxBrowser() {
    return navigator.userAgent.toLowerCase().includes('firefox')
  }

  static isBraveBrowser() {
    //@ts-ignore
    return window.navigator.brave != undefined
  }

  static isFacebookBrowser() {
    if (!window || !window.navigator || !window.navigator.userAgent) {
      return false
    }
    return (
      window.navigator.userAgent.includes('FBAN') ||
      window.navigator.userAgent.includes('FBAV')
    )
  }

  static isInstagramBrowser() {
    if (!window || !window.navigator || !window.navigator.userAgent) {
      return false
    }
    return (
      window.navigator.userAgent.includes('instagram') ||
      window.navigator.userAgent.includes('Instagram') ||
      window.navigator.userAgent.includes('INSTAGRAM')
    )
  }

  static isIOS() {
    try {
      if (!window || !window.navigator || !window.navigator.userAgent) {
        return false
      }

      const md = new MobileDetect(window.navigator.userAgent)
      const os = md.os()

      if (os && os.toUpperCase().includes('IOS')) {
        return true
      }
    } catch (error) {}
    return false
  }

  static isAndroid() {
    try {
      if (!window || !window.navigator || !window.navigator.userAgent) {
        return false
      }

      const md = new MobileDetect(window.navigator.userAgent)
      const os = md.os()

      if (os && os.toUpperCase().includes('ANDROID')) {
        return true
      }
    } catch (error) {}
    return false
  }

  static isSamsungOnAndroid() {
    try {
      if (!window || !window.navigator || !window.navigator.userAgent) {
        return false
      }

      const ua = window.navigator.userAgent

      if (
        ua &&
        ua.toUpperCase().includes('SAMSUNGBROWSER') &&
        ua.toUpperCase().includes('ANDROID')
      ) {
        return true
      }
    } catch (error) {}
    return false
  }

  static isChrome() {
    try {
      if (!window || !window.navigator || !window.navigator.userAgent) {
        return false
      }

      const md = new MobileDetect(window.navigator.userAgent)
      const ua = md.userAgent()

      if (ua && ua.toUpperCase().includes('CHROME')) {
        return true
      }
    } catch (error) {}
    return false
  }

  static computeModalImageHeight(
    windowHeight: number | undefined,
    isBig?: boolean
  ) {
    if (!windowHeight) {
      return 50
    }
    if (isBig) {
      return windowHeight * 0.25
    }
    return windowHeight * 0.15
  }

  static formatTimestampAsDate(ts: number) {
    return moment(ts, 'X')
      .tz('Europe/Paris')
      .format(tt('date_format_date_hour'))
  }

  static formatTimestampAsShortDate(ts: number) {
    return moment(ts, 'X').tz('Europe/Paris').format(tt('date_format_date'))
  }

  static formatTimestamp(ts: number, format: string) {
    return moment(ts, 'X').tz('Europe/Paris').format(format)
  }

  static replaceSpacesWithNBSP(s: string) {
    return s ? s.replace(/ /g, '\u00a0') : ''
  }

  static computeRemainingText = (remaining: number, absoluteTs: number) => {
    const days = Math.trunc(remaining / TSConstants.secondsInADay)
    if (days > 0 && absoluteTs > 0) {
      return moment(absoluteTs, 'X').format(tt('date_format_date'))
    }
    const hours = Math.trunc(remaining / TSConstants.secondsInAnHour)
    let minutes: any = Math.trunc(
      (remaining % TSConstants.secondsInAnHour) / TSConstants.secondsInAMinute
    )
    let seconds: any = Math.trunc(
      (remaining % TSConstants.secondsInAnHour) % TSConstants.secondsInAMinute
    )
    if (minutes < 10) {
      minutes = `0${minutes}`
    }
    if (seconds < 10) {
      seconds = `0${seconds}`
    }
    return tt('date_format_long_time_string', { hours, minutes, seconds })
  }

  static shuffleArray = (array: any[]) => {
    if (!array || array.length === 0) {
      return []
    }
    return [...array].sort(() => Math.random() - 0.5)
  }

  static format(format: string, args: string[]) {
    return format.replace(/{(\d+)}/g, (match, number) => {
      if (number < args.length) {
        return args[number]
      }
      return match
    })
  }

  static gcd(a: number, b: number): number {
    return b ? TSUtils.gcd(b, a % b) : a
  }

  static aspectRatio(width: number, height: number) {
    const divisor = TSUtils.gcd(width, height)
    return `${width / divisor}:${height / divisor}`
  }

  static getRandomInt(max: number) {
    return Math.floor(Math.random() * max)
  }

  static getRandomIntInRange(min: number, max: number) {
    return Math.random() * (max - min) + min
  }

  static stripSpacesAndDiacritics(s?: string | null): string {
    if (!s) {
      return ''
    }
    return s
      .normalize('NFD')
      .replace(/[\s!?]/g, '')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
  }

  static dataURItoBlob(dataURI: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1])

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length)

    // create a view into the buffer
    const ia = new Uint8Array(ab)

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], { type: mimeString })
  }

  static gameIsRedeemed(game: LotteryGameConsume | undefined | null) {
    if (!game) {
      return false
    }
    return game.redemptionCodeUsed || game.redeemedByOwner
  }

  static getGamePrizeLabel(game: LotteryGameConsume | undefined | null) {
    return game?.prizeLongName || game?.prizeLabel || '???'
  }

  static capitalizeFirstLetter(s: string) {
    if (!s) {
      return ''
    }
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  static isGameStep(step: LotteryV2Step) {
    switch (step.stepType) {
      case LotteryV2StepType.Wheel:
      case LotteryV2StepType.GiftMachine:
      case LotteryV2StepType.SlotMachine:
      case LotteryV2StepType.ScratchCard:
      case LotteryV2StepType.ScratchCardChristmas:
      case LotteryV2StepType.AdventCalendar:
      case LotteryV2StepType.Catcher:
      case LotteryV2StepType.Quizz:
      case LotteryV2StepType.Dice:
      case LotteryV2StepType.MysteryBoxes:
      case LotteryV2StepType.Fakir:
        return true
    }
    return false
  }

  static buildSkinBaseURL = (skinId: string, isCDNQuery?: boolean) => {
    if (isCDNQuery) {
      return `image:/cdm/kadow/skins/${skinId}`
    }
    if (process.env.NODE_ENV === 'development') {
      return `/skins/${skinId}`
    }
    return `https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/kadow/skins/${skinId}`
  }

  static getNowTimestamp() {
    return moment().unix()
  }
}
