import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  showTutoSelector,
  tutoIsGoogleSelector,
  tutoIsIGSelector,
  tutoIsIGStorySelector,
  tutoIsTikTokSelector,
  tutoRenderCTASelector,
} from '../../../redux/selectors/lottery'
import Tuto from '../../../components/tuto/Tuto'

const Tutorial: FC<{ isIOS: boolean }> = ({ isIOS }) => {
  const showTuto = useSelector(showTutoSelector)
  const tutoIsIG = useSelector(tutoIsIGSelector)
  const tutoIsIGStory = useSelector(tutoIsIGStorySelector)
  const tutoIsGoogle = useSelector(tutoIsGoogleSelector)
  const tutoRenderCTA = useSelector(tutoRenderCTASelector)
  const tutoIsTikTok = useSelector(tutoIsTikTokSelector)

  return (
    <Tuto
      show={showTuto}
      isIOS={isIOS}
      isIG={tutoIsIG}
      isIGStory={tutoIsIGStory}
      isGoogle={tutoIsGoogle}
      isTikTok={tutoIsTikTok}
      renderCTA={tutoRenderCTA ?? function () {}}
    />
  )
}

export default Tutorial
