import * as React from 'react'
import { FunctionComponent } from 'react'
import { tt } from '../../i18n'

interface Props {
  customPrivacyPolicy?: string
  companyName?: string
  gdprEmail: string
}

const PrivacyPolicy: FunctionComponent<Props> = (props: Props) => {
  if (!!props.customPrivacyPolicy) {
    return (
      <iframe
        title={tt('privacy_policy')}
        src={props.customPrivacyPolicy}
        style={{ width: '100%', height: 500 }}
      />
    )
  }
  return (
    <>
      <div className="row">
        <div className="col-12 text-center">
          <h1>Politique de confidentialité des données</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2 className="mt-5 mb-3">
            Quelle est l’utilité de cette politique ?
          </h2>
          <p>
            {props.companyName} accorde une grande importance à la protection et
            la confidentialité de vos données personnelles qui représentent pour
            nous un gage de sérieux et de confiance.
          </p>
          <p>
            La politique de confidentialité des données témoigne précisément de
            <b>
              notre volonté de faire respecter les règles applicables en matière
              de protection des données
            </b>
            et, notamment, celles du Règlement général sur la protection des
            Données ("RGPD").
          </p>
          <p>
            En particulier, la politique de confidentialité vise à vous informer
            sur la manière et les raisons pour lesquelles nous traitons vos
            données dans le cadre des <b>services</b> que nous fournissons.
          </p>
          <h2 className="mt-5 mb-3">A qui s’adresse cette politique ?</h2>
          <p>
            La politique s’applique à <b>vous</b>, quel que soit votre lieu de
            domiciliation, dès lors que vous êtes âgé d'au moins 15 ans et que
            vous êtes utilisateur de notre jeu concours.
          </p>
          <p>
            Si vous avez moins de 15 ans, vous n’êtes pas autorisé à utiliser
            nos services <b>sans le consentement préalable et explicite</b> de
            l’un de vos parents qui doit être adressé par écrit à l’adresse{' '}
            {props.gdprEmail}. Si vous pensez que nous pourrions détenir des
            informations concernant un de vos enfants âgés de moins de 15 ans
            sans y avoir consenti, vous pouvez nous demander que nous les
            supprimions à l’adresse service {props.gdprEmail}.
          </p>
          <h2 className="mt-5 mb-3">Pourquoi traitons-nous vos données ?</h2>
          <p>
            Dans le cadre des services proposés, nous sommes nécessairement
            amenés à traiter vos données personnelles pour{' '}
            <b>les raisons et les fondements</b> suivants :
          </p>
          <ul>
            <li>
              Pour <b>utiliser et jouer à notre jeu concours</b> (ex : lancer le
              jeu, bénéficier d’un gain, recevoir le gain, etc.) et pour que
              nous puissions <b>répondre à vos demandes</b> (ex : demandes
              d’information, réclamations, etc.) sur la base de nos conditions
              générales de vente, de nos conditions générales d’utilisation, et
              de notre intérêt légitime à vous fournir le meilleur service
              possible.
            </li>
            <li>
              Pour rester informé de nos <b>dernières offres et événements</b>{' '}
              par email sur la base de votre consentement préalable.
            </li>
            <li>
              Pour{' '}
              <b>
                nous suivre et commenter nos publications sur les réseaux
                sociaux
              </b>{' '}
              sur la base des conditions générales d’utilisation du réseau
              social concerné (ex : Instagram) et de notre intérêt légitime à
              bénéficier d’une page dédiée sur les réseaux sociaux.
            </li>
            <li>
              Pour{' '}
              <b>
                garantir et renforcer la sécurité et la qualité de nos services
              </b>{' '}
              au quotidien (ex : statistiques, sécurité des données, etc.) sur
              la base des obligations légales pesant sur nous, de nos conditions
              générales de vente et de notre intérêt légitime à assurer le bon
              fonctionnement de nos services.
            </li>
            <li>
              Enfin, nous pouvons également installer des <b>“Cookies”</b> sur
              votre terminal. Pour obtenir plus d’informations sur l’utilisation
              des “Cookies”, nous vous invitons à consulter notre “Politique
              Cookies”.
            </li>
          </ul>
          <p>
            Vos données sont collectées <b>directement auprès de vous</b>{' '}
            lorsque vous vous connectez à notre site internet et que vous faites
            appel à nos services.
          </p>
          <p>
            Nous nous engageons à ne traiter vos données que pour les{' '}
            <b>raisons décrites précédemment</b>. De plus, nous garantissons qu'
            <b>aucune de vos données</b> ne sera jamais vendue à un partenaire
            ou à un tiers. En revanche, dès lors que vous publiez volontairement
            du contenu sur les pages que nous éditons sur les réseaux sociaux,
            vous reconnaissez être <b>entièrement responsable</b> de toute
            information personnelle que vous pourriez transmettre, quelle que
            soit la nature et l’origine de l’information fournie.
          </p>
          <h2 className="mt-5 mb-3">
            Quelles données traitons-nous et pour combien de temps ?
          </h2>
          <p>
            Nous avons résumé les catégories de données personnelles que nous
            collectons ainsi que leurs durées respectives de conservation.
          </p>
          <p>
            Si vous souhaitez obtenir encore plus de détails sur les durées de
            conservation applicables à vos données, vous pouvez nous contacter à
            l’adresse : {props.gdprEmail}.
          </p>
          <ul>
            <li>
              <b>Données d’identification</b> (ex : nom, prénom) et{' '}
              <b>coordonnées</b> (ex : adresse email) conservées pendant toute
              la durée de la fourniture du service à laquelle s'ajoutent les
              délais de prescriptions de 90 jours.
            </li>
            <li>
              Données aux fins de <b>prospection commerciale, marketing</b> (ex
              : adresse email, etc.) conservées pendant une durée maximum de 3
              ans à compter du dernier contact que nous avons eu avec vous.
            </li>
            <li>
              Données de <b>connexion</b> (ex : logs, adresse IP, etc.)
              conservées pendant une durée de 1 an.
            </li>
            <li>
              <b>Cookies</b> qui sont en général conservés pendant une durée de
              13 mois maximum. Pour plus de détails sur l'utilisation que nous
              faisons de vos cookies, vous pouvez consulter notre politique
              cookies accessible à tout moment sur notre site internet.
            </li>
          </ul>
          <p>
            A l’expiration des durées de conservation résumées précédemment,
            nous <b>supprimons</b> toutes vos données personnelles afin de
            garantir votre confidentialité pour les années futures.
          </p>
          <p>
            La suppression de vos données personnelles est <b>irréversible</b>{' '}
            et nous ne pourrons plus vous les communiquer passé ce délai. Tout
            au plus, nous pouvons uniquement conserver des données anonymes à
            des fins de statistiques.
          </p>
          <p>
            Veuillez également noter qu’en cas de <b>contentieux</b>, nous avons
            pour obligation de conserver l’intégralité des données vous
            concernant durant toute la durée de traitement du dossier même après
            l’expiration de leurs durées de conservation décrites précédemment.
          </p>
          <h2 className="mt-5 mb-3">
            De quels droits disposez-vous pour contrôler l’usage de vos données
            ?
          </h2>
          <p>
            La réglementation applicable en matière de protection des données
            vous octroie <b>des droits spécifiques</b> que vous pouvez exercer,{' '}
            <b>à tout moment et gratuitement</b>, afin de contrôler l’usage que
            nous faisons de vos données.
          </p>
          <ul>
            <li>
              Droit d’<b>accès</b> et de <b>copie</b> de vos données
              personnelles dès lors que cette demande n’est pas en contradiction
              avec le secret des affaires, la confidentialité, ou encore le
              secret des correspondances.
            </li>
            <li>
              Droit de <b>rectification</b> de données personnelles qui seraient
              erronées, obsolètes ou incomplètes.
            </li>
            <li>
              Droit de vous <b>opposer</b> aux traitements de vos données
              personnelles mis en œuvre à des fins de prospection commerciale.
            </li>
            <li>
              Droit de demander <b>l’effacement</b> (“droit à l’oubli”) de vos
              données personnelles qui ne seraient pas essentielles au bon
              fonctionnement de nos services.
            </li>
            <li>
              Droit à la <b>limitation</b> de vos données personnelles qui
              permet de photographier l’utilisation de vos données en cas de
              contestation sur la légitimité d’un traitement.
            </li>
            <li>
              Droit à la <b>portabilité</b> de vos données qui vous permet de
              récupérer une partie de vos données personnelles afin de les
              stocker ou les transmettre facilement d’un système d’information à
              un autre.
            </li>
            <li>
              Droit de donner des <b>directives</b> sur le sort de vos données
              en cas de décès soit par votre intermédiaire, soit par
              l’intermédiaire d’un tiers de confiance ou d’un ayant-droit.
            </li>
          </ul>
          <p>
            Pour qu’une demande soit <b>prise en compte</b>, il est impératif
            qu’elle soit effectuée directement par vous à l’adresse{' '}
            {props.gdprEmail}. Toute demande qui n’est pas effectuée de cette
            manière ne peut pas être traitée.
          </p>
          <p>
            Les demandes ne peuvent pas émaner d’une autre personne que vous.
            Nous pouvons donc vous demander la communication d’une{' '}
            <b>preuve d’identité</b> en cas de doute sur l’identité du
            demandeur.
          </p>
          <p>
            Veuillez noter que nous pouvons toujours <b>refuser</b> de répondre
            à toute demande <b>excessive ou infondée</b> notamment au regard de
            son caractère <b>répétitif</b>.
          </p>
          <h2 className="mt-5 mb-3">Qui peut avoir accès à vos données ?</h2>
          <p>
            Nous ne communiquons vos données qu’aux personnes{' '}
            <b>dûment autorisées</b> à les utiliser pour mettre en œuvre nos
            services. Il peut notamment s’agir de notre personnel en charge de
            la mise en œuvre du service et du marketing.
          </p>
          <h2 className="mt-5 mb-3">Comment protégeons-nous vos données ?</h2>
          <p>
            Nous mettons en œuvre tous les <b>moyens techniques</b> et{' '}
            <b>organisationnels requis</b> pour garantir la <b>sécurité</b> de
            vos données au quotidien et, en particulier, pour lutter contre tout
            risque de destruction, perte, altération, ou divulgation de vos
            données qui ne serait pas autorisée (ex : formation, contrôle des
            accès, mots de passe, antivirus, "https", etc.).
          </p>
          <h2 className="mt-5 mb-3">
            Vos données peuvent-elles être transférées en dehors de l’Union
            européenne ?
          </h2>
          <p>
            Sauf si cela s’avère strictement nécessaire et de manière
            exceptionnelle, nous ne transférons jamais vos données en dehors de
            l’Union européenne et vos données sont toujours hébergées sur le{' '}
            <b>sol européen</b>. En outre, nous faisons notre maximum pour ne
            recruter que des prestataires hébergeant vos données au sein de
            l’Union européenne.
          </p>
          <p>
            Dans le cas où nos prestataires seraient néanmoins amenés à
            transférer des données personnelles vous concernant en dehors de
            l’Union européenne, nous veillons scrupuleusement à ce qu’ils
            mettent en œuvre les
            <b>
              garanties appropriées afin d’assurer la confidentialité et la
              protection de vos données
            </b>
            .
          </p>
          <h2 className="mt-5 mb-3">
            Qui pouvez-vous contacter pour obtenir plus d’informations ?
          </h2>
          <p>
            Notre <b>Délégué à la protection des données</b> (“DPO”) est
            toujours à votre disposition pour vous expliquer plus en détail la
            manière dont nous traitons vos données et pour répondre à vos
            questions sur le sujet à l’adresse suivante: {props.gdprEmail}.
          </p>
          <h2 className="mt-5 mb-3">Comment pouvez-vous contacter la CNIL ?</h2>
          <p>
            Vous pouvez à tout moment contacter{' '}
            <b>
              l’autorité de contrôle française en matière de protection des
              données
            </b>{' '}
            (la “Commission nationale de l'informatique et des libertés” ou
            “CNIL”) aux coordonnées suivantes : Service des plaintes de la CNIL,
            3 place de Fontenoy – TSA 80751, 75334 Paris Cedex 07 ou par
            téléphone au 01.53.73.22.22.
          </p>
          <h2 className="mt-5 mb-3">La politique peut-elle être modifiée ?</h2>
          <p>
            Nous sommes susceptibles de modifier notre politique de
            confidentialité à <b>tout moment</b> pour l’adapter aux nouvelles{' '}
            <b>exigences légales</b> ainsi qu’aux <b>nouveaux traitements</b>{' '}
            que nous pourrions mettre en œuvre dans le futur. Vous serez
            évidemment informés de toute modification de cette politique.
          </p>
          <p>Certifié conforme par Dipeeo ®</p>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy
