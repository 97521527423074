import React, { useCallback, useRef, useState } from 'react'
import { tt } from '../../i18n'
import TSConstants from '../../utils/TSConstants'
import { ModalControlsI } from '../../hooks/useModalControls'
import { useDispatch, useSelector } from 'react-redux'
import { lotteryConfigSelector } from '../../redux/selectors/lottery'
import {
  showCookesPolicyModal,
  showPrivacyPolicyModal,
} from '../../redux/actions/app'
import CONFIG from '../../model/TSConfig'
import Hotjar from '../../analytics/Hotjar'
import amplitude from 'amplitude-js'

interface Props {}

let hasInitializedAmplitude = false

export default function Cookies(props: Props) {
  const config = useSelector(lotteryConfigSelector)
  const [hide, setHide] = useState(false)
  const dispatch = useDispatch()

  const acceptCookies = () => {
    setCookie('accepts-cookies', 'true')
    setHide(true)
  }

  const refuseCookies = () => {
    setCookie('accepts-cookies', 'false')
    setHide(true)
  }

  const showCookiesPolicy = () => {
    dispatch(showCookesPolicyModal())
  }

  if (!config || !config.displayCookiesBanner) {
    return null
  }

  const hasAccepted = getCookie('accepts-cookies')

  if (hasAccepted) {
    if (!hasInitializedAmplitude && CONFIG.ENABLE_AMPLITUDE) {
      amplitude
        .getInstance()
        .init('030d59f452b88e60782775a437163bd2', undefined, {
          serverZone: 'EU',
          serverZoneBasedApi: true,
        })
    }
    return <>{CONFIG.ENABLE_HOTJAR && <Hotjar />}</>
  }

  if (hide) {
    return null
  }

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: '#000000AA',
          zIndex: 1048,
        }}
      />
      <div
        className="d-flex flex-column align-items-center"
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          padding: 15,
          zIndex: 1049,
          color: 'white',
        }}
      >
        <span className="d-block mb-4 text-center">{tt('cookies_text')}</span>
        <div className="d-flex flex-row w-100 mb-4">
          <div className="d-flex w-50 justify-content-center">
            <span
              className="d-block px-4 py-3"
              style={{
                border: '1px solid white',
                borderRadius: 5,
              }}
              onClick={refuseCookies}
            >
              {tt('cookies_refuse')}
            </span>
          </div>
          <div className="d-flex w-50 justify-content-center">
            <span
              id={'cookies_accept'}
              className="d-block px-4 py-3"
              style={{
                borderRadius: 5,
                backgroundColor: 'limegreen',
              }}
              onClick={acceptCookies}
            >
              {tt('cookies_accept')}
            </span>
          </div>
        </div>
        <span className="d-block">
          <span
            style={{
              marginLeft: 2,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={showCookiesPolicy}
          >
            {tt('read_cookies_policy')}
          </span>
        </span>
      </div>
    </>
  )
}

function setCookie(name: string, val: string) {
  const date = new Date()
  const value = val

  // Set it expire in 10 years
  date.setTime(
    date.getTime() +
      365 * 10 * TSConstants.secondsInADay * TSConstants.secondsInAMillisecond
  )

  // Set it
  document.cookie =
    name + '=' + value + '; expires=' + date.toUTCString() + '; path=/'
}

function getCookie(name: string): string | undefined {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')

  if (parts && parts.length == 2) {
    const part = parts.pop()
    if (part) {
      return part.split(';').shift()
    }
  }
  return undefined
}

function deleteCookie(name: string) {
  const date = new Date()

  // Set it expire in -1 days
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000)

  // Set it
  document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/'
}
