import React from 'react'

export default function PotentialFraud() {
  return (
    <>
      <h2 className="w-margin-bottom-half">Cadeau bloqué</h2>
      <p>
        Vous ne pouvez pas utiliser la même adresse email pour gagner 2 cadeaux
        le même jour.
      </p>
    </>
  )
}
