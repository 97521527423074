export function playSound(sound: HTMLAudioElement, loop?: boolean) {
  try {
    sound.loop = !!loop
    sound.currentTime = 0
    sound.play().catch((err) => {
      console.error(err)
    })
  } catch (err) {
    console.error(err)
  }
}

export function getRandomIntInRange(min: number, max: number) {
  return Math.trunc(Math.random() * (max - min) + min)
}
