import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import { renderLogo } from './renderLogo'
import TSConstants from '../../utils/TSConstants'
import { tt } from '../../i18n'
import React from 'react'

export function renderChromeOnIOS(config?: LotteryConfigurationResponse) {
  return (
    <div className="game-container">
      {config && renderLogo(config)}
      {!config && (
        <div className="cm-pad logo-container">
          <img
            className="twic logo"
            src={TSConstants.PLACEHOLDER_URL}
            data-src="image:/cdm/kadow/img/logo_2.png"
          />
        </div>
      )}
      <div className="cm-pad w-margin-top-half w-margin-bottom-half">
        <h2 className="h2 highlight">
          {tt('google_chrome_not_supported_text_1')}
        </h2>
      </div>
      <div className="cm-pad w-margin-bottom-half d-flex align-items-center justify-content-center">
        <p style={{ overflowWrap: 'anywhere' }}>
          Pour jouer, copiez/collez l'adresse suivante dans Safari :{' '}
          {window.location.href}.
        </p>
      </div>
    </div>
  )
}
