import { Dispatch } from 'redux'
import { SMS_AUTH_MODAL_VALIDATE_CODE } from '../reducers/lottery'
import Axios from 'axios'
import axios, { AxiosResponse } from 'axios'
import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import LotteryGameConsume from '@tootsweet/model/lottery/LotteryGameConsume'
import { RootState } from '../combinedReducers'
import axiosRetry from 'axios-retry'
import UserDTO from '@tootsweet/model/datatransfer/UserDTO'
import VenueDTO from '@tootsweet/model/datatransfer/VenueDTO'
import MonthlyLottery from '@tootsweet/model/lottery/MonthlyLottery'
import MonthlyLotteryDraw from '@tootsweet/model/lottery/MonthlyLotteryDraw'
import Config from '../../model/config'
import TSStoreUtils from '../../utils/TSStoreUtils'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import BaseResponse from '../../model/BaseResponse'
import GameWinner from '@tootsweet/model/lottery/GameWinner'
import {
  async_loadFingerprint,
  setUser,
  setUserPaymentEmail,
  setUserPhone,
} from './app'
import GameAndConfigAndVenue from '../../model/GameAndConfigAndVenue'
import { tt, updateResourcesFromSkin } from '../../i18n'
import { setDefaultColorsIfNeeded } from '../../components/FortuneWheel/WheelColors'
import WheelAnalyticsManager from '../../analytics/WheelAnalyticsManager'
import {
  STATUS_INCORRECT_SMS_AUTH_CODE,
  STATUS_OK,
  STATUS_SMS_AUTH_ALREADY_DONE,
  STATUS_SUSPECTED_FRAUD,
  STATUS_TOO_MUCH_GAMES_FOR_PHONE_NUMBER,
} from '../../utils/StatusCodes'
import Skin from '../../model/Skin'
import { LotteryStatus } from '../../model/LotteryStatus'
import Signature from '@tootsweet/model/lottery/Signature'
import { setQuizzStep } from './quizz'
import { QUIZZ_STEP_HOME, QUIZZ_STEP_ML_INTRO } from '../reducers/quizz'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import LotteryType from '@tootsweet/model/lottery/LotteryType'
import { TSUtils } from '../../utils/TSUtils'

export const SET_LOTTERY_LOADING = 'SET_LOTTERY_LOADING'
export const SET_LOTTERY_DATA = 'SET_LOTTERY_DATA'
export const SET_LOTTERY_PRIZE = 'SET_LOTTERY_PRIZE'
export const SET_LOTTERY_STEP = 'SET_LOTTERY_STEP'
export const SET_LOTTERY_VALIDATE = 'SET_LOTTERY_VALIDATE'
export const SET_LOTTERY_RESET = 'SET_LOTTERY_RESET'
export const SET_LOTTERY_STATUS = 'SET_LOTTERY_STATUS'
export const SET_LOTTERY_PICTURE = 'SET_LOTTERY_PICTURE'
export const SET_LOTTERY_MODAL_STATE = 'SET_LOTTERY_MODAL_STATE'
export const SET_LOTTERY_UPLOAD_PROGRESS = 'SET_LOTTERY_UPLOAD_PROGRESS'
export const SET_LOTTERY_LIKES_COUNT = 'SET_LOTTERY_LIKES_COUNT'
export const SET_LOTTERY_GAME_FOR_MANUAL_CONSUME =
  'SET_LOTTERY_GAME_FOR_MANUAL_CONSUME'
export const NEXT_LOTTERY_STEP = 'NEXT_LOTTERY_STEP'
export const SET_IS_OPTOUT = 'SET_IS_OPTOUT'
export const SET_VENUE = 'SET_VENUE'
export const SET_HAS_EMAIL_OPTIN = 'SET_HAS_EMAIL_OPTIN'
export const SET_HAS_PHONE_OPTIN = 'SET_HAS_PHONE_OPTIN'
export const SET_NEXT_GAME_MINIMAL_TS = 'SET_NEXT_GAME_MINIMAL_TS'
export const SET_LAST_3_WINNERS = 'SET_LAST_3_WINNERS'
export const SET_HELP_MODAL_ERROR = 'SET_HELP_MODAL_ERROR'
export const KEY_LAST_GAME_ID = 'lgid'
export const SET_SPONSORSHIP_ID_STEP_2 = 'SET_SPONSORSHIP_ID_STEP_2'
export const SET_SPONSORSHIP_URL = 'SET_SPONSORSHIP_URL'
export const SET_IS_DEMO = 'SET_IS_DEMO'
export const SET_ADVENT_CALENDAR_SHOWN = 'SET_ADVENT_CALENDAR_SHOWN'
export const OFFER_TO_FRIEND = 'OFFER_TO_FRIEND'
export const SET_SHOW_TUTO = 'SET_SHOW_TUTO'
export const SET_FORM_DATA = 'SET_FORM_DATA'
export const SET_FORM_DATA_OBJ = 'SET_FORM_DATA_OBJ'
export const INC_CTA_CLICK_COUNT = 'INC_CTA_CLICK_COUNT'
export const SET_CURRENT_SESSION_FIRST_NAME_AND_EMAIL =
  'SET_CURRENT_SESSION_FIRST_NAME_AND_EMAIL'
export const SET_SMS_AUTH_MODAL_MODE = 'SET_SMS_AUTH_MODAL_MODE'
export const SHOW_SMS_AUTH_MODAL = 'SHOW_SMS_AUTH_MODAL'
export const SET_SKIN = 'SET_SKIN'
export const SET_ML_CONFIG = 'SET_ML_CONFIG'
export const SET_ML_DRAW = 'SET_ML_DRAW'
export const SET_SHOW_ML_OPTIN_MODAL = 'SET_SHOW_ML_OPTIN_MODAL'
export const SET_CAN_REPLAY = 'SET_CAN_REPLAY'
export const SET_SECONDARY_OPTIN = 'SET_SECONDARY_OPTIN'

axiosRetry(axios, {
  retries: 5,
  retryDelay: (retryCount) => {
    // console.log(`retry attempt: ${retryCount}`);
    return retryCount * 1000
  },
  retryCondition: (error) => {
    return !!(
      error &&
      error.response &&
      error.response.status >= 502 &&
      error.response.status <= 504
    )
  },
})

export const setShowSMSAuthModal = (show: boolean) => {
  return {
    type: SHOW_SMS_AUTH_MODAL,
    show,
  }
}

export const setSMSAuthModalMode = (mode: number) => {
  return {
    type: SET_SMS_AUTH_MODAL_MODE,
    mode,
  }
}

export const setLotteryLoading = (
  loading: boolean,
  loaderStep?: LotteryV2Step
) => {
  return {
    type: SET_LOTTERY_LOADING,
    loading,
    loaderStep,
  }
}

export const setLotteryModalState = (lotteryModalState: boolean) => {
  return {
    type: SET_LOTTERY_MODAL_STATE,
    lotteryModalState,
  }
}

export const setLotteryConfiguration = (
  lotteryConfig: LotteryConfigurationResponse | undefined
) => {
  setDefaultColorsIfNeeded(lotteryConfig)
  return {
    type: SET_LOTTERY_DATA,
    lotteryConfig,
  }
}

export const setLotteryPrize = (
  index: number,
  label: string,
  prizeLongDesc: string | undefined,
  win: boolean,
  mustValidate: boolean,
  isMoney: boolean,
  giftId: string
) => {
  return {
    type: SET_LOTTERY_PRIZE,
    index,
    label,
    prizeLongDesc,
    win,
    mustValidate,
    isMoney,
    giftId,
  }
}

export const setLotteryPicture = (picture: any) => {
  return {
    type: SET_LOTTERY_PICTURE,
    picture,
  }
}

export const setLotteryStep = (step: string) => {
  return {
    type: SET_LOTTERY_STEP,
    step,
  }
}

export const setLotteryUploadProgress = (percentCompleted: number | null) => {
  return {
    type: SET_LOTTERY_UPLOAD_PROGRESS,
    percentCompleted,
  }
}

export const setLotteryStatus = (status: number) => {
  return {
    type: SET_LOTTERY_STATUS,
    status,
  }
}

export const setLotteryValidate = (validate: boolean) => {
  return {
    type: SET_LOTTERY_VALIDATE,
    validate,
  }
}

export const resetLottery = () => {
  return {
    type: SET_LOTTERY_RESET,
  }
}

export const setLotteryGameConsume = (
  lotteryGameForConsume: LotteryGameConsume | null
) => {
  return {
    type: SET_LOTTERY_GAME_FOR_MANUAL_CONSUME,
    lotteryGameForConsume,
  }
}

export const setStateAccordingToErrorStatus = (
  payload: any,
  dispatch: Dispatch<any>
) => {
  if (payload) {
    dispatch(setLotteryStatus(payload.status))
  } else {
    dispatch(setLotteryStatus(LotteryStatus.GenericError))
  }
  dispatch(setLotteryLoading(false))
}

export const async_setLotteryPicture =
  (config: Config, file: any) => async (dispatch: Dispatch, getState: any) => {
    const token = TSStoreUtils.getUserToken(getState())

    try {
      const formData = new FormData()
      formData.append('file', file)

      const res = await Axios.post(
        `${config.BACKEND_URL}/lottery_v2/upload`,
        formData,
        {
          method: 'POST',
          headers: {
            'x-ts-token': token || '',
          },
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            dispatch(setLotteryUploadProgress(percentCompleted))
          },
        }
      )

      dispatch(setLotteryPicture(res.data))
      dispatch(setLotteryUploadProgress(100))
    } catch (err) {
      // @ts-ignore
      if (err && err.response && err.response.data) {
        // @ts-ignore
        setStateAccordingToErrorStatus(err.response.data, dispatch)
      }

      dispatch(setLotteryLoading(false))
    }
  }

export const async_uploadSocialPhoto =
  (config: Config, file: any) => async (dispatch: Dispatch, getState: any) => {
    const state = getState()
    const token = TSStoreUtils.getUserToken(state)

    let venueId = ''

    if (state.lottery.venue) {
      venueId = state.lottery.venue.id
    }

    try {
      dispatch(setLotteryUploadProgress(null))

      const formData = new FormData()
      formData.append('file', file)

      await Axios.post(
        `${config.BACKEND_URL}/lottery_v2/upload/social`,
        formData,
        {
          method: 'POST',
          headers: {
            'x-ts-token': token || '',
          },
          params: {
            venueId,
          },
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            dispatch(setLotteryUploadProgress(percentCompleted))

            if (percentCompleted >= 100) {
              dispatch(setLotteryLoading(true))
            }
          },
        }
      )
      dispatch(nextLotteryStep())
    } catch (err) {
      // @ts-ignore
      if (err && err.response && err.response.data) {
        // @ts-ignore
        setStateAccordingToErrorStatus(err.response.data, dispatch)
      }
    } finally {
      dispatch(setLotteryLoading(false))
    }
  }

export const async_hasAlreadyPlayed =
  (config: Config, token: string, skipStep: boolean, draftID?: string | null) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState()
    dispatch(setLotteryLoading(true))

    try {
      const params: any = {
        sponsorshipId: state.lottery.sponsorshipIdStep2,
        draftID
      }

      if (state.lottery.venue) {
        params.venueId = state.lottery.venue
          ? state.lottery.venue.id
          : undefined
      }

      params.fingerprint = state.app.fingerprint
      params.lng = getLanguage(state)

      const res = await Axios(
        `${config.BACKEND_URL}/lottery_v2/hasAlreadyPlayed`,
        {
          method: 'POST',
          headers: { 'x-ts-token': token || '' },
          params,
        }
      )


      if (res.data.status === LotteryStatus.Success) {
        dispatch(setLotteryStatus(LotteryStatus.Success))
        dispatch(setLotteryLoading(false))

        const lotteryConfig: LotteryConfigurationResponse = res.data.config
        dispatch(setLotteryConfiguration(lotteryConfig))



        if (lotteryConfig?.isDisabled && lotteryConfig?.disabledRedirURL) {
          const url = new URL(lotteryConfig?.disabledRedirURL)
          const searchParams = new URLSearchParams(window.location.search)
          if (searchParams.has('utm_campaign')) {
            url.searchParams.set(
              'utm_campaign',
              searchParams.get('utm_campaign') as string
            )
          }
          if (searchParams.has('utm_medium')) {
            url.searchParams.set(
              'utm_medium',
              searchParams.get('utm_medium') as string
            )
          }
          if (searchParams.has('utm_source')) {
            url.searchParams.set(
              'utm_source',
              searchParams.get('utm_source') as string
            )
          }
          document.location.href = url.toString()
          return
        }

        if (lotteryConfig?.hasML) {
          dispatch(setQuizzStep(QUIZZ_STEP_ML_INTRO))
        }

        /**
         * TODO PPE: refactor this
         * Explanation: the first call makes the step state go from Unknown to SearchBar, which renders the home screen
         * The second call makes the step state go from SearchBar to the first step contained in the config
         * => find a solution in which SearchBar is only used when the appropriate parameters are not passed in the URL
         */
        if (state.lottery.venue) {
          dispatch(nextLotteryStep())
        }

        if (skipStep) {
          dispatch(nextLotteryStep())
        }

      } else {
        setStateAccordingToErrorStatus(res.data, dispatch)
      }
    } catch (err) {

      // @ts-ignore
      if (err && err.response && err.response.data) {
        // @ts-ignore
        dispatch(setNextGameMinimalTs(err.response.data.nextGameMinimalTs))

        const lotteryConfig: LotteryConfigurationResponse =
          // @ts-ignore
          err.response.data.config

        if (lotteryConfig) {
          dispatch(setLotteryConfiguration(lotteryConfig))
        }
        // @ts-ignore
        const payload = err.response.data
        dispatch(setLotteryStatus(payload.status))
      }
    } finally {
      dispatch(setLotteryLoading(false))
    }
  }

export const async_updateUserInfo =
  (
    firstName: string | undefined | null,
    email: string | undefined | null,
    phone: string | undefined | null,
    lastName: string | undefined | null
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState()
    const token = state.app.user?.token
    const config = state.config

    if (email) {
      dispatch(setUserPaymentEmail(firstName, email))
    }

    if (phone) {
      dispatch(setUserPhone(firstName, phone))
    }

    try {
      const response = await Axios({
        url: config.AUTH_URL + '/account/change',
        method: 'POST',
        headers: {
          'x-ts-token': token || '',
          'Content-Type': 'application/json',
        },
        params: {
          paymentEmail: email,
          phone,
          firstName,
          lastName,
          lng: getLanguage(state),
        },
      })

      if (firstName && email) {
        dispatch(setCurrentSessionFirstNameAndEmail(firstName, email))
      }

      if (response && response.data) {
        const newUser: UserDTO = response.data
        storeUserLocally(newUser, dispatch, getState())
      }
    } catch (err) {
      // @ts-ignore
      if (err && err.response && err.response.data) {
        dispatch(setLotteryStatus(LotteryStatus.UserUpdateError))
      } else {
        dispatch(setLotteryStatus(LotteryStatus.GenericError))
      }
    }
  }

function getLanguage(state: RootState) {
  let lng = 'fr-FR'
  try {
    lng = state.app.lng || navigator.language
  } catch (err) {}
  return lng
}

export const async_play =
  (
    config: Config,
    firstName: string | undefined,
    email: string | undefined,
    isOptout: boolean,
    phone: string | undefined,
    isCDM: boolean | undefined
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState()
    const token = TSStoreUtils.getUserToken(state)

    if (state.lottery.isDemo) {
      return
    }

    if (state.lottery.lotteryGameConsume) {
      //alert(tt('technical_error'))
      return
    }

    try {
      const res = await Axios(`${config.BACKEND_URL}/lottery_v2/play`, {
        method: 'POST',
        headers: {
          'x-ts-token': token || '',
        },
        params: {
          isOptout,
          isCDM,
          utm_campaign: state.app.utm_campaign,
          utm_source: state.app.utm_source,
          utm_medium: state.app.utm_medium,
          fingerprint: state.app.fingerprint,
          venueId: state.lottery.venue ? state.lottery.venue.id : undefined,
          hasEmailOptin: !isOptout && state.lottery.hasEmailOptin,
          hasPhoneOptin: !isOptout && state.lottery.hasPhoneOptin,
          sponsorshipId: state.lottery.sponsorshipIdStep2,
          lng: getLanguage(state),
        },
        data: state.lottery.lotteryConfig?.wheelItems,
      })

      const payload = res.data

      if (payload.status === LotteryStatus.Success) {
        dispatch(
          setLotteryPrize(
            payload.prizeIndex,
            payload.prizeLabel,
            payload.prizeLongDesc,
            payload.data,
            payload.mustValidate,
            payload.isMoney,
            payload.giftId
          )
        )

        if (payload && payload.game && payload.game.id) {
          localStorage.setItem(KEY_LAST_GAME_ID, payload.game.id)
        }

        if (payload.last3Winners) {
          dispatch(setLast3Winners(payload.last3Winners))
        }

        dispatch(setLotteryGameConsume(payload.game))
      }
    } catch (err) {
      // @ts-ignore
      if (err && err.response && err.response.data) {
        // @ts-ignore
        const payload = err.response.data
        setStateAccordingToErrorStatus(payload, dispatch)
      }
    } finally {
    }
  }

export const async_getConfig =
  (config: Config, token: string, venueId: string, hideLoader?: boolean) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    if (!hideLoader) {
      dispatch(setLotteryLoading(true))
    }

    const res = await Axios(`${config.BACKEND_URL}/lottery_v2/config`, {
      method: 'GET',
      headers: { 'x-ts-token': token || '' },
      params: {
        venueId,
        lng: getLanguage(getState()),
      },
    })

    if (res.data) {
      const lotteryConfig: LotteryConfigurationResponse = res.data
      dispatch(setLotteryConfiguration(lotteryConfig))

      if (res.data.venue) {
        dispatch(setVenue(res.data.venue))
      }
    } else {
      dispatch(setLotteryStatus(LotteryStatus.GenericError))
    }

    if (!hideLoader) {
      dispatch(setLotteryLoading(false))
    }
  }

export const async_lotteryValidate =
  (
    config: Config,
    phoneNumber: string | undefined,
    email: string | undefined,
    eventId: string | undefined,
    token: string,
    isCDM: boolean
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setLotteryLoading(true))
    const state = getState()

    const params: any = {
      phoneNumber,
      useTrackingID: true,
      email,
      isCDM,
    }

    if (state.lottery.venue) {
      params.venueId = state.lottery.venue ? state.lottery.venue.id : undefined
    }

    const res = await Axios(`${config.BACKEND_URL}/lottery_v2/validate`, {
      method: 'POST',
      headers: { 'x-ts-token': token || '' },
      params,
    })

    const user = state.app.user

    if (
      user &&
      (email || phoneNumber) &&
      (user.paymentEmail !== email || user.phone !== phoneNumber)
    ) {
      await async_updateUserInfo(
        undefined,
        email,
        phoneNumber,
        undefined
      )(dispatch, getState)
    }

    if (res.data.status !== 0) {
      alert('Une erreur est survenue')
    } else {
      dispatch(setLotteryValidate(true))
    }

    dispatch(setLotteryLoading(false))
  }

export const setLotteryLikesCount = (likesCount: string) => {
  return {
    type: SET_LOTTERY_LIKES_COUNT,
    likesCount,
  }
}

export const async_getLotteryGameForManualValidation =
  (config: Config, id: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const res = await Axios(
        `${config.BACKEND_URL}/lottery_v2/gameAndConfig`,
        {
          method: 'GET',
          params: {
            id,
            lng: getLanguage(getState()),
          },
        }
      )


      const gameAndVenueAndConfig: GameAndConfigAndVenue = res.data

      dispatch(setLotteryGameConsume(gameAndVenueAndConfig.game))
      dispatch(setVenue(gameAndVenueAndConfig.venue))
      dispatch(setLotteryConfiguration(gameAndVenueAndConfig.config))

      if (gameAndVenueAndConfig.config.enableReplay) {
        await async_canReplay(gameAndVenueAndConfig.game.id)(dispatch, getState)
      }
    } catch (err) {
      dispatch(setLotteryGameConsume(null))
      // @ts-ignore
      if (err && err.response && err.response.data) {
        setStateAccordingToErrorStatus(null, dispatch)
      }
    }
  }

export const async_canReplay =
  (gameId: string) => async (dispatch: Dispatch, getState: () => RootState) => {
    await async_loadFingerprint()(dispatch)
    const state = getState()
    if (
      !state.lottery.venue ||
      !state.app.fingerprint ||
      !state.app.user?.token
    ) {
      alert(`${tt('error')} 16`)
      return
    }
    const res = await Axios(
      `${state.config.BACKEND_URL}/lottery_v2/canReplay`,
      {
        method: 'GET',
        headers: {
          'x-ts-token': state.app.user.token,
        },
        params: {
          venueId: state.lottery.venue.id,
          fp: state.app.fingerprint,
          gameId,
        },
      }
    )
    dispatch(setCanReplay(res.data))
  }

export const setCanReplay = (canReplay: boolean) => {
  return {
    type: SET_CAN_REPLAY,
    canReplay,
  }
}

export const async_consumeLotteryGame =
  (config: Config, id: string, code: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(setLotteryLoading(true))

      const res = await Axios(`${config.BACKEND_URL}/lottery_v2/game/consume`, {
        method: 'PUT',
        params: {
          id,
          code,
        },
      })

      if (res.status === 200) {
        dispatch(setLotteryGameConsume(res.data))
        dispatch(setLotteryLoading(false))
      } else {
        dispatch(setLotteryGameConsume(null))
        dispatch(setLotteryLoading(false))
      }
    } catch (err) {
      dispatch(setLotteryGameConsume(null))
      dispatch(setLotteryLoading(false))
    }
  }

export const nextLotteryStep = () => {
  return {
    type: NEXT_LOTTERY_STEP,
  }
}

export const setIsOptOut = (newIsOptOut: boolean) => {
  return {
    type: SET_IS_OPTOUT,
    isOptOut: newIsOptOut,
  }
}

export const setVenue = (venue: VenueDTO) => {
  return {
    type: SET_VENUE,
    venue,
  }
}

export const async_fetchVenue =
  (config: Config, venueId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const res = await Axios({
        method: 'get',
        url: config.BACKEND_URL + '/places/get',
        params: {
          id: venueId,
        },
      })
      if (res.data) {
        dispatch(setVenue(res.data))
      } else {
        setStateAccordingToErrorStatus(res.data, dispatch)
      }
    } catch (err) {
      // @ts-ignore
      if (err && err.response && err.response.data) {
        // @ts-ignore
        const payload = err.response.data
        setStateAccordingToErrorStatus(payload, dispatch)
      }
    }
  }

export const async_redeem =
  (config: Config, id: string, code: string | undefined, byOwner?: boolean) =>
  async (dispatch: Dispatch) => {
    try {
      if (!byOwner && !code) {
        alert(tt('four_digits_code_mandatory'))
        return
      }

      dispatch(setLotteryLoading(true))

      const res = await Axios({
        method: 'put',
        url: `${config.BACKEND_URL}/lottery_v2/redeem`,
        params: { id, code, byOwner },
      })

      const lotteryGameConsume = res.data
      dispatch(setLotteryGameConsume(lotteryGameConsume))

      switch (lotteryGameConsume.status) {
        case LotteryStatus.GameNotFound:
        case LotteryStatus.GameNotRedeemable:
        case LotteryStatus.GameHasNoRedemptionCode:
        case LotteryStatus.EmptyOrNullCode:
          setStateAccordingToErrorStatus(lotteryGameConsume, dispatch)
          break

        case LotteryStatus.AlreadyRedeemed:
          alert(tt('gift_redeemed'))
          break

        case LotteryStatus.BadRedemptionCode:
          alert(tt('incorrect_code'))
          break

        case LotteryStatus.TooEarlyToRedeem:
          alert(tt('too_early_to_redeem'))
          break
      }
    } catch (err) {
      // @ts-ignore
      if (err && err.response && err.response.data) {
        // @ts-ignore
        const payload = err.response.data
        setStateAccordingToErrorStatus(payload, dispatch)
      }
    } finally {
      dispatch(setLotteryLoading(false))
    }
  }

export const async_wheelFinishedSpinning =
  (config: Config) => async (dispatch: Dispatch, getState: any) => {
    dispatch(setLotteryLoading(true))

    WheelAnalyticsManager.logEvent('wheel_end_spin')

    try {
      const state: RootState = getState()
      const token = TSStoreUtils.getUserToken(state)
      const id = state.lottery.lotteryGameConsume?.id

      if (!id) {
        setStateAccordingToErrorStatus(null, dispatch)
        return
      }

      const res = await Axios(
        `${config.BACKEND_URL}/lottery_v2/afterGameAnimationCompleted`,
        {
          method: 'POST',
          headers: { 'x-ts-token': token || '' },
          params: {
            id,
          },
        }
      )

      if (res.data.status !== STATUS_OK) {
        alert(tt('error'))
        return
      }

      // console.log("state.lottery.lotteryStepIndex", state.lottery.lotteryStepIndex)
      // console.log("state.lottery.lotteryConfig.steps.length", state.lottery.lotteryConfig?.steps?.length)

      if (
        state.lottery.lotteryConfig &&
        state.lottery.lotteryConfig.steps &&
        state.lottery.lotteryStepIndex <
          state.lottery.lotteryConfig.steps.length - 1
      ) {
        dispatch(nextLotteryStep())
      } else {
        await goToRedeem(id)(dispatch, getState)
      }
    } catch (err) {
      setStateAccordingToErrorStatus(null, dispatch)
    } finally {
      dispatch(setLotteryLoading(false))
    }
  }

const storeUserLocally = (
  user: UserDTO,
  dispatch: Dispatch,
  state: RootState
) => {
  const existingUser = state.app.user

  if (existingUser && existingUser.token) {
    user.token = existingUser.token
  }
  localStorage.setItem('user', JSON.stringify(user))
  dispatch(setUser(user))
}

export const setHasEmailOptin = (hasEmailOptin: boolean) => {
  return {
    type: SET_HAS_EMAIL_OPTIN,
    hasEmailOptin,
  }
}

export const setHasPhoneOptin = (hasPhoneOptin: boolean) => {
  return {
    type: SET_HAS_PHONE_OPTIN,
    hasPhoneOptin,
  }
}

export const setNextGameMinimalTs = (nextGameMinimalTs: number) => {
  return {
    type: SET_NEXT_GAME_MINIMAL_TS,
    nextGameMinimalTs,
  }
}

export const async_setMcDonaldsID =
  (config: Config, mcDonaldsID: string) =>
  async (dispatch: Dispatch, getState: any) => {
    dispatch(setLotteryLoading(true))

    const state = getState()
    const token = TSStoreUtils.getUserToken(state)

    try {
      const res = await Axios(
        `${config.BACKEND_URL}/lottery_v2/setMcDonaldsID`,
        {
          method: 'PUT',
          headers: { 'x-ts-token': token || '' },
          params: {
            mcDonaldsID,
          },
        }
      )

      const response: BaseResponse<UserDTO> = res.data

      if (response.success) {
        const user = response.data
        storeUserLocally(user, dispatch, state)
        dispatch(nextLotteryStep())
      } else {
        setStateAccordingToErrorStatus(res.data, dispatch)
      }
    } catch (err) {
      // @ts-ignore
      if (err && err.response && err.response.data) {
        // @ts-ignore
        const payload = err.response.data
        dispatch(setLotteryStatus(payload.status))
      }
    } finally {
      dispatch(setLotteryLoading(false))
    }
  }

export const setLast3Winners = (last3Winners?: GameWinner[]) => {
  return {
    type: SET_LAST_3_WINNERS,
    last3Winners,
  }
}

// export const async_resend_gift_email = (config: Config, email: string) => async (dispatch: Dispatch, getState: any) => {
export const async_resend_gift_email =
  (
    config: Config,
    dateAndTime: string,
    gift: string,
    phoneNumber: string,
    email1: string,
    email2: string,
    placeName: string
  ) =>
  async (dispatch: Dispatch, getState: any) => {
    let emails: string[] = []
    emails.push(email1)
    emails.push(email2)

    if (!emails || emails.length === 0) {
      alert(
        'Vous devez renseigner au moins une adresse email à laquelle vous souhaitez recevoir votre coupon cadeau.'
      )
      return
    }

    dispatch(setLotteryLoading(true))

    const state = getState()
    const token = TSStoreUtils.getUserToken(state)
    try {
      const lgid = localStorage.getItem(KEY_LAST_GAME_ID)

      const res = await Axios(
        `${config.BACKEND_URL}/lottery_v2/resend_gift_email`,
        {
          method: 'POST',
          headers: { 'x-ts-token': token || '' },
          params: {
            lgid,
            fingerprint: state.app.fingerprint,
            dateAndTime,
            gift: gift || '',
            phoneNumber,
            placeName,
          },
          data: emails,
        }
      )

      const response: BaseResponse<LotteryGameConsume> = res.data

      if (response.success) {
        dispatch(setHelpModalError(0))
      } else {
        dispatch(setHelpModalError(1))
      }
    } catch (err) {
      dispatch(setHelpModalError(2))
    } finally {
      dispatch(setLotteryLoading(false))
    }
  }

export const setHelpModalError = (error: number) => {
  return {
    type: SET_HELP_MODAL_ERROR,
    error,
  }
}

export const setSponsorshipIdStep2 = (id: string) => {
  return {
    type: SET_SPONSORSHIP_ID_STEP_2,
    id,
  }
}

export const setSponsorshipURL = (url: string) => {
  return {
    type: SET_SPONSORSHIP_URL,
    url,
  }
}

export const async_fetchSponsorshipURL =
  (config: Config) => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const state = getState()

      let token = ''

      if (state.app.user && state.app.user.token) {
        token = state.app.user.token
      }

      let venueId = ''

      if (state.lottery.venue && state.lottery.venue.id) {
        venueId = state.lottery.venue.id
      }

      if (!venueId || !token) {
        alert('Erreur 11')
        return
      }

      dispatch(setLotteryLoading(true))

      const res = await Axios(`${config.BACKEND_URL}/lottery_v2/sponsor`, {
        method: 'POST',
        headers: { 'x-ts-token': token || '' },
        params: {
          venueId,
        },
      })

      if (res && res.data && res.data.data) {
        const url: string = res.data.data
        dispatch(setSponsorshipURL(url))
      } else {
        alert('Erreur technique 12')
      }
    } catch (err) {
      console.error(err)
      alert('Erreur technique 13')
    } finally {
      dispatch(setLotteryLoading(false))
    }
  }

export const goToRedeem =
  (id?: string) => async (dispatch: Dispatch, getState: () => RootState) => {
    if (id) {
      window.location.href = buildRedeemURL(id)
      return
    }

    const state = getState()

    if (!state.lottery.lotteryGameConsume) {
      alert('Error')
      return
    }

    window.location.href = buildRedeemURL(state.lottery.lotteryGameConsume.id)
  }

export const buildRedeemURL = (id: string) => {
  return `${
    window.location.origin + (window.location.pathname || '/')
  }?v_id=${id}`
}

export const registerOptinIfNeeded =
  (config: Config, hasEmailOptin: boolean, hasPhoneOptin: boolean) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState()

    if (state.lottery.isOptOut) {
      return
    }

    const gameId = state.lottery.lotteryGameConsume?.id

    if (!gameId) {
      alert('Erreur 10')
      return
    }

    const token = TSStoreUtils.getUserToken(state)

    const params: any = {
      hasEmailOptin,
      hasPhoneOptin,
      gameId,
    }

    try {
      await Axios(`${config.BACKEND_URL}/lottery_v2/optin`, {
        method: 'POST',
        headers: {
          'x-ts-token': token || '',
        },
        params,
      })
    } catch (err) {
      console.error(err)
    }
  }

export const setIsDemo = (isDemo: boolean) => {
  return {
    type: SET_IS_DEMO,
    isDemo,
  }
}

export const setAdventCalendarShown = (adventCalendarShown: boolean) => {
  return {
    type: SET_ADVENT_CALENDAR_SHOWN,
    adventCalendarShown,
  }
}

export const offerToFriend = (offerToFriendURL: string) => {
  return {
    type: OFFER_TO_FRIEND,
    offerToFriendURL,
  }
}

export const setShowTuto = (
  showTuto: boolean,
  tutoIsIG: boolean,
  tutoIsIGStory: boolean,
  tutoIsGoogle: boolean,
  tutoIsTikTok: boolean,
  tutoRenderCTA?: () => any
) => {
  return {
    type: SET_SHOW_TUTO,
    showTuto,
    tutoIsGoogle,
    tutoIsIG,
    tutoIsIGStory,
    tutoIsTikTok,
    tutoRenderCTA,
  }
}

export const setFormDataObj = (obj: any) => {
  return {
    type: SET_FORM_DATA_OBJ,
    obj,
  }
}

export const setFormData = (key: string, value: any) => {
  return {
    type: SET_FORM_DATA,
    key,
    value,
  }
}

export const async_saveFormData =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const state = getState()

      const token = state.app.user?.token
      const venueId = state.lottery.venue?.id
      const config = state.config

      if (!config || !venueId || !token) {
        alert(`${tt('error')} 17`)
        return
      }

      dispatch(setLotteryLoading(true))

      if (
        state.lottery.formData.email?.length ||
        state.lottery.formData.firstName?.length ||
        state.lottery.formData.lastName?.length ||
        state.lottery.formData.phone?.length
      ) {
        await async_updateUserInfo(
          state.lottery.formData.firstName || undefined,
          state.lottery.formData.email || undefined,
          state.lottery.formData.phone || undefined,
          state.lottery.formData.lastName || undefined
        )(dispatch, getState)
      }

      await Axios.post(
        `${config.BACKEND_URL}/lottery_v2/formdata`,
        state.lottery.formData,
        {
          method: 'POST',
          headers: {
            'x-ts-token': token || '',
          },
          params: {
            venueId,
          },
        }
      )
      dispatch(nextLotteryStep())
    } catch (err) {
      console.error(err)
    } finally {
      dispatch(setLotteryLoading(false))
    }
  }

export const incrementCTAClickCount = () => {
  return {
    type: INC_CTA_CLICK_COUNT,
  }
}

export const async_recordPoll =
  (config: Config, answer: string, firstName: string, email: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(setLotteryLoading(true))

      const state = getState()
      const token = TSStoreUtils.getUserToken(state)
      const venueId = state.lottery.venue?.id

      await Axios({
        url: `${config.BACKEND_URL}/poll`,
        method: 'POST',
        headers: {
          'x-ts-token': token || '',
        },
        params: { email, firstName, answer, venueId },
      })

      dispatch(incrementCTAClickCount())
    } catch (err) {
      // @ts-ignore
      if (err && err.response && err.response.data) {
        // @ts-ignore
        setStateAccordingToErrorStatus(err.response.data, dispatch)
      }
    } finally {
      dispatch(setLotteryLoading(false))
    }
  }

export const async_recordPoll_v2 =
  (config: Config, answer: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(setLotteryLoading(true))

      const state = getState()
      const token = TSStoreUtils.getUserToken(state)
      const venueId = state.lottery.venue?.id

      await Axios({
        url: `${config.BACKEND_URL}/poll/v2`,
        method: 'POST',
        headers: {
          'x-ts-token': token || '',
        },
        params: { answer, venueId },
      })

      dispatch(incrementCTAClickCount())
    } catch (err) {
      // @ts-ignore
      if (err && err.response && err.response.data) {
        // @ts-ignore
        setStateAccordingToErrorStatus(err.response.data, dispatch)
      }
    } finally {
      dispatch(setLotteryLoading(false))
    }
  }

export const async_lp_save =
  (
    config: Config,
    firstName: string,
    email: string,
    conjuredId: string,
    shop: string,
    cmp: string,
    friendEmail: string
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(setLotteryLoading(true))

      const time = new Date().getMilliseconds()
      await axios({
        method: 'post',
        url: `${config.BACKEND_URL}/lp/share_email`,
        params: {
          firstName,
          email,
          cnjrd: conjuredId,
          shop,
          cmp,
          r: window.location.href,
          time,
          emails: friendEmail,
        },
      })

      await async_updateUserInfo(
        firstName,
        email,
        undefined,
        undefined
      )(dispatch, getState)
      dispatch(setHasEmailOptin(true))
      dispatch(nextLotteryStep())
    } catch (err) {
      // @ts-ignore
      if (err && err.response && err.response.data) {
        // @ts-ignore
        setStateAccordingToErrorStatus(err.response.data, dispatch)
      }
    } finally {
      dispatch(setLotteryLoading(false))
    }
  }

const setCurrentSessionFirstNameAndEmail = (
  firstName: string,
  email: string
) => {
  return {
    type: SET_CURRENT_SESSION_FIRST_NAME_AND_EMAIL,
    firstName,
    email,
  }
}

export const async_request_sms_auth =
  (phoneNumber: string, config: Config) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState()
    const gameId = state.lottery.lotteryGameConsume?.id

    if (!gameId) {
      alert(tt('technical_error') + ' 21')
      return
    }

    dispatch(setLotteryLoading(true))

    try {
      await Axios(`${config.BACKEND_URL}/lottery_v2/requestsmsauth`, {
        method: 'POST',
        params: {
          gameId,
          phoneNumber,
        },
      })

      dispatch(setSMSAuthModalMode(SMS_AUTH_MODAL_VALIDATE_CODE))
    } catch (err) {
      // @ts-ignore
      if (err && err.response && err.response.data) {
        // @ts-ignore
        switch (err.response.data.status) {
          case STATUS_INCORRECT_SMS_AUTH_CODE:
            alert('Code SMS incorrect')
            break
          case STATUS_SMS_AUTH_ALREADY_DONE:
            alert('Déjà fait')
            break
          case STATUS_TOO_MUCH_GAMES_FOR_PHONE_NUMBER:
            alert(
              "L'établissement ne permet pas d'utiliser des codes promo 2 jours d'affilée."
            )
            break
          default:
            alert(tt('technical_error'))
            break
        }
      } else {
        alert(tt('technical_error'))
      }
    } finally {
      dispatch(setLotteryLoading(false))
    }
  }

export const async_sms_auth =
  (code: string, config: Config) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState()
    const gameId = state.lottery.lotteryGameConsume?.id

    if (!gameId) {
      alert(tt('technical_error'))
      return
    }

    dispatch(setLotteryLoading(true))

    try {
      const res = await Axios(`${config.BACKEND_URL}/lottery_v2/smsauth`, {
        method: 'POST',
        params: {
          gameId,
          code,
        },
      })

      const data: BaseResponse<LotteryGameConsume> = res.data

      if (data.success) {
        dispatch(setLotteryGameConsume(data.data))
        dispatch(setShowSMSAuthModal(false))
      } else {
        alert(`${tt('error')} 18`)
      }
    } catch (err) {
      console.error(err)
      alert(tt('technical_error'))
    } finally {
      dispatch(setLotteryLoading(false))
    }
  }

export const setSkin = (skin: Skin | null, skinId: string) => {
  updateResourcesFromSkin(skin)
  return {
    type: SET_SKIN,
    skin,
    skinId,
  }
}

export const async_fetch_skin =
  (skinId?: string) => async (dispatch: Dispatch) => {
    if (!skinId) {
      return
    }

    try {
      let res: AxiosResponse<Skin> = await Axios(
        `${TSUtils.buildSkinBaseURL(skinId)}/skin.json`
      )

      const skin = res.data

      if (skin) {
        dispatch(setSkin(skin, skinId))
      }
    } catch (err) {
      // console.error(err)
      dispatch(setSkin(null, skinId))
    }
  }

export const async_fetch_ml_config =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const state = getState()
      const config = state.config
      const token = state.app.user?.token
      const venueId = state.lottery.venue?.id

      if (!token || !venueId) {
        alert(`${tt('error')} 19`)
        return
      }

      const res = await Axios(`${config.BACKEND_URL}/ml`, {
        method: 'GET',
        headers: {
          'x-ts-token': token,
        },
        params: {
          venueId,
          suffix: state.lottery?.lotteryConfig?.mlSuffix || '',
        },
      })
      dispatch(setMLConfig(res.data))
    } catch (err) {
      console.error(err)
    }
  }

const setMLConfig = (mlConfig: MonthlyLottery) => {
  return {
    type: SET_ML_CONFIG,
    mlConfig,
  }
}

export const setMLDraw = (draw: MonthlyLotteryDraw) => {
  return {
    type: SET_ML_DRAW,
    draw,
  }
}

export const async_ml_draw =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const state = getState()
      const config = state.config
      const token = state.app.user?.token
      const venueId = state.lottery.venue?.id

      if (!token || !venueId) {
        return
      }

      if (state.lottery.draw) {
        return
      }

      const res: AxiosResponse<MonthlyLotteryDraw> = await Axios(
        `${config.BACKEND_URL}/ml`,
        {
          method: 'POST',
          headers: {
            'x-ts-token': token,
          },
          params: {
            fp: state.app.fingerprint,
            venueId,
            suffix: state.lottery?.lotteryConfig?.mlSuffix || '',
          },
        }
      )
      dispatch(setMLDraw(res.data))
    } catch (err) {
      console.error(err)
    }
  }

export const async_ml_advance_and_optin_if_needded =
  (firstName: string, email: string, phone: string | undefined) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      if (!firstName || !email) {
        dispatch(nextLotteryStep())
        return
      }

      const state = getState()
      const config = state.config
      const token = state.app.user?.token
      const draw = state.lottery.draw

      if (!token || !draw) {
        alert(`${tt('error')} 21`)
        return
      }

      if (firstName && email) {
        await async_updateUserInfo(
          firstName,
          email,
          phone,
          undefined
        )(dispatch, getState)
      }

      await Axios(`${config.BACKEND_URL}/ml/optin`, {
        method: 'PUT',
        headers: {
          'x-ts-token': token,
        },
        params: {
          id: draw.id,
          firstName,
          email,
          phone,
          optin: !state.lottery.isOptOut,
        },
      })

      dispatch(setShowMLOptinModal(false))

      if (
        state.lottery.lotteryConfig?.type === LotteryType.Quizz ||
        state.lottery.lotteryConfig?.type === LotteryType.Catcher
      ) {
        dispatch(setQuizzStep(QUIZZ_STEP_HOME))
      } else {
        dispatch(setLotteryStep(LotteryV2StepType.MLEnd))
      }
    } catch (err) {
      console.error(err)
      alert(`${tt('error')} 22`)
    }
  }

export const async_complete_signing_data =
  (signature: Signature) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const state = getState()
      const config = state.config
      const token = state.app.user?.token
      const game = state.lottery.lotteryGameConsume

      if (!token || !game) {
        alert(`${tt('error')} 23`)
        return
      }

      dispatch(setLotteryLoading(true))

      const res = await Axios(
        `${config.BACKEND_URL}/lottery_v2/complete_signing_data`,
        {
          method: 'PUT',
          headers: {
            'x-ts-token': token,
          },
          params: {
            id: game.id,
          },
          data: signature,
        }
      )

      dispatch(setLotteryGameConsume(res.data))
    } catch (err) {
      console.error(err)
      alert(`${tt('error')} 24`)
    } finally {
      dispatch(setLotteryLoading(false))
    }
  }

export const async_upload_id =
  (file: File) => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const state = getState()
      const config = state.config
      const token = state.app.user?.token
      const game = state.lottery.lotteryGameConsume

      if (!token || !game) {
        alert(`${tt('error')} 25`)
        return
      }

      dispatch(setLotteryUploadProgress(0))

      const formData = new FormData()
      formData.append('file', file)

      const res = await Axios(`${config.BACKEND_URL}/lottery_v2/upload_id`, {
        method: 'POST',
        headers: {
          'x-ts-token': token,
        },
        params: {
          id: game.id,
        },
        data: formData,
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          dispatch(setLotteryUploadProgress(percentCompleted))
        },
      })

      dispatch(setLotteryGameConsume(res.data))
    } catch (err) {
      console.error(err)
      alert(`${tt('error')} 26`)
    } finally {
      dispatch(setLotteryUploadProgress(100))
    }
  }

export const async_sign =
  (file: File) => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const state = getState()
      const config = state.config
      const token = state.app.user?.token
      const game = state.lottery.lotteryGameConsume

      if (!token || !game) {
        alert(`${tt('error')} 27`)
        return
      }

      const formData = new FormData()
      formData.append('file', file)

      dispatch(setLotteryLoading(true))

      const res = await Axios(`${config.BACKEND_URL}/lottery_v2/sign`, {
        method: 'POST',
        headers: {
          'x-ts-token': token,
        },
        params: {
          id: game.id,
        },
        data: formData,
      })

      dispatch(setLotteryGameConsume(res.data))
    } catch (err) {
      console.error(err)
      alert(`${tt('error')} 28`)
    } finally {
      dispatch(setLotteryLoading(false))
    }
  }

export const setShowMLOptinModal = (show: boolean) => {
  return {
    type: SET_SHOW_ML_OPTIN_MODAL,
    show,
  }
}

export const setSecondaryOptin = (secondaryOptin: boolean) => {
  return {
    type: SET_SECONDARY_OPTIN,
    secondaryOptin,
  }
}
