import i18n, { t } from 'i18next'
import en from './translations/en.json'
import fr from './translations/fr.json'
import LanguageDetector from 'i18next-browser-languagedetector'
import Translations from './translations/Translations'
import Skin from './model/Skin'

const resources = {
  en: { translation: en },
  fr: { translation: fr },
}

i18n.use(LanguageDetector).init({
  detection: {
    order: [
      'navigator',
      'cookie',
      'localStorage',
      'sessionStorage',
      'htmlTag',
      'querystring',
    ],
  },
  debug: false,
  resources,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export type TranslationKeys = keyof Translations

export function updateResourcesFromSkin(skin: Skin | null) {
  if (!skin || !skin.wordings) {
    return
  }
  if (skin.wordings.fr) {
    Object.keys(skin.wordings.fr).forEach((key) => {
      // @ts-ignore
      resources.fr.translation[key] = skin.wordings.fr[key]
    })
  }
  if (skin.wordings.en) {
    Object.keys(skin.wordings.en).forEach((key) => {
      // @ts-ignore
      resources.en.translation[key] = skin.wordings.en[key]
    })
  }
}
export function tt(key: TranslationKeys, vars?: any) {
  return t(key, vars)
}

export default i18n
