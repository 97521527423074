export const STATUS_OK = 0
export const STATUS_ALREADY_PLAYED = 1
export const STATUS_EVENT_NOT_FOUND = 2
export const STATUS_UPLOAD_ERROR = 3
export const STATUS_NO_SUCH_USER = 4
export const STATUS_NEVER_PLAYED = 6
export const STATUS_CANT_VALIDATE_A_LOST_GAME = 7
export const STATUS_CODE_PROMO_NOT_EXIST = 8
export const STATUS_CODE_PROMO_EXPIRED = 9
export const STATUS_CODE_PROMO_ALREADY_USED = 10
export const STATUS_CODE_PROMO_NOT_VALID_YET = 11
export const STATUS_ERROR_NO_CONF = 12
export const STATUS_ERROR_NO_STOCK = 13
export const STATUS_ERROR_GENERIC = 14
export const STATUS_NO_SUCH_PICTURE = 15
export const STATUS_PHONE_NUMBER_NEEDED = 16
export const STATUS_EMAIL_NEEDED = 17
export const STATUS_VENUE_NOT_FOUND = 18
export const STATUS_ALREADY_REDEEMED = 19
export const STATUS_GAME_NOT_FOUND = 20
export const STATUS_GAME_NOT_REDEEMABLE = 21
export const STATUS_GAME_NO_REDEMPTION_CODE = 22
export const STATUS_EMPTY_OR_NULL_CODE = 23
export const STATUS_BAD_REDEMPTION_CODE = 24
export const STATUS_GAME_LOST = 25
export const STATUS_FIRST_NAME_IS_MANDATORY = 26
export const STATUS_LAST_NAME_IS_MANDATORY = 27
export const NO_SUCH_SPONSORSHIP = 28
export const SPONSORSHIP_USED = 29
export const STATUS_NO_QUIZZ = 30
export const STATUS_NO_QUIZZ_GIFT = 31
export const STATUS_TOO_EARLY_TO_REDEEM = 32
export const STATUS_NO_SMS_AUTH_NEEDED = 33
export const STATUS_NO_SMS_AUTH_CODE = 34
export const STATUS_INCORRECT_SMS_AUTH_CODE = 35
export const STATUS_SMS_AUTH_ALREADY_DONE = 36
export const STATUS_TOO_MUCH_GAMES_FOR_PHONE_NUMBER = 37
export const STATUS_GAMES_LIMIT_REACHED = 39
export const STATUS_SUSPECTED_FRAUD = 40
