import GiftMachine from '../16_GiftMachine/GiftMachine'
import SlotMachine from '../09_SlotMachine/SlotMachine'
import FortuneWheelWrapper from '../../components/FortuneWheel/FortuneWheelWrapper'
import WheelButton from '../../components/cta/WheelButton'
import { tt } from '../../i18n'
import AdventCalendar from '../06_AdventCalendar/AdventCalendar'
import ScratchCardChristmas from '../05_ScratchCard/ScratchCardChristmas'
import ScratchCard from '../05_ScratchCard/ScratchCard'
import { renderTitle } from './renderTitle'
import Fakir from '../18_Fakir/Fakir'
import MysteryBoxes from '../20_MysteryBoxes/MysteryBoxes'
import React, { lazy, ReactNode, Suspense } from 'react'
import LotteryType from '@tootsweet/model/lottery/LotteryType'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import VenueDTO from '@tootsweet/model/datatransfer/VenueDTO'
import { Loader } from '../../components/Loader/Loader'
const DiceGame3D = lazy(() => import('../22_Dice/DiceGame3D'))
const Catcher = lazy(() => import('../17_Catcher/Catcher'))

export default function renderBackgroundGame(
  lotteryConfig: LotteryConfigurationResponse | undefined,
  venue: VenueDTO | undefined | null,
  hideCTA: boolean | undefined,
  hideTitle: boolean | undefined,
  showModal?: () => void,
  title?: () => ReactNode,
  gameState?: any,
  gameDispatch?: any,
  forceInactive?: boolean
) {
  if (!lotteryConfig) {
    return null
  }

  switch (lotteryConfig.type) {
    case LotteryType.GiftMachine:
      return (
        <GiftMachine
          finalPlaceName={venue?.name || ''}
          isPlayStep={false}
          onCTAClicked={showModal}
        />
      )

    case LotteryType.SlotMachine:
      return (
        <SlotMachine
          finalPlaceName={venue?.name || ''}
          isPlayStep={false}
          onCTAClicked={showModal}
        />
      )

    case LotteryType.Wheel:
      return (
        <>
          <FortuneWheelWrapper
            forceHideCTA={true}
            finalPlaceName={venue?.name || ''}
            hideTitle={hideTitle}
            title={title}
          />

          {!hideCTA && (
            <WheelButton
              text={tt('spin_the_wheel')}
              isFixedBottom={true}
              onClick={showModal}
            />
          )}
        </>
      )

    case LotteryType.AdventCalendar:
      return (
        <AdventCalendar
          finalPlaceName={venue?.name || ''}
          onUserStartedScratching={showModal}
          play={false}
          hideTitle={true}
          inactive={!!forceInactive}
        />
      )

    case LotteryType.ScratchCardChristmas:
      return (
        <ScratchCardChristmas
          finalPlaceName={venue?.name || ''}
          onUserStartedScratching={showModal}
          gameStepType={LotteryV2StepType.ScratchCardChristmas}
        />
      )

    case LotteryType.ScratchCard:
      return (
        <ScratchCard
          finalPlaceName={venue?.name || ''}
          onUserStartedScratching={showModal}
          gameStepType={LotteryV2StepType.ScratchCard}
          renderTitle={renderTitle}
        />
      )

    case LotteryType.Fakir:
      return (
        <>
          <Fakir isPlayStep={false} />
          {!hideCTA && (
            <WheelButton
              text={tt('drop_it_now')}
              isFixedBottom={true}
              onClick={showModal}
            />
          )}
        </>
      )
    case LotteryType.MysteryBoxes:
      return (
        <MysteryBoxes
          isPlayStep={false}
          performMarketingStep={showModal}
          hideCTA={hideCTA}
          title={title}
        />
      )

    case LotteryType.Dice:
      return (
        <Suspense
          fallback={
            <Loader
              loading={true}
              displayText={true}
              text={tt('loading')}
              isFixed={true}
            />
          }
        >
          <DiceGame3D isDisabled={true} showModal={showModal} />
        </Suspense>
      )

    case LotteryType.Catcher:
      return (
        <Suspense
          fallback={
            <Loader
              loading={true}
              displayText={true}
              text={tt('loading')}
              isFixed={true}
            />
          }
        >
          <Catcher
            isActive={true}
            updateScore={(amount: number) => {}}
            game={{ state: gameState, dispatch: gameDispatch }}
            zIndex={2}
            infinite={true}
            noCountdown={true}
          />
        </Suspense>
      )
  }

  return null
}
