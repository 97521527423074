import { AnyAction } from 'redux'
import { RootState } from '../../../redux/combinedReducers'

export const countdownStart = (interval: () => void) => ({
  type: Actions.countdown_start,
  interval,
})

export const countdownStop = () => ({
  type: Actions.countdown_stop,
})

enum Actions {
  countdown_start = 'countdown_start',
  countdown_stop = 'countdown_stop',
  countdown_tick = 'countdown_tick',
}

export const countdownTick = () => ({
  type: Actions.countdown_tick,
})

export interface CountdownState {
  remainingSeconds: number
  intervalId: number | null
  isRunning: boolean
  isFinished: boolean
}

const initialState: CountdownState = {
  remainingSeconds: 0,
  intervalId: null,
  isRunning: true,
  isFinished: false,
}

export const COUNTDOWN_SECONDS = 4

export const countdown = (
  state: CountdownState = initialState,
  action: AnyAction
): CountdownState => {
  switch (action.type) {
    case Actions.countdown_start:
      if (state.intervalId) {
        clearInterval(state.intervalId)
      }
      const intervalId = setInterval(action.interval, 1000)
      return {
        ...state,
        remainingSeconds: COUNTDOWN_SECONDS,
        // @ts-ignore
        intervalId,
        isRunning: true,
        isFinished: false,
      }

    case Actions.countdown_stop:
      if (state.intervalId) {
        clearInterval(state.intervalId)
      }
      return {
        ...state,
        remainingSeconds: 0,
        isRunning: false,
        isFinished: true,
      }

    case Actions.countdown_tick:
      let remainingSeconds = state.remainingSeconds - 1
      let isRunning = true
      let isFinished = false
      if (remainingSeconds <= 0) {
        if (state.intervalId) {
          clearInterval(state.intervalId)
        }
        remainingSeconds = 0
        isRunning = false
        isFinished = true
      }
      return {
        ...state,
        remainingSeconds,
        isRunning,
        isFinished,
      }
  }
  return state
}

export const countdownSelector = (state: RootState) => state.countdown
