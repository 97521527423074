import React, { useEffect } from 'react'

interface Props {
  lead?: boolean
  pixelId: string
  route: string
}

export default function FacebookPixel(props: Props) {
  useEffect(() => {
    if (!props.pixelId) {
      return
    }
    fbEvents(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    )
    // @ts-ignore
    window?.fbq('init', props.pixelId)
  }, [props.pixelId])

  useEffect(() => {
    // @ts-ignore
    // console.log('track', 'PageView', props.route, window?.fbq)
    // @ts-ignore
    window?.fbq('track', 'PageView')
    if (props.lead) {
      // @ts-ignore
      // console.log('track', 'Lead', props.route, window?.fbq)
      // @ts-ignore
      window?.fbq('track', 'Lead')
    }
  }, [props.route, props.lead])

  return <></>
}

// @ts-ignore
function fbEvents(
  f?: any,
  b?: any,
  e?: any,
  v?: any,
  n?: any,
  t?: any,
  s?: any
) {
  if (f.fbq) {
    return
  }
  n = f.fbq = function () {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
  }
  if (!f._fbq) {
    f._fbq = n
  }
  n.push = n
  n.loaded = !0
  n.version = '2.0'
  n.queue = []
  t = b.createElement(e)
  t.async = !0
  t.src = v
  s = b.getElementsByTagName(e)[0]
  s.parentNode.insertBefore(t, s)
}
