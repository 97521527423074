import React, { FunctionComponent } from 'react'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import EatalyPoll from '../../08_EatalyPoll/EatalyPoll'
import PollModalContent from '../../11_Poll/PollModalContent'
import WheelFormModalContent, {
  WheelFormType,
} from '../../00_Wheel/02_Form/WheelFormModalContent'
import CustomFormModalContent from '../../13_CustomForm/CustomFormModalContent'
import renderSocialNetwork from '../../00_Wheel/03_SocialNetwork/renderSocialNetwork'
import { useSelector } from 'react-redux'
import {
  lotteryConfigSelector,
  lotteryStepSelector,
} from '../../../redux/selectors/lottery'
import SponsorshipModalContent from '../../00_Wheel/10_Sponsorship/SponsorshipModalContent'
import CustomLinkButton from '../../../components/CustomLinkButton'
import { determineTexts } from '../../00_Wheel/03_SocialNetwork/determineTexts'

interface Props {
  finalPlaceName: string
  hasClickedNo: boolean
  setHasClickedNo: (hasClickedNo: boolean) => void
  isIOS: boolean
}

const QuizzStep: FunctionComponent<Props> = ({
  finalPlaceName,
  hasClickedNo,
  setHasClickedNo,
  isIOS,
}) => {
  const lotteryStep = useSelector(lotteryStepSelector)
  const lotteryConfig = useSelector(lotteryConfigSelector)

  let step: LotteryV2Step | null

  if (hasClickedNo && lotteryStep && lotteryStep.backupStep) {
    step = {
      stepType: lotteryStep.backupStep,
    }
  } else {
    step = lotteryStep
  }

  if (!step || !step.stepType) {
    return null
  }

  switch (step.stepType) {
    case LotteryV2StepType.EatalyPoll:
      return <EatalyPoll />
    case LotteryV2StepType.Poll:
      return <PollModalContent />
    case LotteryV2StepType.Email:
      return (
        <WheelFormModalContent
          formType={WheelFormType.Email}
          finalPlaceName={finalPlaceName}
        />
      )
    case LotteryV2StepType.Phone:
      return (
        <WheelFormModalContent
          formType={WheelFormType.Phone}
          finalPlaceName={finalPlaceName}
        />
      )
    case LotteryV2StepType.CustomForm:
      return <CustomFormModalContent />
    case LotteryV2StepType.Sponsorship:
      return (
        <SponsorshipModalContent
          isModal={true}
          isIOS={isIOS}
          isBeforeWheel={true}
        />
      )
    case LotteryV2StepType.CustomLink:
      const texts = determineTexts(lotteryConfig, step, isIOS, finalPlaceName)
      return (
        <>
          <h2 className="h2 w-margin-bottom">{texts.beforeWheelH1}</h2>
          <p className={`w-p-big`}>{texts.beforeWheelText}</p>
          <div className="w-margin-bottom"></div>
          <CustomLinkButton step={step} isIOS={isIOS} preventNextStep={false} />
        </>
      )
  }

  return renderSocialNetwork(
    lotteryConfig,
    finalPlaceName,
    isIOS,
    true,
    true,
    step,
    step?.tag || null,
    '',
    () => setHasClickedNo(true),
    step.stepType === LotteryV2StepType.Google
  )
}

export default QuizzStep
