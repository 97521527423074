import twemoji from 'twemoji'

export const BOX_SVG_HEIGHT = 430
export const BOX_SVG_WIDTH = 430

export const uid = () =>
  Date.now().toString(36) + Math.random().toString(36).substring(2)

export const shuffle = (array: Array<any>) => {
  let currentIndex = array.length,
    randomIndex

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

const customTwemoji = twemoji as CustomTwemoji

export const getSvgEmoji = (emoji: string, isLost: boolean): string => {
  const regex = /<img.*?src=['"](.*?)['"]/
  let content = customTwemoji.parse(isLost ? '😭' : emoji || '🎁', {
    base: 'https://d19y19rxbpc628.cloudfront.net/jetpack/twemoji/14.0.1/',
    folder: 'svg',
    ext: '.svg',
  })
  // @ts-ignore
  if (!content) {
    content = twemoji.parse('🎁', {
      base: 'https://d19y19rxbpc628.cloudfront.net/jetpack/twemoji/14.0.1/',
      folder: 'svg',
      ext: '.svg',
    })
  }
  // @ts-ignore
  return regex.exec(content)?.[1]
}
