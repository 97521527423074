import {
  ActionTypes,
  SET_CP_MODAL_STATE,
  SET_DISPLAY_MARKETING_MODAL,
  SET_FINGERPRINT,
  SET_FINGERPRINT_BLOCKED,
  SET_INIT_FINISHED,
  SET_LANGUAGE,
  SET_MARKETING_PARAMZ,
  SET_MENU_STATE,
  SET_PP_MODAL_STATE,
  SET_SHOW_FOOTER,
} from '../actions/app'
import UserDTO from '@tootsweet/model/datatransfer/UserDTO'
import { TSUtils } from '../../utils/TSUtils'

export interface AppStateI {
  showFooter: boolean
  fingerprint: string | null
  user: UserDTO | null
  utm_campaign?: string | null
  utm_source?: string | null
  utm_medium?: string | null
  displayMarketingModal: boolean
  displayProfessionalContactForm: boolean
  displayIndividualContactForm: boolean
  isIOS: boolean
  lng: string | null
  initFinished: boolean
  menuShown: boolean
  showPPModal: boolean
  showCPModal: boolean
  fingerprintBlocked: boolean
}

const defaultState: AppStateI = {
  showFooter: true,
  fingerprint: null,
  user: null,
  displayMarketingModal: false,
  displayProfessionalContactForm: false,
  displayIndividualContactForm: false,
  isIOS: TSUtils.isIOS(),
  lng: null,
  initFinished: false,
  menuShown: false,
  showPPModal: false,
  showCPModal: false,
  fingerprintBlocked: false,
}

const app = (state = defaultState, action: any) => {
  switch (action.type) {
    case SET_SHOW_FOOTER:
      return {
        ...state,
        showFooter: action.state,
      }

    case SET_FINGERPRINT:
      return {
        ...state,
        fingerprint: action.fingerprint,
      }

    case ActionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
      }

    case ActionTypes.SET_USER_PAYMENT_EMAIL:
      const newUser = {
        ...state.user,
        paymentEmail: action.email,
        firstName: action.firstName,
      }
      return {
        ...state,
        user: newUser,
      }

    case ActionTypes.SET_USER_PHONE_NUMBER:
      const newUser2 = {
        ...state.user,
        phone: action.phoneNumber,
        firstName: action.firstName,
      }
      return {
        ...state,
        user: newUser2,
      }

    case SET_MARKETING_PARAMZ:
      return {
        ...state,
        utm_campaign: action.utm_campaign,
        utm_source: action.utm_source,
        utm_medium: action.utm_medium,
      }

    case SET_DISPLAY_MARKETING_MODAL:
      return {
        ...state,
        displayMarketingModal: action.display,
        displayProfessionalContactForm: action.displayProfessionalContactForm,
        displayIndividualContactForm: action.displayIndividualContactForm,
      }

    case SET_LANGUAGE:
      return {
        ...state,
        lng: action.lng,
      }

    case SET_INIT_FINISHED:
      return {
        ...state,
        initFinished: action.initFinished,
      }

    case SET_MENU_STATE:
      return {
        ...state,
        menuShown: action.shown,
      }

    case SET_PP_MODAL_STATE:
      return {
        ...state,
        showPPModal: action.shown,
      }

    case SET_CP_MODAL_STATE:
      return {
        ...state,
        showCPModal: action.shown,
      }

    case SET_FINGERPRINT_BLOCKED:
      return {
        ...state,
        fingerprintBlocked: action.fingerprintBlocked,
      }
  }

  return state
}

export default app
