export enum AspectRatio {
  _16_9 = '_16_9',
  _18_9 = '_18_9',
  _19_9 = '_19_9',
  unknown = 'unknown',
}

const _19_9_L_BOUND = 0.45

const aspectRatio = (): AspectRatio => {
  const aspectRatioSelf = window.screen.width / window.screen.height
  // 19:9 => 0.47
  if (aspectRatioSelf >= 0.45 && aspectRatioSelf < 0.48) {
    return AspectRatio._19_9
  }
  // 18:9 => 0.5
  else if (aspectRatioSelf >= 0.48 && aspectRatioSelf <= 0.51) {
    return AspectRatio._18_9
  }
  // 16:9 => 0.5625
  else if (aspectRatioSelf >= 0.56 && aspectRatioSelf <= 0.565) {
    return AspectRatio._16_9
  }
  if (aspectRatioSelf < _19_9_L_BOUND) {
    return AspectRatio._19_9
  }
  return AspectRatio._16_9
}

export const aspect_ratio = aspectRatio()

export const classNameForRatio = () => {
  switch (aspect_ratio) {
    case AspectRatio._16_9:
      return 'ratio_16_9'
  }
  return ''
}

export default aspectRatio
