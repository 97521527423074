import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WheelButton from './cta/WheelButton'
import WheelAnalyticsManager from '../analytics/WheelAnalyticsManager'
import {
  incrementCTAClickCount,
  nextLotteryStep,
  setLotteryLoading,
} from '../redux/actions/lottery'
import TSConstants from '../utils/TSConstants'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import { tt } from '../i18n'
import { FunctionComponent } from 'react'
import { configSelector } from '../redux/selectors/config'

interface Props {
  iOSAppID: string | undefined | null
  androidBundleID: string | undefined | null
  isIOS: boolean
  isFixedBottom?: boolean
  textOnLeft?: boolean
  step: LotteryV2Step | null
  preventNextStep: boolean
  disabled?: boolean
}

const DownloadAppButton: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch()

  const siteConfig = useSelector(configSelector)

  const { isIOS, iOSAppID, androidBundleID, isFixedBottom, textOnLeft } = props

  let link: string
  let img: string

  if (isIOS) {
    link = `https://apps.apple.com/fr/app/id${iOSAppID}?pt=kadowclub`
    if (props.step?.utmCampaign) {
      link += `&ct=${props.step.utmCampaign}`
    }
    img = `${siteConfig.PUBLIC_FILES_URL_CDM}/icons/app-store-icon.png`
  } else {
    link = `https://play.google.com/store/apps/details?id=${androidBundleID}&hl=fr&utm_source=kadowclub`
    if (props.step?.utmCampaign) {
      link += `&utm_campaign=${props.step?.utmCampaign}`
    }
    img = `${siteConfig.PUBLIC_FILES_URL_CDM}/icons/google-play-icon.png`
  }

  const onClickDownloadApp = () => {
    WheelAnalyticsManager.downloadAppClicked()

    dispatch(setLotteryLoading(true, props.step ?? undefined))

    setTimeout(() => {
      dispatch(incrementCTAClickCount())
      if (!props.preventNextStep) {
        dispatch(nextLotteryStep())
      }
      dispatch(setLotteryLoading(false))
    }, TSConstants.LOADER_TIMEOUT_SHORT)
  }

  return (
    <WheelButton
      text={tt('cta_download_app')}
      onClick={onClickDownloadApp}
      className="w-btn-download-app"
      img={img}
      href={link}
      isFixedBottom={isFixedBottom}
      textOnLeft={textOnLeft}
      disabled={props.disabled}
    />
  )
}

export default DownloadAppButton
