import { setDefaultColorsIfNeeded } from '../../components/FortuneWheel/WheelColors'
import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import Skin from '../../model/Skin'
import { TSUtils } from '../../utils/TSUtils'
import React from 'react'

export default function renderCustomCss(
  lotteryConfig2?: LotteryConfigurationResponse,
  skinId?: string,
  skin?: Skin
) {
  // @ts-ignore
  const lotteryConfig: LotteryConfigurationResponse = lotteryConfig2 || {}
  // @ts-ignore
  setDefaultColorsIfNeeded(lotteryConfig)

  if (!lotteryConfig) {
    return null
  }

  const href =
    skinId && skin?.customCss
      ? `${TSUtils.buildSkinBaseURL(skinId)}/${skin?.customCss}`
      : ''

  return (
    <>
      <style type="text/css">{`
    body, p, h1, a.descLink, a.descLink:hover, a.descLink:visited, a.descLink:active {
      color: ${lotteryConfig.colBodyFont};
    }
    .k-glm-optin > .checkbox-text {
      color: ${lotteryConfig.colBodyFont};
    }
    @media (min-width: 768px) {
      html, body, #root {
        background-color: ${
          lotteryConfig.colSidesDesktop || lotteryConfig.colFooterBack
        } ;
      }
    }
    .game-container:not(.no-bg), .loader-wrapper-fixed {
      background-color: ${lotteryConfig.colBodyBack} ;
    }
    .h2, .h4 {
      color: ${lotteryConfig.colBodyFont} ;
    }
    .highlight {
      color: ${lotteryConfig.colHighlight} !important;
    }
    .modal-dialog .modal-content .modal-body h1, 
    .modal-dialog .modal-content .modal-body .h1, 
    .modal-dialog .modal-content .modal-body h2, 
    .modal-dialog .modal-content .modal-body .h2, 
    .modal-dialog .modal-content .modal-body p, 
    .modal-dialog .modal-content .modal-body ol, 
    .modal-dialog .modal-content .modal-body .control-label,
    .modal-dialog .modal-content .modal-body .checkbox-text,
    .rjsf .form-check-label,
    .form-check-label,
    .rjsf .field-radio-group,
    .w-modal-eula
      {
      color: ${lotteryConfig.colModalFont} !important;
    }
    a.modal-bottom-link,
    a.modal-bottom-link:hover,
    a.modal-bottom-link:active,
    a.modal-bottom-link:visited {
      color: ${lotteryConfig.colModalFont} !important;
    }
    .bottom-legal-container, .bottom-legal-cell, .bottom-legal, .bottom-legal:visited, .bottom-legal:hover, .quizz-header {
      color: ${lotteryConfig.colFooterFont} ;
      background-color: ${lotteryConfig.colFooterBack} ;
    }
    .w-button-normal, .w-button-normal:hover, .w-button-normal:visited, .w-button-normal:active, .w-button:focus,
    .w-tutorial-choice, .w-tutorial-choice:hover, .w-tutorial-choice:visited, .w-tutorial-choice:active, .w-tutorial-choice:focus {
      color: ${lotteryConfig.colCTAFont} ;
      background-color: ${lotteryConfig.colCTABack} ;
    }
    .w-btn-disabled {
      background-color: ${lotteryConfig.colCTADisabledBack} ;
      color: ${lotteryConfig.colCTADisabledFont} ;
    }
    .lds-ring > div > div {
      border: 8px solid ${lotteryConfig.colLoader} ;
      border-color: ${
        lotteryConfig.colLoader
      } transparent transparent transparent ;
    }
    .lds-ring-dark > div > div {
      border: 8px solid #0e0074 ;
      border-color: #0e0074 transparent transparent transparent ;
    }
    .lds-text, .lds-text-down {
      color: ${lotteryConfig.colLoader} ;
    }
    .w-btn-inverted, .w-btn-inverted:hover, .w-btn-inverted:visited, .w-btn-inverted:active, .w-btn-inverted:focus {
      border: 1px solid ${lotteryConfig.colCTABack} ;
      background-color: ${lotteryConfig.colCTAFont} ;
      color: ${lotteryConfig.colCTABack} ;
    }
    .h1-scratchcard, .h1-advent-calendar {
      /*color: ${lotteryConfig.colBodyFont} ;*/
    }
    .logo-header:not(.no-bg) {
      background-color: ${lotteryConfig.colHeader || 'transparent'} ;
    }
    .w-voucher-border {
      border: 2px solid ${lotteryConfig.colBodyFont} ;
    }
    .k-glm-title-1 {
      color: ${lotteryConfig.colBodyFont} ; 
    }
    .k-glm-title-2 {
      color: ${lotteryConfig.colHighlight} ;
    }
    .skill-score {
      color: ${lotteryConfig.colCTABack} ;
    }
    .progress-bar {
      background-color: ${lotteryConfig.colCTABack} ;
    }
    .skill-countdown {
      color: ${lotteryConfig.colCTABack} ;
    }
    .catcher-countdown {
      background-color: ${lotteryConfig.colBodyBack} ;
    }
    `}</style>

      {!!skinId && !!skin?.customCss && (
        <link rel="stylesheet" type="text/css" href={href} />
      )}
    </>
  )
}
