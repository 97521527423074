import { RootState } from '../combinedReducers'

export const showFooterSelector = (state: RootState) => state.app.showFooter

export const fingerprintSelector = (state: RootState) => state.app.fingerprint
export const fingerprintBlockedSelector = (state: RootState) =>
  state.app.fingerprintBlocked

export const userSelector = (state: RootState) => state.app.user

export const utmCampaignSelector = (state: RootState) => state.app.utm_campaign

export const utmSourceSelector = (state: RootState) => state.app.utm_source

export const utmMediumSelector = (state: RootState) => state.app.utm_medium

export const displayMarketingModalSelector = (state: RootState) =>
  state.app.displayMarketingModal

export const displayProfessionalContactFormSelector = (state: RootState) =>
  state.app.displayProfessionalContactForm

export const displayIndividualContactFormSelector = (state: RootState) =>
  state.app.displayIndividualContactForm

export const isIOSSelector = (state: RootState) => state.app.isIOS

export const lngSelector = (state: RootState) => state.app.lng

export const initFinishedSelector = (state: RootState) => state.app.initFinished
