import DeliverooButton from '../../../components/DeliverooButton'
import UberEatsButton from '../../../components/UberEatsButton'
import React, { useCallback, useState } from 'react'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import { tt } from '../../../i18n'

interface Props {
  step?: LotteryV2Step
  isModal: boolean
  preventNextStep?: boolean
  isIOS: boolean
  isFixedBottom: boolean
}
const MODE_INITIAL = 0
//dd
const MODE_DELIVEROO = 2

export default function UberEatsDeliverooModalContent(props: Props) {
  const [mode, setMode] = useState(MODE_INITIAL)

  const renderBackArrow = useCallback(() => {
    return (
      <div className="row text-center">
        <div className="col-12">
          <p>
            <span onClick={() => setMode(MODE_INITIAL)}>
              &#x2190; {tt('go_back')}
            </span>
          </p>
        </div>
      </div>
    )
  }, [])

  if (!props.step) {
    return null
  }

  if (props.isFixedBottom) {
    return (
      <>
        <div className="row w-margin-bottom-half">
          <div className="col-12">
            <DeliverooButton
              step={props.step}
              isIOS={props.isIOS}
              enableHref={true}
              isFixedBottom={true}
            />
          </div>
        </div>
        <UberEatsButton
          step={props.step}
          isIOS={props.isIOS}
          enableHref={true}
          isFixedBottom={true}
        />
      </>
    )
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {mode === MODE_INITIAL && (
        <>
          <div className="row w-margin-bottom-half">
            <div className="col-12">
              <p>{tt('before_wheel_text_ubereats_deliveroo')}</p>
            </div>
          </div>
          <div className="row w-margin-bottom-half">
            <div className="col-12">
              <DeliverooButton
                step={props.step}
                isIOS={props.isIOS}
                onClick={() => setMode(MODE_DELIVEROO)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <UberEatsButton step={props.step} isIOS={props.isIOS} />
            </div>
          </div>
        </>
      )}
      {mode === MODE_DELIVEROO && (
        <>
          <div className="row w-margin-bottom-half">
            <div className="col-12">
              <p>{tt('deliveroo_text')}</p>
            </div>
          </div>
          <div className="row w-margin-bottom-half">
            <div className="col-12">
              <DeliverooButton
                step={props.step}
                isIOS={props.isIOS}
                enableHref={true}
              />
            </div>
          </div>
          {renderBackArrow()}
        </>
      )}
    </div>
  )
}
