import React, {
  FunctionComponent,
  useEffect,
  useReducer,
  useState,
} from 'react'
import AsyncSelect from 'react-select/async'
import { useSelector } from 'react-redux'
import VenueDTO from '@tootsweet/model/datatransfer/VenueDTO'
import Axios from 'axios'
import { TSUtils } from '../../../utils/TSUtils'
import { tt } from '../../../i18n'
import Select from 'react-select'
import { configSelector } from '../../../redux/selectors/config'
import {
  lotteryConfigSelector,
  lotteryStepSelector,
  venueSelector,
} from '../../../redux/selectors/lottery'
import renderBackgroundGame from '../../helpers/renderBackgroundGame'
import {
  gameInitialState,
  gameReducer,
} from '../../19_Skill/reducers/gameReducer'

interface Props {}

const PlaceSelector: FunctionComponent<Props> = () => {
  const [selectedOption, setSelectedOption] = useState<VenueDTO | null>(null)
  // TODO: add error handling
  const [hasError, setHasError] = useState<boolean>(false)
  const [placesOptions, setPlacesOptions] = useState([])

  const step = useSelector(lotteryStepSelector)
  const config = useSelector(lotteryConfigSelector)
  const siteConfig = useSelector(configSelector)
  const venue = useSelector(venueSelector)
  const [gameState, gameDispatch] = useReducer(gameReducer, gameInitialState)

  const fetchPlaces = async () => {
    const res = await Axios({
      method: 'get',
      url: `${siteConfig.BACKEND_URL}/places/parent/all?id=${venue?.id}&query=*`,
    })

    // const options: { value: string; label: string }[] = [];
    // res.data.map((place: VenueDTO) => {
    //   options.push({
    //     value: place.id,
    //     label: `${place.name} - ${place.address}`,
    //   });
    // });

    setPlacesOptions(res.data)
  }

  const handlePlaceChange = (selectedOption: any) => {
    if (!selectedOption) {
      alert(tt('error'))
      return
    }

    setSelectedOption(selectedOption)
    const href = new URL(window.location.href)
    href.searchParams.set('venueId', selectedOption.id)
    window.location.href = href.toString()
  }

  const handlePlaceOptionChange = (selected: any) => {
    if (!selected) {
      alert(tt('error'))
      return
    }

    const href = new URL(window.location.href)
    href.searchParams.set('venueId', selected.id)
    window.location.href = href.toString()
  }

  const loadOptions = async (query: string) => {
    if (!query || query.length < 3 || !venue) {
      return []
    }
    const url = `${siteConfig.BACKEND_URL}/places/parent?id=${venue.id}&query=${query}`
    return new Promise(async (resolve: any) => {
      const res = await Axios({
        method: 'get',
        url,
      })
      const venues: VenueDTO[] = res.data
      const options = venues.map((venue: VenueDTO, index: number) => {
        return {
          label: `${venue.address}`,
          value: index,
          ...venue,
        }
      })
      resolve(options)
    })
  }

  const renderPlaceSelector = (dropdown?: boolean) => {
    if (!dropdown) {
      return (
        <AsyncSelect
          autoFocus={false}
          value={selectedOption}
          menuIsOpen={true}
          loadOptions={loadOptions}
          onChange={handlePlaceChange}
          placeholder={tt('place_selection_placeholder')}
          noOptionsMessage={(obj: { inputValue: string }) => {
            if (obj && obj.inputValue && obj.inputValue.length >= 3) {
              return tt('place_selection_no_results')
            }
            return null
          }}
          loadingMessage={() => tt('loading')}
          menuPlacement="top"
          components={{ DropdownIndicator, Option }}
          // isClearable={false}
          // closeMenuOnScroll={false}
          maxMenuHeight={500}
          // escapeClearsValue={false}
          // menuIsOpen={true}
          styles={{
            menu: (base: any) => ({
              ...base,
              color: '#0e0074',
            }),
            control: (base: any) => ({
              ...base,
              '&:hover': { borderColor: hasError ? 'red' : 'gray' }, // border style on hover
              '&:focus': { borderColor: hasError ? 'red' : 'gray' }, // border style on hover
              border: hasError ? '3px solid red' : '1px solid lightray', // default border color
              borderColor: hasError ? 'red' : 'gray',
              outline: 'none',
              boxShadow: 'none',
              height: 55,
            }),
          }}
        />
      )
    } else {
      return (
        <Select
          name={'place'}
          id={'place-selector'}
          inputId={'place-selector-input'}
          options={placesOptions}
          placeholder={'Où êtes vous ?'}
          onChange={handlePlaceOptionChange}
          openMenuOnClick={true}
          isSearchable={false}
          components={{ Option }}
          menuPlacement="top"
          styles={{
            menu: (base: any) => ({
              ...base,
              color: 'black',
            }),
            control: (base: any) => ({
              ...base,
              '&:hover': { borderColor: hasError ? 'red' : 'gray' }, // border style on hover
              '&:focus': { borderColor: hasError ? 'red' : 'gray' }, // border style on hover
              border: hasError ? '3px solid red' : '1px solid lightray', // default border color
              borderColor: hasError ? 'red' : 'gray',
              outline: 'none',
              boxShadow: 'none',
              height: 55,
            }),
          }}
        />
      )
    }
  }

  useEffect(() => {
    if (step?.useDropdown) {
      fetchPlaces()
    }
  }, [step?.useDropdown])

  return (
    <>
      {renderBackgroundGame(
        config,
        venue,
        true,
        false,
        undefined,
        renderTitle,
        gameState,
        gameDispatch,
        true
      )}

      <div
        className="search-box-container place-selector-container w-margin-top-half"
        style={{ zIndex: '100' }}
      >
        <div className="col s12">
          {renderPlaceSelector(step?.useDropdown || false)}
        </div>
      </div>
    </>
  )
}

export default PlaceSelector

const DropdownIndicator = () => {
  return null
}

const Option = (props: any) => {
  if (!props.data) {
    return null
  }
  return (
    <div
      {...props.innerProps}
      className={`place-option ${
        props.isFocused ? 'place-option-selected' : ''
      }`}
    >
      <span
        style={{ display: 'block' }}
        className="font-weight-bold text-uppercase"
      >
        {TSUtils.replaceSpacesWithNBSP(props.data.name)}
      </span>
      <span className="place-option-subtitle">{props.data.address}</span>
    </div>
  )
}

const renderTitle = () => {
  return (
    <>
      <h2
        className="h2 cm-pad"
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        <span className="highlight">{tt('place_selection_title_1')}</span>
        <br />
        {tt('place_selection_title_2')}
      </h2>
    </>
  )
}
