import React from 'react'
import { FoliageProps } from './FoliageFG'

export function LargeFoliage(props: FoliageProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640.59 614.8"
      style={{
        position: 'absolute',
        height: props.height,
        bottom: props.bottom,
        top: props.top,
        right: props.right,
        left: props.left,
        transform: !!props.rotate ? `rotate(${props.rotate})` : undefined,
      }}
    >
      <defs>
        <style>{`
      .bf4e8eb1-8401-4748-869f-6d95328bc869{fill:${props.colors.v2FoliageColor2};}
      `}</style>
      </defs>
      <g id="bf585083-70c1-440e-a5d5-bae1fea6cda0" data-name="Layer 2">
        <g
          id="abba7c3e-9698-4876-ba37-2a4b664733ca"
          data-name="PLANTES_au_dessus"
        >
          <path
            className="bf4e8eb1-8401-4748-869f-6d95328bc869"
            d="M452.58,369.73c-11.67,18.26,118,72.92,100.27,140.4-15.85,60.21-72.52,118-154.41,101.93S230.4,565.47,189.8,530c.64-.11,87.47-3.84,142.8-55.07s36.36-52,34.47-54-72.53,43.87-103.4,60.6-78,40.11-105.1,22S69.27,422,59.3,408.9c0-.5,96.42,1.88,135.92-9.33s95.29-29.08,89.24-43.8-70.55,25.75-109.33,30.45-114.52,17-133.61-15.81S10.63,283,8.13,275.68c.26-.86,32,19.4,63,23.21s171,3,167.58-18.57-82.72,4-130.93.63S6.44,275.64,2.34,238.13,2.31,159,0,147.07c0-.17,34.44,30.93,66.31,42s101.22,31.56,105.77,16.37-52.61-17.57-87.94-30.83-76.67-23.39-68.84-59S40.17,88.36,62.35,33c.55,1.39,17,16,66.3-5.2S205,14.53,224.22,44.91s35,129.36,53.93,128-5.42-83.84-30.38-119.16S204.22,6.29,204.22,6.29s53.57-18,80.35,6.87,41.84,79.7,52.31,120.24,8.3,72,17.12,71.93,4.66-85.41-10.49-127.13S299.42,5.59,299.42,5.59s93.28,8.14,124.23,45.74,25.32,88.53,15,129-27.41,68-11.77,73.12,27.31-25.72,33.73-59.09-5.94-126.95-5.94-126.95S567,118.2,605.33,190s44.78,140.88,22.42,178.69C601,414,538,398.34,511.88,385.37S461.53,355.71,452.58,369.73Z"
          />
        </g>
      </g>
    </svg>
  )
}
