import * as React from 'react'
import { async_recordPoll } from '../../redux/actions/lottery'
import { useDispatch, useSelector } from 'react-redux'
import WheelButton from '../../components/cta/WheelButton'
import WheelAnalyticsManager from '../../analytics/WheelAnalyticsManager'
import { tt } from '../../i18n'
import { configSelector } from '../../redux/selectors/config'

interface Props {}

const EatalyPoll: React.FunctionComponent<Props> = () => {
  const [answer, setAnswer] = React.useState<string | undefined>(undefined)
  const [email, setEmail] = React.useState<string | undefined>(undefined)
  const [firstName, setFirstName] = React.useState<string | undefined>(
    undefined
  )

  const dispatch = useDispatch()

  const siteConfig = useSelector(configSelector)

  React.useEffect(() => {
    WheelAnalyticsManager.logEvent('eataly_poll')
  }, [])

  const renderOption = (id: string, value: string | number, text: string) => {
    return (
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="gridRadios"
          id={id}
          value={value}
          checked={answer === value}
          required={true}
          onChange={(e) => setAnswer(e.target.value)}
        />
        <label className="form-check-label" htmlFor={id}>
          {text}
        </label>
      </div>
    )
  }

  const onSubmit = async (e: any) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    if (!email || !answer || !firstName) {
      alert('Erreur 5')
      return
    }

    dispatch(async_recordPoll(siteConfig, answer, firstName, email))
  }

  return (
    <>
      <h1>Remplissez ce formulaire pour tenter votre chance 🤓</h1>

      <form id="form-eataly-poll" onSubmit={onSubmit}>
        <div className="row w-margin-bottom-half w-padding-top-half">
          <div className="col-12">
            <p>
              Comment évaluez-vous votre expérience au sein de notre
              établissement ?
            </p>
          </div>
        </div>

        <div className="row w-margin-bottom-half">
          <div className="col-12">
            <fieldset className="form-group">
              {renderOption(
                'very_satisfied',
                'very_satisfied',
                'Très satisfait'
              )}
              {renderOption('satisfied', 'satisfied', 'Satisfait')}
              {renderOption('neutral', 'neutral', 'Neutre')}
              {renderOption('not_satisfied', 'not_satisfied', 'Insatisfait')}
            </fieldset>
          </div>
        </div>

        <div className="row w-margin-bottom-half w-padding-top-half">
          <div className="col-12">
            <p>Veuillez saisir vos prénom et adresse email :</p>
          </div>
        </div>

        <div className="row w-margin-bottom-half">
          <div className="col-12">
            <input
              id="firstName"
              type="text"
              placeholder={tt('placeholder_first_name')}
              value={firstName}
              onChange={(e) => setFirstName(e.currentTarget.value)}
              className={`form-control`}
              required
            />
          </div>
        </div>

        <div className="row w-margin-bottom">
          <div className="col-12">
            <input
              id="email"
              type="email"
              placeholder={tt('placeholder_email')}
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              className={`form-control`}
              required
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <WheelButton
              id="cta-eataly-poll"
              containerClassName="cta-eataly-cont"
              text="Valider"
              isSubmit={true}
            />
          </div>
        </div>
      </form>
    </>
  )
}

export default EatalyPoll
