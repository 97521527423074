import { RootState } from '../redux/combinedReducers'

export default class TSStoreUtils {
  public static getUserToken(state: RootState) {
    let token: string | null = null
    if (state && state.app && state.app.user && state.app.user.token) {
      token = state.app.user.token
    }
    return token
  }
}
