import TSConstants from '../utils/TSConstants'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'

interface Props {
  isModal: boolean
  src: string | undefined
  marginV?: number
  marginH?: number
  dataSrc?: string | undefined
  dataSrcTransform?: string | undefined
  heightPc?: number
  containerHeight?: string
  fullWidth?: boolean
  id?: string
  hasCustomPicture?: boolean
}

export const DEFAULT_MARGIN = 15

const FlexPicture: FunctionComponent<Props> = (props) => {
  const container = useRef<HTMLDivElement>(null)
  const [display, setDisplay] = useState(false)
  const [height, setHeight] = useState<number>(0)
  const [width, setWidth] = useState<string | number>(0)

  useEffect(() => {
    setTimeout(() => {
      if (!container || !container.current || !container.current.clientHeight) {
        return
      }
      setDisplay(!!props.dataSrc || !!props.dataSrcTransform)
      // @ts-ignore
      setHeight(
        Math.trunc(
          container.current.clientHeight - 2 * (props.marginV || DEFAULT_MARGIN)
        )
      )
      setWidth('auto')
    }, 600)
  }, [container])

  const style: any = { width, height }
  const containerStyle: any = {}

  let forceDisplay = false

  if (props.isModal) {
    if (props.hasCustomPicture) {
      style.width = '100%'
      style.height = 'auto'
    } else {
      style.width = 'auto'
      style.height = '15vh'
    }
    style.flex = undefined
    containerStyle.height = 'auto'
    forceDisplay = true
  } else {
    style.height = height
    style.minWidth = 0
    style.width = undefined

    if (container?.current?.clientHeight) {
      style.maxWidth =
        container.current.clientWidth - 2 * (props.marginH || DEFAULT_MARGIN)
    } else {
      style.maxWidth = '100%'
    }

    if (props.heightPc) {
      style.height = Math.trunc(style.height * props.heightPc)
      style.minWidth = 0
      style.maxWidth = '100%'
    }
  }

  if (props.containerHeight) {
    containerStyle.height = props.containerHeight
  }

  let dataSrcTransform: string | null = null

  if (props.fullWidth) {
    if (props.isModal) {
      containerStyle.width = '100%'
      containerStyle.maxHeight = '25vh'
      style.width = '100%'
      style.height = 'auto'
      style.maxHeight = '100%'
      dataSrcTransform = 'contain=WxH'
    } else {
      containerStyle.width = '100%'
      style.width = '100%'
    }
  } else {
    //dataSrcTransform = 'contain=W'
  }

  return (
    <div
      ref={container}
      id={props.id}
      className={`pic-container ${props.isModal ? 'pic-container-modal' : ''}`}
      style={containerStyle}
    >
      {(forceDisplay || display) && (
        <img
          className={`twic`}
          src={props.src || TSConstants.PLACEHOLDER_URL}
          data-src={props.dataSrc}
          data-src-transform={dataSrcTransform || props.dataSrcTransform}
          style={style}
        />
      )}
    </div>
  )
}

const DEFAULT_MODAL_HEIGHT = '13vh'
// const DEFAULT_MODAL_HEIGHT_MAX = "20vh"

export default FlexPicture
