import React from 'react'

export const renderScratchCardTitle = (content: any) => {
  return (
    <h2
      className="h2 cm-pad text-center sc-title"
      style={{ flex: 1 }}
      id="lot-p-4"
    >
      {content}
    </h2>
  )
}
