import React from 'react'
import './nature.scss'
import aspectRatio, { AspectRatio } from '../../../utils/aspect_ratio'
import TSConstants from '../../../utils/TSConstants'

const aspectRatio1 = aspectRatio()

export function buildEasterSkin() {
  return {
    renderBackground: (width: number, height: number) => {
      let butterfly1Height = '13%'
      let butterfly1Left = '50%'
      let butterfly1Top = '-11%'

      let eggRedHeight = '50%'
      let eggRedLeft = '20%'
      let eggRedTop = '12%'

      let flower1Height = '15%'
      let flower1Top = '0%'
      let flower1Left = '52%'
      let flower1Display: string | undefined = 'none'

      let flower2Height = '15%'
      let flower2Bottom = '50%'
      let flower2Right = '0%'

      switch (aspectRatio1) {
        case AspectRatio._16_9:
          butterfly1Height = '15%'
          butterfly1Left = '45%'
          butterfly1Top = '-12%'

          eggRedHeight = '40%'
          eggRedLeft = '20%'
          eggRedTop = '12%'

          flower1Height = '15%'
          flower1Top = '0%'
          flower1Left = '48%'
          flower1Display = undefined

          flower2Height = '15%'
          flower2Bottom = '45%'
          flower2Right = '20%'

          break

        case AspectRatio._19_9:
        case AspectRatio._18_9:
          break
      }

      return (
        <div style={{ width: '100%', height }}>
          <img
            className="twic"
            src={TSConstants.PLACEHOLDER_URL}
            data-src={`image:/cdm/kadow/img/easter/egg_red.png`}
            style={{
              position: 'absolute',
              height: eggRedHeight,
              top: eggRedTop,
              left: eggRedLeft,
            }}
          />
          <img
            className="twic"
            src={TSConstants.PLACEHOLDER_URL}
            data-src={`image:/cdm/kadow/img/easter/flower.png`}
            style={{
              position: 'absolute',
              height: flower1Height,
              top: flower1Top,
              left: flower1Left,
              display: flower1Display,
            }}
          />
          <img
            className="twic"
            src={TSConstants.PLACEHOLDER_URL}
            data-src={`image:/cdm/kadow/img/easter/butterfly1.png`}
            style={{
              position: 'absolute',
              height: butterfly1Height,
              top: butterfly1Top,
              left: butterfly1Left,
            }}
          />
          <img
            className="twic"
            src={TSConstants.PLACEHOLDER_URL}
            data-src={`image:/cdm/kadow/img/easter/flower.png`}
            style={{
              position: 'absolute',
              height: flower2Height,
              bottom: flower2Bottom,
              right: flower2Right,
            }}
          />
        </div>
      )
    },
    renderForeground: (width: number, height: number) => {
      let eggBlueHeight = '50%'
      let eggBlueTop = '-10%'
      let eggBlueRight = '-33%'

      let eggPinkHeight = '45%'
      let eggPinkBottom = '-5%'
      let eggPinkRight = '-30%'

      let butterflyHeight = '10%'
      let butterflyBottom = '33%'
      let butterflyRight = '3%'

      switch (aspectRatio1) {
        case AspectRatio._16_9:
          eggBlueHeight = '60%'
          eggBlueTop = '-10%'
          eggBlueRight = '-20%'

          eggPinkHeight = '45%'
          eggPinkBottom = '0%'
          eggPinkRight = '-15%'

          butterflyHeight = '10%'
          butterflyBottom = '38%'
          butterflyRight = '5%'

          break
        case AspectRatio._19_9:
        case AspectRatio._18_9:
          break
      }

      return (
        <div style={{ width: '100%', height, overflow: 'hidden' }}>
          <img
            className="twic"
            src={TSConstants.PLACEHOLDER_URL}
            data-src={`image:/cdm/kadow/img/easter/egg_blue.png`}
            style={{
              position: 'absolute',
              height: eggBlueHeight,
              top: eggBlueTop,
              right: eggBlueRight,
            }}
          />
          <img
            className="twic"
            src={TSConstants.PLACEHOLDER_URL}
            data-src={`image:/cdm/kadow/img/easter/egg_pink.png`}
            style={{
              position: 'absolute',
              height: eggPinkHeight,
              bottom: eggPinkBottom,
              right: eggPinkRight,
            }}
          />
          <img
            className="twic"
            src={TSConstants.PLACEHOLDER_URL}
            data-src={`image:/cdm/kadow/img/easter/butterfly2.png`}
            style={{
              position: 'absolute',
              height: butterflyHeight,
              bottom: butterflyBottom,
              right: butterflyRight,
            }}
          />
        </div>
      )
    },
    renderCTAForeground: (width?: number, height?: number) => {
      if (!width || !height) {
        return null
      }

      let rabbitHeight = '20%'
      let rabbitBottom = '8%'
      let rabbitLeft = '0%'

      switch (aspectRatio1) {
        case AspectRatio._16_9:
          rabbitHeight = '20%'
          rabbitBottom = '10%'
          rabbitLeft = '0%'

          break
        case AspectRatio._19_9:
        case AspectRatio._18_9:
          break
      }

      return (
        <>
          <img
            className="twic"
            src={TSConstants.PLACEHOLDER_URL}
            data-src={`image:/cdm/kadow/img/easter/rabbit.png`}
            style={{
              position: 'fixed',
              height: rabbitHeight,
              bottom: rabbitBottom,
              left: rabbitLeft,
              zIndex: 1000,
            }}
          />
        </>
      )
    },
  }
}
