import { SVGComponentProps } from '../Balloon'
import TSConstants from '../../../../utils/TSConstants'
import * as React from 'react'

export default function Flowers(props: SVGComponentProps) {
  return (
    <div
      style={{
        width: props.width,
        height: props.height,
        position: 'absolute',
        top: props.top,
        left: props.left,
        right: props.right,
        bottom: props.bottom,
      }}
    >
      <img
        className="twic"
        src={TSConstants.PLACEHOLDER_URL}
        data-src={`image:/cdm/img/otcp/flowers_2.png`}
        style={{ width: '100%' }}
      />
    </div>
  )
}
