import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import CONFIG from '../../model/TSConfig'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import TSConstants from '../../utils/TSConstants'
import React from 'react'
import './renderLogo.scss'
import LotteryType from '@tootsweet/model/lottery/LotteryType'

export function renderLogo(
  config?: LotteryConfigurationResponse,
  hasBG_URL?: boolean,
  step?: LotteryV2Step,
  hasError?: boolean,
  hasLost?: boolean,
  hasNotch?: boolean,
  isSkillGameWithPlaceSelector?: boolean
) {
  const logoURL = config && config.customizeColors && config?.logoURL
  const logoHeight =
    (config && config.customizeColors && config?.logoHeight) || undefined

  let logoSrc: string | undefined = undefined

  if (logoURL) {
    logoSrc = `image:/cdm/${CONFIG.CUSTOMER_LOGO_FILES_FOLDER}/${logoURL}`
  }

  let className = 'logo-container'

  if (
    !config?.colHeader ||
    config?.colHeader === 'transparent' ||
    config?.colBodyBack === config?.colHeader
  ) {
    if (config?.type !== LotteryType.Quizz) {
      className = 'logo-container-no-margin-bottom'
    }
  }

  const style: any = {}

  switch (config?.type) {
    case LotteryType.ScratchCard:
    case LotteryType.SlotMachine:
    case LotteryType.GiftMachine:
    case LotteryType.Fakir:
    case LotteryType.Dice:
    case LotteryType.MysteryBoxes:
      style.display = 'flex'
      style.justifyContent = 'center'
      break
  }

  switch (step?.stepType) {
    case LotteryV2StepType.ML_Intro:
    case LotteryV2StepType.ML_Game:
    case LotteryV2StepType.UTMRedirection:
      style.display = 'flex'
      style.justifyContent = 'center'
      break
  }

  if (!config?.disableCentering && (hasLost || hasError)) {
    className = className + ' logo-container-centered'
  }

  if (isSkillGameWithPlaceSelector) {
    style.display = 'flex'
    style.justifyContent = 'center'
  }

  if (hasNotch) {
    return (
      <div id="logo-notch-container">
        <div
          id="logo-notch"
          style={{
            backgroundColor: config?.colLogoBg ?? 'white',
          }}
        >
          {!!logoURL && (
            <img
              className="twic"
              src={TSConstants.PLACEHOLDER_URL}
              data-src={logoSrc}
              style={{
                maxWidth: '90%',
                maxHeight: 65,
              }}
            />
          )}
          {!logoURL && (
            <img
              className="twic logo"
              src={TSConstants.PLACEHOLDER_URL}
              data-src="image:/cdm/kadow/img/logo_2.png"
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <div
        className={`cm-pad logo-header ${hasBG_URL ? 'no-bg' : ''}`}
        style={{ zIndex: 99 }}
      >
        <div className={className} style={style}>
          {!!logoURL && (
            <img
              className="twic"
              src={TSConstants.PLACEHOLDER_URL}
              data-src={logoSrc}
              style={{ height: logoHeight }}
            />
          )}
          {!logoURL && (
            <img
              className="twic logo"
              src={TSConstants.PLACEHOLDER_URL}
              data-src="image:/cdm/kadow/img/logo_2.png"
            />
          )}
        </div>
      </div>
    </>
  )
}
