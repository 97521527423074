import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WheelButton from '../../../components/cta/WheelButton'
import {
  async_setLotteryPicture,
  nextLotteryStep,
  setLotteryUploadProgress,
} from '../../../redux/actions/lottery'
import { tt } from '../../../i18n'
import FlexPicture from '../../../components/FlexPicture'
import { FunctionComponent, useEffect } from 'react'
import { configSelector } from '../../../redux/selectors/config'

interface Props {
  isModal?: boolean
}

const INPUT_ID = 'w-camera-input'

const Photo: FunctionComponent<Props> = () => {
  const dispatch = useDispatch()

  dispatch(setLotteryUploadProgress(0))

  const siteConfig = useSelector(configSelector)

  const next = () => {
    onPressCamera()
  }

  const onPictureChosen = async (e: any) => {
    if (!e.target.files[0]) {
      return
    }

    const file = e.target.files[0]
    dispatch(async_setLotteryPicture(siteConfig, file))
    dispatch(nextLotteryStep())
  }

  const onPressCamera = () => {
    const input = document.getElementById(INPUT_ID)
    if (input) {
      input.click()
    }
  }

  useEffect(() => {
    const fileInput = document.getElementById(INPUT_ID)

    if (fileInput) {
      fileInput.addEventListener('change', onPictureChosen)
    }
  }, [])

  return (
    <>
      <h2
        className="h2 cm-pad w-margin-bottom w-margin-top-2-thirds"
        style={{ marginBottom: 0 }}
      >
        {tt('game_reserved_to_customers_1')}
        <span className="highlight"> {tt('game_reserved_to_customers_2')}</span>
      </h2>

      <p>{tt('take_photo_to_discover_gift')}</p>

      <FlexPicture isModal={false} src={undefined} />

      <input
        id={INPUT_ID}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        // @ts-ignore
        capture="capture"
      />

      <div className="w-margin-bottom-half">
        <WheelButton onClick={next} text={tt('take_photo_of_expenses')} />
      </div>
    </>
  )
}

export default Photo
