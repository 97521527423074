import React from 'react'
import Modal from 'react-bootstrap/Modal'

interface Props {
  show: boolean
  closeModal: () => void
}

export default function LegalMentionsModal(props: Props) {
  return (
    <Modal
      show={props.show}
      onHide={props.closeModal}
      centered={true}
      size="lg"
      className="w-modal-eula"
    >
      <Modal.Body>
        <i className="fas fa-times modal-close" onClick={props.closeModal} />
        <div className="d-flex flex-column w-modal">
          <h1>Mentions légales</h1>
          <p>
            Ce site est édité par la société Toot Sweet, SAS au capital de
            54.614,40 € immatriculée au RCS de Paris sous le numéro 811346287,
            dont le siège social est situé au 23 rue des Lombards, 75004, Paris.
            Numéro de TVA : FR25811346287.
            <br />
            Numéro de téléphone : +33.1.88.32.79.00
            <br />
            Email de contact : contact@kadow.club
            <br />
            <br />
            Toutes les images, photographies et illustrations sont libres de
            droit ou la propriété exclusive de Toot Sweet SAS.
            <br />
            <br />
            Hébergeur : AWS FRANCE, SAS au capital de 100 000,00€, immatriculée
            au RCS de Nanterre sous le numéro B 487 482 143, dont le siège
            social est situé au 52 rue du Port - 92000 Nanterre. Numéro de TVA :
            FR42487482143
            <br />
            Contact :{' '}
            <a href="https://aws.amazon.com/fr/contact-us/" target="_blank">
              cliquer ici
            </a>
          </p>
        </div>
      </Modal.Body>
    </Modal>
  )
}
