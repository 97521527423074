import React, { FunctionComponent, ReactNode, useState } from 'react'
import Form from 'react-bootstrap/Form'
import './cta/styles.scss'
import Axios from 'axios'
import CONFIG from '../model/TSConfig'
import { Loader } from './Loader/Loader'
import { tt } from '../i18n'
import { CONTACT_INDIV, CONTACT_PRO } from './modals/MarketingModal'

interface Props {
  onFinished: () => void
  isIOS: boolean
  source: string
  contactType?: string
  fp?: string | null
}

const ProfessionalContactForm: FunctionComponent<Props> = (props) => {
  const [firstName, setFirstName] = useState('')
  const [phone, setPhone] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [venue] = useState('non spécifié')

  const submit = async (e: any) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    const partner = {
      firstName,
      phone,
      companyName,
      message,
      email,
      source: props.source,
      isCDM: true,
      isUser: false,
      fp: props.fp,
      venue,
      url: window.location.href,
    }

    if (props.contactType === CONTACT_INDIV) {
      partner.isUser = true
    }

    try {
      setIsLoading(true)

      await Axios({
        method: 'post',
        url: `${CONFIG.BACKEND_URL}/becomeapartner`,
        data: partner,
      })

      if (props.contactType === 'individual') {
        alert(tt('contact_indivs_message_received'))
      } else {
        alert(tt('contact_pros_message_received'))
      }
    } catch (err) {
      console.error(err)
      alert(tt('error'))
    } finally {
      setIsLoading(false)
      props.onFinished()
    }
  }

  return (
    <Form id="contact-form" onSubmit={submit} action="#">
      {renderFormControl(
        <Form.Control
          type="text"
          placeholder={tt('placeholder_name')}
          value={firstName}
          onChange={(event: any) => setFirstName(event.target.value)}
          disabled={isLoading}
          required={true}
        />
      )}
      {renderFormControl(
        <Form.Control
          type="text"
          placeholder={tt('placeholder_phone_number')}
          value={phone}
          inputMode="tel"
          onChange={(event: any) => setPhone(event.target.value)}
          disabled={isLoading}
          required={true}
        />
      )}
      {renderFormControl(
        <Form.Control
          type="email"
          placeholder={tt('placeholder_email')}
          value={email}
          onChange={(event: any) => setEmail(event.target.value)}
          disabled={isLoading}
          required={true}
        />
      )}
      {props.contactType === CONTACT_PRO &&
        renderFormControl(
          <Form.Control
            type="text"
            placeholder={tt('placeholder_company_name')}
            value={companyName}
            onChange={(event: any) => setCompanyName(event.target.value)}
            disabled={isLoading}
            required={true}
          />
        )}
      {/*{ props.contactType === CONTACT_INDIV && renderFormControl(<Form.Control type="text"*/}
      {/*                                                                       placeholder={tt('placeholder_venue')}*/}
      {/*                                                                       value={venue}*/}
      {/*                                                                       onChange={(event: any) => setVenue(event.target.value)}*/}
      {/*                                                                       disabled={isLoading}*/}
      {/*                                                                       required={true}/>)}*/}
      {props.contactType === CONTACT_INDIV &&
        renderFormControl(
          <Form.Control
            as="textarea"
            rows={5}
            placeholder={
              props.contactType === CONTACT_INDIV
                ? tt('placeholder_your_message_individual')
                : tt('placeholder_your_message_here')
            }
            value={message}
            onChange={(event: any) => {
              setMessage(event.target.value)
            }}
            disabled={isLoading}
            required={true}
          />
        )}
      {
        props.contactType !== CONTACT_INDIV && <p>
          <div className="form-group form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              required={true}
            />
            <label className="form-check-label" htmlFor="exampleCheck1">
              {tt('optin_pro_contact_form')}
            </label>
          </div>
        </p>
      }
      {!isLoading &&
        renderFormControl(
          <button type="submit" className="w-button w-button-normal">
            <span>{tt('validate')}</span>
          </button>,
          true
        )}
      {isLoading &&
        renderFormControl(
          <Loader
            loading={true}
            displayText={false}
            isFixed={false}
            isDark={true}
          />
        )}
    </Form>
  )
}

const renderFormControl = (control: ReactNode, noMarginBottom?: boolean) => {
  return (
    <div
      className="form-group row"
      style={{
        marginBottom: noMarginBottom ? 0 : undefined,
      }}
    >
      <div className="col-12 text-center">{control}</div>
    </div>
  )
}

export default ProfessionalContactForm
