import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WheelButton from './cta/WheelButton'
import WheelAnalyticsManager from '../analytics/WheelAnalyticsManager'
import {
  incrementCTAClickCount,
  nextLotteryStep,
  setLotteryLoading,
} from '../redux/actions/lottery'
import TSConstants from '../utils/TSConstants'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import { tt } from '../i18n'
import { FunctionComponent } from 'react'
import { lotteryConfigSelector } from '../redux/selectors/lottery'
import { configSelector } from '../redux/selectors/config'

interface Props {
  snapchatUserId: string | undefined | null
  isFixedBottom?: boolean
  textOnLeft?: boolean
  preventNextStep: boolean
  step: LotteryV2Step | null
  style?: any | null
}

const SnapchatButton: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch()

  const config = useSelector(lotteryConfigSelector)
  const siteConfig = useSelector(configSelector)

  const { snapchatUserId, isFixedBottom, textOnLeft } = props

  const link = `https://www.snapchat.com/add/${
    snapchatUserId || (config ? config.snapchatProfileId : '')
  }`

  const onClickAddSnapchatUser = () => {
    WheelAnalyticsManager.snapchatClicked()

    dispatch(setLotteryLoading(true, props.step || undefined))

    setTimeout(() => {
      dispatch(incrementCTAClickCount())
      if (!props.preventNextStep) {
        dispatch(nextLotteryStep())
      }
      dispatch(setLotteryLoading(false))
    }, TSConstants.LOADER_TIMEOUT_SHORT)
  }

  return (
    <WheelButton
      text={tt('cta_snapchat')}
      onClick={onClickAddSnapchatUser}
      className="w-btn-snapchat"
      img={`${siteConfig.PUBLIC_FILES_URL}/static/img/snapchat_logo.png`}
      href={link}
      isFixedBottom={isFixedBottom}
      textOnLeft={textOnLeft}
      style={props.style}
    />
  )
}

export default SnapchatButton
