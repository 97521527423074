import Logo from './Logo'
import CONFIG from '../../../model/TSConfig'
import React from 'react'
import { useSelector } from 'react-redux'
import { lotteryConfigSelector } from '../../../redux/selectors/lottery'
import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'

interface SkillAppLogoProps {
  hasNotch?: boolean
  gameContainerWidth?: number
  useWidth?: boolean
  logoHeight?: number | string
  logoWidth?: number | string
}

export default function SkillAppLogo(props: SkillAppLogoProps) {
  const lotteryConfig = useSelector(lotteryConfigSelector)
  return (
    <Logo
      hasNotch={props.hasNotch}
      logoSrc={
        lotteryConfig?.customizeColors && lotteryConfig?.logoURL
          ? `image:/cdm/${CONFIG.CUSTOMER_LOGO_FILES_FOLDER}/${lotteryConfig.logoURL}`
          : 'image:/cdm/kadow/img/logo_2.png'
      }
      notchBackground={lotteryConfig?.colLogoBg ?? 'white'}
      logoHeight={
        props.logoHeight ||
        (props.hasNotch ? undefined : computeLogoHeight(lotteryConfig))
      }
      useWidth={props.useWidth}
    />
  )
}

function computeLogoHeight(
  lotteryConfig: LotteryConfigurationResponse | undefined
) {
  return lotteryConfig?.customizeColors && lotteryConfig?.logoURL
    ? lotteryConfig?.logoHeight || undefined
    : lotteryConfig?.logoHeight || 80
}
