import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WheelButton from '../../components/cta/WheelButton'
import { buildRedeemURL } from '../../redux/actions/lottery'
import { setShowFooter } from '../../redux/actions/app'
import { tt } from '../../i18n'
import FlexPicture from '../../components/FlexPicture'
import { FunctionComponent, useEffect } from 'react'
import {
  lotteryConfigSelector,
  lotteryGameConsumeSelector,
  venueSelector,
} from '../../redux/selectors/lottery'

interface Props {
  offeredGiftLabel?: string | null
  friendName?: string | null
}

const OfferToFriend: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch()

  const lotteryConfig = useSelector(lotteryConfigSelector)
  const lotteryGame = useSelector(lotteryGameConsumeSelector)
  const venue = useSelector(venueSelector)

  dispatch(setShowFooter(false))

  useEffect(() => {
    return () => {
      dispatch(setShowFooter(true))
    }
  }, [])

  const isEcommerce = lotteryConfig?.isEcommerce
  const site = lotteryConfig?.site
  const longName = lotteryGame?.prizeLongName

  return (
    <>
      <div className="w-margin-top w-margin-bottom-half cm-pad">
        <h2 className="h2 highlight">{tt('offer_to_friend_title')}</h2>
      </div>

      <p className="cm-pad">
        {isEcommerce &&
          !!site &&
          tt('offer_to_friend_text_ecom', {
            giftLabel: longName || props.offeredGiftLabel,
            site,
          })}
        {(!isEcommerce || !site) &&
          tt('offer_to_friend_text', {
            giftLabel: longName || props.offeredGiftLabel,
            finalPlaceName: venue?.name,
          })}
      </p>

      <FlexPicture
        isModal={false}
        src={undefined}
        dataSrc={`image:/cdm/img/gift_multicolor.png`}
        dataSrcTransform={'contain=WxH'}
        heightPc={0.5}
      />

      <div className="w-margin-bottom-half cm-pad">
        <WheelButton
          text={tt('offer_to_friend_view_gift_details')}
          href={
            lotteryGame && lotteryGame.id
              ? buildRedeemURL(lotteryGame.id)
              : undefined
          }
          forceTargetSelf={true}
        />
      </div>
    </>
  )
}

export default OfferToFriend
