import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import LotteryType from '@tootsweet/model/lottery/LotteryType'
import { tt } from '../../../i18n'

export const getHeading = ({
  lotteryConfig,
  ...props
}: {
  lotteryConfig: LotteryConfigurationResponse
  [key: string]: any
}) => {
  switch (lotteryConfig.type) {
    case LotteryType.Quizz:
      return (
        lotteryConfig.qIntroText ||
        (props.hasAudio
          ? tt('quizz_blind_test_default_text')
          : tt('quizz_answer_correctly'))
      )
    case LotteryType.Catcher:
      return lotteryConfig.qIntroText ?? tt('catcher_heading')
    default:
      return ''
  }
}

export const getCTAText = ({
  lotteryConfig,
  ...props
}: {
  lotteryConfig: LotteryConfigurationResponse
  [key: string]: any
}) => {
  switch (lotteryConfig.type) {
    case LotteryType.Quizz:
      return props.hasAudio ? tt('quizz_blind_test_start') : tt('quizz_start')
    case LotteryType.Catcher:
      return tt('catcher_start')
  }
}
