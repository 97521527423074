import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import FacebookButton from '../../components/FacebookButton'
import InstagramButton from '../../components/InstagramButton'
import TripAdvisorButton from '../../components/TripAdvisorButton'
import GoogleRatingButton from '../../components/GoogleRatingButton'
import SnapchatButton from '../../components/SnapchatButton'
import DownloadAppButton from '../../components/DownloadAppButton'
import McDonaldsPopupContent from './09_McDonalds/McDonaldsPopupContent'
import DeliverooButton from '../../components/DeliverooButton'
import UberEatsButton from '../../components/UberEatsButton'
import SponsorshipButton from '../../components/SponsorshipButton'
import React from 'react'
import FnacButton from '../../components/FnacButton'
import CDiscountButton from '../../components/CDiscountButton'
import { tt } from '../../i18n'
import CustomLinkButton from '../../components/CustomLinkButton'
import TikTokButton from '../../components/TikTokButton'
import StaffingButton from '../../components/StaffingButton'
import UberEatsDeliverooModalContent from './15_UberEatsDeliveroo/UberEatsDeliverooModalContent'

export function determineBeforeWheelInstagramH1(step: LotteryV2Step | null) {
  if (!step) {
    return ''
  }
  if (step.isIGStory) {
    return tt('one_story_and_its_on')
  }
  return tt('like_us')
}

export function buildBeforeWheelInstagramText(
  finalPlaceName: string,
  step: LotteryV2Step | null,
  isIOS: boolean
) {
  if (!step) {
    return ''
  }
  if (step.isIGStory) {
    if (isIOS) {
      return tt('before_wheel_ig_story_ios', { tag: step.tag })
    }
    return tt('before_wheel_ig_story_android', { tag: step.tag })
  }

  if (isIOS) {
    return tt('before_wheel_ig_ios', { finalPlaceName })
  }

  return tt('before_wheel_ig_android', { finalPlaceName })
}

export function buildAfterWheelInstagramText(
  finalPlaceName: string,
  prizeLabel: string | undefined,
  step: LotteryV2Step
) {
  if (step.isIGStory) {
    return tt('after_wheel_ig_story', { tag: step.tag })
  }
  return tt('after_wheel_ig', { finalPlaceName, prizeLabel })
}

export function buildH1TextForGoogleStepBeforeWheel(
  finalPlaceName: string,
  step: LotteryV2Step
) {
  if (step && !!step.tag2) {
    return tt('before_wheel_google_trip_advisor', { finalPlaceName })
    // return `Notez ${finalPlaceName} sur Google ou Tripadvisor puis cliquez sur la flèche en bas de l'écran pour revenir au jeu.`;
  }
  return tt('before_wheel_google', { finalPlaceName })
}

export function buildH1TextForGoogleStepAfterWheel(
  finalPlaceName: string,
  prizeLabel: string | undefined,
  step: LotteryV2Step
) {
  if (step && !!step.tag2) {
    return tt('after_wheel_h1_text_google_trip_advisor', {
      finalPlaceName,
      prizeLabel,
    })
  }
  return tt('before_wheel_h1_text_google')
}

function renderGoogleButton(
  step: LotteryV2Step,
  isModal: boolean,
  lotteryStepTag: string | null,
  isFixedBottom: boolean,
  preventNextStep: boolean,
  isAfterWheel?: boolean
) {
  return (
    <GoogleRatingButton
      step={step}
      googlePlaceId={lotteryStepTag}
      isFixedBottom={isFixedBottom}
      img="https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/google_icon.png"
      isWhite={true}
      isAfterWheel={isAfterWheel}
      preventNextStep={preventNextStep}
      showTuto={isModal}
    />
  )
}

export const renderCTAForStep = (
  step: LotteryV2Step | null | undefined,
  lotteryStepTag: string | null,
  isModal: boolean,
  isIOS: boolean,
  disabled: boolean,
  isFixedBottom: boolean,
  preventNextStep: boolean,
  forceGoogleButton?: boolean,
  googleTag?: string | null,
  isAfterWheel?: boolean
) => {
  if (!step || !step.stepType) {
    return undefined
  }

  if (forceGoogleButton && googleTag) {
    return renderGoogleButton(
      step,
      isModal,
      googleTag,
      isModal,
      isFixedBottom,
      preventNextStep
    )
  }

  switch (step.stepType) {
    case LotteryV2StepType.Facebook:
      return (
        <FacebookButton
          step={step}
          facebookPageId={lotteryStepTag}
          isFixedBottom={!isModal}
          preventNextStep={preventNextStep}
        />
      )

    case LotteryV2StepType.Instagram:
      return (
        <InstagramButton
          step={step}
          instagramProfileId={lotteryStepTag}
          isFixedBottom={!isModal}
          isStory={step.isIGStory}
          disabled={disabled}
          isAfterWheel={isAfterWheel}
          preventNextStep={preventNextStep}
          showTuto={isModal}
        />
      )

    case LotteryV2StepType.Google:
      if (!!step.tag2) {
        return (
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <div className="row w-margin-bottom-half">
              <div className="col-12">
                <TripAdvisorButton
                  step={step}
                  link={step.tag2}
                  isIOS={isIOS}
                  isFixedBottom={!isModal}
                  preventNextStep={preventNextStep}
                />
              </div>
            </div>
            <div className="row w-margin-bottom-half">
              <div className="col-12">
                <GoogleRatingButton
                  step={step}
                  googlePlaceId={lotteryStepTag}
                  isFixedBottom={!isModal}
                  img="https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/google_icon.png"
                  isWhite={true}
                  preventNextStep={preventNextStep}
                  showTuto={isModal}
                />
              </div>
            </div>
          </div>
        )
      }

      return renderGoogleButton(
        step,
        isModal,
        lotteryStepTag,
        isFixedBottom,
        preventNextStep,
        isAfterWheel
      )

    case LotteryV2StepType.Snapchat:
      return (
        <SnapchatButton
          step={step}
          snapchatUserId={lotteryStepTag}
          isFixedBottom={!isModal}
          preventNextStep={preventNextStep}
        />
      )

    case LotteryV2StepType.DownloadApp:
      return (
        <DownloadAppButton
          step={step}
          iOSAppID={step.tag}
          androidBundleID={step.tag2}
          isIOS={isIOS}
          isFixedBottom={!isModal}
          preventNextStep={preventNextStep}
          disabled={disabled}
        />
      )

    case LotteryV2StepType.TikTok:
      return (
        <TikTokButton
          step={step}
          tikTokUserId={lotteryStepTag}
          isFixedBottom={!isModal}
          preventNextStep={preventNextStep}
          showTuto={isModal}
        />
      )

    case LotteryV2StepType.TripAdvisor:
      return (
        <TripAdvisorButton
          step={step}
          link={step.tag || undefined}
          isIOS={isIOS}
          isFixedBottom={isFixedBottom}
          preventNextStep={preventNextStep}
        />
      )

    case LotteryV2StepType.McDoPlus:
      return <McDonaldsPopupContent />

    case LotteryV2StepType.Deliveroo:
      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          {step.addGoogleButton && (
            <div className="row w-margin-bottom-half">
              <div className="col-12">
                <GoogleRatingButton
                  step={step}
                  googlePlaceId={lotteryStepTag}
                  isFixedBottom={!isModal}
                  img={
                    isModal
                      ? undefined
                      : 'https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/google_icon.png'
                  }
                  isWhite={!isModal}
                  preventNextStep={preventNextStep}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <DeliverooButton
                step={step}
                isIOS={isIOS}
                isFixedBottom={!isModal}
                enableHref={true}
              />
            </div>
          </div>
        </div>
      )

    case LotteryV2StepType.UberEats:
      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          {step.addGoogleButton && (
            <div className="row w-margin-bottom-half">
              <div className="col-12">
                <GoogleRatingButton
                  step={step}
                  googlePlaceId={lotteryStepTag}
                  isFixedBottom={!isModal}
                  img={
                    isModal
                      ? undefined
                      : 'https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/google_icon.png'
                  }
                  isWhite={!isModal}
                  preventNextStep={preventNextStep}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <UberEatsButton
                step={step}
                isIOS={isIOS}
                isFixedBottom={!isModal}
                enableHref={true}
              />
            </div>
          </div>
        </div>
      )

    case LotteryV2StepType.DeliverooUberEats:
      return (
        <UberEatsDeliverooModalContent
          step={step}
          isIOS={isIOS}
          isModal={isModal}
          isFixedBottom={isFixedBottom}
        />
      )

    case LotteryV2StepType.TwoSocialNetworks:
    case LotteryV2StepType.ThreeSocialNetworks:
      if (step && step.subSteps && step.subSteps.length) {
        const className = LotteryV2StepType.ThreeSocialNetworks
          ? 'w-button-bottom-triple'
          : 'w-button-bottom-double'
        return (
          <div className={className}>
            {step.subSteps.map((subStep: LotteryV2Step, index: number) => {
              return renderSubStep(
                subStep,
                index,
                // @ts-ignore
                step.subSteps.length,
                isModal,
                step,
                preventNextStep,
                isIOS
              )
            })}
          </div>
        )
      }
      return null

    case LotteryV2StepType.Sponsorship:
      return (
        <SponsorshipButton
          isFixedBottom={isFixedBottom}
          isIOS={isIOS}
          preventNextStep={preventNextStep}
          isAfterWheel={isAfterWheel}
        />
      )

    case LotteryV2StepType.CustomLink:
      return (
        <CustomLinkButton
          step={step}
          isFixedBottom={isFixedBottom}
          isIOS={isIOS}
          preventNextStep={preventNextStep}
          isAfterWheel={isAfterWheel}
        />
      )

    case LotteryV2StepType.Staffing:
      return (
        <StaffingButton
          step={step}
          isFixedBottom={isFixedBottom}
          isIOS={isIOS}
          preventNextStep={preventNextStep}
          isAfterWheel={isAfterWheel}
        />
      )
  }

  return null
}

const renderSubStep = (
  subStep: LotteryV2Step,
  index: number,
  length: number,
  isModal: boolean,
  parentStep: LotteryV2Step | null,
  preventNextStep: boolean,
  isIOS: boolean
) => {
  if (!subStep || !subStep.stepType) {
    return null
  }

  let content: any | null = null

  let style: any | null = null

  if (parentStep?.stepType === LotteryV2StepType.ThreeSocialNetworks) {
    let width: string | undefined = undefined
    let marginLeft: string | undefined = undefined
    let marginTop: number | undefined = undefined
    if (index !== 0) {
      width = '66%'
      marginLeft = '17%'
    }
    if (index === 1) {
      marginTop = 30
    }
    style = {
      width,
      zoom: width,
      marginLeft,
      marginTop,
    }
  }

  switch (subStep.stepType) {
    case LotteryV2StepType.Facebook:
      content = (
        <FacebookButton
          isFixedBottom={!isModal}
          facebookPageId={subStep.tag}
          textOnLeft={true}
          step={parentStep}
          preventNextStep={preventNextStep}
          style={style}
        />
      )
      break

    case LotteryV2StepType.Instagram:
      content = (
        <InstagramButton
          isFixedBottom={!isModal}
          instagramProfileId={subStep.tag}
          textOnLeft={true}
          step={parentStep}
          preventNextStep={preventNextStep}
          showTuto={isModal}
          style={style}
        />
      )
      break

    case LotteryV2StepType.Snapchat:
      content = (
        <SnapchatButton
          isFixedBottom={!isModal}
          snapchatUserId={subStep.tag}
          textOnLeft={true}
          step={parentStep}
          preventNextStep={preventNextStep}
          style={style}
        />
      )
      break

    case LotteryV2StepType.TikTok:
      content = (
        <TikTokButton
          isFixedBottom={!isModal}
          tikTokUserId={subStep.tag}
          textOnLeft={true}
          step={parentStep}
          preventNextStep={preventNextStep}
          style={style}
          showTuto={isModal}
        />
      )
      break

    case LotteryV2StepType.Google:
      content = (
        <GoogleRatingButton
          googlePlaceId={subStep.tag}
          isFixedBottom={!isModal}
          textOnLeft={true}
          img="https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/google_white.png"
          step={parentStep}
          preventNextStep={preventNextStep}
          showTuto={isModal}
          style={style}
        />
      )
      break

    case LotteryV2StepType.Fnac:
      content = (
        <FnacButton
          link={subStep.tag}
          step={parentStep}
          isFixedBottom={!isModal}
          textOnLeft={true}
          preventNextStep={preventNextStep}
        />
      )
      break
    case LotteryV2StepType.CDiscount:
      content = (
        <CDiscountButton
          link={subStep.tag}
          step={parentStep}
          isFixedBottom={!isModal}
          textOnLeft={true}
          preventNextStep={preventNextStep}
        />
      )
      break

    case LotteryV2StepType.Sponsorship:
      content = (
        <SponsorshipButton
          isFixedBottom={!isModal}
          isIOS={isIOS}
          preventNextStep={preventNextStep}
          textOnLeft={true}
        />
      )
      break
  }

  if (content) {
    return (
      <>
        {content}
        {index < length - 1 && <div style={{ height: 10 }} />}
      </>
    )
  }

  return null
}

export function getImageName(stepType: LotteryV2StepType | null) {
  if (!stepType) {
    return undefined
  }

  switch (stepType) {
    case LotteryV2StepType.Google:
    case LotteryV2StepType.TwoSocialNetworks:
    case LotteryV2StepType.TripAdvisor:
    case LotteryV2StepType.Instagram:
    case LotteryV2StepType.TikTok:
      return 'hearts.png'

    case LotteryV2StepType.DownloadApp:
      return 'download_app.png'

    case LotteryV2StepType.Deliveroo:
      return 'img/deliveroo.png'

    case LotteryV2StepType.Staffing:
      return 'staffing.png'
  }

  return undefined
}
