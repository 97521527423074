import React from 'react'
import Cube, { Back, Bottom, Front, Left, Right, Top } from './Cube'
import { aspect_ratio, AspectRatio } from '../../../utils/aspect_ratio'

const red1 = '#E67271'
const red2 = '#B14152'

const yellow1 = '#FFD080'
const yellow2 = '#DB7F62'

const green1 = '#55B59D'
const green2 = '#3A71AE'

const blue1 = '#B7C2F0'
const blue2 = '#7082D0'

const FRONT_SNOW = require('../img/frontSnow.png')
const FRONT_SNOW_LEFT = require('../img/frontSnowLeft.png')
const FRONT_SNOW_RIGHT = require('../img/frontSnowRight.png')
const LEFT_SNOW = require('../img/leftSnow.png')

interface Props {
  color: 'green' | 'red' | 'blue' | 'yellow'
  top: number
  left: number
  width: number
  height: number
  frontSnow?: 'front_snow' | 'front_snow_left' | 'front_snow_right'
  leftSnow?: boolean
  day: number
  translateZ?: number
  /**
   * Return true if want to start the animation false otherwise
   */
  shouldAnimate: (day: number) => boolean
  /**
   * When the box finished the scale up animation
   */
  onAnimationEnd: () => void
  blink: boolean
  symbol?: any
  symbolRatio?: number
}

export default class AdventCalendarItem extends React.PureComponent<Props> {
  get color1() {
    return {
      green: green1,
      red: red1,
      blue: blue1,
      yellow: yellow1,
    }[this.props.color]
  }

  get color2() {
    return {
      green: green2,
      red: red2,
      blue: blue2,
      yellow: yellow2,
    }[this.props.color]
  }

  getFrontSnow = () => {
    if (this.props.frontSnow === 'front_snow') {
      return FRONT_SNOW
    }
    if (this.props.frontSnow === 'front_snow_left') {
      return FRONT_SNOW_LEFT
    }
    if (this.props.frontSnow === 'front_snow_right') {
      return FRONT_SNOW_RIGHT
    }
  }

  onClick = () => {
    const shouldAnimate = this.props.shouldAnimate(this.props.day)
    if (shouldAnimate) {
      this.animate()
    }
  }

  animate = () => {
    const anim = document
      .getElementById(`cube-${this.props.day}`)
      ?.animate(
        [
          { transform: 'translateZ(0) rotateY(0deg)' },
          { transform: 'translateZ(50px)' },
        ],
        {
          fill: 'both',
          duration: 1500,
          easing: 'ease-out',
        }
      )

    if (anim) {
      anim.onfinish = () => {
        document.getElementById(`cube-container-${this.props.day}`)?.animate(
          [
            {
              zIndex: 0,
              top: this.props.top,
              left: this.props.left,
              opacity: 1,
            },
            { zIndex: 9999, top: 0, left: '-1000px', opacity: 0.8 },
          ],
          {
            fill: 'both',
            duration: 500,
            easing: 'ease-in',
            delay: 1000,
          }
        )

        const animScale = document
          .getElementById(`perspective-${this.props.day}`)
          ?.animate([{ transform: 'scale(1)' }, { transform: 'scale(25)' }], {
            fill: 'both',
            duration: 500,
            easing: 'ease-in',
            delay: 1000,
          })

        if (animScale) {
          animScale.onfinish = () => {
            this.props.onAnimationEnd()
          }
        }
      }
    }
  }

  renderNumber = () => {
    let img1
    let img2

    let width = this.props.width / 2.5
    let height = this.props.width / 2.5

    if (aspect_ratio === AspectRatio._16_9) {
      width = this.props.width / 3.5
      height = this.props.width / 3.5
    }

    if (this.props.day > 9) {
      const [left, right] = this.props.day.toString().split('')
      img1 = left
      img2 = right
    } else {
      img1 = this.props.day
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
        className={this.props.blink ? 'advent-blink' : ''}
      >
        <img
          src={`https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/kadow/advent-calendar/${img1}.svg`}
          style={{
            width,
            height,
          }}
        />
        {!!img2 && (
          <img
            src={`https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/kadow/advent-calendar/${img2}.svg`}
            style={{
              marginLeft: aspect_ratio === AspectRatio._16_9 ? -7 : -7,
              width,
              height,
            }}
          />
        )}
      </div>
    )
  }

  render() {
    return (
      <div
        style={{
          position: 'absolute',
          top: this.props.top,
          left: this.props.left,
        }}
        id={`cube-container-${this.props.day}`}
        className={'cube-container'}
      >
        <Cube
          width={this.props.width}
          height={this.props.height}
          depth={this.props.height * 0.5}
          top={this.props.top}
          left={this.props.left}
          perspectiveX={-2000}
          perspectiveY={-600}
          translateZ={this.props.translateZ}
          id={`cube-${this.props.day}`}
          containerId={`perspective-${this.props.day}`}
        >
          <Front>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: this.color1,
              }}
              className="clickme"
              onClick={this.onClick}
            >
              <div
                style={{
                  position: 'absolute',
                  backgroundColor: this.color2,
                  width: 4,
                  transform: 'skew(0deg, 45deg)',
                  top: 10,
                  bottom: 5,
                  right: 5,
                }}
              />

              <div
                style={{
                  position: 'absolute',
                  backgroundColor: this.color2,
                  width: 4,
                  top: 5,
                  bottom: 10,
                  right: 5,
                }}
              />

              <div
                style={{
                  position: 'absolute',
                  backgroundColor: 'white',
                  height: 4,
                  transform: 'skew(45deg, 0deg)',
                  left: 10,
                  right: 5,
                  bottom: 3,
                }}
              />

              <div
                style={{
                  position: 'absolute',
                  backgroundColor: 'white',
                  height: 4,
                  left: 5,
                  right: 10,
                  bottom: 3,
                }}
              />

              {this.props.frontSnow && (
                <img
                  src={this.getFrontSnow()}
                  style={{
                    position: 'absolute',
                    width: this.props.width,
                    height: this.props.width / 3,
                    top: -1,
                    left: 0,
                  }}
                />
              )}

              {this.renderNumber()}
            </div>
          </Front>

          <Top>
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
              }}
            ></div>
          </Top>

          <Left>
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: this.color2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {!!this.props.symbol && !!this.props.symbolRatio && (
                <img
                  src={this.props.symbol}
                  style={{
                    position: 'absolute',
                    width: (this.props.height / this.props.symbolRatio) * 0.8,
                    height: this.props.height * 0.8,
                    top: '10%',
                    rotate: '-2deg',
                  }}
                />
              )}
              {this.props.leftSnow && (
                <img
                  src={LEFT_SNOW}
                  style={{
                    position: 'absolute',
                    width: this.props.height * 0.5,
                    height: this.props.width * 0.5,
                    top: 0,
                  }}
                />
              )}
            </div>
          </Left>

          <Right>
            {' '}
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'transparent',
              }}
            />
          </Right>
          <Bottom>
            {' '}
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'transparent',
              }}
            />
          </Bottom>
          <Back>
            {' '}
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'transparent',
              }}
            />
          </Back>
        </Cube>
      </div>
    )
  }
}
