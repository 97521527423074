import {
  COLOR_WHITE,
  DEFAULT_GREY_1,
  DEFAULT_GREY_2,
  DEFAULT_GREY_3,
  DEFAULT_RED,
} from './WheelColors'

export const renderStyles = () => {
  return `
              .cs-isolate{isolation:isolate;}
              .cls-bg-objects-5{opacity:0.5;}
              .cls-bg-objects-5,.cls-bg-objects-7{mix - blend - mode:overlay;}
              .cls-bg-objects-7{fill:#fff;opacity:0.65;}
              .cs-mix-blend-opacity{mix-blend-mode:overlay;opacity:0.5}
              .cs-fill-none{fill:none;}
              .cs-fill-color-red{fill:${DEFAULT_RED};}
              .cs-fill-color-grey-1{fill:${DEFAULT_GREY_1}; } 
              .cs-fill-color-grey-2{fill:${DEFAULT_GREY_2}; }
              .cs-fill-color-grey-3{fill:${DEFAULT_GREY_3}; }
              .cs-fill-color-white{fill:${COLOR_WHITE}; }
              
        `
}
