import * as React from 'react'
import { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import WheelAnalyticsManager from '../../analytics/WheelAnalyticsManager'
import CONFIG from '../../model/TSConfig'
import TSConstants from '../../utils/TSConstants'
import { tt } from '../../i18n'
import Scratch from '../../components/Scratch/Scratch'
import SnowFlakes from '../../components/christmas/SnowFlakes'
import { Garland, GarlandColor } from '../../components/christmas/Garland'
import { setShowFooter } from '../../redux/actions/app'
import './styles.scss'
import { ZINDEX_CANVAS, ZINDEX_GARLANDS, ZINDEX_SNOWFLAKES } from './constants'
import { async_play } from '../../redux/actions/lottery'
import { userSelector } from '../../redux/selectors/app'
import {
  drawSelector,
  isOptOutSelector,
  lotteryConfigSelector,
  lotteryGameConsumeSelector,
  prizeSelector,
} from '../../redux/selectors/lottery'
import { configSelector } from '../../redux/selectors/config'
import { aspect_ratio, AspectRatio } from '../../utils/aspect_ratio'
import { TSUtils } from '../../utils/TSUtils'
import renderTokens from '../../components/MonthlyLottery/renderTokens'

interface Props {
  finalPlaceName?: string
  renderSnowflakes?: boolean
  force100PcHeight?: boolean
  onUserStartedScratching?: () => void
  onGameComplete?: () => void
  gameStepType: LotteryV2StepType
  play?: boolean
}

const MSG =
  "Vous n'avez pas gratté la carte. Etes-vous sûr(e) de vouloir quitter le jeu ?"
const CALLBACK_1_PERCENT = 1

const unloadCallback = (e: BeforeUnloadEvent) => {
  e.preventDefault()
  e.returnValue = MSG
  return MSG
}

const ScratchCardChristmas: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch()

  const user = useSelector(userSelector)
  const isOptOut = useSelector(isOptOutSelector)
  const siteConfig = useSelector(configSelector)
  const prize = useSelector(prizeSelector)
  const game = useSelector(lotteryGameConsumeSelector)
  const lotteryConfig = useSelector(lotteryConfigSelector)
  const draw = useSelector(drawSelector)

  let audio: HTMLAudioElement | null = null
  let hasPlayed = false

  const [container, setContainer] = useState<HTMLDivElement | null>(null)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [skin, setSkin] = useState<string>('-1')

  const renderGarlands = () => {
    return (
      <>
        <Garland
          color={GarlandColor.Blue}
          bottomPc={12}
          rightPc={12}
          zIndex={ZINDEX_GARLANDS}
        />
        <Garland
          color={GarlandColor.Yellow}
          bottomPc={10}
          rightPc={20}
          zIndex={ZINDEX_GARLANDS}
        />
        <Garland
          color={GarlandColor.Red}
          bottomPc={9}
          rightPc={30}
          zIndex={ZINDEX_GARLANDS}
        />

        <Garland
          color={GarlandColor.Blue}
          bottomPc={7}
          rightPc={40}
          zIndex={ZINDEX_GARLANDS}
        />
        <Garland
          color={GarlandColor.Yellow}
          bottomPc={5}
          rightPc={48}
          zIndex={ZINDEX_GARLANDS}
        />

        <Garland
          color={GarlandColor.Blue}
          bottomPc={6}
          leftPc={23}
          zIndex={ZINDEX_GARLANDS}
        />
        <Garland
          color={GarlandColor.Red}
          bottomPc={9}
          leftPc={16}
          zIndex={ZINDEX_GARLANDS}
        />
        <Garland
          color={GarlandColor.Yellow}
          bottomPc={12.5}
          leftPc={21}
          zIndex={ZINDEX_GARLANDS}
        />
        <Garland
          color={GarlandColor.Blue}
          bottomPc={18}
          leftPc={22}
          zIndex={ZINDEX_GARLANDS}
        />
        <Garland
          color={GarlandColor.Red}
          bottomPc={19}
          leftPc={14}
          zIndex={ZINDEX_GARLANDS}
        />
        <Garland
          color={GarlandColor.Yellow}
          bottomPc={21}
          leftPc={6}
          zIndex={ZINDEX_GARLANDS}
        />
        <Garland
          color={GarlandColor.Blue}
          bottomPc={23}
          leftPc={-2}
          zIndex={ZINDEX_GARLANDS}
        />
      </>
    )
  }

  const play = () => {
    if (!user) {
      return
    }

    dispatch(
      async_play(
        siteConfig,
        user.firstName,
        user.paymentEmail,
        isOptOut,
        user.phone,
        true
      )
    )

    window.onbeforeunload = null
    window.removeEventListener('beforeunload', unloadCallback)
  }

  const getPrizeLabel = () => {
    let label = prize?.label
    let blur = false

    if (!label) {
      if (game && !game.outcome) {
        label = tt('lost')
      } else {
        label = tt('mystery_gift')
        blur = true
      }
    }

    return { label, blur }
  }

  const renderPrizeLabel = () => {
    const res = getPrizeLabel()

    let size = 30

    if (res.label.length > 100) {
      size = 15
    } else if (res.label.length > 65) {
      size = 20
    } else if (res.label.length > 40) {
      size = 25
    }
    if (aspect_ratio === AspectRatio._16_9) {
      size = Math.trunc(size * 0.7)
    }

    return (
      <span
        className={`sc-prize-label ${res.blur ? 'sc-prize-blur' : ''}`}
        style={{ fontSize: size }}
      >
        {res.label}
      </span>
    )
  }

  const startMusic = async () => {
    audio = new Audio(
      'https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/kadow/sound/jinglebells.ogg'
    )

    const canPlayOgg =
      !!audio.canPlayType &&
      audio.canPlayType('audio/ogg; codecs="vorbis"') != ''

    if (!canPlayOgg) {
      audio = null
      return
    }

    audio.loop = true
    try {
      await audio.play()
    } catch (err) {
      console.error(err)
    }
  }

  const onUserStartedScratching = () => {
    if (props.play && !hasPlayed) {
      hasPlayed = true
      play()
    }

    if (props.onUserStartedScratching) {
      props.onUserStartedScratching()
    }
  }

  const renderGame = () => {
    if (skin === '-1') {
      return null
    }

    const logoURL =
      (lotteryConfig &&
        lotteryConfig.customizeColors &&
        lotteryConfig?.logoURL) ||
      ''

    let imgHeight = Math.floor(height * IMG_GIFTS_HEIGHT_PC)
    let imgWidth = Math.floor(imgHeight * IMG_GIFTS_WIDTH_HEIGHT_RATIO)

    if (imgWidth > 0.9 * width) {
      imgWidth = 0.9 * width
      imgHeight = imgWidth / IMG_GIFTS_WIDTH_HEIGHT_RATIO
    }

    imgWidth = Math.floor(imgWidth)
    imgHeight = Math.floor(imgHeight)

    const scratchContainerMargin = Math.floor((width - imgWidth) / 2)
    const displayTokens = lotteryConfig?.hasML && prize?.index === -1 && draw

    return (
      <div className="sc-fg-container">
        <h1 className="h1-scratchcard">
          {tt('scratch_card_scratch_1')}
          <br />
          {tt('scratch_card_scratch_2')}
        </h1>
        <div
          style={{
            position: 'absolute',
            top: 0,
            width: width,
            height: '100%',
          }}
        >
          <div
            style={{
              position: 'absolute',
              bottom: '5%',
              right: width * 0.05,
              width: width * 0.9,
              height: height * 0.55,
            }}
          />
          <div
            style={{
              position: 'absolute',
              bottom: '5%',
              right: scratchContainerMargin,
              zIndex: ZINDEX_CANVAS,
            }}
          >
            {!!width && !!height && (
              <Scratch
                key={`${width}-${height}`}
                image={`${IMG_GIFT_BASE_URL}/contain=${
                  width * window.devicePixelRatio
                }x${height * window.devicePixelRatio}/quality=100`}
                width={imgWidth}
                height={imgHeight}
                finishPercent={60}
                onComplete={props.onGameComplete}
                // customCheckZone={{
                //   x: imgWidth * 0.1,
                //   y: imgHeight * 0.45,
                //   width: imgWidth * 0.8,
                //   height: imgHeight * 0.25,
                // }}
                callback1={onUserStartedScratching}
                callback1Percent={CALLBACK_1_PERCENT}
                fadeOutOnComplete={true}
              >
                <div
                  style={{
                    width: imgWidth,
                    height: imgHeight,
                    backgroundColor: '#ddd',
                    maskMode: 'match-source',

                    maskImage: `url(${IMG_GIFT_BASE_URL}/contain=${imgWidth}x${imgHeight}/quality=100)`,
                    maskRepeat: 'no-repeat',
                    maskSize: 'contain',

                    WebkitMaskImage: `url(${IMG_GIFT_BASE_URL}/contain=${imgWidth}x${imgHeight}/quality=100)`,
                    WebkitMaskRepeat: 'no-repeat',
                    WebkitMaskSize: 'contain',
                  }}
                />

                <div className="sc-prize-label-container sc-christmas-prize-label-container">
                  {displayTokens &&
                    !!draw &&
                    renderTokens(
                      lotteryConfig,
                      draw,
                      Math.trunc(imgHeight * 0.1),
                      imgWidth * 0.8,
                      imgHeight * 0.2
                    )}

                  {!displayTokens && renderPrizeLabel()}
                </div>
              </Scratch>
            )}
          </div>
        </div>

        {renderGarlands()}

        {props.renderSnowflakes && (
          <div style={{ position: 'absolute', height: '100%', width: '100%' }}>
            <SnowFlakes zIndex={ZINDEX_SNOWFLAKES} />
          </div>
        )}
      </div>
    )
  }

  useEffect(() => {
    if (props.play) {
      WheelAnalyticsManager.logEvent('scratch_card_christmas')
    }

    startMusic()
    dispatch(setShowFooter(true))

    if (props.play) {
      window.addEventListener('beforeunload', unloadCallback)
      window.onbeforeunload = unloadCallback
    }

    return () => {
      if (audio) {
        audio.pause()
        audio = null
      }

      if (props.play) {
        window.onbeforeunload = null
        window.removeEventListener('beforeunload', unloadCallback)
      }
    }
  }, [])

  useEffect(() => {
    if (container) {
      setWidth(container.clientWidth)
      setHeight(container.clientHeight)
    }
  }, [container])

  useEffect(() => {
    if (skin === '-1') {
      const gameStep =
        lotteryConfig &&
        lotteryConfig.steps &&
        lotteryConfig.steps.find(
          (s: LotteryV2Step) => s.stepType === props.gameStepType
        )
      setSkin(gameStep?.skin || TSConstants.SKIN_DEFAULT)
    }
  }, [])

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          height: props.force100PcHeight ? '100%' : '100%',
        }}
        ref={(ref) => setContainer(ref)}
      >
        {renderGame()}
      </div>
    </>
  )
}

export default ScratchCardChristmas

const IMG_GIFT_BASE_URL =
  'https://tootsweet.twic.pics/cdm/img/christmas/scratch_card.png?twic=v1'

const IMG_GIFTS_HEIGHT_PC = 0.7
const IMG_GIFTS_WIDTH_HEIGHT_RATIO = 1000 / 1487
