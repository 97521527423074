import React, { FC, ReactNode } from 'react'
import { QuizzGift } from '@tootsweet/model/lottery/Quizz'
import { tt } from '../../../i18n'
import WheelButton from '../../../components/cta/WheelButton'
import { quizzSelector } from '../../../redux/selectors/quizz'
import { useSelector } from 'react-redux'

interface EndProps {
  logo: ReactNode
  gifts: QuizzGift[]
  score: number
  onGiftChosen: (gift: QuizzGift) => void
}

const End: FC<EndProps> = ({ gifts, score, logo, onGiftChosen }) => {
  const quizz = useSelector(quizzSelector)
  return (
    <div
      className={
        'd-flex flex-column align-items-center justify-content-around cm-pad text-center'
      }
    >
      {logo}
      {gifts.length === 0 && (
        <h2 className="h2">
          {!quizz?.winScore || quizz?.winScore !== -1
            ? tt('quizz_no_good_answer')
            : tt('quizz_not_enough_points_1')}
        </h2>
      )}

      {gifts.length === 0 && (
        <div id="end-announcement">
          <p>{tt('quizz_not_enough_points_2')}</p>
        </div>
      )}

      {gifts.length > 0 && (
        <h2 className="h2">
          {tt('quizz_score', {
            score: score,
            pointsStr: score > 1 ? 'points' : 'point',
          })}
        </h2>
      )}
      {gifts.length > 0 && (
        <p id="end-announcement" className="w-margin-bottom">
          {tt('quizz_enough_points')}
        </p>
      )}
      <div className="w-100" style={{ maxHeight: 300, overflowY: 'scroll' }}>
        {gifts.length > 0 &&
          gifts.map((gift, idx) => {
            return (
              <div
                key={`gift-${idx}`}
                className={`w-100 quizz-gift-container ${
                  idx === gifts.length - 1 ? '' : 'w-margin-bottom-half'
                }`}
              >
                <WheelButton
                  text={gift.label}
                  onClick={() => onGiftChosen(gift)}
                />
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default End
