import React, { FunctionComponent, ReactNode, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WheelAnalyticsManager from '../../analytics/WheelAnalyticsManager'
import { setShowMLOptinModal } from '../../redux/actions/lottery'
import './styles.scss'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import Scratch from '../../components/Scratch/Scratch'
import { tt } from '../../i18n'
import TitleV2 from '../../components/MonthlyLottery/Title/TitleV2'
import {
  drawSelector,
  lotteryConfigSelector,
  mlConfigSelector,
  skinSelector,
} from '../../redux/selectors/lottery'
import renderTokens from '../../components/MonthlyLottery/renderTokens'

interface Props {
  isQuizz?: boolean
  skillNextStep?: () => void
}

const MonthlyLotteryGame: FunctionComponent<Props> = (props) => {
  const [container, setContainer] = React.useState<HTMLDivElement | null>(null)
  const [showScratchText, setShowScratchText] = React.useState<boolean>(false)
  const skin = useSelector(skinSelector)

  const dispatch = useDispatch()

  const lotteryConfig = useSelector(lotteryConfigSelector)
  const draw = useSelector(drawSelector)
  const mlConfig = useSelector(mlConfigSelector)

  useEffect(() => {
    WheelAnalyticsManager.logEvent(LotteryV2StepType.ML_Game)
    // async_ml_draw();
  }, [])

  const onScratchComplete = () => {
    setTimeout(() => {
      dispatch(setShowMLOptinModal(true))
      if (props.skillNextStep) {
        props.skillNextStep()
      }
    }, 2000)
  }

  const hideScratchText = () => {
    setShowScratchText(false)
  }

  if (!mlConfig || !draw || !draw.nTokens) {
    return null
  }

  let width = 0
  let height = 0
  let imgWidth = 0
  let imgHeight = 0
  let tokenImgWidth = 0
  let imgURL = ''
  let img = ''

  if (container) {
    height = container.clientHeight
    width = Math.trunc(container.clientWidth * 0.9)
    imgWidth = width
    imgHeight = height
    tokenImgWidth = Math.trunc(height * 0.25)
    imgURL = `https://tootsweet.twic.pics/cdm/kadow/glm/sc.png?twic=v1`
    if (mlConfig?.scratchImg) {
      imgURL = `https://tootsweet.twic.pics/cdm/kadow/glm/${mlConfig?.scratchImg}?twic=v1`
    }
    if (mlConfig?.scratchImgIsSquare) {
      height = width
      imgHeight = imgWidth
    }
    if (skin?.ml?.scImg && skin?.ml?.scImgW && skin?.ml?.scImgH) {
      imgURL = `https://tootsweet.twic.pics/cdm/kadow/glm/${skin?.ml?.scImg}?twic=v1`
      if (skin?.ml?.scImgW === skin?.ml?.scImgH) {
        height = width
        imgHeight = imgWidth
      }
    }
    img = `${imgURL}/contain=${imgWidth}x${imgHeight}/quality=100`
  }

  return (
    <>
      <div id="k-ml-game" className="cm-pad w-margin-top">
        {props.isQuizz ? (
          <TitleV2 title1={tt('ml_title_quizz')} />
        ) : (
          <TitleV2 title1={lotteryConfig?.customMLGameTitle || 'Surprise !'} />
        )}
      </div>

      <div className="cm-pad k-glm-text">
        <p>
          {props.isQuizz
            ? tt('ml_subtitle_quizz')
            : lotteryConfig?.customMLGameText || tt('ml_second_chance')}
        </p>
      </div>

      <div
        className="cm-pad d-flex justify-content-center align-items-center w-margin-top w-margin-bottom"
        style={{ flex: 1, position: 'relative' }}
        ref={(ref) => setContainer(ref)}
      >
        {!!height && !!width && (
          <>
            <Scratch
              key="sc-glm"
              image={img}
              width={width}
              height={height}
              finishPercent={60}
              onComplete={onScratchComplete}
              callback1Percent={1}
              callback1={hideScratchText}
              fadeOutOnComplete={true}
            >
              <div
                style={{
                  width,
                  height,
                  backgroundColor: '#e0e0e0',
                  maskMode: 'match-source',

                  maskImage: `url(${imgURL}/contain=${width}x${height}/quality=100)`,
                  maskRepeat: 'no-repeat',
                  maskSize: `${width}px ${height}px`,

                  WebkitMaskImage: `url(${imgURL}/contain=${width}x${height}/quality=100)`,
                  WebkitMaskRepeat: 'no-repeat',
                  WebkitMaskSize: `${width}px ${height}px`,
                }}
              />
              <div className="k-glm-prize-label-container">
                {renderTokens(
                  lotteryConfig,
                  draw,
                  tokenImgWidth,
                  width,
                  height
                )}
              </div>
            </Scratch>
            {showScratchText && (
              <div className="k-glm-scratch-text">
                <span>{tt('scratch_here')}</span>
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default MonthlyLotteryGame
