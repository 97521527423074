import Config from '../../model/config'

export const SET_CONFIG = 'SET_CONFIG'

export const setConfig = (config: Config) => {
  return {
    type: SET_CONFIG,
    config,
  }
}
