import Modal from 'react-bootstrap/Modal'
import ProfessionalContactForm from '../ProfessionalContactForm'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { tt } from '../../i18n'

interface Props {
  show: boolean
  onHide: () => void
  isIOS: boolean
  displayProfessionalContactForm?: boolean
  displayIndividualContactForm?: boolean
  fp?: string | null
}

export const CONTACT_INDIV = 'individual'
export const CONTACT_PRO = 'pros'

const MarketingModal: FunctionComponent<Props> = (props: Props) => {
  const [displayProfessionalContactForm, setDisplayProfessionalContactForm] =
    useState(false)
  const [displayIndividualContactForm, setDisplayIndividualContactForm] =
    useState(false)

  useEffect(() => {
    if (props.show) {
      setDisplayProfessionalContactForm(
        props.displayProfessionalContactForm || false
      )
      setDisplayIndividualContactForm(
        props.displayIndividualContactForm || false
      )
    }
  }, [
    props.show,
    props.displayProfessionalContactForm,
    props.displayIndividualContactForm,
  ])

  return (
    <Modal show={props.show} onHide={props.onHide} centered={true} size="lg">
      <Modal.Body>
        <div className="w-modal">
          <i className="fas fa-times modal-close" onClick={props.onHide} />

          {displayProfessionalContactForm && (
            <>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <h2 className="h2">{tt('contact_pro_title')}</h2>
                </div>
              </div>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <p>{tt('contact_pro_text')}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <ProfessionalContactForm
                    source="footer-contact"
                    onFinished={props.onHide}
                    isIOS={props.isIOS}
                    contactType={CONTACT_PRO}
                    fp={props.fp}
                  />
                </div>
              </div>
            </>
          )}

          {displayIndividualContactForm && (
            <>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <h2 className="h2">{tt('contact_indivs')}</h2>
                </div>
              </div>
              <div className="row w-margin-bottom-half">
                <div className="col-12">
                  <p>{tt('contact_indivs_1')}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <ProfessionalContactForm
                    source="footer-contact"
                    onFinished={props.onHide}
                    isIOS={props.isIOS}
                    contactType={CONTACT_INDIV}
                    fp={props.fp}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default MarketingModal
